import { Box } from '@mui/material';
import { Parents } from '../Components/ParentComponents/MyFamilyComponents/Parents';
import { ApprenticesAndTribes } from '../Components/ParentComponents/MyFamilyComponents/ApprenticesAndTribes';


export const MyFamily = ({ household, familyTribes, setParentTab, setAppBarTitle }) => {
    return (
        <Box height="100%">
            <Parents isFromTribe={false} setParentTab={setParentTab} />
            <ApprenticesAndTribes household={household} familyTribes={familyTribes} setAppBarTitle={setAppBarTitle} />
        </Box >
    );
}