import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';

import { HBox, VBox } from 'assets/SharedComponents';
import useGlobal from 'store/store';
import { TextField, Button, CircularProgress, Box, Typography } from '@mui/material';

import useApi from 'hooks/useApi';
import { updateUser, sendParentMail } from 'api/api'
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSnackbar } from 'notistack';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from 'raviger';

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    buttonGreen: {
        color: '#ffffff !important ',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important',
        width: '12rem'
    },
    pageStyle: {
        maxWidth: '30rem !important',
        paddingBottom: '6rem !important',
        margin: 'auto !important',
        '&.MuiCardContent-root': {
            padding: '0px !important'
        }
    }, padding1: {
        padding: "1rem"
    },
    alignItemsBaseline: {
        alignItems: "baseline"
    },
    marginBottom2: {
        marginBottom: "2rem"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    }

}));

export const AddSecondParent = ({ setIsUpdated, isUpdated, fromTribe = false, family = {}, setFamilyUpdated, familyUpdated }) => {
    const classes = useStyles();
    const updateUserData = useApi(updateUser);
    const [currentFamily, setCurrentFamily] = useState({});
    const [household] = useGlobal((state) => state.household);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef();
    const inputRef1 = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (fromTribe) {
            setCurrentFamily(family);
        }
        else {
            setCurrentFamily(household);
        }
    }, [])

    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectName')),
        lastName: yup
            .string(t("lastName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectLName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectLName')),
        id: yup
            .string(t("id"))
            .required(t("mandatoryField"))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^[0-9]*$/),
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        phoneNumber: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .required(t("mandatoryField"))
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            id: currentFamily.Parent2_ID ? currentFamily.Parent2_ID : "",
            email: currentFamily.Parent2_Mail ? currentFamily.Parent2_Mail : "",
            phoneNumber: ""

        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values.email.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG })
                return
            }
            const data = {
                "Source": fromTribe ? "ASH" : "APH",
                "CardCode": currentFamily.CardCode,
                "Parent2_FirstName": values.firstName,
                "Parent2_LastName": values.lastName,
                "Parent2_ID": values.id,
                "Parent2_Mail": values.email,
                "Parent2_Tel1": values.phoneNumber,
                "Parent2_LiveDiffAddress": currentFamily.Parent2_LiveDiffAddress,
                "Parent1_LiveDiffAddress": currentFamily.Parent1_LiveDiffAddress,
                "Parent2_City": currentFamily.Parent2_City,
                "Parent2_Street": currentFamily.Parent2_Street,
                "Parent2_MaritalStatus": currentFamily.Parent2_MaritalStatus,
                "Parent1_ID": currentFamily.Parent1_ID,
                "Parent1_FirstName": currentFamily.Parent1_FirstName,
                "Parent1_LastName": currentFamily.Parent1_LastName,
                "Parent1_MaritalStatus": currentFamily.Parent1_MaritalStatus,
                "Parent1_Profession": currentFamily.Parent1_Profession,
                "Parent1_Mail": currentFamily.Parent1_Mail,
                "Parent1_PlaceOfBirth": currentFamily.Parent1_PlaceOfBirth,
                "Parent1_EmigrationYear": currentFamily.Parent1_EmigrationYear,
                "Parent1_PastScout": currentFamily.Parent1_PastScout,
                "Parent1_IsVolunteered": currentFamily.Parent1_IsVolunteered,
                "Parent1_ID_Type": currentFamily.Parent1_ID_Type,
                "Parent1_MedicalQalifications": currentFamily.Parent1_MedicalQalifications || [],
                "Parent2_Profession": currentFamily.Parent2_Profession,
                "Parent2_PlaceOfBirth": currentFamily.Parent2_PlaceOfBirth,
                "Parent2_EmigrationYear": currentFamily.Parent2_EmigrationYear,
                "Parent2_PastScout": currentFamily.Parent2_PastScout,
                "Parent2_IsVolunteered": currentFamily.Parent2_IsVolunteered,
                "Parent2_ID_Type": "3",
                "Parent2_MedicalQalifications": currentFamily.Parent2_MedicalQalifications || [],
                "Parent1_City": currentFamily.Parent1_City,
                "Parent1_Street": currentFamily.Parent1_Street,
                "Parent1_Country": currentFamily.Parent1_Country,
                "Parent1_StreetNo": currentFamily.Parent1_StreetNo,
                "Parent1_ZipCode": currentFamily.Parent1_ZipCode,
                "Parent2_Country": currentFamily.Parent2_Country,
                "Parent2_StreetNo": currentFamily.Parent2_StreetNo,
                "Parent2_ZipCode": currentFamily.Parent2_ZipCode,
                "Parent1_Tel1": currentFamily.Parent1_Tel1,
                "Parent1_Tel1Type": currentFamily.Parent1_Tel1Type,
                "Parent1_Tel2": currentFamily.Parent1_Tel2,
                "Parent1_Tel2Type": currentFamily.Parent1_Tel2Type,
                "Parent1_ApprInfo": currentFamily.Parent1_ApprInfo,
                "Parent2_Tel1Type": currentFamily.Parent2_Tel1Type,
                "Parent2_Tel2": currentFamily.Parent2_Tel2,
                "Parent2_Tel2Type": currentFamily.Parent2_Tel2Type,
                "Parent1_Language": currentFamily.Parent1_Language,
                "Parent2_Language": currentFamily.Parent2_Language,
                "Parent2_ApprInfo": currentFamily.Parent2_ApprInfo,
            }
            let updateResult = await updateUserData.requestPromise(data);
            if (updateResult.Status) {
                const data = {
                    emailAddress: values.email,
                    link: `${window.location.origin}/register/fparent`,
                };
                sendParentMail(data);
                if (fromTribe) {
                    setFamilyUpdated(familyUpdated + 1);
                    navigate(`/tribeMyFamily/${currentFamily.CardCode}`);
                }
                else {
                    setIsUpdated(isUpdated + 1);
                    navigate('/MyFamily');
                }
                enqueueSnackbar(`${t('parentDetails')} ${values.firstName} ${t("updateSuccssefuly")} `, { className: classes.orangeBG });
            }
            else {
                let sMsg;
                try {
                    sMsg = updateResult.ErrorMessage.split("Message: ")[1];
                } catch (error) {
                    sMsg = updateResult.ErrorMessage;
                }
                enqueueSnackbar(`${sMsg} SAP error`, { className: classes.redBG });
            }
        }
    })

    return (
        <VBox className={`${classes.padding1} ${classes.pageStyle} `} justifyContent="center" alignItems='center'>
            <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'1.5rem'} paddingTop={'1.5rem'} alignItems={'Center'}>
                <Typography><b>{t("recommended")}</b>{t("toaddParent2")}</Typography>
                <Typography> {t("inOrderToAdd")}</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit} >
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <TextField width={'50%'}
                        id="firstName"
                        name="firstName"
                        label={t("secondParentFN")}
                        className={`${classes.width50} `}
                        variant="outlined"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <TextField width={'50%'}
                        id="lastName"
                        name="lastName"
                        label={t("secondParentLN")}
                        className={classes.width50}
                        variant="outlined"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <TextField width={'50%'}
                        type="number"
                        id="id"
                        name="id"
                        label={t("secondParentId")}
                        className={`${classes.width50} ${classes.RemoveTextFieldNumberArrow}`}
                        variant="outlined"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        InputProps={{
                            inputRef: inputRef,
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            }
                        }}
                        onWheel={(e) => {
                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                inputRef.current.blur();
                            }

                        }}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    <TextField width={'50%'}
                        type="number"
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t("phoneNumber")}
                        className={`${classes.width50} ${classes.RemoveTextFieldNumberArrow} `}
                        variant="outlined"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        InputProps={{
                            inputRef: inputRef1,
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            }
                        }}
                        onWheel={(e) => {
                            if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                inputRef1.current.blur();
                            }

                        }}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    <TextField width={'50%'}
                        id="email"
                        name="email"
                        label={t("email")}
                        className={`${classes.width50} `}
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        InputProps={{
                            inputProps: {
                                inputMode: 'url'
                            }

                        }}
                    />
                </HBox>
                <HBox justifyContent="center">
                    {!updateUserData.loading ? <><Button className={classes.buttonGreen} type="submit"  >
                        {t("addParent")}<ArrowBackIosNewOutlinedIcon />
                    </Button> </> :
                        <CircularProgress color="primary" />
                    }
                </HBox>

            </form >
        </VBox >)
}