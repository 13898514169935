import { useTranslation } from "react-i18next";
import * as Styled from "./StyledComponents";
import cancelReg from '../../assets/cancelReg.svg'
import regAllredyPayed from '../../assets/regAllredyPayed.svg'
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const TopCancelReg = ({ closeMsgBox }) => {
    return (
        <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            <IconButton onClick={closeMsgBox}>
                <CloseIcon />
            </IconButton>
        </Box >
    )
}
export const ContactCancelReg = ({ tribe = false }) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" flexDirection="column" alignItems="center" >
            <img
                alt={"..."}
                width="200px"
                height="120px"
                src={cancelReg}
            />
            <Typography sx={{ color: '#3a7735', fontSize: "1.1rem", textAlign: "center" }}>
                {t("cancelReqUnpaid1")}
            </Typography>
            <Typography sx={{ color: '#3a7735', fontSize: "1.1rem", fontWeight: "bold", textAlign: "center" }}>
                {t("cancelReqSuccess")}
            </Typography>
            <Typography sx={{ color: '#3a7735', fontSize: "0.875rem", marginTop: "1rem", textAlign: "center" }}>
                {t("cancelReqUnpaid2")}
            </Typography >
            <Typography sx={{ color: '#3a7735', fontSize: "0.875rem", textAlign: "center" }}>
                {t("cancelReqUnpaid3")}
            </Typography>
            {tribe && <Typography sx={{ color: '#3a7735', fontSize: "0.875rem", textAlign: "center" }}>
                {t("cancelReqUnpaid4")}
            </Typography>}
        </Box>
    )
}
export const BottomCancelReg = ({ closeMsgBox }) => {
    const { t } = useTranslation();
    return (
        <Styled.RoundedButton variant="contained" onClick={closeMsgBox}>
            {t("understandBackToActivities")}
        </Styled.RoundedButton>
    )
}
export const ContactCantCancelReg = () => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" >
            <img
                alt={"..."}
                width="200px"
                height="120px"
                src={regAllredyPayed}
            />
            <Typography sx={{ color: '#3a7735', fontSize: "1.1rem", fontWeight: "bold", textAlign: "center" }}>
                {t("attentionPaidReq")}
            </Typography>
            <Typography sx={{ color: '#3a7735', fontSize: "1.1rem", textAlign: "center" }}>
                {t("attentionPaidReq1")}
            </Typography>
            <Typography sx={{ color: '#3a7735', fontSize: "0.875rem", marginTop: "1rem", textAlign: "center" }}>
                {t("contactSupportForRefund")}
            </Typography>

        </Box>
    )
}
export const BottomCantCancelReg = ({ closeMsgBox, navtoContactForm }) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent='space-between' sx={{ marginTop: "0.875rem" }}>
            <Styled.RoundedButton variant="contained" onClick={closeMsgBox}>
                {t("understandNoCancel")}
            </Styled.RoundedButton>
            <Styled.RoundedButton variant="outlined" onClick={navtoContactForm} >
                {t("gotoContactForm")}
            </Styled.RoundedButton>
        </Box >
    )
}

