import { useState, useRef } from 'react'
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'raviger';
import useApi from 'hooks/useApi';
import useGlobal from 'store/store';
//libs
import * as yup from 'yup';
//mui
import { makeStyles } from '@mui/styles';
import { TextField, Button, Autocomplete, Box, IconButton, CircularProgress, Typography, ButtonGroup, Tooltip } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import EditIcon from '@mui/icons-material/Edit';
//components
import { HBox, VBox } from 'assets/SharedComponents';
import { getAuth } from "firebase/auth";
import AutocompleteGooglePlaces from 'Components/Common/AutocompleteGooglePlaces';
//api
import { updateUser, updateParentDetails, sendResetPasswordEmail, checkUserName } from "api/api";

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    alignItemsBaseline: {
        alignItems: "baseline"
    },
    textFieldStart: {
        textAlign: "start"
    },
    IdentificationBtn: {
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",

        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    selectedBtn: {
        textTransform: "capitalize !important",
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "#ffffff !important"
    },
    IdnBtn: {
        textTransform: "capitalize !important",
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important"
    },
    width50: {
        width: "50%",
        overflowWrap: 'anywhere'
    },
    padding1: {
        padding: "1rem"
    },
    marginBottom1: {
        marginBottom: "0.5rem !important"
    },
    marginBottom2: {
        marginBottom: "0.8rem"
    },
    buttonGreen: {
        '& .MuiButton-label': {
            color: 'white !important',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important',
        marginBottom: '2rem !important',
        color: 'white !important',
        textTransform: "capitalize !important"
    },
    pageStyle: {
        maxWidth: '30rem',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px'
        }
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    TextfieldILabelStyle: {
        width: "50%"
    },
    '& .MuiAutocomplete-root.MuiAutocomplete-inputRoot.MuiAutocomplete-input': {
        width: "50% !important"
    },
    marginEnd5p: {
        marginEnd: "5% !important"
    },
    width70: {
        width: "70%"
    },
    notchedOutline: {
        borderColor: "transparent !important"
    }
}));

export const FirstParentSetting = ({ household, setIsUpdated, isUpdated, setFamilyUpdated, familyUpdated }) => {
    const [user] = useGlobal(state => state.user);
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const maritalStatuss = [
        { text: t("divorced"), key: 'di' },
        { text: t("married"), key: 'ma' },
        { text: t("sharedParentship"), key: 'sh' },
        { text: t("single"), key: 'sg' },
        { text: t("widdowed"), key: 'wi' },
        { text: t("guardian"), key: 'gu' },
        { text: t("Knowninpublic"), key: 'na' },
        { text: t("singleParent"), key: 'si' },
        { text: t("prod"), key: 'se' }
    ]
    const [topFieldsEdit, setTopFieldsEdit] = useState(true);
    const [phoneFieldEdit, setPhoneFieldEdit] = useState(true);
    const [emailFieldEdit, setEmailFieldEdit] = useState(true);
    const [addressField, setAddressField] = useState(true);
    const [passwordField, setPasswordField] = useState(false);
    const [streetOpen, setStreetOpen] = useState(false);
    const updateUserData = useApi(updateUser);
    const users = useApi(checkUserName);
    const parent1Material = maritalStatuss.find(obj => obj.key === household.Parent1_MaritalStatus);
    const { enqueueSnackbar } = useSnackbar();
    const auth = getAuth();
    const inputRef = useRef();
    const inputRef1 = useRef();

    const valueChanged = (value) => { formik.setValues({ ...formik.values, maritalStatus: value.key }); }

    const addressChanged = (oAddress) => {
        formik.setValues({ ...formik.values, street: oAddress.StreetName, streetNo: oAddress.StreetNum, city: oAddress.CityName });
        setStreetOpen(false);
    }
    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t('mandatoryField'))
            .min(2, t("insertCorrectName"))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t("insertCorrectName")),
        lastName: yup
            .string(t("lastName"))
            .required(t('mandatoryField'))
            .min(2, t("insertCorrectLName"))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t("insertCorrectLName")),
        id: yup
            .string(t("id"))
            .required(t('mandatoryField'))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^0*[0-9]*$/, t('enterValidValue')),
        maritalStatus: yup
            .string(t("maritalStatus"))
            .required(t('mandatoryField'))
            .min(2, t('enterValidValue')),
        emailAddress: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t('mandatoryField')),
        phoneNumber: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .matches(/^[0-9]*$/, t("insertValidPhone"))
            .required(t('mandatoryField')),
        streetNo: yup
            .string(t("streetNumber"))
            .nullable()
            .matches(/^\d+(\/\d+)*$/, t("streetNumber"))
    });

    const formik = useFormik({
        initialValues: {
            firstName: household.Parent1_FirstName,
            lastName: household.Parent1_LastName,
            idType: household.Parent1_ID_Type || '3',
            id: household.Parent1_ID,
            maritalStatus: parent1Material?.key || '',
            street: household.Parent1_Street,
            streetNo: household.Parent1_StreetNo,
            city: household.Parent1_City,
            emailAddress: household.Parent1_Mail,
            phoneNumber: household.Parent1_Tel1 && household.Parent1_Tel1.startsWith("+972")
                ? `0${household.Parent1_Tel1.slice(-9)}` : household.Parent1_Tel1.slice(-10)
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.emailAddress.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            setTopFieldsEdit(true);
            try {
                const idExsits = await users.requestPromise({ username: household.Parent1_ID });
                if (idExsits.userExists) {
                    const res = await updateParentDetails({
                        id: auth.currentUser?.uid || user.firebaseId,
                        data: {
                            userName: values.id,
                            usernameType: values.idType,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            maritalStatus: values.maritalStatus,
                            email: values.emailAddress,
                            phoneNumber: `+972${values.phoneNumber}`,
                            city: values.city,
                            street: values.street,
                            streetNo: values.streetNo,
                            fromTribe: user.role === "tribe" ? true : false,
                            oldEmailAddress: user.role === "tribe" ? household.Parent1_Mail : "false"
                        }
                    });
                }
                const data = {
                    "Source": user.role === "tribe" ? "ASH" : "APH",
                    "CardCode": household.CardCode,
                    "Parent1_ID": values.id.toString(),
                    "Parent1_FirstName": values.firstName,
                    "Parent1_LastName": values.lastName,
                    "Parent1_MaritalStatus": values.maritalStatus,
                    "Parent1_City": values.city,
                    "Parent1_Street": values.street,
                    "Parent1_StreetNo": values.streetNo,
                    "Parent1_Mail": values.emailAddress,
                    "Parent1_Tel1": values.phoneNumber,
                    "Parent1_ID_Type": values.idType,
                    "Parent1_ApprInfo": 'N',
                    "Parent1_Profession": household.Parent1_Profession,
                    "Parent1_PlaceOfBirth": household.Parent1_PlaceOfBirth,
                    "Parent1_LiveDiffAddress": household.Parent1_LiveDiffAddress,
                    "Parent2_LiveDiffAddress": household.Parent2_LiveDiffAddress,
                    "Parent1_EmigrationYear": household.Parent1_EmigrationYear,
                    "Parent1_PastScout": household.Parent1_PastScout,
                    "Parent1_IsVolunteered": household.Parent1_IsVolunteered,
                    "Parent1_MedicalQalifications": household.Parent1_MedicalQalifications || [],
                    "LiveDifAddress": household.LiveDifAddress,
                    "Parent2_ID": household.Parent2_ID ? household.Parent2_ID : "",
                    "Parent2_ID_Type": household.Parent2_ID_Type ? household.Parent2_ID_Type : "3",
                    "Parent2_FirstName": household.Parent2_FirstName,
                    "Parent2_LastName": household.Parent2_LastName,
                    "Parent2_Profession": household.Parent2_Profession,
                    "Parent2_Mail": household.Parent2_Mail,
                    "Parent2_PlaceOfBirth": household.Parent2_PlaceOfBirth,
                    "Parent2_EmigrationYear": household.Parent2_EmigrationYear,
                    "Parent2_PastScout": household.Parent2_PastScout,
                    "Parent2_IsVolunteered": household.Parent2_IsVolunteered,
                    "Parent2_MedicalQalifications": household.Parent2_MedicalQalifications || [],
                    "Parent1_Country": household.Parent1_Country,
                    "Parent1_ZipCode": household.Parent1_ZipCode,
                    "Parent2_City": household.Parent2_City,
                    "Parent2_Street": household.Parent2_Street,
                    "Parent2_Country": household.Parent2_Country,
                    "Parent2_StreetNo": household.Parent2_StreetNo,
                    "Parent2_ZipCode": household.Parent2_ZipCode,
                    "Parent1_Tel1Type": household.Parent1_Tel1Type,
                    "Parent1_Tel2": household.Parent1_Tel2,
                    "Parent1_Tel2Type": household.Parent1_Tel2Type,
                    "Parent2_Tel1": household.Parent2_Tel1,
                    "Parent2_Tel1Type": household.Parent2_Tel1Type,
                    "Parent2_Tel2": household.Parent2_Tel2,
                    "Parent2_Tel2Type": household.Parent2_Tel2Type,
                    "Parent1_Language": household.Parent1_Language,
                    "Parent2_Language": household.Parent2_Language,
                    "Parent2_ApprInfo": household.Parent2_ApprInfo,
                    "Parent2_MaritalStatus": household.Parent2_MaritalStatus
                }

                const updateResult = await updateUserData.requestPromise(data);
                if (updateResult.Status) {
                    if (user.role === "tribe") {
                        setFamilyUpdated(familyUpdated + 1);
                        enqueueSnackbar(`${t('parentDetails')} ${household.Parent1_FirstName} ${t("updateSuccssefuly")}`, { className: classes.orangeBG });
                        navigate(`/tribeMyFamily/${household.CardCode}`);
                        return
                    } else {
                        setIsUpdated(isUpdated + 1);
                    }
                    enqueueSnackbar(`${t('parentDetails')} ${household.Parent1_FirstName} ${t("updateSuccssefuly")}`, { className: classes.orangeBG });
                }
                else {
                    let sMsg;
                    try {
                        sMsg = updateResult.ErrorMessage.split("Message: ")[1];
                        enqueueSnackbar(`${sMsg} SAP error`, { className: classes.redBG });
                    } catch (error) {
                        sMsg = updateResult.ErrorMessage;
                        enqueueSnackbar(`${sMsg} SAP error`, { className: classes.redBG });
                    }
                }
            }
            catch (error) {
                console.log(error);
                enqueueSnackbar(`${t("updateFBError")}`, { className: classes.redBG });
            }
        }
    });

    const resetPassword = async () => {
        const email = formik.values.emailAddress;
        try {
            const data = { email };
            await sendResetPasswordEmail(data);
            enqueueSnackbar(`${t("resetPassMail")} ${email}`, { className: classes.orangeBG });
        } catch (error) {
            if (error?.message.includes("There is no user record")) {
                enqueueSnackbar(t("resetPassMailFaild"), { className: classes.redBG });
            }
            else {
                enqueueSnackbar(error?.message, { className: classes.redBG });
            }
        }
    }

    const openAddress = () => {
        setAddressField(false);
        formik.setValues({ ...formik.values, streetNo: "" });
    }

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }

    return (<VBox className={`${classes.padding1} ${classes.pageStyle}`} sx={{ padding: { xs: "3.5rem", md: "1rem" } }} justifyContent="center">
        <form onSubmit={formik.handleSubmit}>
            <HBox justifyContent='space-between'>
                <Typography fontWeight={'600'}>{`${t("parent1")} : ${household.Parent1_FirstName} ${household.Parent1_LastName}`}</Typography>
                <IconButton onClick={e => setTopFieldsEdit(!topFieldsEdit)} color="inherit" aria-label="menu" >
                    <EditIcon sx={{ color: "#3a7735" }} />
                </IconButton>
            </HBox>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={` ${classes.textFieldStart}`}
                    fontWeight={'600'}>{t("firstName")}</Typography>
                <TextField width={'50%'}
                    id="firstName"
                    name="firstName"
                    className={`${classes.width50} `}
                    variant="outlined"
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={topFieldsEdit}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    InputProps={{
                        classes: {
                            notchedOutline: topFieldsEdit && classes.notchedOutline
                        }
                    }}
                />
            </HBox>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={` ${classes.textFieldStart}`}
                    fontWeight={'600'}>{t("lastName")}</Typography>
                <TextField width={'50%'}
                    id="lastName"
                    name="lastName"
                    className={classes.width50}
                    variant="outlined"
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={topFieldsEdit}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    InputProps={{
                        classes: {
                            notchedOutline: topFieldsEdit && classes.notchedOutline
                        }
                    }}
                />
            </HBox>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                <ButtonGroup disabled={topFieldsEdit} color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`} >
                    <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}> {t("israelId")}</Button>
                    <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{t("passport")} </Button>
                    <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{t("journeyId")} </Button>
                </ButtonGroup>
            </HBox>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={` ${classes.textFieldStart}`}
                    fontWeight={'600'}>{t("id")}</Typography>
                <TextField width={'50%'}
                    type='text'
                    id="id"
                    name="id"
                    className={`${classes.width50} ${classes.RemoveTextFieldNumberArrow}`}
                    variant="outlined"
                    value={formik.values.id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={topFieldsEdit}
                    error={formik.touched.id && Boolean(formik.errors.id)}
                    helperText={formik.touched.id && formik.errors.id}
                    InputProps={{
                        inputRef: inputRef,
                        inputProps: {
                            inputMode: 'tel',
                            pattern: "[0-9]*"
                        },
                        classes: {
                            notchedOutline: topFieldsEdit && classes.notchedOutline
                        }
                    }}
                    onWheel={(e) => {
                        if (inputRef && inputRef.current && inputRef.current.blur) {
                            inputRef.current.blur();
                        }
                    }}
                />

            </HBox>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={`${classes.textFieldStart}`}
                    fontWeight={'600'}>{t("maritalStatus")}</Typography>
                <Autocomplete
                    id="maritalStatus"
                    name="maritalStatus"
                    style={{ width: "50%" }}
                    disabled={topFieldsEdit}
                    disableClearable={true}
                    defaultValue={parent1Material}
                    className={`${classes.marginBottom1}`}
                    options={maritalStatuss}
                    onBlur={formik.handleBlur}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    onChange={(e, value) => valueChanged(value)}
                    getOptionLabel={(option) => `${option.text}`}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.key}  >
                            {option.text}
                        </Box>
                    )}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                            helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    notchedOutline: topFieldsEdit && classes.notchedOutline
                                }
                            }} />}
                />
            </HBox>
            <HBox width={'100%'} className={`${classes.marginBottom2}`} justifyContent='space-between' alignItems={"center"}>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={` ${classes.textFieldStart}`}
                    fontWeight={'600'}> {t("address")} </Typography>
                {addressField && <Typography
                    className={`${classes.width50} ${classes.textFieldStart} ${classes.alignItemsBaseline} `}
                    color={"#00000061"}>{`${household.Parent1_Street} ${household.Parent1_StreetNo ? household.Parent1_StreetNo : ""}, ${household.Parent1_City}`} </Typography>}
                {!addressField &&
                    <HBox width={'100%'} className={`${classes.marginBottom1}`} alignItems="center">
                        <AutocompleteGooglePlaces
                            id="address"
                            name="address"
                            onPlaceSelected={(placeDetails) => addressChanged(placeDetails.addressDetails)}
                            disabled={addressField}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                            noOptionsText={t("addressNoOption")}
                            onBlur={formik.handleBlur}
                            inputLabel={t('cityStreetAndHouseNum')}
                            inputPlaceholder={t("addressSearchPlaceholder")}
                            inputError={formik.touched.address && Boolean(formik.errors.streetNo)}
                            inputHelperText={formik.touched.address && formik.errors.streetNo}
                        />
                        {/* <Autocomplete
                            disableClearable={true}
                            disabled={addressField}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.marginEnd5p} ${classes.width70}`}
                            open={streetValue.length >= 3 && streetOpen}
                            id="street"
                            name="street"
                            inputValue={streetValue}
                            onInputChange={(event, newInputValue) => {
                                setStreetValue(newInputValue);
                                setStreetOpen(true)
                            }}
                            noOptionsText={t("addressNoOption")}
                            onChange={(e, value) => streetValueChanged(value)}
                            onBlur={formik.handleBlur}
                            options={streetsList}
                            getOptionLabel={(option) => `${option.StreetName} ${option.CityName}`}
                            isOptionEqualToValue={(option, value) => option.Code === value.Code}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.Code} style={{ fontSize: "0.8rem" }}>
                                    {option.StreetName}, <div style={{ fontWeight: "bold" }}>{option.CityName}</div>
                                </Box>
                            )}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label={t('cityAndStreet')}
                                    placeholder={t("searchPlaceHolder1")}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {!streetsList.length && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>)
                                    }} />}
                        />
                        <TextField
                            label={t('number')}
                            sx={{ width: "25%" }}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                            id="streetNo"
                            name="streetNo"
                            value={formik.values.streetNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.streetNo && Boolean(formik.errors.streetNo)}
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    inputMode: 'tel',
                                    pattern: "[0-9]*"
                                }
                            }}
                        /> */}
                    </HBox>
                }
                {addressField && <IconButton onClick={e => openAddress()} color="inherit" aria-label="menu" >
                    <EditIcon sx={{ color: "#3a7735" }} />
                </IconButton>}
            </HBox>
            <hr></hr>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                <Typography
                    sx={{ width: { xs: "30%", md: "50%" } }}
                    className={`${classes.textFieldStart}`}
                    fontWeight={'600'}>{t("phoneNumber1")}</Typography>
                <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    variant="outlined"
                    disabled={phoneFieldEdit}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    type="tel"
                    InputProps={{
                        inputRef: inputRef1,
                        inputProps: {
                            inputMode: 'tel',
                            pattern: "[0-9]*"
                        },
                        classes: {
                            notchedOutline: phoneFieldEdit && classes.notchedOutline
                        }
                    }}
                    onWheel={(e) => {
                        if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                            inputRef1.current.blur();
                        }
                    }}
                />
                <IconButton onClick={e => setPhoneFieldEdit(false)} color="inherit" aria-label="menu" >
                    <EditIcon sx={{ color: "#3a7735" }} />
                </IconButton>
            </HBox>
            <hr></hr>
            <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                <Box width={'100%'} display='flex' flexDirection={{ xs: "column", md: "row" }} alignItems='flex-start' className={`${classes.alignItemsBaseline} `} justifyContent='space-between'>
                    <Typography
                        sx={{ width: { md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}> {t("emaillong")}</Typography>
                    <TextField width={'50%'}
                        id="emailAddress"
                        name="emailAddress"
                        variant="outlined"
                        disabled={emailFieldEdit}
                        onBlur={formik.handleBlur}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                        InputProps={{
                            inputProps: {
                                inputMode: 'url'
                            },
                            classes: {
                                notchedOutline: emailFieldEdit && classes.notchedOutline
                            }
                        }}
                    />
                    <IconButton onClick={e => setEmailFieldEdit(false)} color="inherit" aria-label="menu" sx={{ alignSelf: "flex-end" }}>
                        <EditIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </Box>
            </HBox>
            {user.email === household.Parent1_Mail || user.role === "tribe" ?
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}> {t("password")} </Typography>
                    <TextField width={'50%'}
                        className={`${classes.width50}`}
                        disabled={true}
                        value={"********"}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <Tooltip title={t("mailToResetPass")}>
                        <IconButton onClick={e => setPasswordField(true)} color="inherit" aria-label="menu" >
                            <EditIcon sx={{ color: "#3a7735" }} />
                        </IconButton>
                    </Tooltip>
                </HBox> : <></>}
            {passwordField && <Typography style={{ fontSize: "0.9rem", textDecoration: "underLine", cursor: "pointer", marginBottom: "1rem", color: "#3a7735" }} onClick={e => resetPassword()}>{t("mailToResetPass")}</Typography>}
            {(!topFieldsEdit || !emailFieldEdit || !phoneFieldEdit || !addressField) && <Button className={`${classes.buttonGreen} ${classes.marginBottom2}`} type="submit" >
                {t("update")} <ArrowBackIosNewOutlinedIcon />
            </Button>}
            {updateUserData.loading && <CircularProgress sx={{ marginTop: "2rem" }} />}
        </form>
    </VBox>)
}