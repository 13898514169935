import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import TransactionLayout from 'layouts/TransactionLayout';
import useApi from 'hooks/useApi';
import { CGinquireTransaction, loggerService } from '../../api/api'
import { xmlInquireTransactionRequest } from "../../data/CGxmlRequests";
import useGlobal from 'store/store';

const useStyles = makeStyles(() => ({
    cardBox: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem'
    },
    splitCard: {
        height: '7rem',
        width: '20rem',
        justifyContent: 'center !important',
        marginBottom: '1rem',
        borderRadius: '50px',
        alignItems: 'center',
    },
    cardTitleAndIcon: {
        alignItems: 'center'
    },
    cardTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginRight: '1.3rem'
    },
    text: {
        color: 'black',
        fontSize: '1rem',
    }
}));

const ErrorPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const logger = useApi(loggerService);
    const inquireTransactionReq = useApi(CGinquireTransaction);
    const [user] = useGlobal((state) => state.user);
    useEffect(() => {
        inquireTransaction();
    }, [])
    const inquireTransaction = async () => {
        let int_in = xmlInquireTransactionRequest({
            txId: window.parent.txid,
            terminalNumber: window.parent.terminal,
            mid: window.parent.mid
        });
        try {
            let data = { int_in, products: window.parent.products, isDivorced: window.parent.isDivorced, invoices: window.parent.invoices, isTribeUser: user.role === 'tribe' ? true : false, isError: true };
            const response = await inquireTransactionReq.requestPromise(data);
            logger.requestPromise(response);
            window.parent.postMessage({
                error: true,
                paymentIframe: true,
                txId: window.parent.txid,
                terminalNumber: window.parent.terminal,
                mid: window.parent.mid
            }, "*")
        } catch (error) {
            console.log(error);
        }

    }
    return (
        <TransactionLayout bodyText={t("backToPaymentMethodSelection")}>
            <GppBadOutlinedIcon />
            <Typography className={classes.cardTitle}>{t("paymentError")}</Typography>
        </TransactionLayout>

    );
}

export default ErrorPage;