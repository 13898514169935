import { useState } from "react";
import { useNavigate, usePath } from 'raviger'
//mui
import { makeStyles } from '@mui/styles';
import { IconButton, Box } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//style
import * as Styled from "../Common/StyledComponents"

const useStyles = makeStyles(() => ({
    linkDesktop: {
        color: 'white !important',
        marginBottom: '3px !important',
        textDecorationLine: 'none !important',
        '&:hover': {
            cursor: 'pointer',
            marginBottom: '0 !important',
            borderBottom: '3px solid white'
        }
    },
    activeLink: {
        marginBottom: '0 !important',
        borderBottom: '3px solid white'
    },
    smallMarginEnd: {
        marginInlineEnd: '0.5rem !important'
    }
}));

const AppBarDropdown = ({ setAppBarTitle, links, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = usePath();
    const isActive = links.some((el) => location.includes(el.href));

    const goTo = (link, title) => {
        navigate(link)
        setAppBarTitle(title)
        setAnchorEl(null);
    }
    const arrowIcon = anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
    return (
        <Box display="flex" alignItems="center" className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive ? classes.activeLink : ''} `}>
            {title}
            <>
                <IconButton color="inherit"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={e => setAnchorEl(e.currentTarget)}>
                    {arrowIcon}
                </IconButton>

                <Styled.AppBarMenu
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    disableScrollLock={true}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={e => setAnchorEl(null)}
                    PaperProps={{
                        style: {
                            left: '50%',
                            transform: 'translateY(8%)',
                            maxHeight: '20rem',
                        }
                    }}
                    MenuListProps={{
                        style: {
                            padding: 0,
                        },
                    }}>
                    {links.map((link, index) => (
                        <Styled.AppBarMenuItem width="20rem" onClick={e => { goTo(link.href, link.title) }} key={`${index}`}>
                            {link.name}
                        </Styled.AppBarMenuItem>
                    ))}
                </Styled.AppBarMenu>
            </>
        </Box >
    )
}
export default AppBarDropdown;