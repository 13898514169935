import { useState } from 'react'
import { useRoutes, usePathParams } from "raviger";

import { RegisterBar } from '../Components/Register/RegisterBar';
import { VBox } from '../assets/SharedComponents';
import { useTranslation } from 'react-i18next';

import useGlobal from '../store/store.js';
import { useStyles } from '../assets/RegisterStyle.jsx'
import { registerRouteWithAuth, registerPublicRoute } from '../router/Routes.js';
import { RegisterContext } from 'context/registerContext';
import { RegisterStepper } from 'Components/Register/RegisterStepper';

export const Register = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [userObject, setUserObject] = useState({});
    const [updateParent, setUpdateParent] = useState({});
    const steps = [t("parentReg"), t("phoneVerification"), t("emailVerification"), t("addStudents")];

    const [isLoggedIn] = useGlobal(state => state.isLoggedIn)

    const contextObject = { userObject, updateParent, setUserObject, setUpdateParent };

    const routeResult = isLoggedIn === undefined ? null : isLoggedIn ? { ...registerRouteWithAuth, ...registerPublicRoute } : registerPublicRoute;

    const Routes = useRoutes(routeResult, { basePath: "/register" });
    const props = usePathParams('/register/mailver/:email');
    let currentPath = Routes?.props?.path

    currentPath = !!props ? currentPath.replace(`/${props.email}`, "") : Routes?.props?.path;
    const activeStep = activeSteps[currentPath];

    return (
        <VBox className={` ${classes.backgruond}`} >
            {window.self === window.top && <RegisterBar />}
            <RegisterStepper aSteps={steps} activeStep={activeStep} />
            <RegisterContext.Provider value={contextObject}>
                {Routes}
            </RegisterContext.Provider>
        </VBox >
    );
}

const activeSteps = {
    '/fparent': 1,
    '/smsver': 2,
    '/mailver': 3,
    '/addchild': 4
}
