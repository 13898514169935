import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, signInWithEmailAndPassword, sendSignInLinkToEmail } from "firebase/auth";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { sendResetPasswordEmail, checkMatchingParentAPI } from '../api/api';
import useFeathers from "./useFeathers";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const hookFeathers = useFeathers();

    const sendCode = ({ sPhoneNumber, handleSuccess, handleError }) => {

        const auth = getAuth();
        try {
            if (window.recaptchaVerifier && window.recaptchaWrapperRef) {
                window.recaptchaVerifier.clear()
                window.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
            window.recaptchaVerifier = new RecaptchaVerifier(
                'recaptcha-container',
                {
                    size: 'invisible', callback: function (response) {
                    }
                }
                , auth);
            const appVerifier = window.recaptchaVerifier;
            return signInWithPhoneNumber(auth, sPhoneNumber, appVerifier).then((confirmationResult) => {
                handleSuccess(confirmationResult);
            }).catch((error) => {
                handleError(error);
            });
        } catch (e) {

            console.error(e)
        }

    };
    const sendOTP = (sPhoneNumber) => {

        const auth = getAuth();
        try {
            if (window.recaptchaVerifier && window.recaptchaWrapperRef) {
                window.recaptchaVerifier.clear()
                window.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
            window.recaptchaVerifier = new RecaptchaVerifier(
                'recaptcha-container',
                {
                    size: 'invisible', callback: function (response) {
                    }
                }
                , auth);
            const appVerifier = window.recaptchaVerifier;
            return signInWithPhoneNumber(auth, sPhoneNumber, appVerifier)
        } catch (e) {
            console.error(e)
        }
    };

    const signIn = async (e, verificationCode, confirmationResult) => {
        return confirmationResult.confirm(verificationCode);
    };

    const loginWithEmailAndPassword = async (email, password) => {
        try {
            const auth = getAuth();
            const result = await signInWithEmailAndPassword(auth, email, password);
            return result;
        } catch (error) {
            console.log(error)
        }

    }

    const checkExistance = async (phonenumber, username) => {
        await hookFeathers.loginAnonymously();
        try {
            const matchingParent = await checkMatchingParentAPI({ phonenumber, username });
            return matchingParent;
        } catch (error) {
            throw error;
        } finally {
            await hookFeathers.logoutAnonymously();
        }
    }

    const SendPasswordReset = async (email) => {
        try {
            const data = { email };
            await sendResetPasswordEmail(data)
            enqueueSnackbar(`${t("resetPassMail")} ${email}`, { style: { backgroundColor: "#ff6645 !important" } });
        } catch (error) {
            enqueueSnackbar(error);
        }
    }
    const sendSignInEmail = async (id, email, phoneNumber) => {
        const auth = getAuth();
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `${window.location.origin}/fbUserAction?idForSignIn=${id}_${phoneNumber}`,
            validDurationSeconds: 120,
            // This must be true.
            handleCodeInApp: true,
        };
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                // ...
            });
    }
    const parseFirebaseError = (error) => {
        let msg = "";
        if (error?.code) { //firebase errors
            switch (error.code) {
                case "auth/invalid-verification-code":
                    msg = t("invalidVerificationCode")
                    break;
                case "auth/missing-verification-code":
                    msg = t("missingVerificationCode")
                    break;
                case "auth/code-expired":
                    msg = t("expiredVerificationCode")
                    break;
                case "auth/too-many-requests":
                    msg = t("tooManyPhoneRequests")
                    break;
                default:
                    break;
            }
        }

        if (!msg) {
            msg = error?.message || t("Error");
        }

        return msg;

    }

    return {
        sendCode,
        sendOTP,
        signIn,
        loginWithEmailAndPassword,
        checkExistance,
        SendPasswordReset,
        sendSignInEmail,
        parseFirebaseError
    };
};


