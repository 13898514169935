import { useState, useMemo, useEffect } from "react";
import debouce from "lodash.debounce";
// store
import useGlobal from "../../store/store"
// i18n
import { useTranslation } from "react-i18next";
// mui
import { Button, Box, InputAdornment, SwipeableDrawer, IconButton, Typography, AccordionSummary, AccordionDetails, Stack, Tab, LinearProgress, Tooltip } from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
// components
import { TribeAppBar } from "./TribeAppBar";
import { permanentInfo } from "../../data/permanentInfo";
import { MuiTabs } from 'pages/parentHistoryPages/sharedHistoryComponents/HistoryComponents';
// style
import * as Styled from "../Common/StyledComponents";
// api
import { searchInParent } from "../../api/api";
import useApi from "../../hooks/useApi";
import { useNavigate } from "raviger";

export const TribeTabBar = ({ tribe, setTribe, tribes, currentTab, setCurrentTab, allTribes }) => {
    const { t } = useTranslation();
    const [leaderName, setLeaderName] = useState("");

    useEffect(() => {
        setLeaderName(allTribes?.find(tr => tr.Code === tribe.UnitCode)?.LeaderName);
    }, [tribe]);

    const changeTribe = (tribe, index) => {
        setTribe(tribe);
        setCurrentTab(index);
    }

    const isLeader = tribes.length > 1; // user can effect on more then 1 tribe is a leader
    return (
        <Box display="flex" alignItems="center">
            {isLeader &&
                <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                    <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1.125rem" }, fontWeight: "bold", width: { xs: "30%", md: "8%" }, textAlign: "center" }}>{`${leaderName}`}</Typography>
                    <MuiTabs
                        value={currentTab}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tribes.map((oTribe, i) => (
                            <Tab label={`${t("tribe")} ${oTribe.UnitName}`} key={i} onClick={e => changeTribe(oTribe, i)} />
                        ))}
                    </MuiTabs>
                </Box >}
        </Box >
    )
}
export const TribeAccordion = ({ titleIcon, title, children, expended = true, first = false }) => {
    const [expand, setExpand] = useState(expended);
    const icon = expand ? < RemoveIcon /> : <AddIcon />;

    return (
        <Styled.StyledAccordion expanded={expand} defaultExpanded square >
            <AccordionSummary
                onClick={() => setExpand(!expand)}
                expandIcon={icon}>

                <Box display="flex" alignItems={'center'}>
                    {titleIcon}
                    <Typography fontWeight={'bold'} fontSize={{ sm: '1.5rem', xs: '1.2rem' }} margin="0 0.5rem" >{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '.5rem .5rem 1.5rem' }}>
                {children}
                {/* {first && <hr></hr>} */}
            </AccordionDetails>
        </Styled.StyledAccordion>
    )
}
export const TribeCartBadge = ({ length, setCartState }) => {
    return (
        <Styled.MyBadge onClick={e => setCartState(true)} color="secondary"
            showZero
            badgeContent={length}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}>
            <ShoppingCartOutlinedIcon fontSize="large" />
        </Styled.MyBadge>
    )
}
export const TribeNavBar = ({ tribe, tribes, setAddPupilToFamily, setAddNewFamily, setFamily, family }) => {
    const [searchState, setSearchState] = useState(false);
    const leader = tribes.length > 1;

    return (
        <TribeAppBar searchState={searchState} leader={leader} tribe={tribe} setFamily={setFamily} family={family}>
            {searchState ? <TribeSearch tribe={tribe} searchState={searchState} setSearchState={setSearchState} setAddPupilToFamily={setAddPupilToFamily} setAddNewFamily={setAddNewFamily} />
                : <IconButton size="small" onClick={e => setSearchState(!searchState)}>
                    <SearchIcon color="neutral" />
                </IconButton>}
        </TribeAppBar>)

}

export const TribeSearch = ({ tribe, searchState, setSearchState, setAddPupilToFamily, setAddNewFamily }) => {
    const { t } = useTranslation();
    const [searchResults, setSearchResults] = useState([]);
    const search = useApi(searchInParent);
    const [noMatchResults, setNoMatchResult] = useState(false);
    const [manyResults, setManyResults] = useState();
    const navigate = useNavigate();

    const handleChange = async (e) => {
        const toSearch = e.target.value.trim();
        if (!!toSearch) {
            const data = await search.requestPromise(tribe?.UnitCode, toSearch);
            if (data?.d?.results?.length <= 10) {
                setManyResults(false);
                setSearchResults(data.d.results);
                if (data?.d?.results.length === 0) {
                    setNoMatchResult(true);
                    setManyResults(false);
                }
                else {
                    setManyResults(false);
                    setNoMatchResult(false);
                }

            } else {
                setManyResults(true);
            }
        }
        else {
            setSearchResults([]);
            setNoMatchResult(false);
            setManyResults(false);
        }
    }

    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 700);
    }, [tribe]);
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, []);
    const handleAddFamily = () => {
        setSearchState(false);
        navigate('/tribe/tribeSearch');
        setAddNewFamily({ family: true });
    }

    return (
        <Box >
            <SwipeableDrawer PaperProps={{
                sx: {
                    maxHeight: '90%',
                    width: '100%',
                    boxShadow: 'none',
                }
            }}
                disableScrollLock={true} anchor="top" onClose={e => setSearchState(false)} onOpen={e => setSearchState(true)} dir="rtl" open={searchState}>
                <Box display='flex' flexDirection='column' width={'100%'} sx={{ backgroundColor: '#fff' }} >
                    <Styled.StyledTextF inputRef={input => input && input.focus()} variant="standard" color="success" onChange={debouncedResults} placeholder={t('searchPlaceHolder')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" color="success">
                                    <IconButton padding={'0 !important'} onClick={e => setSearchState(false)}>
                                        <ArrowBackIosNewIcon fontSize="2rem" sx={{ color: "#3a7735" }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box sx={{ maxHeight: { xs: '20rem', xl: '30rem' }, overflow: 'overlay' }}>
                        {
                            search.loading ?
                                <Stack spacing={2}>
                                    <Typography align='center'>{t('searching')}</Typography>
                                    <LinearProgress />
                                </Stack>
                                :
                                search.error &&
                                <p>{search.error}</p>
                        }
                        {
                            noMatchResults &&
                            <Box display='flex' flexDirection='column' sx={{ color: '#797979', paddingLeft: '0.5rem' }} >
                                <Typography>{t('noMatchResults')} </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>{t('noMatchResults1')} </Typography>
                            </Box>
                        }

                        {
                            manyResults ?
                                <Typography color={'#797979'} paddingLeft={'0.5rem'}>{t('manySearchResults')} {t('manySearchResults1')}</Typography>
                                :
                                searchResults.map((filterdFamily, i) => {
                                    return <TribeFamilySearch
                                        filterdFamily={filterdFamily}
                                        setAddPupilToFamily={setAddPupilToFamily}
                                        key={i}
                                        setSearchState={setSearchState} />
                                })}

                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" marginBottom="1rem">
                        <Styled.AddFamilyButton onClick={e => handleAddFamily()} endIcon={<ArrowBackIosOutlinedIcon />}>{t('newFamily')}</Styled.AddFamilyButton>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </Box>

    )
}

const TribeFamilySearch = ({ setAddPupilToFamily, filterdFamily, setSearchState }) => {
    const [expand, setExpand] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const childrensList = filterdFamily.ChildrenList.results;
    const Childrens = childrensList.map((child, i) => <TribeChildrenSearch child={child} i={i} key={i} />)

    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);

    const handleEditFamily = (familyT) => {
        setFamily(familyT);
        setExpand(false);
        setSearchState(false);
        navigate(`tribeMyFamily/${familyT.CardCode}`);
    }

    const add = () => {
        navigate('/tribe/tribeSearch');
        setAddPupilToFamily({ CardCode: filterdFamily.CardCode, lastName: filterdFamily.Parent1_LastName, pupil: true });
        setSearchState(false);
    }
    return (
        <Box display='flex' flexDirection='column'>
            <Styled.FamilyAccordion defaultExpanded square expanded={expand} onClick={e => setExpand(!expand)}>
                <AccordionSummary >
                    <Box display="flex" width='100%' alignItems='center' justifyContent='space-between'>
                        <Box display="flex" width={{ xs: '40%', md: '48%' }}>
                            <GroupsOutlinedIcon />
                            <Tooltip title={t("pressToEdit")}>
                                <Typography marginLeft={'0.5rem'} sx={{ textDecoration: "underline" }} onClick={e => handleEditFamily(filterdFamily)}>{filterdFamily.Parent1_LastName}</Typography>
                            </Tooltip>
                            <Typography marginLeft={'0.75rem'}>{filterdFamily.Parent1_ID}</Typography>
                        </Box>
                        {expand ? < ExpandMoreIcon /> : < ExpandLessIcon />}
                    </Box>
                </AccordionSummary>
                {Childrens}
                <Button sx={{ borderRadius: '50px', marginLeft: '2rem' }} color="primary" onClick={e => add()} endIcon={<AddIcon />}>
                    <Typography fontWeight={'400'}>{t('newPupil')}</Typography>
                    <Typography marginLeft={'0.2rem'} fontWeight={'600'}>{filterdFamily.Parent1_LastName}</Typography>
                </Button>
            </Styled.FamilyAccordion>
        </Box >
    )
}

const TribeChildrenSearch = ({ child, i }) => {
    const { t } = useTranslation();
    const backGround = i % 2 === 0 ? '#ffffff' : '#f9fdf7';

    return (
        <AccordionDetails style={{ padding: '0 2rem 0 1.5rem', background: backGround, height: '30px' }}>
            <Box display="flex" width={'100%'} alignItems={'center'}>
                <Box display="flex" width={{ xs: '40%', md: '48%' }}>
                    <PersonOutlineOutlinedIcon />
                    <Typography marginLeft={'0.5rem'}>{child.CardName}</Typography>
                </Box>
                <Box display="flex" width={'30%'} >
                    <Typography>{child.LicTradNum}</Typography>
                </Box>
                <Box display="flex" justifyContent={'end'} flex={'1'} >
                    <Box display="flex" width={'8rem'} alignItems={'center'}>
                        {child.Class !== '' &&
                            <Styled.InfoLabel label={permanentInfo.childClasses[child.Class]} size="small" />}
                        <Typography marginLeft={'1rem'}>{t('tribe')} {child.BPLName.slice(5)}</Typography>
                    </Box>
                </Box>
            </Box>
        </AccordionDetails>
    )
}

