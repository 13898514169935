import { useEffect, useState } from 'react'
import { Box, Fab, Typography } from '@mui/material';
import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { addRegAPI } from "../../api/api";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import useGlobal from 'store/store';

const useStyles = makeStyles((theme) => ({
    fabStyle: {
        backgroundColor: '#fff496 !important;',
        color: '#3a7735 !important;',
        '&.Mui-disabled': {
            backgroundColor: '#c3c3c394 !important;',
            color: '#00000042 !important;',
            '& .MuiBadge-badge': {
                backgroundColor: '#9d9d9d !important;',
                color: '#00000042 !important;'
            }
        },
        '& .MuiBadge-badge': {
            fontWeight: "bold",
            fontSize: "1rem",
            color: theme.palette.green.main
        }
    },
    fabWrapper: {
        backgroundColor: '#3a7735 !important',
        position: "relative",
        flexDirection: "column",
        display: "flex",
        borderRadius: "3rem",
        borderWidth: "0.75rem",
        borderStyle: "solid",
        borderColor: "#3a7735 !important"
    },
    cartIcon: {
        fontSize: "2rem !important"
    },
    yellowBackground: {
        backgroundColor: '#ffbb41 !important'
    },
    grayBackground: {
        backgroundColor: '#d3dae0 !important'
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    }
}));


export default ({ household, addProduct, householdSelections, setHouseholdSelections, setKibbutzUser }) => {

    const classes = useStyles();
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [cartState, setCartState] = useGlobal((state) => state.cartState, (actions) => actions.setCartState);
    const onfloatingCartClick = async () => {
        setBusy(true);
        try {
            const regReqs = await addActivitiesToCart(addProduct, householdSelections, setHouseholdSelections, household);
            //add registration requests to db prior showing cart
            const isKibbutz = regReqs.length && regReqs[0].Type === "02"
            setKibbutzUser(isKibbutz)
            setCartState(true);
        } catch (error) {
            enqueueSnackbar(error?.message || t("Error"), { className: classes.redBG });
        } finally {
            setBusy(false);

        }
    }

    useEffect(() => {
        setHouseholdSelections([]);
        setHouseholdSelections(householdSelections);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box className={classes.fabWrapper}>
                <Fab size="large" disabled={!householdSelections.length ? true : false} className={classes.fabStyle}>
                    {busy ? <CircularProgress /> :
                        <Badge onClick={e => onfloatingCartClick()} color="secondary"
                            showZero
                            badgeContent={`${householdSelections.length}+`}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <AddShoppingCartIcon className={classes.cartIcon} />
                        </Badge>}


                </Fab>

            </Box>
            <Typography sx={{ paddingBottom: "3rem" }}>{t("addToCartNote")}</Typography>
        </Box>


    )
}
export const addActivitiesToCart = async (addProduct, householdSelections, setHouseholdSelections, household) => {
    //add registration requests to db prior showing cart
    const regReqs = await addRegistrationRequest(householdSelections, household);
    addProduct(regReqs);
    setHouseholdSelections([]);
    return regReqs;
    // setCartState(true);
}
const addRegistrationRequest = (aSelections, household) => {
    return new Promise((resolve, reject) => {

        const activities = [];
        aSelections.forEach(activity => {
            const child = household.ChildrenList.results.find(c => { return c.CardCode === activity.StudentCardCode });
            const obj = {
                Status_request: "1", //new request
                ParentCardCode: household.CardCode,
                Parent1_Mail: household.Parent1_Mail,
                Parent1_ID: household.Parent1_ID,
                Parent1_FirstName: household.Parent1_FirstName,
                Parent1_LastName: household.Parent1_LastName,
                Parent2_Mail: household.Parent2_Mail,
                Parent2_ID: household.Parent2_ID,
                Parent2_FirstName: household.Parent2_FirstName,
                Parent2_LastName: household.Parent2_LastName,
                LicTradNum: child.LicTradNum,
                Pupil_FirstName: child.Pupil_FirstName,
                Pupil_lastName: child.Pupil_lastName,
                Class: child.Class,
                FinalPrice: activity.DiscPrice, //initialize final price field 
                ...activity
            }
            activities.push(obj);
        });
        addRegAPI(activities).then(result => {
            result.forEach(r => {
                let sel = aSelections.find(s => { return (r.ActivCode === s.ActivCode && r.StudentCardCode === s.StudentCardCode) });
                sel._id = r._id; //update db id in store
                sel.selected = false;
            });
            resolve(result);
        }).catch(error => {
            reject(error);
        });

    });
}

