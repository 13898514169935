import { useStyles } from '../assets/LoginStyle.jsx'
import { Box, Button, Container, Dialog, Link, TextField, Typography } from '@mui/material';
import { VBox } from '../assets/SharedComponents';
import ReactCodeInput from 'react-verification-code-input';
import { useTranslation } from 'react-i18next';
import { CustomBusy } from './Common/CustomBusy.jsx';
import { useEffect } from 'react';
import { useState } from 'react';
import ReLinkCounter from './Common/ReLinkCounter.jsx';
import useVerification from 'hooks/useVerification.js';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import useFeathers from 'hooks/useFeathers.js';
import { isEmail } from 'validator';
import getSMS from '../assets/getSMS.svg'
import wrongSmsCode from '../assets/wrongSmsCode.svg'
import MailSuccess from '../assets/MailSuccess.svg';
import useApi from 'hooks/useApi.js';
import { resetPassViaEmail } from 'api/api.js';

export const SmsVerification = ({ type, PhoneNumber, username, handleNoMatchingParentFound, setLoginIdProcess, setEmail, changePhoneValue }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const hookVerification = useVerification();
    const { enqueueSnackbar } = useSnackbar();
    const [visibleResend, setVisibleResend] = useState(false);
    const [wrongPinInput, setWrongPinInput] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [mailValid, setMailValid] = useState(true);
    const [resetByMail, setResetByMail] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [busy, setBusy] = useState(false);
    const [id, setId] = useState("");
    const [emailWasSent, setEmailWasSent] = useState(false);
    const [maskedEmail, setMaskedEmail] = useState(false);
    const [errorByEmail, setErrorByEmail] = useState(false);
    const [VerificationState, setVerificationState] = useState({ VerificationValue: "", VerificationPress: false });
    const pinInputRef = useRef(null);
    const feathers = useFeathers();
    const isForgotPass = type === 'forgotPass';
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const resetViaEmail = useApi(resetPassViaEmail);

    useEffect(() => {
        setTimeout(() => {
            try {
                pinInputRef.current.iRefs[0].current.focus();
            } catch (error) { console.log(error); }
        }, 500)
        resetView();
    }, [])

    const resetView = () => {
        setBusy(false);
        setWrongPinInput(false);
        setErrMessage("");
        setVerificationState({ ...VerificationState, VerificationValue: "", VerificationPress: false });
        try {
            pinInputRef.current.__clearvalues__();
        } catch (error) {
            console.log(error);
        }
    }

    const loginPhoneHandler = async (e) => {
        try {
            setBusy(true);
            let sValue = e;
            const confirmationResult = await hookVerification.signIn({}, sValue, PhoneNumber.confirmationResult);
            const { accessToken, emailVerified } = confirmationResult.user;
            await feathers.authenticationWithToken('firebase', accessToken, emailVerified, username, isForgotPass);
            const handleInsertResetpassCode = async (confirmationResult) => {
                const { email, accessToken, emailVerified } = confirmationResult?.user;
                if (confirmationResult?.user?.emailVerified) { // email exsits     
                    try {
                        await hookVerification.SendPasswordReset(email)
                        setEmail(email);
                        setLoginIdProcess(5);
                    } catch (error) {
                        const msg = hookVerification.parseFirebaseError(error);
                        setErrMessage(msg);
                        enqueueSnackbar(msg, { className: classes.redBG });
                    }
                } else {
                    try {
                        await feathers.authenticationWithToken('firebase', accessToken, emailVerified, username);
                        return true;
                    } catch (error) {
                        enqueueSnackbar(error?.message || t("Error"), { className: classes.redBG });
                        return false;
                    }
                }
            };
            if (isForgotPass) {
                await handleInsertResetpassCode(confirmationResult);
                window.localStorage.clear();
            }
        } catch (error) {

            if (error.data && error.data.noMatchingParentFound) { //no matching parent for id and phone number
                resetView();
                handleNoMatchingParentFound(error.data.username, error.data.phone);
            }
            else {
                const msg = hookVerification.parseFirebaseError(error);
                setErrMessage(msg);
                // enqueueSnackbar(msg, { className: classes.redBG });
                handleWrongPinInput();
            }
        } finally {
            setBusy(false);
        }
    }

    const handleWrongPinInput = () => {
        setWrongPinInput(true);
        const inputElement = pinInputRef.current;
        inputElement.__clearvalues__();
        setVerificationState({ ...VerificationState, VerificationValue: "", VerificationPress: false });
    }

    const reSendVerification = async (e) => {
        try {
            setWrongPinInput(false);
            let sPhoneNoHyphen = PhoneNumber['phone'].replaceAll("-", "")
            const sPhoneNumber = "+972" + (sPhoneNoHyphen[0] === '0' ? sPhoneNoHyphen.slice(1, sPhoneNoHyphen.length) : sPhoneNoHyphen);
            const confirmationResult = await hookVerification.sendOTP(sPhoneNumber);
            setVerificationState({ ...VerificationState, Resend: true });
            changePhoneValue(confirmationResult, "confirmationResult")
        } catch (error) {
            enqueueSnackbar(error?.message || t("Error"), { className: classes.redBG });
        }
    }

    const signInByMail = async () => {
        try {
            let phone = PhoneNumber.phone.slice(1);
            const sendEmailLink = await hookVerification.sendSignInEmail(username, emailAddress, `+972${phone}`);
            setVerificationState({ ...VerificationState, mailLink: true });
            setOpenDialog(false);
        } catch (error) {
            enqueueSnackbar(`${t("errorOccuredText")}`, { className: classes.redBG });
            console.log(error);
        }

    }

    const setAndValidateMail = (str) => {
        let mailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        setEmailAddress(str);
        if (isEmail(str) && mailReg.test(str)) {
            setMailValid(true);
        }
        else {
            setMailValid(false);
        }
    }

    const sendPasswordLinkToEmail = () => {
        setResetByMail(true);
    }

    const handleId = (e) => {
        let temp = e.target.value.slice(0, 30);
        setId(temp);
    }
    const sendToEmailResetLink = async () => {
        // detailsError,cantSendTheEmail
        await feathers.loginAnonymously();
        try {
            setBusy(true);
            const phonenumber = PhoneNumber.phone;
            const username = id;
            const data = { phonenumber, username };
            const res = await resetViaEmail.requestPromise(data);
            if (res[0].statusCode === 202) {
                setEmailWasSent(true);
                setMaskedEmail(res[0].maskedEmail);
            }
            else {
                setErrorByEmail(true);
            }
        } catch (error) {
            console.log(error);
            setErrorByEmail(true);
        } finally {
            await feathers.logoutAnonymously();
            setBusy(false);
        }
    }

    return (
        <>
            {!resetByMail ? <Box width={"100%"} display='inline-grid' flexDirection='column'>
                {!wrongPinInput ?
                    <VBox alignItems="center">
                        <Typography className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} ${classes.fontSize1_5}`} sx={{ fontWeight: "bold" }}>
                            {isForgotPass ? t("forgotPassword") : t("enterWithOTP")}
                        </Typography>
                        {!VerificationState?.mailLink && <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={getSMS}
                        />}
                        {VerificationState?.mailLink && <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={MailSuccess}
                        />}
                        {!VerificationState?.mailLink && <> <Typography align="center" className={`${classes.paddingBottom1rem}`} >
                            {VerificationState?.Resend ?
                                <>{`${t("resendingCodeToPhone")} ${t("another")} ${t("toPhoneNum")}`}</>
                                :
                                `${t("sendingCodeToPhone")} ${t("toPhoneNum")}`}
                            <b className={classes.phoneDir}> {`${PhoneNumber.phone}`} </b>
                        </Typography>
                            <Typography align="center" className={`${classes.paddingBottom1rem}`}>
                                {t("pleaseEnterCode")}
                            </Typography>
                        </>}
                        {VerificationState?.mailLink &&
                            <Typography align="center" className={`${classes.paddingBottom1rem}`} sx={{ fontWeight: "bold" }} >{`${t("inThisMoment")} ${t("linkPop")}`}</Typography>}
                    </VBox>
                    :
                    <VBox alignItems="center">
                        {!VerificationState?.mailLink && <Typography className={`${classes.paddingTop2_5rem} ${classes.fontSize1_5}`} sx={{ fontWeight: "bold", color: "#ff0000" }}>
                            {t("errorOccured")}
                        </Typography>}
                        {errMessage && <Typography align="center" className={`${classes.paddingBottom1rem}`} sx={{ fontWeight: "bold", color: "#ff0000" }}>
                            {errMessage}
                        </Typography>}
                        {!VerificationState?.mailLink && <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={wrongSmsCode}
                        />}
                        {VerificationState?.mailLink && <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={MailSuccess}
                        />}
                        {!VerificationState?.mailLink && <Typography align="center" className={`${classes.paddingBottom1rem}`}>
                            {t("reEnterCode")}
                        </Typography>}
                        {VerificationState?.mailLink &&
                            <Typography align="center" className={`${classes.paddingBottom1rem}`} sx={{ fontWeight: "bold" }} >{`${t("inThisMoment")} ${t("linkPop")}`}</Typography>}
                    </VBox>
                }
                {isForgotPass && <Link onClick={sendPasswordLinkToEmail} color={'#007f00'} className={`${classes.CenterItems} ${classes.LinkStyle} ${classes.marginTop1} ${classes.paddingBottom1rem}`} sx={{ fontSize: "0.875rem !important" }}>
                    {t("resetPasswordByMail")}
                </Link>}
                {!VerificationState?.mailLink &&
                    <VBox alignItems="center" style={{ direction: 'ltr', marginBottom: "0.5rem" }}>
                        <ReactCodeInput
                            onComplete={loginPhoneHandler}
                            autoComplete={false}
                            type='number'
                            inputMode="numeric"
                            autoFocus={true}
                            fields={6}
                            ref={pinInputRef}
                            color='black'
                            className={classes.codePinInputStyle}
                            disabled={!!busy} />
                    </VBox>}
                <VBox justifyContent={'space-around'}>
                    {busy && <CustomBusy />}
                    {!VerificationState?.mailLink &&
                        <VBox alignItems={"center"} justifyContent={"center !important"} marginTop={"0.2rem"}>
                            {!visibleResend ? <ReLinkCounter setVisible={setVisibleResend} /> :
                                <Link onClick={reSendVerification} color={'#007f00'} className={`${classes.CenterItems} ${classes.LinkStyle} `} sx={{ fontSize: "0.875rem !important" }}>
                                    {t("resendToken")}
                                </Link>}
                        </VBox>}
                    {!VerificationState?.mailLink && !isForgotPass && <Button onClick={e => setOpenDialog(true)} sx={{ textDecoration: "underline" }}>{t("didntGetSMS")} </Button>}
                    {/* {!VerificationState?.mailLink && <Typography sx={{ color: "#ff0000", textAlign: "center", fontSize: "0.65rem" }}>ללקוחות פרטנר ישנה תקלה זמנית בקבלת קוד אימות בהודעות sms</Typography>}
                    {!VerificationState?.mailLink && <Typography sx={{ color: "#ff0000", textAlign: "center", fontSize: "0.7rem" }}>כדי להתחבר לפורטל יש ללחוץ על הכפתור לא קיבלתי sms</Typography>} */}
                    {openDialog && <SentToEmailDialog openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        emailAddress={emailAddress}
                        mailValid={mailValid}
                        setAndValidateMail={setAndValidateMail}
                        signInByMail={signInByMail} />}
                </VBox>
            </Box> :
                <Box width={"100%"} display='inline-grid' flexDirection='column'>
                    <VBox alignItems="center">
                        <Typography className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} ${classes.fontSize1_5}`} sx={{ fontWeight: "bold" }}>
                            {t("forgotPassword")}
                        </Typography>
                        {!emailWasSent ?
                            <>
                                <Typography align="center" className={`${classes.paddingBottom1rem} ${classes.margin0_3}`} >
                                    {t("pleaseEnterYourId")}
                                </Typography>
                                {errorByEmail &&
                                    <>
                                        <Typography sx={{ fontWeight: "bold", color: "#ff0000" }}>{t("detailsError")}</Typography>
                                        <Typography sx={{ fontWeight: "bold", color: "#ff0000" }}>{t("cantSendTheEmail")}</Typography>
                                    </>
                                }
                                <TextField
                                    type="number"
                                    name="Id"
                                    label={t("parentIdDoc")}
                                    placeholder={t("idPlaceholder")}
                                    style={{ width: "100% !important" }}
                                    value={id}
                                    className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow}`}
                                    onChange={e => handleId(e)}
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                            inputMode: 'tel',
                                            pattern: "[0-9]*"
                                        },
                                        inputRef: inputRef1
                                    }}
                                    onWheel={(e) => {
                                        if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                            inputRef1.current.blur();
                                        }
                                    }} />
                                <TextField
                                    placeholder={t("matchPhonePlaceholder")}
                                    style={{ width: "100% !important" }}
                                    className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow}`}
                                    InputProps={{
                                        inputProps: {
                                            inputMode: 'tel',
                                            pattern: "[0-9]*"
                                        },
                                        maxLength: 20,
                                        type: "number",
                                        inputRef: inputRef2
                                    }}
                                    onWheel={(e) => {
                                        if (inputRef2 && inputRef2.current && inputRef2.current.blur) {
                                            inputRef2.current.blur();
                                        }
                                    }}
                                    label={t("phoneNumber")}
                                    onChange={e => changePhoneValue(e, 'phone')}
                                    value={PhoneNumber.phone}
                                    disabled={busy}
                                    variant="outlined"
                                />

                                {busy ? <CustomBusy /> : <Button
                                    disabled={!id || !PhoneNumber.phone}
                                    sx={{ marginTop: "1rem" }}
                                    className={`${!id || !PhoneNumber.phone ? classes.buttonGreenLight : classes.buttonGreen}`}
                                    variant="contained"
                                    onClick={e => sendToEmailResetLink()}>
                                    {t("sendLinkToMail")}
                                </Button>}

                            </>
                            :
                            <>
                                <img
                                    alt={"..."}
                                    width="200px"
                                    height="120px"
                                    src={MailSuccess}
                                />
                                <Typography>{t("inThisMomentWeSend")}</Typography>
                                <Typography>{maskedEmail}</Typography>
                            </>
                        }
                    </VBox>
                </Box>}
        </>
    );
}
const SentToEmailDialog = ({ openDialog, setOpenDialog, emailAddress, mailValid, setAndValidateMail, signInByMail }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={openDialog} onClose={e => setOpenDialog(false)} PaperProps={{ sx: { borderRadius: "40px !important", backgroundColor: "#f0f3f0", width: "16rem", height: "20rem" } }}>
            <Container>
                <Box display="flex" flexDirection="column" alignItems='center' justifyContent='center' mt="2rem">
                    <Typography sx={{ marginBottom: "1rem" }}>{t("pleaseEnterMail")}</Typography>
                    <TextField
                        name="emailAddress"
                        width={'50%'}
                        label={t("email")}
                        variant="outlined"
                        value={emailAddress}
                        error={!mailValid}
                        onChange={e => setAndValidateMail(e.target.value)}
                        InputProps={{
                            inputProps: {
                                inputMode: 'url'
                            }
                        }}
                    />
                    <Button onClick={e => signInByMail()} disabled={!mailValid || !emailAddress.length} sx={{ marginTop: "1rem" }}>{t("send1")}</Button>
                </Box>
            </Container>
        </Dialog>
    )
}