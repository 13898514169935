import { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from "raviger";
import { useTranslation } from 'react-i18next';
import useGlobal from '../store/store';
import useFeathers from '../hooks/useFeathers';
//mui
import { makeStyles } from '@mui/styles';
import { TextField, TextareaAutosize, Autocomplete, Paper, FormControl, Box, Button, Typography, Stack, Container, Backdrop, Select, MenuItem, InputLabel } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
//libs
import { Animated } from "react-animated-css";
import { isEmail } from 'validator';
//api
import { contactTribe } from '../api/api';
import { getTribes } from '../api/api';
//assets
import { permanentInfo } from '../data/permanentInfo';
import contactParent from '../assets/contactParent.svg';
import contactSentSvg from '../assets/contactSentSvg.svg';

const useStyles = makeStyles((theme) => ({
    contactFormBackground: {
        textAlign: "-webkit-center !important",
        paddingRight: "2rem !important",
        paddingLeft: "2rem !important"

    },
    contactForm: {
        maxWidth: '30rem',
        padding: "1rem",
        "& .MuiFormControl-root": {
            marginBottom: "0.5rem"
        }
    },
    submitButton: {
        width: "9rem",
        marginBottom: "1rem !important",
        borderRadius: "1.5rem !important"
    },
    centerContent: {
        marginTop: "5rem"
    },
    font16: {
        fontSize: "1rem !important",
        fontFamily: "Alef,sans-serif !important",
        paddingRight: "12px"
    },
    paperStyle: {
        borderTopLeftRadius: "50px !important",
        borderTopRightRadius: "50px !important",
        height: "100%",
        paddingTop: "1rem",
        textAlign: "center",
        boxShadow: "none !important"
    },
    LogoSize: {
        width: '9rem !important'
    },
    container: {
        paddingRight: "0px !important",
        paddingLeft: "0px !important"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    }
}));

const ParentContact = ({ anonymous = false, setDialogOpen }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [tribeList, setTribeList] = useState([]);
    const [tribeSelected, setTribeSelected] = useState(null);
    const [parentList, setParentList] = useState([]);
    const [parentSelected, setParentSelected] = useState(null);
    const [childrenList, setChildrenList] = useState([]);
    const [childSelected, setChildSelected] = useState(null);
    const [childID, setChildID] = useState("");
    const [childClass, setChildClass] = useState('');
    const [refundActName, setRefundActName] = useState("");
    const [reasonList, setReasonList] = useState([]);
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [mailValid, setMailValid] = useState(true);
    const [reasonSelected, setReasonSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [longtext, setLongText] = useState("");
    const [contactSent, setContactSent] = useState(false);
    const inputRef = useRef()
    const inputRef1 = useRef()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [household, [setHousehold]] = useGlobal(
        state => state.household,
        actions => [actions.setHousehold]
    );
    const hookFeathers = useFeathers();

    const getTribesAnonymously = async () => {
        await hookFeathers.loginAnonymously();
        let tribes;
        try {
            tribes = await getTribes();
            tribes = tribes.d.results;
        } catch (error) {
            throw error;
        } finally {
            await hookFeathers.logoutAnonymously();
            return tribes;
        }

    }

    useEffect(async () => {
        let isMounted = true;
        isMounted && setSelections(household);
        isMounted && setContactSent(false);
        isMounted && setLoading(false);
        return () => { isMounted = false }
    }, []);

    const setAndValidateMail = (str) => {
        let mailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        setMail(str);
        if (isEmail(str) && mailReg.test(str)) {
            setMailValid(true);
        }
        else {
            setMailValid(false);
        }

    }
    const longTextValid = (e) => {
        const regex = /^[a-zA-Z0-9\s\u0590-\u05fe,.'-,.',.'?!-]+$/i
        if (regex.test(e.target.value) || e.target.value === "") {
            setLongText(e.target.value);
        }
    }

    const setSelections = async (household) => {
        const parents = [];
        const children = [];
        const reasons = [];
        let tribesObj = {};
        let tribes = [];

        household?.ChildrenList?.results?.forEach(child => {
            tribesObj[child.DflWhs] = child.BPLName.slice(5);
            children.push({
                id: child.LicTradNum,
                label: `${child.Pupil_FirstName} ${child.Pupil_lastName}`,
                childClass: permanentInfo.childClasses[child.Class]
            });

        });

        if (anonymous) {
            tribes = await getTribesAnonymously();
            tribes = tribes.map(t => { return { id: t.Code, label: t.TribeCityConcat || t.Name } });
        }
        else {
            for (const [key, value] of Object.entries(tribesObj)) {
                tribes.push({ id: key, label: value });
            }
        }

        if (household.Parent1_ID) {
            parents.push({
                id: household.Parent1_ID,
                mail: household.Parent1_Mail,
                phone: household.Parent1_Tel1.slice(-9),
                label: `${household.Parent1_FirstName} ${household.Parent1_LastName}`
            });
        }
        if (household.Parent2_ID) {
            parents.push({
                id: household.Parent2_ID,
                mail: household.Parent2_Mail,
                phone: household.Parent2_Tel1.slice(-9),
                label: `${household.Parent2_FirstName} ${household.Parent2_LastName}`
            });
        }

        reasons.push(
            { label: t("regCancelAndRefund"), code: "1" },
            { label: t("techIssues"), code: "2" },
            { label: t("finIssues"), code: "3" },
            { label: t("personalDetails"), code: "4" }
        );

        setTribeList(tribes);
        setParentList(parents);
        setChildrenList(children);
        setReasonList(reasons);

        if (tribes.length === 1) {
            setTribeSelected(tribes[0]);
        }

        if (children.length === 1) {
            setChildSelected(children[0]);
            setChildClass(children[0].childClass);
            setChildID(children[0].id);
        }

        if (parents.length === 1) {
            setParentSelected(parents[0]);
            setPhone(parents[0].phone);
            setAndValidateMail(parents[0].mail);
        }

    }

    const submitForm = async () => {
        setLoading(true);
        const contactText = reasonSelected.code === "1" ? `${t("activeNames")}: ${refundActName} , ${longtext} ` : longtext;
        try {
            const data = {
                TribeId: tribeSelected.id,
                TribeName: tribeSelected.label,
                ParentName: anonymous ? parentSelected : parentSelected.label,
                ChildName: anonymous ? childSelected : childSelected.label,
                ChildID: childID,
                Phone: phone,
                Mail: mail,
                ReasonName: reasonSelected.label,
                LongText: contactText,
                ChildClass: childClass
            };

            if (anonymous) {
                await hookFeathers.loginAnonymously();
            }

            const res = await contactTribe(data);
            setContactSent(true);
            if (res) { setLoading(false); }

            if (anonymous) {
                await hookFeathers.logoutAnonymously();
            }

        } catch (error) {
            setLoading(false);
            enqueueSnackbar(t("errorOccuredText"), { className: classes.orangeBG });
        }
    }

    return (

        <div style={{ backgroundColor: "transparent" }}>
            <div >
                <Animated animationIn="fadeInUp" animationInDuration={anonymous ? 0 : 2000} isVisible={true}>
                    <Container className={classes.container}>
                        {!contactSent ?
                            <Paper className={classes.paperStyle} sx={{ backgroundColor: anonymous ? "#f0f3f0" : "#ffffff" }}>
                                <img
                                    className={classes.LogoSize}
                                    alt={'...'}
                                    src={contactParent}
                                />
                                <Typography fontWeight={"bold"}>{t('waitToContact')}</Typography>
                                <Box height={'100%'} className={classes.contactFormBackground} sx={{ backgroundColor: anonymous ? "#f0f3f0" : "#ffffff" }}>
                                    <>
                                        <Box component="form" display={'flex'} flexDirection={'column'} className={classes.contactForm}>
                                            <FormControl variant="standard">
                                                <Autocomplete
                                                    disablePortal
                                                    options={tribeList}
                                                    value={tribeSelected}
                                                    noOptionsText={t('notOption')}
                                                    onChange={(event, newValue, reason, details) => {
                                                        setTribeSelected(newValue);
                                                    }}
                                                    readOnly={!tribeList.length}
                                                    renderInput={(params) => <TextField {...params} label={t("tribe")} placeholder={anonymous ? t("tribeSearch") : t("choose")} />}
                                                />
                                            </FormControl>
                                            <FormControl variant="standard">
                                                {anonymous ?
                                                    <TextField id="outlined-basic" label={t("parentName")} value={parentSelected}
                                                        onChange={(event) => {
                                                            setParentSelected(event.target.value);
                                                        }}
                                                        variant="outlined" />
                                                    :
                                                    <Autocomplete
                                                        disablePortal
                                                        options={parentList}
                                                        value={parentSelected}
                                                        noOptionsText={t('notOption')}
                                                        onChange={(event, newValue, reason, details) => {
                                                            setParentSelected(newValue);
                                                            setPhone(newValue ? newValue.phone : "");
                                                            setAndValidateMail(newValue ? newValue.mail : "");
                                                        }}
                                                        readOnly={!parentList.length}
                                                        renderInput={(params) => <TextField {...params} label={t("parentName")} placeholder={!anonymous && t("choose")} />}
                                                    />
                                                }
                                            </FormControl>
                                            <FormControl variant="standard">
                                                {anonymous ?
                                                    <TextField id="outlined-basic" label={t("studentName")} value={childSelected} variant="outlined"
                                                        onChange={(event) => {
                                                            setChildSelected(event.target.value);
                                                        }} />
                                                    :
                                                    <Autocomplete
                                                        disablePortal
                                                        options={childrenList}
                                                        value={childSelected}
                                                        noOptionsText={t('notOption')}
                                                        onChange={(event, newValue, reason, details) => {
                                                            setChildSelected(newValue);
                                                            setChildClass(newValue ? newValue.childClass : "");
                                                            setChildID(newValue ? newValue.id : "");
                                                        }}
                                                        readOnly={childrenList.length === 0}
                                                        renderInput={(params) => <TextField {...params} label={t("studentName")} placeholder={!anonymous && t("choose")} />}
                                                    />
                                                }
                                            </FormControl>
                                            <FormControl fullWidth >
                                                <InputLabel>{t('class')}</InputLabel>
                                                <Select
                                                    sx={{ textAlign: "left" }}
                                                    id="class"
                                                    name="class"
                                                    label={t('class')}
                                                    value={childClass}
                                                    onChange={(e) => setChildClass(e.target.value)}
                                                    variant='outlined'
                                                    disabled={!anonymous}
                                                >
                                                    {Object.keys(permanentInfo.childClasses).map((childClass, i) => {
                                                        return (
                                                            <MenuItem value={permanentInfo.childClasses[i + 1]} key={i} >{permanentInfo.childClasses[i + 1]}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="standard">
                                                {anonymous ?
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={t("studentId")}
                                                        value={childID}
                                                        type="number"
                                                        className={classes.RemoveTextFieldNumberArrow}
                                                        InputProps={{
                                                            inputProps: {
                                                                inputMode: "tel",
                                                                pattern: "[0-9]*"
                                                            },
                                                            inputRef: inputRef
                                                        }}
                                                        onWheel={(e) => {
                                                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                                                inputRef.current.blur();
                                                            }

                                                        }}
                                                        onChange={(event) => {
                                                            (event.target.value.length <= 30) &&
                                                                setChildID(event.target.value);
                                                        }}
                                                        variant="outlined" />
                                                    :
                                                    <TextField id="outlined-basic" label={t("studentId")} value={childID} disabled variant="outlined" />
                                                }
                                            </FormControl>
                                            <FormControl variant="standard">
                                                <TextField
                                                    id="outlined-basic"
                                                    label={t("phoneNumber")}
                                                    value={phone}
                                                    type="number"
                                                    className={classes.RemoveTextFieldNumberArrow}
                                                    InputProps={{
                                                        inputProps: {
                                                            inputMode: "tel",
                                                            pattern: "[0-9]*"
                                                        },
                                                        inputRef: inputRef1
                                                    }}
                                                    onWheel={(e) => {
                                                        if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                                            inputRef1.current.blur();
                                                        }

                                                    }}
                                                    onChange={(event) => {
                                                        event.target.value.length <= 10 &&
                                                            setPhone(event.target.value);
                                                    }}
                                                    variant="outlined" />
                                            </FormControl>
                                            <FormControl variant="standard">
                                                <TextField id="outlined-basic" label={t("emaillong")} value={mail}
                                                    onChange={(event) => {
                                                        setAndValidateMail(event.target.value);
                                                    }}
                                                    error={!mailValid}
                                                    helperText={!mailValid && t("insertValidMail")}
                                                    variant="outlined" />
                                            </FormControl>
                                            <FormControl variant="standard">
                                                <Autocomplete
                                                    disablePortal
                                                    options={reasonList}
                                                    value={reasonSelected}
                                                    noOptionsText={t('notOption')}
                                                    onChange={(event, newValue, reason, details) => {
                                                        setReasonSelected(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label={t("contactReason")} />}
                                                />
                                            </FormControl>
                                            {reasonSelected?.code === "1" && <FormControl variant="standard">
                                                <TextField id="outlined-basic" label={t("activeNames")} value={refundActName}
                                                    onChange={(event) => {
                                                        setRefundActName(event.target.value);
                                                    }}
                                                    error={!refundActName}
                                                    helperText={!refundActName && t("mandatoryField")}
                                                    variant="outlined" />
                                            </FormControl>}
                                            <FormControl variant="standard">
                                                <TextareaAutosize color={'red'}
                                                    minRows={6}
                                                    maxRows={6}
                                                    maxLength={250}
                                                    placeholder={t("contactDetails")}
                                                    value={longtext}
                                                    onChange={(event) => {
                                                        longTextValid(event);
                                                    }}
                                                    className={classes.font16}
                                                />
                                            </FormControl>
                                        </Box>
                                        {loading ?
                                            <Stack spacing={2}>

                                                <Backdrop
                                                    sx={{ alignItems: "center", justifyContent: "center", zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#3a7735b3" }}
                                                    open={loading}
                                                >
                                                    <Animated animationIn="flash" animationInDuration={2000} animationOutDelay={2500} isVisible={true}>
                                                        <Typography align='center' variant='h5' sx={{ color: "#ffffff" }}>{t("contactSending")}</Typography>
                                                    </Animated>
                                                </Backdrop>

                                            </Stack>
                                            :
                                            <Button variant="contained" size="large" className={classes.submitButton} endIcon={<NavigateBeforeOutlinedIcon />}
                                                disabled={!tribeSelected || !parentSelected || !childSelected || !childID || !childClass || !phone || !mail || !mailValid || !reasonSelected || !longtext || (!refundActName && reasonSelected.code === "1")}
                                                onClick={submitForm}>
                                                {t("sendContact")}
                                            </Button>
                                        }
                                    </>
                                </Box >
                            </Paper> :
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" className={classes.centerContent}>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="3rem">
                                    <img
                                        className={classes.LogoSize}
                                        alt={'...'}
                                        src={contactSentSvg}
                                    />
                                    <CheckCircleOutlineIcon sx={{ color: '#3a7735', marginTop: "1rem" }} fontSize="large" />
                                    <Typography sx={{ color: anonymous ? '#3a7735' : '#333333', fontSize: "1.3rem", marginTop: "1rem", fontWeight: "bold" }}>
                                        {t("contactSendSuccess")}
                                    </Typography>
                                    <Typography sx={{ color: anonymous ? '#3a7735' : '#333333', marginTop: "1rem" }}>
                                        {t("tribeWillContact")}
                                    </Typography>
                                    {
                                        anonymous ?
                                            <Button variant="contained" size="large" className={classes.submitButton} onClick={() => { setDialogOpen(false) }} >
                                                {t("close")}
                                            </Button>
                                            :
                                            <Button variant="contained" size="large" className={classes.submitButton} sx={{ marginTop: "1rem", width: "100%" }} onClick={() => { navigate('/homepage'); }} >
                                                {t("backToHomepage")}
                                            </Button>
                                    }
                                </Box>

                            </Box>}
                    </Container>
                </Animated >
            </div>
        </div>
    );

}

export default ParentContact;


