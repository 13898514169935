
import { useTranslation } from "react-i18next";
import { useNavigate } from "raviger";
import { Box, Typography, Button, TableContainer, Tabs, Tab, styled, TableCell } from "@mui/material";
//assets
import inactiveChildren from '../../../assets/inactiveChildren.svg'
import NoHistory from '../../../assets/NoHistory.svg'

export const MuiTabs = styled(Tabs)(() => ({
    background: "white",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    height: "4rem",
    paddingTop: "0.5rem",
    '& .MuiButtonBase-root': {
        color: '#3A7735',
        fontSize: '1.125rem',
        textTransform: "capitalize !important",
        ':hover': {
            backgroundColor: "#cee2cd",
            borderRadius: "25px"
        },
    },
    '& .MuiButtonBase-root.Mui-selected': {
        color: 'white',
        backgroundColor: "#3A7735",
        borderRadius: "25px"
    },
    '& .Mui-disabled': {
        display: "none !important"
    },
    '& .MuiTabs-indicator': {
        display: "none !important"
    },
    '& .MuiButtonBase-root MuiTab-root .MuiTab-textColorPrimary .MuiButtonBase-root .MuiTab-root': {
        '& :hover': {
            backgroundColor: "#cee2cd",
            borderRadius: "25px"
        }
    }
}));
export const MuiTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: '75rem',
    padding: '1rem',
    "& .MuiTableRow-root": {
        margin: '2rem auto',
        padding: '2rem',
        [theme.breakpoints.down('sm')]: {
            margin: '2rem auto',
            padding: '0 1rem'
        }
    }
}));
export const MuiTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: 'center',
    alignItems: "center",
    fontSize: '1rem',
    "&.MuiTableCell-head": {
        fontWeight: "bold",
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
        padding: '0.5rem',

    }
}));
export const NoHistoryBox = ({ text }) => {
    return (
        <Box mb={5} key={"inactiveChildrenHist"}>
            <img
                alt={"..."}
                src={NoHistory}
                width="300px"
                height="300px"
            />
            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold" }} > {text}</Typography>
        </Box>)
}
export const InactiveChildrenBox = ({ setAppBarTitle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = () => {
        setAppBarTitle(t("myFamily"));
        navigate('/MyFamily');
    }
    return (
        <Box mb={5} key={"inactiveChildren"}>
            <img
                alt={"..."}
                src={inactiveChildren}
                width="300px"
                height="300px"
            />
            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold" }} > {t("inactiveChildren")}</Typography>
            <Typography sx={{ color: "#ff0000", fontSize: "1.3rem", fontWeight: "bold" }} >{t("notactive")}</Typography>
            <Typography sx={{ color: "#3a7735", fontSize: "1rem", fontWeight: "bold" }}> {t("youCanChangeIt")}</Typography>
            <Button onClick={e => handleNavigate()} sx={{ backgroundColor: "#3a7735 !important", color: "#ffffff", marginTop: "1rem" }}>{t("toMyFamily")}</Button>
        </Box>)
}
export const ChildTabsBox = ({ childList, currentTab, setCurrentTab, familyName }) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" flexDirection="row" alignItems="center" paddingTop="1rem">
            <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, fontWeight: "bold", paddingLeft: "1rem" }}>{`${t("familyOf")} ${familyName}`}</Typography>


            <MuiTabs
                value={currentTab}
                onChange={(e, childIndex) => setCurrentTab(childIndex)}
                variant="scrollable"
                scrollButtons="auto"
            >
                {childList.length !== 1 && <Tab label={t('everyone')} key={`ChildTabEveryone`} />}
                {childList.map((child, i) => (
                    <Tab label={child.name} key={`$ChildHisTab-${i}`} />
                ))}
            </MuiTabs>
        </Box>
    )
}