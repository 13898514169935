import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { VBox, HBox } from '../../assets/SharedComponents';
import { Dialog, DialogActions, Button, Typography, Container, Box, ButtonGroup, TextField } from '@mui/material';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { updateUser, checkUserName } from '../../api/api'
import useApi from '../../hooks/useApi';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomBusy } from 'Components/Common/CustomBusy';
import { useSnackbar } from 'notistack';
import verticalRope from '../../assets/verticalRope.svg'


const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    hiddenXs: {
        '@media (max-width: 576px)': {
            display: "none"
        }
    },
    IdentificationBtn: {
        backgroundColor: "#e1e1e1 !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    marginBottom1: {
        marginBottom: '1rem !important'
    }, selectedBtn: {
        textTransform: "capitalize !important",
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important"
    },
    IdnBtn: {
        backgroundColor: "#e1e1e1  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important"
    }, width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    }, buttonGreen: {
        color: 'white !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        marginTop: "1rem !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
}));

const AddParent2Dialog = ({ open, setOpen, household, setLevel, level }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const IdTextByType = { "1": t("israelId"), "2": t("passport"), "3": t("journeyId") };
    const inputRef = useRef();
    const inputRef1 = useRef();
    const updateUserData = useApi(updateUser);
    const users = useApi(checkUserName);
    const validationSchema = yup.object({
        id: yup
            .string(t("id"))
            .required(t("mandatoryField"))
            .min(9, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^[0-9]*$/, t('enterValidValue')),
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        phone: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .matches(/^[0-9]*$/, t('insertValidPhone'))
            .required(t("mandatoryField")),
    });
    const formik = useFormik({
        initialValues: {
            id: household.Parent2_ID || '',
            idType: household.Parent2_ID_Type || "1",
            email: household.Parent2_Mail || '',
            phone: ''
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            const emailExsits = await users.requestPromise({ email: values.email });
            if (emailExsits.userExists) {
                enqueueSnackbar(t("mailExsit"), { className: classes.redBG });
                return
            }
            if (values.email.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG });
                return
            }
            const idExsits = await users.requestPromise({ username: values.id.toString() })
            if (idExsits.userExists) {
                enqueueSnackbar(t("idExsit"), { className: classes.redBG });
                return
            }
            let phoneNumber = values.phone;
            if (phoneNumber[0] === '0') {
                phoneNumber = phoneNumber.slice(1, phoneNumber.length);
            }
            const phonExsits = await users.requestPromise({ phone_number: `+972${phoneNumber}` });
            if (phonExsits.userExists) {
                enqueueSnackbar(t("phoneExsit"), { className: classes.redBG });
                return
            }
            let user = {
                "Source": "APH",
                "CardCode": household.CardCode,
                "Parent2_ID": values.id.toString(),
                "Parent2_ID_Type": values.idType,
                "Parent2_Mail": values.email,
                "Parent2_Tel1": `+972${values.phone}`,
                "Parent2_FirstName": "",
                "Parent2_LastName": "",
                "Parent2_LiveDiffAddress": household.Parent2_LiveDiffAddress,
                "Parent1_LiveDiffAddress": household.Parent1_LiveDiffAddress,
                "Parent2_City": household.Parent2_City,
                "Parent2_Street": household.Parent2_Street,
                "Parent2_MaritalStatus": household.Parent2_MaritalStatus,
                "Parent1_ID": household.Parent1_ID,
                "Parent1_FirstName": household.Parent1_FirstName,
                "Parent1_LastName": household.Parent1_LastName,
                "Parent1_MaritalStatus": household.Parent1_MaritalStatus,
                "Parent1_Profession": household.Parent1_Profession,
                "Parent1_Mail": household.Parent1_Mail,
                "Parent1_PlaceOfBirth": household.Parent1_PlaceOfBirth,
                "Parent1_EmigrationYear": household.Parent1_EmigrationYear,
                "Parent1_PastScout": household.Parent1_PastScout,
                "Parent1_IsVolunteered": household.Parent1_IsVolunteered,
                "Parent1_ID_Type": household.Parent1_ID_Type,
                "Parent1_MedicalQalifications": household.Parent1_MedicalQalifications || [],
                "Parent2_Profession": household.Parent2_Profession,
                "Parent2_PlaceOfBirth": household.Parent2_PlaceOfBirth,
                "Parent2_EmigrationYear": household.Parent2_EmigrationYear,
                "Parent2_PastScout": household.Parent2_PastScout,
                "Parent2_IsVolunteered": household.Parent2_IsVolunteered,
                "Parent2_MedicalQalifications": household.Parent2_MedicalQalifications || [],
                "Parent1_City": household.Parent1_City,
                "Parent1_Street": household.Parent1_Street,
                "Parent1_Country": household.Parent1_Country,
                "Parent1_StreetNo": household.Parent1_StreetNo,
                "Parent1_ZipCode": household.Parent1_ZipCode,
                "Parent2_Country": household.Parent2_Country,
                "Parent2_StreetNo": household.Parent2_StreetNo,
                "Parent2_ZipCode": household.Parent2_ZipCode,
                "Parent1_Tel1": household.Parent1_Tel1,
                "Parent1_Tel1Type": household.Parent1_Tel1Type,
                "Parent1_Tel2": household.Parent1_Tel2,
                "Parent1_Tel2Type": household.Parent1_Tel2Type,
                "Parent1_ApprInfo": household.Parent1_ApprInfo,
                "Parent2_Tel1Type": household.Parent2_Tel1Type,
                "Parent2_Tel2": household.Parent2_Tel2,
                "Parent2_Tel2Type": household.Parent2_Tel2Type,
                "Parent1_Language": household.Parent1_Language,
                "Parent2_Language": household.Parent2_Language,
                "Parent2_ApprInfo": household.Parent2_ApprInfo,
            }
            try {
                const response = await updateUserData.requestPromise(user);
                if (response.Status) {
                    const key = enqueueSnackbar(t("parent2Success"), { className: classes.orangeBG })
                    setTimeout(() => { closeSnackbar(key) }, 2200)
                    setOpen(false);
                    setLevel(level);
                }
                else {
                    let sMsg;
                    try {
                        sMsg = response.ErrorMessage.split("Message: ")[1];
                    } catch (error) {
                        sMsg = response.ErrorMessage;
                    }
                    enqueueSnackbar(`${sMsg} SAP error`, { className: classes.redBG });
                }
            } catch (error) {
                console.log(error);
            }

        }
    });
    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }
    return (
        <Dialog
            open={open}
            onClose={e => { setOpen(false) }}
            PaperProps={{
                sx: { width: { xs: "20rem", md: "30rem" }, borderRadius: "50px !important", backgroundColor: "#f0f3f0" }
            }}
        >
            <Container>
                <HBox className={classes.dialog} >
                    <DialogActions>
                        <Button onClick={e => { setOpen(false) }} sx={{ backgroundColor: "transparent !important" }}>
                            <CloseOutlinedIcon sx={{ marginLeft: { xs: "14rem", md: "23rem" } }} />
                        </Button>
                    </DialogActions>

                </HBox>
                <VBox alignItems="center">
                    <Typography sx={{ color: "#3a7735", fontWeight: "bold" }}>{t('cantSplitPay')}</Typography>
                    <Typography sx={{ color: "#3a7735" }}>{t('needToAdd')}</Typography>
                </VBox>
                <Box display="flex" flexDirection="row" alignItems="center" padding="1rem" justifyContent="space-between">
                    <img
                        alt={"..."}
                        src={verticalRope}
                        width="23px"
                        height="142px"
                        className={classes.hiddenXs}
                    />
                    <form onSubmit={formik.handleSubmit}>
                        <Box display="flex" flexDirection="column" alignItems="center" padding="1rem">
                            <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`} sx={{ width: { xs: "15rem", sm: "17rem" } }}>
                                <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}>{IdTextByType[1]}</Button>
                                <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{IdTextByType[2]}</Button>
                                <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{IdTextByType[3]} </Button>
                            </ButtonGroup>
                            <TextField
                                type="number"
                                label={IdTextByType[formik.values.idType]}
                                className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                id="id"
                                name="id"
                                sx={{ width: "100%" }}
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.id && Boolean(formik.errors.id)}
                                helperText={formik.touched.id && formik.errors.id}
                                InputProps={{
                                    inputRef: inputRef,
                                    inputProps: {
                                        inputMode: "tel",
                                        pattern: "[0-9]*"
                                    }
                                }}
                                onWheel={(e) => {
                                    if (inputRef && inputRef.current && inputRef.current.blur) {
                                        inputRef.current.blur();
                                    }
                                }}
                                variant="outlined"
                            />
                            <TextField
                                className={`${classes.marginBottom1} `}
                                label={t("emaillong")}
                                sx={{ width: "100%" }}
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                        inputMode: "url"
                                    }
                                }}
                            />
                            <TextField
                                className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                sx={{ width: "100%" }}
                                label={t("myPhone")}
                                InputProps={{
                                    inputRef: inputRef1,
                                    inputProps: {
                                        inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    }
                                }}
                                onWheel={(e) => {
                                    if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                        inputRef1.current.blur();
                                    }
                                }}
                                type="number"
                                id="phone"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                variant="outlined"
                            />
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {!updateUserData.loading ?
                                    <Button
                                        className={`${classes.buttonGreen} ${classes.marginBottom1}`}
                                        type="submit"
                                        sx={{ backgroundColor: formik.values.email === "" || formik.values.id === "" || formik.values.phone === "" || Boolean(formik.errors.email) || Boolean(formik.errors.id) || Boolean(formik.errors.phone) ? "#00000061" : "#3a7735" }}
                                        disabled={formik.values.email === "" || formik.values.id === "" || formik.values.phone === "" || Boolean(formik.errors.email) || Boolean(formik.errors.id) || Boolean(formik.errors.phone)}>
                                        {t("addParent2Details")}
                                    </Button> :
                                    <CustomBusy />}
                            </Box>
                        </Box>
                    </form>
                    <img
                        alt={"..."}
                        src={verticalRope}
                        width="23px"
                        height="142px"
                        className={classes.hiddenXs}
                    />
                </Box>
            </Container>
        </Dialog >
    )
}
export default AddParent2Dialog;