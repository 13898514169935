import Logo from '../../assets/Logo.png'
import { Animated } from 'react-animated-css'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

const ZofimLoader = () => {
    const { t } = useTranslation()
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Animated animationOut="fadeOut" animationOutDelay={2900} animationOutDuration={1000} isVisible={true}>
                <img
                    width="90px"
                    height="auto"
                    alt="..."
                    src={Logo}
                    loading="lazy"
                />
            </Animated>
            <Animated animationIn="flash" animationInDuration={2000} animationOutDelay={2900} isVisible={true}>
                <Typography sx={{ fontWeight: "bold", color: "#ffffff", fontSize: "1.2rem" }}>{t("scoutsAreComing")}</Typography>
            </Animated>
            <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDelay={2900} animationOutDuration={1000} isVisible={true} style={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "black", fontSize: "0.9rem", bottom: "3rem", position: 'fixed' }}>Powered by <b>Alterno.io</b></Typography>
            </Animated>

        </Box>
    )
}
export default ZofimLoader;