import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { VBox, HBox } from '../../assets/SharedComponents';
import { Button, Typography, Card, Container, CircularProgress } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    cardBox: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginTop: '5rem !important'
    },
    splitCard: {
        height: '23rem !important',
        width: '15rem !important',
        justifyContent: 'center !important',
        marginBottom: '1rem !important',
        borderRadius: '50px !important'
    },
    cardTitleAndIcons: {
        justifyContent: 'space-between !important',
        marginTop:"1rem"
    },
    cardTitleAndIcon: {
        alignItems: 'center !important'
    },
    cardTitle: {
        fontSize: '1.1rem !important',
        fontWeight: 'bold !important',
    },
    text: {
        color: 'black !important',
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#3a7735 !important',
        marginTop: '2rem !important',
        borderRadius: '50px !important',
        width: '9.75rem !important',
        color: '#ffffff !important',
        fontSize: '1rem !important'
    },
}));
export const PaymentMessage = ({ title, setLevel, approvePayment,tribe=false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false)

    const confirm = () => {
        approvePayment()
        setLoader(true)
    }
    return (
        <VBox className={classes.cardBox}>
            <Card className={classes.splitCard}>
                <Container style={{ marginTop: '2rem', textAlign: 'center' }}>
                    <HBox className={classes.cardTitleAndIcons}>
                        <HBox className={classes.cardTitleAndIcon} >
                            <AccountBalanceWalletOutlinedIcon />
                            <Typography className={classes.cardTitle}>{t('payWitheRightBalance')} </Typography>
                        </HBox>
                        <CloseOutlinedIcon onClick={e => setLevel(1)} style={{ cursor: "pointer" }} />
                    </HBox>
                    <VBox alignItems={"center"} marginTop={"1rem"}>
                        <Typography className={classes.text} fontSize={tribe?'1.1rem !important':"0.9rem !important"}>{title}</Typography>
                        {!loader ? <Button className={classes.greenButton} onClick={e => confirm()} mb={"1rem"}>{t("agreeBalance")}</Button> : <CircularProgress />}
                    </VBox>
                </Container>
            </Card>
        </VBox>)
}