import { Box } from '@mui/material';
import { IdPassTab } from '../../Components/Login/IdPassTab';
import { SmsVerification } from '../../Components/SmsVerification';
import { ForgetPassword } from '../../Components/Login/ForgetPassword';
import { ResetPassword } from '../../Components/Login/ResetPassword';
import { MySlider } from '../../Components/Common/MySlider';
import useGlobal from 'store/store.js';
import { useState } from 'react';

export const IdPassProcess = (props) => {
    const { handleNoMatchingParentFound, PhoneNumber, changePhoneValue, containerSlideRef } = props;

    const [email, setEmail] = useState("");

    const [LoginIdProcess, setLoginIdProcess] = useGlobal(state => state.LoginIdProcess, actions => actions.setLoginIdProcess);
    return (
        <Box height={'100%'} justifyContent="center" >
            {LoginIdProcess === 1 &&
                <MySlider container={containerSlideRef.current} CurrentLevel={LoginIdProcess === 1} hideTimeout={true}>
                    <IdPassTab setLoginIdProcess={setLoginIdProcess} />
                </MySlider>}
            {LoginIdProcess === 2 &&
                <MySlider container={containerSlideRef.current} CurrentLevel={LoginIdProcess === 2} hideTimeout={false}>
                    <ForgetPassword
                        changePhoneValue={changePhoneValue}
                        PhoneNumber={PhoneNumber}
                        setLoginIdProcess={setLoginIdProcess} />
                </MySlider>}

            {LoginIdProcess === 3 &&
                <MySlider container={containerSlideRef.current} CurrentLevel={LoginIdProcess === 3} hideTimeout={false}>
                    <SmsVerification
                        changePhoneValue={changePhoneValue}
                        setLoginIdProcess={setLoginIdProcess}
                        setEmail={setEmail}
                        type="forgotPass"
                        PhoneNumber={PhoneNumber}
                        handleNoMatchingParentFound={handleNoMatchingParentFound}
                    >
                    </SmsVerification>
                </MySlider>}
            {LoginIdProcess === 5 &&
                <MySlider container={containerSlideRef.current} CurrentLevel={LoginIdProcess === 5} hideTimeout={false}>
                    <ResetPassword
                        email={email}
                        handleLoginIdProcess={e => setLoginIdProcess(1)} />
                </MySlider>}
        </Box>


    );
}