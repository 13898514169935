import { useEffect } from 'react'
import { usePathParams } from 'raviger'
import useGlobal from 'store/store';
import { useNavigate } from 'raviger';
import { system } from "../api/client"

const DeepLinkHandle = () => {
    const { dlCode } = usePathParams('/dl/:dlCode');
    const navigate = useNavigate();
    const [deepLink, setDeepLink] = useGlobal(
        (state) => state.deepLink,
        (actions) => actions.setDeepLink);
    const [user] = useGlobal(
        (state) => state.user);
    useEffect(() => {
        const deepLinkProps = getDeepLinkProps();
        !!deepLinkProps && setDeepLink(deepLinkProps);

        const hasToken = localStorage.getItem(system === "prod" ? `zofim-parents-portal-token` : `zofim-parents-portal-token-${system}`);
        if (hasToken === null || user.role === "tribe") {
            navigate("/");
        }
        else {
            navigate("/parent");
        }
    }, [])

    const getDeepLinkProps = () => {
        let deepLinkProps = null;
        const props = dlCode.split("-");
        if (props.length === 4)
            deepLinkProps = {
                tribeCode: props[0],
                activCode: props[1],
                classCode: props[2],
                unlockCode: props[3]
            }
        return deepLinkProps;
    }
    return (<></>)
}
export default DeepLinkHandle;