import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { paymentsService, addCreditTransactionAPI } from '../../api/api'
import { Box, Typography } from '@mui/material';
import useGlobal from "../../store/store";
import { PaymentLoader } from "./PaymentLoader";
import noActivity from '../../assets/noActivity.svg';

const SplitPaymentHandler = ({ products, invoices }) => {
    const { t } = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState();
    const paymentsServiceAPI = useApi(paymentsService);
    const [someError, setSomeError] = useState(false);
    const [user] = useGlobal((state) => state.user);

    useEffect(() => {
        transaction();
    }, [])

    const transaction = async () => {
        try {
            const pymtData = {
                "registrationRequests": products,
                "getPaymentLink": true,
                "divorced": { isDivorced: true, divorced2: true },
                "origin": window.location.origin
            }


            const result = await paymentsServiceAPI.requestPromise(pymtData);

            setRedirectUrl(result.cg.ashrait.response.doDeal.mpiHostedPageUrl._text);
            const productsIds = pymtData.registrationRequests.map((pr) => { return pr._id });

            const transction = {
                mid: result.cg.ashrait.response.doDeal.mid._text,
                terminalNumber: result.cg.ashrait.response.doDeal.terminalNumber._text,
                txId: result.cg.ashrait.response.doDeal.token._text,
                isDivorced: true,
                invoices,
                productsIds,
                userEmail: user.email,
                userPhoneNumber: user.phone_number,
                parent1_Email: pymtData.registrationRequests[0].Parent1_Mail,
                parent1_Id: pymtData.registrationRequests[0].Parent1_ID,
                parent1_Firstname: pymtData.registrationRequests[0].Parent1_FirstName,
                parent1_Lastname: pymtData.registrationRequests[0].Parent1_LastName,
                parentCardCode: pymtData.registrationRequests[0].ParentCardCode,
                tribeCode: pymtData.registrationRequests[0].TribeCode,
                userRole: user.role,
                date: new Date().toString()
            }
            if (result.cg.ashrait.response.doDeal.mpiHostedPageUrl._text) {
                setRedirectUrl(result.cg.ashrait.response.doDeal.mpiHostedPageUrl._text);
            }
            else {
                const error = { cgFaild: true };
                throw error;
            }
            await addCreditTransactionAPI(transction);
        } catch (error) {
            setSomeError(true);
            console.log(error);
        }
    }
    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            {(paymentsServiceAPI.error || someError) &&
                <><img
                    width='100px'
                    height='100px'
                    alt="..."
                    src={noActivity}
                    loading="lazy"
                />
                    <Typography sx={{ fontWeight: 'bold', color: "#064601", textAlign: "center" }}>{t("oops")}</Typography>
                    <Typography sx={{ fontWeight: 'bold', color: "#064601", textAlign: "center" }}>{t("dontfall")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("youCanChoose")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("refrash")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("supports")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("otherPaymentMethod")}</Typography>
                </>}
            {paymentsServiceAPI.loading ?
                <PaymentLoader /> :
                <iframe src={redirectUrl}
                    id={"creditGuardiFrame"}
                    style={{ display: "block", border: "none", height: "43rem", width: "100%", marginTop: "1rem" }}
                    title={"splitPayment"} />
            }
        </Box>
    )
}
export default SplitPaymentHandler;



