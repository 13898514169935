import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns'
import { VBox, HBox } from '../../../assets/SharedComponents';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { he } from "date-fns/locale";
import { TextField, Grid, Button, Typography, Container, Autocomplete, IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { associationInfo } from '../../../api/api'
import useApi from 'hooks/useApi';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    field: {
        marginBottom: "1rem !important",
        marginLeft: "1rem !important",
        width: "10rem",
    },
    field1: {
        width: "7rem !important",
    },
    checkInfo: {
        alignItems: 'center',
        justifyContent: ' space-between !important'
    },
    checkIndex: {
        lineHeight: '20px !important',
        fontSize: '1rem !important',
        color: '#007f00 !important'
    },
    clearFields: {
        textAlign: "left !important",
        fontSize: '1rem !important',
        color: '#007f00 !important'
    }
}));


const PaymentByCheck = ({ check, setTotalCheckAmount, setPaymentDetails, paymentDetails, checkOverFlow, overPay, finalPrice, confirmChack, setOverPay, removeCheck, setDoneDisable }) => {
    const [disabeldField, setDisabeldField] = useState(false)
    return (
        <VBox sx={{ p: 1 }} marginBottom={"1rem"}>
            <Grid container={true} columns={1} rows={3} flexDirection={'column'}>
                <Container>
                    <CheckFields
                        check={check}
                        setPaymentDetails={setPaymentDetails}
                        paymentDetails={paymentDetails}
                        checkOverFlow={checkOverFlow}
                        overPay={overPay}
                        setOverPay={setOverPay}
                        setTotalCheckAmount={setTotalCheckAmount}
                        finalPrice={finalPrice}
                        confirmChack={confirmChack}
                        disabeldField={disabeldField}
                        setDisabeldField={setDisabeldField}
                        removeCheck={removeCheck}
                        setDoneDisable={setDoneDisable}
                    />
                </Container>
            </Grid>
        </VBox >

    )
}

const CheckFields = ({ check, setPaymentDetails, paymentDetails, overPay, setTotalCheckAmount, finalPrice, setOverPay, disabeldField, setDisabeldField, removeCheck, setDoneDisable }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getBanks = useApi(associationInfo);
    const [bankList, setBankList] = useState([]);
    const [dateError, setDateError] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const bankRef = useRef(null);
    const today = new Date();
    const currentmonth = today.getMonth();
    const currentYear = today.getFullYear();
    const sixMonthAgo = new Date();
    sixMonthAgo.setMonth(currentmonth - 6);
    const yearFromNow = new Date();
    yearFromNow.setFullYear(currentYear + 1);

    useEffect(async () => {
        const res = await getBanks.requestPromise();
        setBankList(res.d.results[0].MasterDataBankList.results);
    }, []);

    const validationSchema = yup.object({
        CheckSum: yup
            .string(t("amount"))
            .required(t("enterAmount"))
            .matches(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/, t("enterCorrectAmount"))
            .min(1, t("enterCorrectAmount"))
            .max(finalPrice, t("amountHigherThanRequired")),
        CheckNum: yup
            .string(t("chequeNum"))
            .required(t("enterChequeNum"))
            .min(3, t("enterCorrectChequeNum"))
            .max(15, t("enterCorrectChequeNum"))
            .matches(/^0*[0-9]*$/, t("enterCorrectChequeNum")),
        BankAccount: yup
            .string(t("accountNumber"))
            .required(t("enterAccountNum"))
            .min(5, t("enterCorrectAccountNum"))
            .matches(/^[0-9]*$/),
        BankBranch: yup
            .string(t("branchNum"))
            .required(t("enterBranchNum"))
            .min(3, t("enterCorrectBranchNum"))
            .matches(/^[0-9]*$/),
        BankCode: yup
            .string(t("bankNum"))
            .nullable()
            .required(t("chooseBankNum")),
        CheckDate: yup
            .date(t("dueDate"))
            .required(t("mandatoryField"))
    });
    const formik = useFormik({
        initialValues: {
            checkIndex: check.checkIndex,
            CheckSum: check.CheckSum,
            CheckNum: check.CheckNum,
            BankAccount: check.BankAccount,
            BankBranch: check.BankBranch,
            BankCode: check.BankCode,
            CheckDate: check.CheckDate || new Date()
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onReset: (values) => {
            const ele = bankRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();
            values.CheckDate = new Date();
            values.CheckSum = 0;
            values.BankCode = null;
            values.checkIndex = values.checkIndex;
            values.CheckNum = '';
            values.BankAccount = '';
            values.BankBranch = '';
            const newCheck = values;
            const checks = paymentDetails.checks;
            checks[check.checkIndex === "1" ? 0 : check.checkIndex - 1] = newCheck;
            setPaymentDetails({ ...paymentDetails, checks });
            setTotalCheckAmount(0);
            setDisabeldField(false);
        },
        onSubmit: (values) => {
            console.log(values)
            if (values.BankCode === null) {
                enqueueSnackbar(t("chooseBankNum"), { className: classes.redBG });
                return;
            }
            setDisabeldField(true);
            values.CheckDate = format(new Date(values.CheckDate), "yyyy-MM-dd");
            values.CheckSum = parseFloat(values.CheckSum);
            const newCheck = values;
            const checks = paymentDetails.checks;
            checks[check.checkIndex - 1] = newCheck;
            setPaymentDetails({ ...paymentDetails, checks });
            setTotalCheckAmount(paymentDetails.checks.reduce((accum, check) => accum + (parseFloat(check.CheckSum) || 0), 0));
            setDoneDisable(false);
            enqueueSnackbar(t("checkNote"), { className: classes.orangeBG });
        }
    });
    useEffect(() => {
        const checksToatl = paymentDetails.checks.reduce((accum, check) => accum + (parseFloat(check.CheckSum) || 0), 0) + +formik.values.CheckSum;
        if (Number(checksToatl) > finalPrice) {
            setOverPay(true);
        }
        else {
            setOverPay(false);
        }
    }, [formik.values.CheckSum])

    const acceptCheckDates = (newValue) => {
        sixMonthAgo.setHours(0, 0, 0, 0);
        formik.setFieldValue("CheckDate", newValue);
        if (newValue >= sixMonthAgo && newValue <= yearFromNow) {
            setDateError(false);
        } else {
            setDateError(true);
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <HBox className={classes.checkInfo}>
                <Typography className={classes.checkIndex}>{t('check')} {check.checkIndex} </Typography>
                <Button onClick={formik.handleReset} className={classes.clearFields} type="reset">{t('clear')}</Button>
                {check.checkIndex !== 1 && <IconButton onClick={e => removeCheck(check.checkIndex)}><DeleteIcon sx={{ color: "#99292e" }} /></IconButton>}
            </HBox>
            <VBox flexDirection={'column'} alignItems={'flex-end'}>
                <TextField
                    disabled={disabeldField}
                    className={`${classes.field} ${classes.RemoveTextFieldNumberArrow}`}
                    id="CheckSum"
                    name="CheckSum"
                    label={t('amount')}
                    value={formik.values.CheckSum || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={(formik.touched.CheckSum && Boolean(formik.errors.CheckSum)) || overPay}
                    helperText={overPay ? t('overAmountError') : (formik.touched.CheckSum && formik.errors.CheckSum)}
                    type="text"
                    InputProps={{
                        inputProps: {
                            inputMode: 'decimal',
                            pattern: "[0-9]+(\.[0-9]{1,2})?",
                            title: "נא להזין עד שתי ספרות אחר הנקודה"
                        }
                    }}
                />
                <TextField
                    disabled={disabeldField}
                    className={`${classes.field} ${classes.RemoveTextFieldNumberArrow}`}
                    id="CheckNum"
                    name="CheckNum"
                    label={t('checkNumber')}
                    value={formik.values.CheckNum}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.CheckNum && Boolean(formik.errors.CheckNum)}
                    helperText={formik.touched.CheckNum && formik.errors.CheckNum}
                    type='text'
                    InputProps={{
                        inputProps: {
                            inputMode: 'tel'
                        }
                    }}
                />
            </VBox>
            <VBox flexDirection={'column'}>
                <HBox flexDirection={{ md: 'row', xs: 'column' }}>
                    {bankList &&
                        <Autocomplete
                            disabled={disabeldField}
                            ref={bankRef}
                            id="BankCode"
                            name="BankCode"
                            isOptionEqualToValue={(option, value) => ((option.BankCode === value.BankCode) || null)}
                            className={classes.field}
                            noOptionsText={t("bankNotFound")}
                            options={bankList}
                            onBlur={formik.handleBlur}
                            getOptionLabel={(option) => option !== null ? `${option.BankName}` : ""}
                            onChange={(e, value) => formik.setValues({ ...formik.values, "BankCode": value === null ? null : value.BankCode })}
                            renderInput={(params) =>
                                <TextField {...params} label={t('bankNumber')}
                                    id="BankCode"
                                    name="BankCode"
                                    error={formik.touched.BankCode && Boolean(formik.errors.BankCode)}
                                    helperText={formik.touched.BankCode && formik.errors.BankCode} />}
                        />}

                    <TextField
                        disabled={disabeldField}
                        className={`${classes.field} ${classes.RemoveTextFieldNumberArrow}`}
                        id="BankBranch"
                        name="BankBranch"
                        label={t('branchNumber')}
                        value={formik.values.BankBranch}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.BankBranch && Boolean(formik.errors.BankBranch)}
                        helperText={formik.touched.BankBranch && formik.errors.BankBranch}
                        type="number"
                        InputProps={{
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            }
                        }}
                    />
                    <TextField
                        disabled={disabeldField}
                        className={`${classes.field} ${classes.RemoveTextFieldNumberArrow}`}
                        id="BankAccount"
                        name="BankAccount"
                        label={t('bankAccountNumber')}
                        value={formik.values.BankAccount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.BankAccount && Boolean(formik.errors.BankAccount)}
                        helperText={formik.touched.BankAccount && formik.errors.BankAccount}
                        type="number"
                        InputProps={{
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            }
                        }}
                    />
                </HBox>
            </VBox>
            <VBox alignItems={'flex-end'}>
                <LocalizationProvider adapterLocale={he} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disabled={disabeldField}
                        inputFormat="dd/MM/yyyy"
                        label={t("dueDate")}
                        minDate={sixMonthAgo}
                        maxDate={yearFromNow}
                        id="CheckDate"
                        name="CheckDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.CheckDate}
                        onChange={(newValue) => acceptCheckDates(newValue)}
                        error={formik.touched.CheckDate && Boolean(formik.errors.CheckDate)}
                        onAccept={(newValue) => acceptCheckDates(newValue)}
                        helperText={formik.touched.CheckDate && formik.errors.CheckDate}
                        renderInput={(params) => <TextField {...params} helperText={null} className={classes.field} />}
                    />
                </LocalizationProvider>
            </VBox>
            <Button color="primary" variant="contained" type="submit" sx={{ width: "max-content !important" }} disabled={formik.values.CheckSum === 0 || overPay || disabeldField}>
                {t("confirmChequeDetails")}
            </Button>
        </form>
    )
}
export default PaymentByCheck;