import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

//
import palette from './palette';
import typography from './typography';

//rtl
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';


const ThemeProvider = ({ children }) => {
    const cacheRtl = createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin]
    });
    const themeOptions = useMemo(
        () => ({
            direction: 'rtl',
            palette,
            typography
        }),
        []
    );
 
    const theme = createTheme(themeOptions);
    return (
        <StylesProvider>
            <CacheProvider value={cacheRtl}>
                <MUIThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </MUIThemeProvider>
            </CacheProvider>
        </StylesProvider>
    );
}


export default ThemeProvider