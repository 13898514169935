import { useState, useRef } from "react";
import { Box, Button, CircularProgress, Typography, TextField, FormHelperText } from "@mui/material"
import { styled } from '@mui/system';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { useTranslation } from "react-i18next";
import securityQuestions from '../../assets/securityQuestions.svg';
import getSMS from '../../assets/getSMS.svg';
import foundFamily from "../../assets/foundFamily.svg"
import MobileSuccess from '../../assets/MobileSuccess.svg';
import GenericDialog from "Components/Common/GenericDIalog";
import { useSnackbar } from 'notistack';
import useVerification from "hooks/useVerification";
import ReactCodeInput from 'react-verification-code-input';
import { makeOneHosuehold } from "api/api.js";
import { useNavigate } from "raviger";
import DoneIcon from '@mui/icons-material/Done';
import ContactDialog from "Components/Common/ContactDialog.jsx";

export const GreenButton = styled(Button)(({ negative }) => ({
    "&:hover": {
        background: '#cee2cd !important'
    },
    backgroundColor: negative === 'true' ? '#fff ' : '#3a7735 !important',
    color: negative === 'true' ? '#3a7735 !important' : "#ffffff !important",
    border: negative === 'true' ? '1.5px solid #3a7735' : 'none',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    margin: '1rem !important',
    borderRadius: '50px !important',
    width: '14rem !important',
    textTransform: "capitalize !important"
}));

const AddAnotherParent = ({ open, setOpen, anotherParentName, anotherParentPhone, anotherParentId, regParentId, regParentIdType, regParentPhone }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [lastName, setLastName] = useState("");
    const [stage, setStage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [pupilId, setPupilId] = useState("");
    const [pupilBirthday, setPupilBirthday] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const hookVerification = useVerification();
    const pinInputRef = useRef(null);
    const inputRef = useRef();

    const navigate = useNavigate();

    const senderCode = async () => {
        setLoading(true);
        try {
            const confirmationResult = await hookVerification.sendOTP(anotherParentPhone);
            if (confirmationResult) {
                window.confirmationResult = confirmationResult;
                setStage(2);
                setLoading(false);
            }
        } catch (error) {
            enqueueSnackbar(error?.message || t("Error"), { variant: "error" });
        }
    }
    const handleComplete = async (value) => {
        setLoading(true);
        window.confirmationResult.confirm(value).then(async () => {
            try {
                const data = { anotherParentId, anotherParentPhone, regParentId, regParentIdType, regParentPhone, pupilUpdate: false };
                const res = await makeOneHosuehold(data);
                if (res.Status) {
                    setLastName(res.lastName)
                    setStage(4);
                }
            } catch (error) {

                enqueueSnackbar(error, { className: classes.redBG });
            }
        }).catch((error) => {
            const msg = hookVerification.parseFirebaseError(error);
            enqueueSnackbar(msg, { className: classes.redBG });
            try {
                pinInputRef.current.__clearvalues__();
            }
            catch (error) {
                console.log(error);
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleId = (e) => {
        let temp = e.target.value.slice(0, 30);
        setPupilId(temp);
    }
    const verifyPupil = async () => {
        setUpdateError(false);
        setLoading(true);
        const pupilBirthdayFormat = `${pupilBirthday?.year}-${pupilBirthday?.month}-${pupilBirthday?.day}`
        try {
            const data = { anotherParentId, anotherParentPhone, regParentId, regParentIdType, regParentPhone, pupilUpdate: true, pupilBirthdayFormat, pupilId };
            const res = await makeOneHosuehold(data);
            if (res.Status) {
                setLastName(res.lastName)
                setStage(4);
            }
            else {
                setUpdateError(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error, { className: classes.redBG });
        }
    }

    const svgIcon = stage === 1 ? foundFamily : stage === 2 ? getSMS : stage === 3 ? securityQuestions : stage === 4 && MobileSuccess;
    return (
        <>
            <GenericDialog open={open} setOpen={setOpen} iconSVG={svgIcon} showRopes={true} showExit={false} height={"26rem"}>
                {stage === 1 && <>
                    <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "0.9rem", md: "1.5rem" } }}>{`${t('joinToFamily')}${anotherParentName} ${t('joinToFamily1')}`}</Typography>
                    <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('needApprove')}</Typography>
                    <Typography mt="3rem" sx={{ fontSize: "0.875rem", color: "#3a7735", textAlign: "center" }}>{`${t('sentVer')}${anotherParentName} ${t('toApprove')}`}</Typography>
                    {loading ?
                        <CircularProgress color="inherit" size={20} sx={{ margin: '1rem' }} /> :
                        <GreenButton onClick={e => senderCode()}>{t('sendToPhone')}</GreenButton>}

                </>
                }
                {stage === 2 &&
                    <>
                        <Typography color="#064601" variant="mediumBoldText" mb="3rem">{t('pleaseEnterCode')}</Typography>
                        <Box display="flex" alignItems="center" flexDirection='column' justifyContent='center' style={{ direction: 'ltr', marginTop: "3rem", marginBottom: "1rem" }}>
                            {loading &&
                                <CircularProgress color="inherit" size={20} sx={{ margin: '1rem' }} />}
                            <ReactCodeInput
                                autoComplete={false}
                                type='number'
                                inputMode="numeric"
                                autoFocus={true}
                                onComplete={handleComplete}
                                fields={6}
                                ref={pinInputRef}
                                color='black'
                                className={classes.codePinInputStyle}
                                disabled={!!loading} />
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="left">
                            <Button onClick={e => setStage(3)} className={classes.buttonWhite} sx={{ width: { xs: "100%", md: "15rem" } }}>
                                {t("doWithQuestion")}
                            </Button>
                        </Box>
                    </>
                }
                {stage === 3 &&
                    <>
                        <Box display="flex" flexDirection='column' alignItems='center'>
                            <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('familyChildId')} </Typography>
                            <TextField
                                type="number"
                                name="pupilId"
                                sx={{ width: "70% !important" }}
                                value={pupilId}
                                className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow}`}
                                onChange={e => handleId(e)}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                        inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    },
                                    inputRef: inputRef
                                }}
                                onWheel={(e) => {
                                    if (inputRef && inputRef.current && inputRef.current.blur) {
                                        inputRef.current.blur();
                                    }
                                }} />
                            <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('familyChildBirthday')}</Typography>
                            <BirthdayInput setPupilBirthday={setPupilBirthday} />

                            {updateError && <Typography sx={{ fontSize: "1.1rem", color: "#bb0000", textAlign: "center" }} >{t('notCorrectDetails')}</Typography>}

                            {loading ?
                                <CircularProgress color="inherit" size={30} sx={{ margin: '1rem' }} /> :
                                <Box display='flex' mt="1rem" flexDirection={{ xs: 'column', sm: 'row' }} alignItems='center'>
                                    <GreenButton disabled={!pupilBirthday || !pupilId} onClick={e => verifyPupil()}>{t('doneToVerify1')}</GreenButton>
                                    <GreenButton negative={"true"} onClick={e => setOpenContact(true)}>{t("support")}</GreenButton>
                                </Box>
                            }
                        </Box>
                    </>
                }
                {stage === 4 &&
                    <>
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start'>
                            <DoneIcon sx={{ fontSize: "1.1rem", color: "#3a7735", marginRight: "0.3rem", fontWeight: "bold" }} />
                            <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('funYouAdded')} {lastName}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start'>
                            <DoneIcon sx={{ fontSize: "1.1rem", color: "#3a7735", marginRight: "0.3rem", fontWeight: "bold" }} />
                            <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('weCreateUser')}</Typography>
                        </Box>
                        <Typography mt="3rem" sx={{ fontSize: "0.875rem", color: "#3a7735", textAlign: "center" }}>{t('nowJustLogin')}</Typography>
                        <GreenButton mt="1rem" onClick={e => navigate('/')}>{t('enterWithOTP')}</GreenButton>
                    </>
                }
            </GenericDialog>

            {openContact &&
                <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}

            <div ref={ref => window.recaptchaWrapperRef = ref}>
                <div id="recaptcha-container"></div>

            </div>
        </>
    )
}
const BirthdayInput = ({ setPupilBirthday }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [birthdayError, setBirthdayError] = useState(false);
    const [birthday, setBirthday] = useState({ year: '', month: '', day: '' });
    const yearRef = useRef(null);
    const monthRef = useRef(null);
    const dayRef = useRef(null);

    const isValidYear = (value) => {
        const currentYear = new Date().getFullYear();
        const limitMinYear = currentYear - 19;
        const limitMaxYear = currentYear - 7;
        if (value >= limitMinYear && value <= limitMaxYear) {
            setBirthday({ ...birthday, year: value })
            setBirthdayError(false);
            setPupilBirthday({ ...birthday, year: value })
        } else
            setBirthdayError(true);

    }

    const isValidMonth = (value) => {
        if (value >= 1 && value <= 12) {
            setBirthday({ ...birthday, month: value })
            setBirthdayError(false);
            yearRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }

    const isValidDay = (value) => {
        if (value >= 1 && value <= 31) {
            setBirthday({ ...birthday, day: value })
            setBirthdayError(false);
            monthRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }

    const onBirthdayDayChange = day => {
        setBirthday({ ...birthday, day });
    }
    const onBirthdayMonthChange = month => {
        setBirthday({ ...birthday, month });
        if (month === "") {
            dayRef.current.iRefs[1].current.focus();
        }
    }
    const onBirthdayYearChange = year => {
        setBirthday({ ...birthday, year });
        if (year === "") {
            monthRef.current.iRefs[1].current.focus();
        }
    }
    return (
        <>
            <Box display='flex' flexDirection='row' sx={{
                alignItems: "flex-end",
                width: "max-content",
                direction: "rtl !important"
            }}>
                <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidDay} autoFocus={false} onChange={onBirthdayDayChange} values={birthday.day} fields={2}
                    className={classes.datePinInputStyle} ref={dayRef} />
                <Typography align="center" component="h2" variant="h6">
                    /
                </Typography>
                <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidMonth} autoFocus={false} onChange={onBirthdayMonthChange} values={birthday.month} fields={2}
                    className={classes.datePinInputStyle} ref={monthRef} />
                <Typography align="center" component="h2" variant="h6">
                    /
                </Typography>
                <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidYear} autoFocus={false} onChange={onBirthdayYearChange} values={birthday.year} fields={4}
                    className={classes.datePinInputStyle} ref={yearRef} />
            </Box>
            <FormHelperText error={true} className={birthdayError ? classes.marginBottom1 : ''}>{birthdayError && t('validDate')}</FormHelperText>
        </>
    )
}
export default AddAnotherParent;
