import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//mui
import { makeStyles } from '@mui/styles';
import { Card, TextField, Autocomplete, Typography, Input, LinearProgress, Container, Box, Table, TableRow, TableHead, TableBody, TableCell, FormHelperText, Button, IconButton } from '@mui/material';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
//components
import { VBox, HBox } from '../../../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important',
        alignItems: 'center !important',
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    statmentcard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important',
    },
    statmentCardContent: {
        textAlign: 'right !important',
        justifyContent: 'flex-start !important',
        alignItems: "center !important"
    },
    select: {
        '&.MuiInput-underline:after': {
            'borderBottom': '#e0e0e0 !important',
        },
        height: '2rem !important',
        width: '10rem !important',
        borderRadius: '1.7rem !important',
        marginLeft: '0.5rem !important'
    },
    statmentAutoComplite: {
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: "0 !important",
            minWidth: "0px !important"
        }
    },
    tc: {
        "&.MuiTableCell-body": {
            padding: '0 !important'
        }
    },
    buttons: {
        "&:hover": {
            background: '#e0e0e0 !important',
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important ',
        marginRight: '0.2rem !important',
        marginBottom: '0.2rem !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    },
    buttons1: {
        "&:hover": {
            background: '#b6f3b5 !important',
        },
        backgroundColor: '#b6f3b5 !important',
        border: '1px solid #007f00 !important',
    },
}));

const OTHER_ALERGY = "18";
const NO_ALLERGY = "0";

const Allergies = ({ household, allergies, allergiesListApi, otherAllergyName, allergiesValidator, setAllergiesValidator, cardCode, setNeedEmerContact, clearSignature }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [allergiesList, setAllergiesList] = useState([]);
    const [userAllergyInput, setUserAllergyInput] = useState("")
    const [haveAllergy, setHaveAllergy] = useState(false);
    const [printArray, setPrintArray] = useState([]);

    const allergiesListApiFiltered = allergiesListApi ? allergiesListApi.filter(el => el.AllergyCode !== NO_ALLERGY) : [];

    //initialization of child allergies from Sap
    useEffect(() => {
        const childAllergies = [];
        if (allergies.length > 0 && !!allergiesListApi) {
            // get children allergies from sap and check they also in the allergiesAPI
            allergies.forEach((allergy) => {
                const allergyFound = allergiesListApi.find((allergyName) => allergy.AllergyCode === allergyName.AllergyCode && allergy.AllergyCode !== NO_ALLERGY);
                if (allergyFound) {
                    childAllergies.push({ Medicare: allergy.Medicare, Danger: allergy.Danger, Treatment: allergy.Treatment, AllergyCode: allergy.AllergyCode });
                }
            })
            if (childAllergies.length) {
                setHaveAllergy(true);
                if (childAllergies.some(dis => dis.Medicare === "Y")) {
                    setNeedEmerContact(true);
                }
            }
            setAllergiesList(childAllergies);
        }
        if (otherAllergyName !== null) {
            setAllergiesValidator(true);
            setUserAllergyInput(otherAllergyName);
        }
    }, [allergiesListApi])

    //select chosen option and user input validation, 18 code means otherAllergy
    useEffect(() => {
        clearSignature();
        const validList = allergiesList.every((allergy) => {
            const diseaseExsist = !!allergy.AllergyCode;
            const validOtherDisease = (allergy.AllergyCode !== OTHER_ALERGY || !!userAllergyInput);
            const validDanger = (allergy.Medicare === "N" || !!allergy.Treatment);

            return validDanger && validOtherDisease && diseaseExsist;
        })
        const validator = !haveAllergy || validList;
        setAllergiesValidator(validator);
        if (allergiesList.some(dis => dis.Medicare === "Y")) {
            setNeedEmerContact(true);
        } else {
            setNeedEmerContact(false);
        }
    }, [allergiesList, userAllergyInput, haveAllergy]);

    // insert to household for every change
    useEffect(() => {
        if (!!allergiesListApiFiltered.length)
            insertToHousehold();
    }, [userAllergyInput, allergiesList, haveAllergy]);

    //code list creation to update child allergies
    const insertToHousehold = () => {
        const child = household.ChildrenList?.results.find((child) => child.CardCode === cardCode);

        const arrayToPrint = [];
        if (allergiesList.length > 0) {
            child.AllergiesList.results = allergiesList;
            for (let i = 0; i <= allergiesListApiFiltered.length; i++) {
                for (let j = 0; j <= allergiesList.length; j++) {
                    if (allergiesListApiFiltered[i]?.AllergyCode === allergiesList[j]?.AllergyCode) {
                        if (allergiesList[j]?.Medicare === "Y") {
                            arrayToPrint.push(`${allergiesListApiFiltered[i]?.AllergyName} - ${t("lifeDanger")}, ${t("medTreatment")} :${allergiesList[j]?.Treatment}`);
                        }
                        else {
                            arrayToPrint.push(allergiesListApiFiltered[i]?.AllergyName);
                        }
                    }
                }
            }
            if (userAllergyInput.length > 0) {
                child.OtherAllergyName = userAllergyInput;
                arrayToPrint.push(userAllergyInput);
            }
        }
        // no allergy
        else {
            child.AllergiesList.results = [{ Medicare: "N", Danger: "N", Treatment: "", AllergyCode: "0" }];
            arrayToPrint.push(t("noAllergiesCompareString"));
        }
        setPrintArray(arrayToPrint);
    }

    const disabledOptions = (option) => {
        const isChosen = allergiesList.find(({ AllergyCode }) => AllergyCode === option.AllergyCode);
        return !!isChosen;
    }
    const setOtherAllergy = (e, type) => {
        const regex = /^[a-z\s\u0590-\u05fe]+$/i
        const value = e.target.value;
        if (regex.test(value) || value === '') {
            if (type === "otherAllergy")
                setUserAllergyInput(value);
        }
    }
    const addAllergy = () => {
        setAllergiesList(curr => [...curr, { Danger: "N", Medicare: "N", Treatment: "", AllergyCode: "" }]);
    }
    const changeAllergy = (value, index, reason) => {
        const updateList = [...allergiesList];
        const clearClick = reason === "clear";
        updateList[index].AllergyCode = clearClick ? '' : value.AllergyCode;
        setAllergiesList(updateList);
    }

    const changeDangerous = (index, value) => {
        const updateList = structuredClone(allergiesList);
        updateList[index].Medicare = value;
        updateList[index].Danger = value;
        if (value === "N") {
            updateList[index].Treatment = '';
        }
        setAllergiesList(updateList);
    }
    const changeTreatment = (e, index) => {
        const value = e.target.value;
        const updateList = [...allergiesList];

        updateList[index].Treatment = value;
        setAllergiesList(updateList);

    }
    const findAllergyName = (AllergyCode) => {
        const result = allergiesListApiFiltered.find(el => el.AllergyCode === AllergyCode);
        return result ? result.AllergyName : "";
    }
    const removeAllergy = (index) => {
        if (allergiesList.length === 1) {
            setHaveAllergy(false);
        }
        setAllergiesList(prev => prev.filter((el, i) => i !== index)); // filter by index
    }
    const handleHaveAllergy = (boolean) => {
        setAllergiesList([]);
        setHaveAllergy(boolean);

        if (boolean) {
            addAllergy();
        }
    }

    return (
        !!allergiesListApiFiltered ?
            <>
                <Card className={classes.statmentcard} expanded={(allergiesList.length > 0).toString()} data-html2canvas-ignore="true" >
                    <Container>
                        <HBox className={classes.statmentCardContent} width="100%">
                            <VBox width="100%">
                                <HBox className={classes.cardTitleAndIcon} >
                                    <CoronavirusOutlinedIcon sx={{ color: "#3a7735" }} />
                                    <Typography className={classes.cardTitle} >{t('allergies')} </Typography>
                                </HBox>
                                <Typography variant='smallText'>{t("haveAllergies")} </Typography>
                                <HBox mb={"1rem"} alignItems="center">
                                    <Button sx={{ minWidth: '3rem', mr: '0.5rem' }} className={`${!haveAllergy && classes.buttons1} ${classes.buttons}`} onClick={e => handleHaveAllergy(false)}>{t('no')}</Button>
                                    <Button sx={{ minWidth: '3rem' }} className={`${haveAllergy && classes.buttons1} ${classes.buttons}`} onClick={e => handleHaveAllergy(true)}>{t('yes')}</Button>
                                </HBox>
                                {haveAllergy &&
                                    allergiesList.map((allergy, index) =>
                                        <VBox mb="2rem" key={allergy.AllergyCode + 'index'} flexWrap="wrap">
                                            <Box display='flex' flexDirection={{ xs: "column", lg: "row" }} flexWrap="wrap">
                                                {/* allergy options */}
                                                <Box flex={1}>
                                                    <Typography variant='smallText' >{t("theDisease")} </Typography>
                                                    <Autocomplete
                                                        getOptionDisabled={disabledOptions}
                                                        options={allergiesListApiFiltered.sort((a, b) => {
                                                            if (a.AllergyName === t("otherCompareString")) {
                                                                return 1;
                                                            }
                                                            if (b.AllergyName === t("otherCompareString")) {
                                                                return -1;
                                                            }
                                                            if (a.AllergyName === t("noAllergiesCompareString")) {
                                                                return -1;
                                                            }
                                                            if (b.AllergyName === t("noAllergiesCompareString")) {
                                                                return 1;
                                                            }
                                                            return a.AllergyName.toString().localeCompare(b.AllergyName.toString());
                                                        }
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" {...props} key={option.key}>
                                                                {option.AllergyName}
                                                            </Box>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.AllergyCode === value}
                                                        defaultValue={allergy.AllergyCode}
                                                        className={classes.statmentAutoComplite}
                                                        noOptionsText={t('notOption')}
                                                        getOptionLabel={findAllergyName}
                                                        onChange={(event, value, reason) => changeAllergy(value, index, reason)}
                                                        sx={{ mr: '0.9rem' }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant='standard' />
                                                        )}
                                                    />
                                                </Box>
                                                {allergy.AllergyCode === OTHER_ALERGY &&
                                                    <Box flex={1}>
                                                        <Typography variant='smallText' >{t("insertAllergy")} </Typography>
                                                        <Input
                                                            value={userAllergyInput}
                                                            className={classes.select}
                                                            inputProps={{ maxLength: 50 }}
                                                            onChange={e => setOtherAllergy(e, "otherAllergy")}
                                                        />
                                                    </Box>}

                                                {/* is dangerous ? */}
                                                <VBox flex={1} alignItems='flex-start'>
                                                    <Typography variant='smallText' >{t("isDangerous")} </Typography>
                                                    <HBox marginTop={"1rem"} alignItems="center">
                                                        <Button sx={{ minWidth: '3rem', mr: '0.5rem' }} className={`${allergy.Medicare === "N" && classes.buttons1} ${classes.buttons}`} onClick={e => changeDangerous(index, "N")}>{t('no')}</Button>
                                                        <Button sx={{ minWidth: '3rem' }} className={`${allergy.Medicare === "Y" && classes.buttons1} ${classes.buttons}`} onClick={e => changeDangerous(index, "Y")}>{t('yes')}</Button>
                                                    </HBox>
                                                </VBox>
                                                {/* treatment */}
                                                {allergy.Medicare === "Y" &&
                                                    <VBox flex={1} justifyContent='space-between'>
                                                        <Typography variant='smallText' >{t("whatIsTheTreatment")} </Typography>
                                                        <HBox alignItems="center">
                                                            <Input
                                                                defaultValue={allergy.Treatment}
                                                                inputProps={{ maxLength: 30 }}
                                                                onBlur={e => { changeTreatment(e, index) }}
                                                            />
                                                        </HBox>
                                                    </VBox>}
                                            </Box>
                                            <Box display='flex' justifyContent='flex-end' alignItems='center'>
                                                <IconButton onClick={e => removeAllergy(index)}><DeleteIcon sx={{ color: "#99292e" }} /></IconButton>
                                            </Box>
                                            {/* error msg */}
                                            {!allergiesValidator &&
                                                <FormHelperText error={true} style={{ fontSize: "0.8rem" }} >
                                                    {t('allergiesError')}
                                                </FormHelperText>}
                                        </VBox>)
                                }
                                {haveAllergy &&
                                    <HBox justifyContent='end' m=".5rem 0">
                                        <Button sx={{ display: allergiesListApi?.length === allergiesList.length && 'none' }} onClick={addAllergy} size="small" endIcon={<AddCircleIcon />}>
                                            {t('anotherDisease')}
                                        </Button>
                                    </HBox>}
                            </VBox>
                        </HBox>
                    </Container>
                </Card>

                {/* for print only */}
                <Box justifyContent='center' className={`${"showOnPrint"}_${cardCode}`} width={"100%"} sx={{ display: "none" }}>
                    <VBox className={classes.cardTitleAndIcon} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }} >{`${t('allergies')} `} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tc}>
                                        {printArray.join(", ")}
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                        {allergiesList.find(({ AllergyCode }) => AllergyCode === OTHER_ALERGY) && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }}> {t('otherAllergy')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tc}> {userAllergyInput}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>}
                    </VBox>
                </Box>
            </>
            : <LinearProgress color="primary" />

    )
}
export default Allergies;
