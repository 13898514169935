import { useState } from "react";
import useGlobal from "../store/store";
import { useNavigate, usePath } from 'raviger'
import { feathersClient } from "../api/client";
import { getChildrenListAPI } from "api/api";
import { getAuth, signInAnonymously } from "firebase/auth";
// import Quack from '@thequack/sdk'

const useFeathers = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useGlobal((state) => state.isLoggedIn, (actions) => actions.setIsLoggedIn);
    const [guestPayment, setGuestPayment] = useGlobal((state) => state.guestPayment, (actions) => actions.setGuestPayment);
    const [emailExsist, setEmailExsist] = useGlobal(state => state.emailExsist, actions => actions.setEmailExsist);
    const [emailEmpty, setEmailEmpty] = useGlobal(state => state.emailEmpty, actions => actions.setEmailEmpty);
    const [user, setUser] = useGlobal((state) => state.user, (actions) => actions.setUser);

    const hash = usePath();

    const handleEmailExsist = () => {
        navigate('/');
        setEmailExsist(true)
    }
    const handleEmailEmpty = () => {
        navigate('/');
        setEmailEmpty(true)
    }

    const authenticationWithToken = (strategy, access_token, emailVerified = false, username, resetPaawordCase = false, phoneNumber) => {
        return new Promise((resolve, reject) => {
            try {
                feathersClient.authenticate({
                    strategy,
                    access_token,
                    username,
                    phoneNumber
                }).then((authenticatedUser) => {
                    setIsLoggedIn(true);
                    // for a case there is no email on this stage
                    try {
                        // role determination
                        if (guestPayment) {
                            authenticatedUser.role = "guestPayment";
                        }
                        else {
                            authenticatedUser.role = authenticatedUser?.email.includes('zofim.org.il') ? 'tribe' : 'parent';
                        }
                        setUser(authenticatedUser);
                        // navigation target determination
                        if (resetPaawordCase) {
                            setUser(authenticatedUser);
                        }

                        else if (!authenticatedUser.hasChildren && authenticatedUser.role === 'parent' && emailVerified) {
                            navigate('/register/addchild')
                        } else {
                            switch (authenticatedUser.role) {
                                case 'tribe':
                                    navigate('/tribe');
                                    break;
                                case 'parent':
                                    navigate(emailVerified || authenticatedUser.createdFromSAP ? '/parent' : `/register/mailver/${authenticatedUser.email}`);
                                    break;
                                case 'guestPayment':
                                    navigate(guestPayment);
                                    break;
                                default:
                                    break;
                            }
                        }
                    } catch (e) {
                        setUser(authenticatedUser);
                    }
                    // try {
                    //     Quack.identify(authenticatedUser);
                    // } catch (error) {
                    //     console.log(error);
                    // }
                    resolve(authenticatedUser);
                }).catch(e => {
                    console.log(e);
                    if (e.data && !!e.data.emailExsist) {
                        handleEmailExsist();
                    }
                    else if (e.data && !!e.data.emailEmpty) {
                        handleEmailEmpty();
                    }
                    setIsLoggedIn(false);
                    reject(e);
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        });
    };

    const getEmailByCredentials = async (username, password) => {
        try {
            const res = await feathersClient.authenticate({ strategy: 'local', username, password });
            return res;
        } catch (error) {
            console.log(error);
            if (username.length === 8) {
                return getEmailByCredentials(`0${username}`, password); // Retry the function recursively
            }
        }
    }

    const reAuthenticate = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            try {
                feathersClient.reAuthenticate().then(async (authenticatedUser) => {
                    if (!!guestPayment) {
                        authenticatedUser.role = "guestPayment"
                    }
                    else {
                        authenticatedUser.role = authenticatedUser?.email?.includes('zofim.org.il') ? 'tribe' : 'parent';
                    }
                    setUser(authenticatedUser);
                    // try {
                    //     setTimeout(() => {
                    //         Quack.identify(authenticatedUser);
                    //     }, 2000);
                    // } catch (error) {
                    //     console.log(error);
                    // }
                    switch (authenticatedUser.role) {
                        case 'tribe':
                            if (hash === '/')
                                navigate('/tribe');
                            break;
                        case 'parent':
                            const parent = await getChildrenListAPI();
                            const hasSapChildren = parent.d.results[0].ChildrenList.results.length > 0 ? true : false
                            if (!authenticatedUser.hasChildren && !hasSapChildren) {
                                navigate('/register/addchild');
                                break;
                            }
                            // deleted because deeplink bug at 28.6.23 by gal || hash.includes('/dl/')
                            if (hash === '/') {// came from login or deeplink
                                navigate('/parent');
                                break;
                            }
                            break;
                        case 'guestPayment':
                            navigate(guestPayment);
                            break;
                        default:
                            break;
                    }
                    setIsLoggedIn(true);
                    resolve(authenticatedUser);
                }).catch((e) => {
                    setIsLoggedIn(false);
                    reject();
                });
            } catch (err) {
                setError(err);
                setIsLoggedIn(false);
                reject();
            } finally {
                setLoading(false);
            }
        });
    };

    const logOut = async () => {
        try {
            feathersClient.logout();
            setIsLoggedIn(false);
            setUser({});
            window.localStorage.clear();
            navigate('/');
        } catch (e) { console.error(e); }
    }

    const loginAnonymously = async () => {
        const auth = getAuth();
        const firebaseResult = await signInAnonymously(auth);
        const feathersResult = await feathersClient.authenticate({ strategy: "firebase", access_token: firebaseResult.user.accessToken });
        return { firebaseResult, feathersResult };
    }

    const logoutAnonymously = async () => {
        const auth = getAuth();
        auth.signOut();
        window.localStorage.clear();
    }

    return {
        loading,
        error,
        isLoggedIn,
        user,
        app: feathersClient,
        authenticationWithToken,
        reAuthenticate,
        getEmailByCredentials,
        logOut,
        loginAnonymously,
        logoutAnonymously
    };
};
export default useFeathers;