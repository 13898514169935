import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "raviger";
import useFeathers from "hooks/useFeathers";
import { Animated } from "react-animated-css";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { checkUserName, getAddresses, parentsExsistnece } from '../../api/api';
import useApi from '../../hooks/useApi';
import { RegisterContext } from 'context/registerContext';
import { HBox, VBox } from '../../assets/SharedComponents';
import { TextField, Card, InputAdornment, IconButton, ButtonGroup, Button, Autocomplete, CircularProgress, Box, Typography, Checkbox, Container, Divider } from '@mui/material';
import TermsDialog from "Components/Common/TermsDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import GenericDialog from "Components/Common/GenericDIalog";
import AddAnotherParent from "./AddAnotherParent";
import familyError from '../../assets/familyError.svg'
import AutocompleteGooglePlaces from "Components/Common/AutocompleteGooglePlaces";

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    padding1: {
        padding: "1rem"
    },
    marginEnd5p: {
        marginEnd: "5% !important"
    },
    marginBottom1: {
        marginBottom: "1rem !important"
    },
    width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    },
    width70: {
        width: "70%",
    },
    width25: {
        width: "25%",
    },
    TextfieldILabelStyle: {
        '& .MuiInputLabel-root': {
            color: 'black !important',
            fontSize: '1rem !important',
            alignItems: "center"
        },
        '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 'bold !important',
            color: 'black !important',
            fontSize: '1rem !important',
        },
        '& .Muirtl-1in441m': {
            fontSize: '1.2rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        }
    },
    buttonGreen: {
        '& .MuiButton-label': {
            color: 'white !important',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        borderRadius: '2rem !important',
        marginBottom: '2rem !important',
        color: 'white !important',
        width: "14rem !important",
        textTransform: "capitalize !important"
    },
    greenBG: { backgroundColor: '#3a7735 !important' },
    grayBG: { backgroundColor: '#0000001c !important' },
    selectedBtn: {
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important",
        textTransform: "capitalize !important"
    },
    IdnBtn: {
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important",
    },
    pageStyle: {
        maxWidth: '30rem !important',
        paddingBottom: '6rem !important',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px !important'
        },
    },
    IdentificationBtn: {
        textTransform: "capitalize !important",
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        width: "17rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    redBG: {
        backgroundColor: " #ff6645 !important"
    },
    termsLink: {
        color: "#3a7735",
        fontWeight: 'bold !important',
        textDecoration: "underline",
        cursor: "pointer"
    },
    paperStyle: {
        '@media (min-width:320px)': {
            width: "21.75rem",
            height: "34.5rem"
        },
        '@media (min-width:600px)': {
            width: "28.5rem",
            height: "34.5rem",
        },
        '@media (min-width:1025px)': {
            width: "40.5rem",
            height: "38.5rem"
        },
        '@media (min-width:1281px)': {
            width: "47.5rem",
            height: "42.5rem"
        },
        padding: "2px 2px 2px 2px",
        borderRadius: "50px !important",
        backgroundColor: "#ffffff !important",
        boxShadow: "15px 15px 30px 0px rgba(0, 0, 0, 0.26) !important",
        display: "flex",
        justifyContent: "center"
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#3a7735 !important',
        marginTop: '2rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#ffffff !important',
        fontSize: '1rem !important',
        textTransform: "capitalize !important"
    }
}));

export const FirstParent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useApi(checkUserName);
    const { userObject, setUserObject } = useContext(RegisterContext);
    const maritalStatuss = [
        { text: t("divorced"), key: 'di' },
        { text: t("married"), key: 'ma' },
        { text: t("sharedParentship"), key: 'sh' },
        { text: t("single"), key: 'sg' },
        { text: t("widdowed"), key: 'wi' },
        { text: t("guardian"), key: 'gu' },
        { text: t("Knowninpublic"), key: 'na' },
        { text: t("singleParent"), key: 'si' },
        { text: t("prod"), key: 'se' }
    ]
    const [openDialog, setOpenDialog] = useState(false);
    const [parentExsistDialog, setParentExsistDialog] = useState(false);
    const [anotherParentExsist, setAnotherParentExsist] = useState(false);
    const [anotherParentName, setAnotherParentName] = useState("");
    const [anotherParentPhone, setAnotherParentPhone] = useState("");
    const [anotherParentId, setAnotherParentId] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [streetValue, setStreetValue] = useState('');
    const [streetOpen, setStreetOpen] = useState(false);
    const address = useApi(getAddresses);
    const IdTextByType = { "1": t("israelId"), "2": t("passport"), "3": t("journeyId") };
    const inputRef = useRef();
    const inputRef1 = useRef();
    const inputRefP2 = useRef();
    const inputRefPhone2 = useRef();
    const hookFeathers = useFeathers();

    const handleClickShowPassword = (type) => {
        type === 1 ? setShowPassword(!showPassword) : setshowConfirmPassword(!showConfirmPassword);
    };

    const idChange = (e, parent) => {
        if (parent === "p1") { formik.setValues({ ...formik.values, id: e.target.value }); }
        if (parent === "p2") { formik.setValues({ ...formik.values, parent2Id: e.target.value }); }
    }
    const valueChanged = (value) => {
        formik.setValues({ ...formik.values, familyStatus: value.key });
    }
    const addressChanged = (oAddress) => {
        formik.setValues({ ...formik.values, street: oAddress.StreetName, streetNo: oAddress.StreetNum, city: oAddress.CityName })
        setStreetOpen(false)
    }
    const termsConfirms = (bool) => {
        setOpenDialog(false);
        formik.setValues({ ...formik.values, termsUse: bool });
    }

    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectName')),
        lastName: yup
            .string(t("lastName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectLName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectLName')),
        id: yup
            .string(t('id'))
            .required(t("mandatoryField"))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^[0-9]*$/, t('enterValidValue')),
        familyStatus: yup
            .string(t("maritalStatus"))
            .required(t("mandatoryField"))
            .min(2, t('enterValidValue')),
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        phone: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .matches(/^[0-9]*$/, t('insertValidPhone'))
            .required(t("mandatoryField")),
        password: yup
            .string(t("password"))
            .required(t("mandatoryField"))
            .min(8, t("passwordRule"))
            .matches(/(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}\w+/, t("passwordRule")),
        confirmPassword: yup
            .string(t("confirmPassword"))
            .oneOf([yup.ref('password'), null], t("matchPasswords")),
        streetNo: yup
            .string(t("streetNumber"))
            .nullable()
            .matches(/^\d+(\/\d+)*$/, t("streetNumber")),
        thereIsParent2: yup.boolean(),
        parent2Id: yup
            .string()
            .when('thereIsParent2', {
                is: true,
                then: yup
                    .string()
                    .required(t('mandatoryField'))
                    .min(8, t('enterValidValue'))
                    .max(30, t('enterValidValue')),
                otherwise: yup.string().nullable(),
            }),
        parent2Phone: yup
            .string()
            .when('thereIsParent2', {
                is: true,
                then: yup
                    .string()
                    .required(t('mandatoryField'))
                    .min(9, t('insertValidPhone'))
                    .max(10, t('insertValidPhone'))
                    .matches(/^[0-9]*$/, t('insertValidPhone')),
                otherwise: yup.string().nullable(),
            }),
    });
    const formik = useFormik({
        initialValues: {
            firstName: userObject.firstName ? userObject.firstName : '',
            lastName: userObject.lastName ? userObject.lastName : '',
            id: userObject.id ? userObject.id : '',
            idType: userObject.idType ? userObject.idType : '1',
            familyStatus: userObject.familyStatus ? userObject.familyStatus : '',
            phone: userObject.phone ? `0${userObject.phone}` : '',
            email: userObject.email ? userObject.email : '',
            street: userObject.street ? userObject.street : '',
            streetNo: userObject.streetNo ? userObject.streetNo : '',
            city: userObject.city ? userObject.city : '',
            password: '',
            confirmPassword: '',
            termsUse: false,
            parent2Id: userObject.parent2Id ? userObject.parent2Id : '',
            parent2IdType: userObject.parent2IdType ? userObject.parent2IdType : '1',
            parent2Phone: userObject.parent2Phone ? userObject.parent2Phone : '',
            thereIsParent2: true
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.email.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            if (values.parent2Id) {
                if (!is_israeli_id_number(values.parent2Id) && values.parent2IdType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
                if (values.parent2Id.toString().length < 9) {
                    if (values.parent2IdType === "3") {
                        enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                        return
                    }
                    if (values.parent2IdType === "1") {
                        enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                        return
                    }
                }
            }
            try {
                const emailExsits = await users.requestPromise({ email: values.email });
                if (emailExsits.userExists) {
                    enqueueSnackbar(t("mailExsit"), { className: classes.redBG });
                    return
                }
                const idExsits = await users.requestPromise({ username: values.id });
                if (idExsits.userExists) {
                    enqueueSnackbar(t("idExsit"), { className: classes.redBG });
                    return
                }
                let phoneNumber = values.phone;
                if (phoneNumber[0] === '0') {
                    phoneNumber = phoneNumber.slice(1, phoneNumber.length);
                }
                const phonExsits = await users.requestPromise({ phone_number: `+972${phoneNumber}` });
                if (phonExsits.userExists) {
                    enqueueSnackbar(t("phoneExsit"), { className: classes.redBG });
                    return
                }
                await checkParentsExsist(values.id, values.phone, values.parent2Id, values.parent2Phone);

                setUserObject(values);
                navigate('/smsver');
            } catch (error) {
                console.log(error)
                enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG })
            }

        }
    });

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }

    // const clicker = async () => {
    //     await checkParentsExsist(formik.values.id, formik.values.phone, formik.values.parent2Id, formik.values.parent2Phone)
    // }

    const checkParentsExsist = async (p1Id, p1Phone, p2Id, p2Phone) => {
        const query = { p1Id, p1Phone, p2Id, p2Phone }
        try {
            await hookFeathers.loginAnonymously();
            const res = await parentsExsistnece(query);
            if (res.notInUseIds) {
                return
            }
            if (res.parentExsist) {
                setParentExsistDialog(true);
            }
            if (res.anotherParentExsist) {
                setAnotherParentName(res.parentName);
                setAnotherParentPhone(res.parentPhone);
                setAnotherParentId(res.parentId);
                setAnotherParentExsist(true);
            }

        } catch (error) {
            console.log(error);
        }
        finally {
            await hookFeathers.logoutAnonymously();
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" flexWrap="wrap" margin="1rem" >
            <Card className={classes.paperStyle}>
                <Container sx={{ overflow: "auto" }}>
                    <Animated animationIn="fadeInUp" animationInDuration={2500} isVisible={true}>
                        <VBox className={`${classes.padding1} ${classes.pageStyle}`} justifyContent="center" >
                            <Typography style={{ marginBottom: "1rem", fontSize: "1.125rem", fontWeight: "bold" }} >{t('personalDetails1')}</Typography>
                            <HBox justifyContent='center'>
                                <Typography style={{ marginBottom: "0.875rem", fontSize: "0.875rem", color: "#a2a2a2" }} >{t('yourDetails')}</Typography>
                            </HBox>
                            <form onSubmit={formik.handleSubmit}>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`} marginTop={"1rem"}>
                                    <TextField
                                        label={t("firstName")}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        id="firstName"
                                        name="firstName"
                                        style={{ width: "100%" }}
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        variant="outlined"
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        label={t("lastName")}
                                        id="lastName"
                                        name="lastName"
                                        style={{ width: "100%" }}
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        variant="outlined"
                                    />
                                </HBox>
                                <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                                    <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}>{IdTextByType[1]} </Button>
                                    <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{IdTextByType[2]}</Button>
                                    <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{IdTextByType[3]}</Button>
                                </ButtonGroup>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        label={`${IdTextByType[formik.values.idType]} ${t("yourId")}`}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                        id="id"
                                        name="id"
                                        style={{ width: "100%" }}
                                        value={formik.values.id}
                                        onChange={e => idChange(e, "p1")}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.id && Boolean(formik.errors.id)}
                                        helperText={formik.touched.id && formik.errors.id}
                                        InputProps={{
                                            inputRef: inputRef,
                                            inputProps: {
                                                inputMode: 'tel',
                                                pattern: "[0-9]*"
                                            }
                                        }}
                                        onWheel={(e) => {
                                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                                inputRef.current.blur();
                                            }

                                        }}
                                        variant="outlined"
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <Autocomplete
                                        id="familyStatus"
                                        name="familyStatus"
                                        noOptionsText={t('notOption')}
                                        disableClearable={true}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        style={{ width: "100%" }}
                                        options={maritalStatuss}
                                        defaultValue={userObject.familyStatus && maritalStatuss.find(stat => stat.key === userObject.familyStatus)}
                                        isOptionEqualToValue={(option, value) => option.key === value.key}
                                        onChange={(e, value) => valueChanged(value)}
                                        getOptionLabel={(option) => `${option.text}`}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.key}>
                                                {option.text}
                                            </Box>
                                        )}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={t("maritalStatus")}
                                                error={formik.touched.familyStatus && Boolean(formik.errors.familyStatus)}
                                                helperText={formik.touched.familyStatus && formik.errors.familyStatus}
                                                InputProps={{
                                                    ...params.InputProps
                                                }} />}
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                        label={t("myPhone")}
                                        InputProps={{
                                            inputRef: inputRef1,
                                            inputProps: {
                                                inputMode: 'tel',
                                                pattern: "[0-9]*"
                                            }
                                        }}
                                        onWheel={(e) => {
                                            if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                                inputRef1.current.blur();
                                            }

                                        }}
                                        type="number"
                                        id="phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                        variant="outlined"
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        label={t("emaillong")}
                                        style={{ width: "100%" }}
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: {
                                                inputMode: 'url'
                                            }
                                        }}
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <AutocompleteGooglePlaces
                                        id="address"
                                        name="address"
                                        onPlaceSelected={(placeDetails) => addressChanged(placeDetails.addressDetails)}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        noOptionsText={t("addressNoOption")}
                                        onBlur={formik.handleBlur}
                                        inputLabel={t('cityStreetAndHouseNum')}
                                        inputPlaceholder={t("addressSearchPlaceholder")}
                                        inputError={formik.touched.address && Boolean(formik.errors.streetNo)}
                                        inputHelperText={formik.touched.address && formik.errors.streetNo}
                                    />
                                    {/* <Autocomplete
                                        disableClearable={true}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.marginEnd5p} ${classes.width70}`}
                                        id="street"
                                        name="street"
                                        open={streetValue.length >= 3 && streetOpen}
                                        inputValue={streetValue}
                                        onInputChange={(event, newInputValue) => {
                                            setStreetValue(newInputValue);
                                            setStreetOpen(true)
                                        }}
                                        noOptionsText={t("addressNoOption")}
                                        onChange={(e, value) => streetValueChanged(value)}
                                        options={streetsList}
                                        isOptionEqualToValue={(option, value) => option.Code === value.Code}
                                        getOptionLabel={(option) => `${option.StreetName} ${option.CityName}`}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.Code}>
                                                {option.StreetName}, <div style={{ fontWeight: "bold", marginRight: "3px" }}>{option.CityName}</div>
                                            </Box>
                                        )}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={t('cityAndStreet')}
                                                placeholder={t("searchPlaceHolder1")}
                                                error={formik.touched.street && Boolean(formik.errors.street)}
                                                helperText={formik.touched.street && formik.errors.street}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    inputMode: "search",
                                                    endAdornment: (
                                                        <>
                                                            {address.loading && <CircularProgress color="inherit" size={20} />}
                                                            {params.InputProps.endAdornment}
                                                        </>)
                                                }} />}
                                    />
                                    <TextField
                                        label={t('number')}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle1} ${classes.width25}`}
                                        id="streetNo"
                                        name="streetNo"
                                        value={formik.values.streetNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.streetNo && Boolean(formik.errors.streetNo)}
                                        helperText={formik.touched.streetNo && formik.errors.streetNo}
                                        variant="outlined"
                                    /> */}
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        label={t('choosePassword')}
                                        type={showPassword ? "text" : "password"}
                                        style={{ width: "100%" }}
                                        id="password"
                                        name="password"
                                        placeholder={t("passwordRule")}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={(formik.touched.password && formik.errors.password)}
                                        variant="outlined"
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={e => handleClickShowPassword(1)}
                                                        onMouseDown={e => handleClickShowPassword(1)}
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                    <TextField
                                        placeholder={t("matchPasswords")}
                                        className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                        label={t('choosePassword1')}
                                        type={showConfirmPassword ? "text" : "password"}
                                        style={{ width: "100%" }}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        variant="outlined"
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={e => handleClickShowPassword(2)}
                                                        onMouseDown={e => handleClickShowPassword(2)}
                                                    >
                                                        {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </HBox>
                                <HBox justifyContent='center'>
                                    <Typography sx={{ marginBottom: "0.875rem", fontSize: "0.875rem", color: "#a2a2a2" }}>{t('secondParent')}</Typography>
                                </HBox>
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`} alignItems="center">
                                    <Checkbox checked={formik.values.thereIsParent2} onChange={formik.handleChange} id="thereIsParent2" name="thereIsParent2"
                                        icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} />}
                                        checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} />}
                                    />
                                    <Typography>{t("thereIsParent")}</Typography>
                                </HBox>
                                {formik.values.thereIsParent2 && <>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                                        <Button className={`${formik.values.parent2IdType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "1" })}>{IdTextByType[1]}</Button>
                                        <Button className={`${formik.values.parent2IdType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "2" })}>{IdTextByType[2]}</Button>
                                        <Button className={`${formik.values.parent2IdType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "3" })}>{IdTextByType[3]}</Button>
                                    </ButtonGroup>
                                    <HBox width={'100%'} className={`${classes.marginBottom1}`}>
                                        <TextField
                                            label={`${IdTextByType[formik.values.parent2IdType]} ${t("secParent")}`}
                                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                            id="parent2Id"
                                            name="parent2Id"
                                            style={{ width: "100%" }}
                                            value={formik.values.parent2Id}
                                            onChange={e => idChange(e, "p2")}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.parent2Id && Boolean(formik.errors.parent2Id)}
                                            helperText={formik.touched.parent2Id && formik.errors.parent2Id}
                                            InputProps={{
                                                inputRef: inputRefP2,
                                                inputProps: {
                                                    inputMode: 'tel',
                                                    pattern: "[0-9]*"
                                                }
                                            }}
                                            onWheel={(e) => {
                                                if (inputRefP2 && inputRefP2.current && inputRefP2.current.blur) {
                                                    inputRefP2.current.blur();
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </HBox>
                                    <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                        <TextField
                                            style={{ width: "100%" }}
                                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                            label={`${t("myPhone")} ${t("secParent")}`}
                                            InputProps={{
                                                inputRef: inputRefPhone2,
                                                inputProps: {
                                                    inputMode: 'tel',
                                                    pattern: "[0-9]*"
                                                }
                                            }}
                                            onWheel={(e) => {
                                                if (inputRefPhone2 && inputRefPhone2.current && inputRefPhone2.current.blur) {
                                                    inputRefPhone2.current.blur();
                                                }
                                            }}
                                            type="number"
                                            id="parent2Phone"
                                            name="parent2Phone"
                                            value={formik.values.parent2Phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.parent2Phone && Boolean(formik.errors.parent2Phone)}
                                            helperText={formik.touched.parent2Phone && formik.errors.parent2Phone}
                                            variant="outlined"
                                        />
                                    </HBox>
                                </>}
                                <Divider sx={{ color: "#797979" }} />
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`} alignItems="center">
                                    <Checkbox
                                        onTouchEnd={e => setOpenDialog(true)}
                                        onClick={e => setOpenDialog(true)}
                                        icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} />}
                                        checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} />}
                                        checked={formik.values.termsUse} />
                                    <Typography className={classes.termsLink} sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }} onClick={e => setOpenDialog(true)}>{t("regTerms")}</Typography>
                                </HBox>
                                <TermsDialog
                                    openDialog={openDialog}
                                    setOpenDialog={setOpenDialog}
                                    termsConfirms={termsConfirms}
                                    title={t("termsUse")}
                                    src={`${process.env.REACT_APP_REGISTRATION_TERMS_URL}`}
                                    showButtons={true} />
                                <HBox width={'100%'} className={` ${classes.marginBottom1}`} alignItems="center" justifyContent='center'>
                                    {users.loading ? <CircularProgress sx={{ color: '#3a7735' }} /> :
                                        <Button
                                            className={!formik.values.termsUse ? `${classes.grayBG} ${classes.buttonGreen}` : `${classes.greenBG} ${classes.buttonGreen}`}
                                            disabled={!formik.values.termsUse}
                                            type="submit">
                                            {t('continue')}
                                        </Button>}
                                </HBox>
                            </form>
                        </VBox>
                    </Animated>
                </Container >
            </Card>
            {parentExsistDialog && <GenericDialog open={parentExsistDialog} setOpen={setParentExsistDialog} iconSVG={familyError} showRopes={true} showExit={false} height={"23rem"}>
                <Typography mt="1rem" sx={{ fontWeight: "bold", fontSize: { xs: "0.9rem", md: "1.5rem" } }}>{t('hosueholdExsist')}</Typography>
                <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }} >{t('cantReg')}</Typography>
                <Typography mt="3rem" sx={{ fontSize: "0.875rem", color: "#3a7735", textAlign: "center" }}>{t('loginToFamily')}</Typography>
                <Button mt="1rem" className={`${classes.greenButton}`} onClick={e => navigate('/')}>{t('enterWithOTP')}</Button>
            </GenericDialog>}
            {anotherParentExsist && <AddAnotherParent
                open={anotherParentExsist}
                setOpen={setAnotherParentExsist}
                anotherParentName={anotherParentName}
                anotherParentPhone={anotherParentPhone}
                anotherParentId={anotherParentId}
                regParentId={formik.values.id}
                regParentIdType={formik.values.idType}
                regParentPhone={formik.values.phone}
            />}
        </Box >
    );

}