// i18n
import { useTranslation } from 'react-i18next';
// mui
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItem, Typography, IconButton, Box, Divider, Card, CardHeader } from "@mui/material";
import { styled } from '@mui/system';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// components
import Product from "./Product"
import emptyCart from "../../assets/emptyCart.svg"
import { activityUseStyles } from "Components/TribeComponents/ActivityItem"
// api
import useGlobal from 'store/store';

const Header = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#f0f3f0 !important'
}));
export const ReuqestsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '2rem 1rem'
}));

const FirstStep = ({ products, onCloseCart, onDeleteFromCart }) => {
    const { t } = useTranslation();
    const [UTDRequests] = useGlobal((state) => state.UTDRequests);

    const childrenList = groupByChildren(products);
    // up to date requests
    const UPTDChildren = groupByChildren(UTDRequests);
    const isDisbaledExsist = !!UTDRequests.length;

    return (
        <Box pb="2rem" display='flex' flexDirection='column' overflow="auto" height='100%'>
            <Header>
                {!!products.length ?
                    <Typography sx={{ fontSize: { xs: "1rem", lg: "1.5rem" }, fontWeight: "bold" }} color='#000000'>
                        {`${t('cartTitle')} ${t("familys")} ${products[0].Parent1_LastName} (${products.length})`}</Typography> :
                    <Typography sx={{ fontSize: { xs: "1rem", lg: "1.5rem" }, fontWeight: "bold" }} color='#000000'>
                        {`${t('cartTitle')}`}</Typography>
                }
                <IconButton onClick={e => onCloseCart(e)} >
                    <CloseIcon sx={{ color: "#3a7735" }} />
                </IconButton>
            </Header>
            <ReuqestsContainer>
                {!products.length ?
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img
                            alt={"..."}
                            src={emptyCart}
                            width="290px"
                            height="340px"
                        />
                        <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.5rem", textAlign: "center" }}>{t("yourCartIsEmpty")}</Typography>
                        <Typography sx={{ color: "#3a7735", fontSize: "1.5rem", textAlign: "center" }}>{t("addActivites")}</Typography>

                    </Box>
                    : <List>
                        {/* group by childrens */}
                        {childrenList.map(el => (
                            <ListItem sx={{ padding: 0 }} disableGutters key={el.LicTradNum}>
                                <ChildrenRegReqs products={products} children={el} onDeleteFromCart={onDeleteFromCart} />
                            </ListItem>

                        ))}
                    </List>}

            </ReuqestsContainer>
            {isDisbaledExsist && <>
                <Box display="flex" flexDirection='column' >
                    <Typography fontSize={'1rem'} color="disable.main">{t('swipeToDelete')}</Typography>
                    <Divider sx={{ color: "#797979" }} />
                </Box>
                <List>
                    {/* group by childrens */}
                    {UPTDChildren.map(el => (
                        <ListItem sx={{ padding: 0 }} disableGutters key={el.LicTradNum}>
                            <ChildrenRegReqs utd={true} products={UTDRequests} children={el} onDeleteFromCart={onDeleteFromCart} />
                        </ListItem>
                    ))

                    }
                </List>
            </>}
        </Box>
    )
}

const ChildrenRegReqs = ({ children, onDeleteFromCart, products, utd = false }) => {
    const childrenRegReqs = products.filter((item) => item.LicTradNum === children.LicTradNum);
    const childrenRegularProducts = childrenRegReqs.filter((item) => item.ItemPurposeType !== '4');
    const childrenGeshemProducts = childrenRegReqs.filter((item) => item.ItemPurposeType === '4');

    return (
        <Box display="flex" flexDirection="column" width={'100%'}>
            <Box display="flex" alignItems={'center'} justifyContent={"flex-start"}>
                <PersonOutlineIcon color={utd ? 'inherit' : '#000000'} />
                <Typography fontWeight='bold' fontSize="1.1rem" color={utd ? 'inherit' : '#000000'}>{children.Pupil_FirstName}</Typography>
            </Box>
            {!utd && <Divider color='#333333' />}
            {!!childrenRegularProducts.length &&
                <List>
                    {childrenRegularProducts.map((item) => <Product onDelete={onDeleteFromCart} props={item} key={item._id} active={!utd} deleteOption={true} />)}
                </List>}
            {!!childrenGeshemProducts.length && <GeshemProducts geshemProducts={childrenGeshemProducts} onDelete={onDeleteFromCart} active={!utd} />}
        </Box>
    );
}

const GeshemProducts = ({ geshemProducts, onDelete, utd = false }) => {
    const classes = activityUseStyles();
    const geshemFather = geshemProducts.find(activ => !activ.Father);
    const geshemChildren = geshemProducts.filter(activ => activ.Father);
    const [products, [setProducts]] = useGlobal(
        (state) => state.products,
        (actions) => [actions.setProducts]);

    const onDeleteFather = async () => {
        let promises = [];
        const updatedProducts = products.filter(product => !geshemProducts.find(gPrd => gPrd._id === product._id));
        setProducts(updatedProducts);

        for (const act of geshemProducts) {
            promises.push(onDelete(act, true));
        }
        try {
            await Promise.all(promises);
        } catch (error) {

        }
    }
    return (
        <Box display="flex" flexDirection="column" width='100%' >
            <Card sx={{ position: 'relative', backgroundColor: "#e1ebe1 !important", padding: "0.5rem", boxShadow: '0 8px 9px rgb(125 125 125 / 7%)' }} >
                <Box display="flex" flexDirection="row" width='100%' justifyContent='space-between' >
                    <CardHeader sx={{ padding: '0.25rem 1rem' }} title={geshemFather?.ActivName} className={classes.cardHeader} />
                    <IconButton size='small'
                        sx={{ color: "#99292e" }}
                        onClick={e => onDeleteFather()}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Box>
                {geshemChildren.map((act) => {
                    return (
                        <Product onDelete={onDelete} props={act} key={act._id} active={!utd} deleteOption={false} />)
                })}
            </Card>
        </Box>
    );
}
const groupByChildren = (products) => {
    const childrenArray = {};
    const distinctChildren = products.filter(el => {
        if (!childrenArray[el.Pupil_FirstName]) {
            childrenArray[el.Pupil_FirstName] = el;
            return true
        }
        return false;
    });
    return distinctChildren;
}

export default FirstStep;