import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useGlobal from '../../../store/store';
import useApi from 'hooks/useApi';
//libs
import { format, startOfMonth, addMonths } from 'date-fns'
//mui
import { Typography, Container, IconButton, Box, Stepper, StepLabel, Step, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
//api
import { addPaymentHana, UpdateRegAPI, getGuid } from '../../../api/api'
import { system } from '../../../api/client'
//components
import { StartButton } from './PaymentOptions';
import CheckPaymentHandler from './CheckPaymentHandler';
import PaymentByCreditCard from './PaymentByCreditCard';
import PaymentByCash from "./PaymentByCash"

const PaymentStepStyle = styled(Step)(({ theme, ...props }) => ({
    "& circle": {
        color: props.active === "true" && '#3a7735 !important'
    },
    '& .MuiStepIcon-text': {
        fill: "#ffffff !important",
    },
    '& .MuiStepLabel-labelContainer': {
        color: props.active === "true" ? "#f9bc45 !important" : "#ffffff !important"
    },
    '& .MuiStepLabel-label.Mui-active': {
        color: '#f9bc45 !important',
    },
    '& .MuiStepLabel-label.Mui-completed': {
        color: '#f9bc45 !important',
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: '0.5rem !important',
    },
    '& .MuiStepIcon-root.Mui-active': {
        color: '#f9bc45 !important',
        width: '40px',
        height: '40px',
    },
    '& .MuiStepIcon-root.Mui-completed': {
        color: '#f9bc45 !important',
        width: '40px',
        height: '40px',
    },
    '& .MuiStepIcon-root': {
        color: '#3a7735 !important',
        width: '40px',
        height: '40px',
        marginTop: "2rem"
    },
    '& .MuiStepConnector-root': {
        top: "20px !important"
    }
}));

const useStyles = makeStyles(() => ({
    subTitle: {
        fontWeight: 'bold !important',
        textAlign: 'center !important',
        fontSize: '1.2rem !important',
        marginTop: '3rem !important'
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    }
}));

const SecondStep = ({ chosenMethod, setTribePayment, setDisabledButton, products, tribePayment, handlePayment }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [paymentDetails, setPaymentDetails] = useState({ creditCard: 0, cash: 0, checks: [] });
    const [totalCheckAmount, setTotalCheckAmount] = useState(paymentDetails.checks.reduce((accum, check) => accum + (parseFloat(check.CheckSum) || 0), 0));
    const [totalPayed, setTotalPayed] = useState(0);
    const [overPay, setOverPay] = useState(false);
    const [card, setCard] = useState(0);
    const [checks, setChecks] = useState(0);
    const [cash, setCash] = useState(0);
    const [cashDisabled, setCashDisabled] = useState(false);
    const [checkDialog, setCheckDialog] = useState(false);
    const [redayForPayment, setRedayForPayment] = useState(chosenMethod.creditCard && !chosenMethod.cash && !chosenMethod.check);
    const [doneDisable, setDoneDisable] = useState(false);
    const [itZeroPay, setItZeroPay] = useState(false);

    const [optionArray, setOptionArray] = useState([]);
    const getSapGuid = useApi(getGuid);
    const { enqueueSnackbar } = useSnackbar();
    const today = format(new Date(), 'yyyy-MM-dd');

    const [tribePaid, setTribePaid] = useGlobal((state) => state.tribePaid, (actions) => actions.setTribePaid);
    const [finalPrice, setFinalPrice] = useGlobal((state) => state.finalPrice, (action) => action.setFinalPrice);
    const [payByExtra, [setPayByExtra]] = useGlobal((state) => state.payByExtra, (actions) => [actions.setPayByExtra]);
    const [payWithAllExtra, [setPayWithAllExtra]] = useGlobal((state) => state.payWithAllExtra, (actions) => [actions.setPayWithAllExtra]);

    useEffect(() => {
        setDisabledButton(true);
        if (paymentDetails.checks.length === 0) {
            addNewCheck();
        }
        let temp = [];
        Object.values(chosenMethod).forEach((op, i) => {
            op && temp.push(i + 1);
        })
        setOptionArray(temp);
    }, [])

    useEffect(() => {
        setTribePaid({ checks: parseFloat(totalCheckAmount), cash: parseFloat(paymentDetails.cash), card: parseFloat(paymentDetails.creditCard) });
        const total = parseFloat(totalCheckAmount) + parseFloat(paymentDetails.cash) + parseFloat(paymentDetails.creditCard) || 0;
        setTotalPayed(total);
        setOverPay(total > finalPrice);
        if (total === finalPrice) {
            setDisabledButton(false);
            if (finalPrice === 0) {
                setItZeroPay(true);
            }
        }
    }, [paymentDetails, totalCheckAmount]);


    //get updated registration requests from mongoDB 
    useEffect(() => {
        console.log(tribePayment)
        if (tribePayment) {
            activePay();
        }
    }, [tribePayment]);

    const activePay = async () => {
        const res = await getSapGuid.requestPromise(products, "tribe");
        let promises = [];
        let payMethod = {};
        let paymentExecutionType;
        if (card)
            paymentExecutionType = "1";
        if (cash)
            paymentExecutionType = "2";
        if (checks)
            paymentExecutionType = "3";
        if (card && cash)
            paymentExecutionType = "4";
        if (card && checks)
            paymentExecutionType = "5";
        if (cash && checks)
            paymentExecutionType = "6";
        if (cash && checks && card)
            paymentExecutionType = "7";
        if (payByExtra && !payWithAllExtra) {
            const payMethod = {
                Guid: res,
                ParentCardCode: products[0].ParentCardCode,
                UnitCode: products[0].TribeCode,
                PaymentDate: today,
                PaymentExecutionType: "10"
            }
            if (system !== "prod") {
                payMethod.PaymentPercent = products[0].Status_request === "3" ? 50 : 100;
                payMethod.InvoiceNum = "0";
            }
            const result = await addPaymentHana(payMethod);
        }
        const splitProducts = products.filter((pr) => pr.Status_request === "3");
        payMethod = {
            ...card,
            ...cash,
            ...checks,
            Guid: res,
            ParentCardCode: products[0].ParentCardCode,
            UnitCode: products[0].TribeCode,
            PaymentDate: today,
            PaymentExecutionType: itZeroPay ? "2" : paymentExecutionType,
        }
        if (system !== "prod") {
            payMethod.PaymentPercent = splitProducts.length ? 50 : 100;
            payMethod.InvoiceNum = splitProducts.length ? splitProducts[0].GUID : "0";
        }
        const result = await addPaymentHana(payMethod);
        if (result.Status) {
            products.map((product) => {
                promises.push(UpdateRegAPI({ _id: product._id, data: { Status_request: "4", paid: product.FinalPrice, Payment_Method: paymentExecutionType } }));
            })
            if (promises.length) {
                const res = await Promise.all(promises);
                if (res) {
                    handlePayment();
                }
            }
        }
        else {
            enqueueSnackbar(t("paymentErrorOccuredText"), { variant: "error" });
        }
    }

    const addNewCheck = (fromCheck = false) => {
        setDoneDisable(true);
        const checks = paymentDetails.checks;
        checks.push({
            checkIndex: paymentDetails.checks.length + 1,
            CheckNum: fromCheck ? Number(paymentDetails.checks[paymentDetails.checks.length - 1].CheckNum) + 1 : '',
            BankAccount: fromCheck ? paymentDetails.checks[0].BankAccount : '',
            BankBranch: fromCheck ? paymentDetails.checks[0].BankBranch : '',
            BankCode: '',
            CheckDate: fromCheck ? getNextMonthSameDate(paymentDetails.checks[paymentDetails.checks.length - 1].CheckDate) : '',
            CheckSum: 0,
        });
        setPaymentDetails({ ...paymentDetails, checks });

    };
    const getNextMonthSameDate = (date) => {
        const tempDate = new Date(date);
        const currentMonth = tempDate.getMonth();
        const currentYear = tempDate.getFullYear();

        // Calculate the number of days in the current month
        const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

        // Get the same date in the next month
        tempDate.setMonth(currentMonth + 1);

        // Handle the case where the current day is greater than the number of days in the next month
        if (tempDate.getDate() > daysInCurrentMonth) {
            tempDate.setDate(daysInCurrentMonth);
        }

        return tempDate;
    }
    const editCashSum = () => {
        setCashDisabled(false);
        setPaymentDetails({ ...paymentDetails, cash: 0 });
        setCash({ CashSum: 0 });
        setDisabledButton(true);
    }
    return (
        <Container>
            <Typography className={classes.subTitle}>{t('paymentMethodToSelect')}</Typography>
            <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-evenly" mt="3rem">
                <Stepper activeStep={1} orientation="vertical" sx={{ display: "block" }}>
                    {optionArray.map((step, index) => (
                        <PaymentStepStyle completed={false} active={false} key={`${index}`} >
                            <StepLabel />
                        </PaymentStepStyle>))}
                </Stepper>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {chosenMethod.cash &&
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="2rem">
                            <PaymentByCash
                                setPaymentDetails={setPaymentDetails}
                                paymentDetails={paymentDetails}
                                overPay={overPay}
                                setOverPay={setOverPay}
                                finalPrice={finalPrice}
                                setCash={setCash}
                                totalPayed={totalPayed}
                                cashDisabled={cashDisabled}
                                setCashDisabled={setCashDisabled}
                                redayForPayment={redayForPayment}
                                setCheckDialog={setCheckDialog}
                            />
                            <IconButton onClick={e => editCashSum()} sx={{ display: redayForPayment && 'none' }}>
                                <EditIcon sx={{ color: "#3a7735", cursor: "pointer" }} />
                            </IconButton>
                        </Box>}
                    {chosenMethod.check &&
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="2rem">
                            <CheckPaymentHandler
                                paymentDetails={paymentDetails}
                                setPaymentDetails={setPaymentDetails}
                                doneDisable={doneDisable}
                                setDoneDisable={setDoneDisable}
                                setChecks={setChecks}
                                checkDialog={checkDialog}
                                setCheckDialog={setCheckDialog}
                                finalPrice={finalPrice}
                                setTotalCheckAmount={setTotalCheckAmount}
                                overPay={overPay}
                                setOverPay={setOverPay}
                                addNewCheck={addNewCheck}
                                chosenMethod={chosenMethod}
                            />
                            <Tooltip title={t("checkPayment")}>
                                <IconButton onClick={e => setCheckDialog(true)} sx={{ display: redayForPayment && 'none' }}>
                                    <EditIcon sx={{ color: "#3a7735", cursor: "pointer" }} />
                                </IconButton>
                            </Tooltip>
                        </Box>}
                    {chosenMethod.creditCard && (chosenMethod.check || chosenMethod.cash) &&
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="2rem">
                            <StartButton onClick={e => setRedayForPayment(true)} disabled={!cash.CashSum && !checks.PaymentChecks} sx={{ width: "13rem !important" }}>
                                {t("moveToCreditPay")}
                            </StartButton>
                        </Box>}
                    {chosenMethod.creditCard && redayForPayment &&
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="2rem">
                            <PaymentByCreditCard
                                overPay={overPay}
                                totalPayed={totalPayed}
                                finalPrice={finalPrice}
                                setCard={setCard}
                                setPaymentDetails={setPaymentDetails}
                                paymentDetails={paymentDetails}
                                chosenMethod={chosenMethod}
                                setRedayForPayment={setRedayForPayment}
                                redayForPayment={redayForPayment}
                                setTribePayment={setTribePayment}
                            />
                        </Box>}
                </Box>
            </Box>
        </Container >
    );
}
export default SecondStep;