import { useQueryParams, useNavigate } from 'raviger'
import { useEffect } from 'react';
import ResetPasswordPage from './ResetPasswordPage';
import ConfirmEmailPage from './ConfirmEmailPage';
import { useState } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import useFeathers from 'hooks/useFeathers';

const FirebaseLandingPage = () => {
    const urlParams = useQueryParams();
    const [action, setAction] = useState("");
    const navigate = useNavigate();
    const feathers = useFeathers();
    useEffect(() => {
        // Get the action to complete.
        const mode = urlParams[0].mode;
        if (mode === "resetPassword" || mode === "verifyEmail") {
            setAction(mode)
        }
        else if (mode === "signIn") {
            // Confirm the link is a sign-in with email link.
            const auth = getAuth();
            if (isSignInWithEmailLink(auth, window.location.href)) {
                // Additional state parameters can also be passed via URL.
                // This can be used to continue the user's intended action before triggering
                // the sign-in operation.
                // Get the email if available. This should be available if the user completes
                // the flow on the same device where they started it.
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {
                    // User opened the link on a different device. To prevent session fixation
                    // attacks, ask the user to provide the associated email again. For example:
                    email = window.prompt('יש להזין את כתובת הדוא\"ל שאליה קיבלת את הקישור ');
                }
                // The client SDK will parse the code from the link for you.
                signInWithEmailLink(auth, email, window.location.href)
                    .then(async (result) => {
                        // Clear email from storage.
                        window.localStorage.removeItem('emailForSignIn');
                        let username = urlParams[0].continueUrl.split("=")[1].split("_")[0];
                        let phoneNumber = urlParams[0].continueUrl.split("=")[1].split("_")[1];
                        const { accessToken } = result.user;
                        await feathers.authenticationWithToken('firebase', accessToken, true, username, false, phoneNumber);
                    })
                    .catch((error) => {
                        if (error?.code?.toString().includes('auth/invalid-action-code')) {
                            window.alert('קישור זה אינו בתוקף -נא לנסות שוב בעוד מספר דקות');
                          }
                          if (error?.message?.includes('email: value already exists.')) {
                            window.alert('כתובת המייל שאיתה ניסיתם להתחבר אינה מזוהה במערכת ככתובת המייל שלכם לכן החיבור נחסם,ניתן לפנות לתמיכה במסך הבא');
                          }
                        navigate("/");
                        console.log(error);
                    });
            }
        }
        else {
            navigate("/")
        }

    }, [])

    return (
        <>
            {action === "resetPassword" && <ResetPasswordPage />}
            {action === "verifyEmail" && <ConfirmEmailPage />}
        </>
    )
}
export default FirebaseLandingPage;