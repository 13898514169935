import { useState, useEffect, useContext } from 'react';
import * as Styled from "../Common/StyledComponents";
import { useStyles } from '../../assets/LoginStyle.jsx';
import { Box, Link, Button, Dialog, TextField, DialogTitle, DialogContentText, Typography, IconButton, Container, Card } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, Redirect } from "raviger";
import { useTranslation } from 'react-i18next';
import { Animated } from "react-animated-css";
import { useSnackbar } from 'notistack';
import { MessageBox } from 'Components/Common/MessageBox';
import { getAuth } from "firebase/auth";
import { updateUser, checkUserName, checkParentChildren, generateVerificationEmail, updateParentDetails, isMailVerified } from '../../api/api';
import useApi from '../../hooks/useApi';
import { RegisterContext } from 'context/registerContext.js';
import Logo from '../../assets/Logo.png';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReLinkCounter from '../Common/ReLinkCounter.jsx';
import ContactDialog from 'Components/Common/ContactDialog';
import { CustomBusy } from 'Components/Common/CustomBusy';
import MailSuccess from '../../assets/MailSuccess.svg';
import MobileSuccess from '../../assets/MobileSuccess.svg';

export const MailVerification = ({ email = '' }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const EmailResendingBodyText = t("EmailResendingBodyText")

    const { setUpdateParent } = useContext(RegisterContext)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageBoxState, setMessageBox] = useState({ open: false });
    const [cahngeEmailBox, setCahngeEmailBox] = useState(false);
    const [text, setText] = useState();
    const [visible, setVisible] = useState(false)
    const [userEmail, setUserEmail] = useState(email)
    const [parent2Nav, setParent2Nav] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [showAnimation, setShowAnimation] = useState(true);
    const [disableContinue, setDisableContinue] = useState(true);
    const [intervalId, setIntervalId] = useState(null);
    const [count, setCount] = useState(0);
    const updateUserData = useApi(updateUser);
    const users = useApi(checkUserName);
    const auth = getAuth();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowAnimation(false);
        }, 4000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const timeoutId1 = setTimeout(() => {
            setDisableContinue(false);
        }, 12000);

        return () => clearTimeout(timeoutId1);
    }, []);

    useEffect(() => {
        setIntervalId(
            setInterval(() => {
                setCount(count => count + 1);
                checkEmailVerified("auto");
            }, 10000)
        );

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (count === 3) {
            clearInterval(intervalId);
        }
    }, [count]);

    useEffect(() => {
        if (!userEmail) {
            navigate('/fparent');
        }
    }, []);

    const verifyEmail = (newEmail = null) => {
        if (!auth.currentUser?.emailVerified) {
            if (newEmail) {
                let email = newEmail
                generateVerificationEmail({ email });
            }
            else {
                let email = userEmail
                generateVerificationEmail({ email });
            }
        } else {
            setText(t("emailAlredy"));
        }
    }
    const handleResending = () => {
        verifyEmail();
        setText(EmailResendingBodyText);
    };
    const contact = () => {
        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                    <Box display="flex" flexDirection="row">
                        <WarningAmberIcon sx={{ marginTop: "0.5rem" }} />
                        <Typography variant="largeDarkGreyBold">
                            {t("errorCreatingUser")}
                        </Typography>
                    </Box>
                    <IconButton>
                        <CloseIcon onClick={closeMsgBox} />
                    </IconButton>
                </Box>,
            content:
                <Typography variant="normalDarkGrey">
                    {t("errorNote")}
                </Typography>,
            actions:
                <Box display="flex" flexDirection="column">
                    <Styled.RoundedButton variant="contained" onClick={e => setOpenContact(true)}>
                        {t("gotoContactForm")}
                    </Styled.RoundedButton>
                    {openContact &&
                        <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}
                    <Styled.RoundedButton variant="outlined" sx={{ marginTop: "1rem" }} onClick={e => navigate('/')} >
                        {t("backToLogin")}
                    </Styled.RoundedButton>
                </Box>
        });

    }
    const closeMsgBox = () => {
        setMessageBox({ open: false });
    };
    const checkEmailVerified = async (type) => {
        const userAuthId = auth.currentUser.uid;
        try {
            setLoading(true);
            const response = await isMailVerified(userEmail)
            if (response.verified) {
                setCount(3);
                let parent2 = false;
                const oUserData = await getUserData(userEmail);
                let id = oUserData.username;
                const responseParent1 = await checkParentChildren(id);
                const baseParent = responseParent1?.d?.results[0];
                //if the current user taht try to register is parent2 
                if (baseParent) {
                    const res = updateParentDetails({ id: userAuthId, data: { id: userAuthId, hasChildren: true } });
                    parent2 = true;
                }
                const user =
                {
                    CardCode: parent2 ? baseParent.CardCode : "",
                    Parent1_City: parent2 ? baseParent.Parent1_City : oUserData.city,
                    Parent1_Street: parent2 ? baseParent.Parent1_Street : oUserData.street,
                    Parent1_StreetNo: parent2 ? baseParent.Parent1_StreetNo : oUserData.streetNo,
                    Parent1_Tel1: parent2 ? baseParent.Parent1_Tel1 : oUserData.phone_number,
                    Parent1_FirstName: parent2 ? baseParent.Parent1_FirstName : oUserData.firstName,
                    Parent1_LastName: parent2 ? baseParent.Parent1_LastName : oUserData.lastName,
                    Parent1_ID: parent2 ? baseParent.Parent1_ID : oUserData.username,
                    Parent1_ID_Type: parent2 ? baseParent.Parent1_ID_Type : oUserData.usernameType,
                    Parent1_Mail: parent2 ? baseParent.Parent1_Mail : oUserData.email,
                    Parent1_MaritalStatus: parent2 ? baseParent.Parent1_MaritalStatus : oUserData.maritalStatus,
                    Parent1_MedicalQalifications: [],
                    Parent1_ApprInfo: 'N',
                    Parent1_IsVolunteered: "N",
                    Parent1_PastScout: "N",
                    Parent1_PlaceOfBirth: "",
                    Parent1_Profession: "",
                    Parent1_CodeFirstHelp: "",
                    Parent1_Country: "",
                    Parent1_EmigrationYear: "",
                    Parent1_FirstHelpName: "",
                    Parent1_Tel1Type: "1",
                    Parent1_Tel2: "",
                    Parent1_Tel2Type: "1",
                    Parent1_TribeName: "",
                    Parent1_WeaponType: "",
                    Parent1_WorkPlace: "",
                    Parent1_ZipCode: "",
                    Parent2_ApprInfo: "N",
                    Parent2_Tel1: parent2 ? oUserData.phone_number : oUserData.parent2Phone,
                    Parent2_ID_Type: parent2 ? oUserData.usernameType : oUserData.parent2IdType,
                    Parent2_ID: parent2 ? oUserData.username : oUserData.parent2Id,
                    Parent2_City: parent2 ? oUserData.city : "",
                    Parent2_Street: parent2 ? oUserData.street : "",
                    Parent2_StreetNo: parent2 ? oUserData.streetNo : "",
                    Parent2_FirstName: parent2 ? oUserData.firstName : "",
                    Parent2_LastName: parent2 ? oUserData.lastName : "",
                    Parent2_Mail: parent2 ? oUserData.email : "",
                    Parent2_MaritalStatus: parent2 ? oUserData.maritalStatus : "",
                    Parent2_MedicalQalifications: [],
                    Parent2_PastScout: "Y",
                    Parent2_PlaceOfBirth: "",
                    Parent2_Profession: "",
                    Parent2_CodeFirstHelp: "",
                    Parent2_Country: "",
                    Parent2_EmigrationYear: "",
                    Parent2_FirstHelpName: "",
                    Parent2_IsVolunteered: "N",
                    Parent2_Tel1Type: "1",
                    Parent2_Tel2: "",
                    Parent2_Tel2Type: "1",
                    Parent2_TribeName: "",
                    Parent2_WeaponType: "",
                    Parent2_WorkPlace: "",
                    Parent2_ZipCode: "",
                    Source: "APH",
                    Tel: "",
                    TelEmergency: ""
                };
                const data = await updateUserData.requestPromise(user);
                if (data.Status && !data.ErrorMessage) {
                    user.CardCode = data.CardCode;
                    setUpdateParent(user);
                    if (parent2) {
                        setParent2Nav(true)
                    } else {
                        navigate('/addchild');
                    }
                }
                else {
                    let sMsg;
                    try {
                        if (data.CardCode === "") {
                            contact();
                            window.localStorage.clear();
                            sMsg = data.ErrorMessage.split("System.Exception: ")[1].split("at XIS")[0];
                        }

                    } catch (error) {
                        sMsg = data.ErrorMessage;

                    }
                    enqueueSnackbar(sMsg, { className: classes.redBG });
                    setLoading(false);
                }
            } else {
                setLoading(false);
                if (type === "click") { enqueueSnackbar(t("notConfirmedYet"), { className: classes.redBG }); }
            }
        } catch (error) {
            if (error.code === 401) { enqueueSnackbar(t("notAuthError"), { className: classes.redBG }); }
        }


    }
    const getUserData = async (sEmail) => {
        try {
            const res = await users.requestPromise({ email: sEmail })
            return res.userData;
        } catch (error) {
            setLoading(false)
            { enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG }); }
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" flexWrap="wrap" margin="1rem" >
            <Card className={classes.paperStyle}>
                <Container sx={{ overflow: "auto", padding: "24px", marginTop: { xs: "1.5rem", md: "5rem" } }}>
                    {showAnimation && <Animated animationIn="zoomIn" animationOutDelay={4000} isVisible={false}>
                        <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginTop: "2rem" }}>
                            <Typography align="center" className={`${classes.paddingBottom1rem}  ${classes.GreenColor}`} component="h2" variant="h5">
                                {t("phoneNumberSuccessfullyVerified")}
                            </Typography>
                            <img
                                alt={"..."}
                                width="200px"
                                height="120px"
                                src={MobileSuccess}
                            />
                        </Box>
                    </Animated>}
                    <Animated animationIn="zoomIn" animationInDelay={4000} animationInDuration={2200} isVisible={true}>
                        <Box display="flex" flexDirection="column" >
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <img
                                    alt={"..."}
                                    width="200px"
                                    height="120px"
                                    src={MailSuccess}
                                />
                                <Typography sx={{ fontSize: { xs: "1rem", md: "1.5rem" }, fontWeight: "bold" }}> {t("emailVerTitle")} </Typography>
                                <Typography align="center" className={`${classes.paddingBottom1rem}`}>{userEmail}</Typography>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="0.2rem">
                                    {!visible ?
                                        <ReLinkCounter setVisible={setVisible} /> :
                                        <Link color={'#007f00'} onClick={handleResending} className={`${classes.CenterItems} ${classes.LinkStyle} ${classes.paddingBottom1rem}`}>
                                            {t("didntReciveMail")}
                                        </Link>}
                                    <Typography align="center" className={`${classes.paddingBottom1rem}`}>{t("afterMailVer")}</Typography>

                                </Box>
                            </Box>
                            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent='space-around'>
                                {loading ?
                                    <CustomBusy text={t("waitPlease")} /> :
                                    <>
                                        <Button onClick={e => setCahngeEmailBox(true)} className={`${classes.buttonWhite}`} sx={{ width: { xs: "100%", md: "13rem" } }}>
                                            {t("notYourMail")}
                                        </Button>
                                        <Button onClick={e => checkEmailVerified("click")} className={disableContinue ? classes.buttonGreenLight : classes.buttonGreen} sx={{ width: { xs: "100%", md: "13rem" } }} disabled={disableContinue}>
                                            {t("doneToVerify")}
                                        </Button>
                                    </>}
                                <ChangeMailDialog cahngeEmailBox={cahngeEmailBox} setCahngeEmailBox={setCahngeEmailBox} verifyEmail={verifyEmail} setUserEmail={setUserEmail} />
                            </Box>
                        </Box>
                    </Animated>
                </Container>
            </Card>
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
            {parent2Nav && <Redirect to='/parent/homepage' />}
        </Box>
    );
}
const ChangeMailDialog = ({ cahngeEmailBox, setCahngeEmailBox, verifyEmail, setUserEmail }) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const users = useApi(checkUserName);
    const { enqueueSnackbar } = useSnackbar();
    const { userObject, setUserObject } = useContext(RegisterContext)
    const auth = getAuth();

    const validationSchema = yup.object({
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        confirmEmail: yup
            .string(t("email"))
            .oneOf([yup.ref('email'), null], t("matchEmail")),

    });
    const formik = useFormik({
        initialValues: {
            email: "",
            confirmEmail: ""

        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const emailExsits = await users.requestPromise({ email: values.email })
            if (emailExsits.userExists) {
                enqueueSnackbar(t("mailExsit"), { className: classes.redBG })
                return
            }
            await updateParentDetails({ id: auth.currentUser.uid, data: { email: values.email, inRegistrationProccess: true } })
            enqueueSnackbar(` ${t("updateMailSuccess")} ${values.email} `, { className: classes.orangeBG });
            setUserObject({ ...userObject, email: values.email })
            verifyEmail(values.email)
            setUserEmail(values.email)
            setCahngeEmailBox(false)
        }
    });

    return (
        <Dialog
            className={classes.dialog}
            open={cahngeEmailBox}
            onClose={e => { setCahngeEmailBox(false) }}
            scroll={'paper'}
            PaperProps={{
                sx: { borderRadius: "40px !important", backgroundColor: "#f0f3f0" }
            }}
        >
            <DialogTitle>
                <Box display="flex" flexDirection="row" className={classes.dialogTitle}>
                    <img
                        className={classes.LogoSize1}
                        src={Logo}
                        alt='..'
                        loading="lazy"
                    />
                    <Typography className={classes.dialogTitleText}>{t("changeMailTitle")}</Typography>
                    <IconButton onClick={e => { setCahngeEmailBox(false) }}><CloseIcon /></IconButton>
                </Box>
            </DialogTitle>
            <Container >
                <DialogContentText>
                    {t("changeMailText")}
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" flexDirection="row" width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom1} ${classes.marginTop1}`} justifyContent={"center"}>
                        <TextField

                            label={t("email")}
                            style={{ width: "60%" }}
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    inputMode: "url"
                                }
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom1} ${classes.marginTop1}`} justifyContent={"center"}>
                        <TextField
                            label={t("emaillongVerf")}
                            style={{ width: "60%" }}
                            id="confirmEmail"
                            name="confirmEmail"
                            value={formik.values.confirmEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)}
                            helperText={formik.touched.confirmEmail && formik.errors.confirmEmail}
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    inputMode: "url"
                                }
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" width={'100%'} className={`${classes.marginBottom1}`} alignItems="center" justifyContent={"center"}>
                        <Button type='submit' className={`${classes.buttonGreen}`} style={{ marginBottom: "1rem !important" }}>{t("changeEmail")}</Button></Box>
                </form>
            </Container>
        </Dialog>)
}

