import { useState, useRef } from 'react'
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'raviger';
import useApi from 'hooks/useApi';
import useGlobal from 'store/store';
//libs
import * as yup from 'yup';
//mui
import { makeStyles } from '@mui/styles';
import { TextField, IconButton, Typography, Button, CircularProgress, Box, ButtonGroup, Autocomplete, Tooltip } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
//components
import { HBox, VBox } from 'assets/SharedComponents';
import { GreenSwitch } from './ChildDetailsEditor'
import AutocompleteGooglePlaces from 'Components/Common/AutocompleteGooglePlaces';
//api
import { updateUser, sendResetPasswordEmail } from "api/api"

const useStyles = makeStyles((theme) => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    displayInherit: {
        display: 'inherit !important;'
    },
    alignItemsBaseline: {
        alignItems: "baseline"
    },
    textFieldStart: {
        textAlign: "start"
    },

    width50: {
        width: "50%",
        overflowWrap: 'anywhere'
    },

    padding1: {
        padding: "1rem"
    },
    IdentificationBtn: {
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",

        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    selectedBtn: {
        textTransform: "capitalize !important",
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "#ffffff !important"
    },
    IdnBtn: {
        textTransform: "capitalize !important",
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important"
    },
    marginBottom1: {
        marginBottom: "0.5rem !important"
    },
    marginBottom2: {
        marginBottom: "0.8rem"
    },
    buttonGreen: {
        '& .MuiButton-label': {
            color: 'white !important',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important',
        marginBottom: '2rem !important',
        color: 'white !important',
    },
    pageStyle: {
        maxWidth: '30rem',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px'
        }
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    TextfieldILabelStyle: {
        width: "50%"
    },
    notchedOutline: {
        borderColor: "transparent !important"
    }
}));

export const SecondParentSetting = ({ household, setIsUpdated, isUpdated, setFamilyUpdated, familyUpdated }) => {
    const [user] = useGlobal(state => state.user);
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const maritalStatuss = [
        { text: t("divorced"), key: 'di' },
        { text: t("married"), key: 'ma' },
        { text: t("sharedParentship"), key: 'sh' },
        { text: t("single"), key: 'sg' },
        { text: t("widdowed"), key: 'wi' },
        { text: t("guardian"), key: 'gu' },
        { text: t("Knowninpublic"), key: 'na' },
        { text: t("singleParent"), key: 'si' },
        { text: t("prod"), key: 'se' }
    ]
    const [topFieldsEdit, setTopFieldsEdit] = useState(true);
    const [emailFieldEdit, setEmailFieldEdit] = useState(true);
    const [phoneFieldEdit, setPhoneFieldEdit] = useState(true);
    const [passwordField, setPasswordField] = useState(false);
    const [addressFieldEdit, setAddressFieldEdit] = useState(false);
    const [streetOpen, setStreetOpen] = useState(false);
    const updateUserData = useApi(updateUser);
    const { enqueueSnackbar } = useSnackbar();
    const parent2Material = maritalStatuss.find(obj => obj.key === household.Parent2_MaritalStatus);
    const inputRef = useRef();
    const inputRef1 = useRef();

    const addressChanged = (oAddress) => {
        formik.setValues({ ...formik.values, street: oAddress.StreetName, streetNo: oAddress.StreetNum, city: oAddress.CityName });
        setStreetOpen(false);
    }
    const valueChanged = (value) => {
        formik.setValues({ ...formik.values, maritalStatus: value.key });
    }

    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectName')),
        lastName: yup
            .string(t("lastName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectLName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectLName')),
        id: yup
            .string(t("id"))
            .required(t("mandatoryField"))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^0*[0-9]*$/, t('enterValidValue')),
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        phoneNumber: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .required(t('mandatoryField')),
        maritalStatus: yup
            .string(t("maritalStatus"))
            .required(t("mandatoryField"))
            .min(2, t('enterValidValue')),
        streetNo: yup
            .string(t("streetNumber"))
            .nullable()
            .matches(/^\d+(\/\d+)*$/, t("streetNumber"))
    });
    const formik = useFormik({
        initialValues: {
            firstName: household.Parent2_FirstName,
            lastName: household.Parent2_LastName,
            idType: household.Parent2_ID_Type || '3',
            id: household.Parent2_ID,
            email: household.Parent2_Mail,
            liveDifAddress: household.LiveDifAddress === "Y" ? true : false,
            street: household.Parent2_Street,
            city: household.Parent2_City,
            streetNo: household.Parent2_StreetNo,
            maritalStatus: parent2Material?.key || '',
            phoneNumber: household.Parent2_Tel1 && household.Parent2_Tel1.startsWith("+972")
                ? `0${household.Parent2_Tel1.slice(-9)}` : household.Parent2_Tel1.slice(-10)
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.email.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            setTopFieldsEdit(true);
            setEmailFieldEdit(true);
            setPhoneFieldEdit(true);

            const data = {
                "Source": user.role === "tribe" ? "ASH" : "APH",
                "CardCode": household.CardCode,
                "Parent2_FirstName": values.firstName,
                "Parent2_LastName": values.lastName,
                "Parent2_ID": values.id.toString(),
                "Parent2_Mail": values.email,
                "Parent2_Tel1": values.phoneNumber,
                "LiveDifAdress": values.liveDifAddress ? "Y" : "N",
                "Parent2_LiveDiffAddress": values.liveDifAddress ? "Y" : "N",
                "Parent1_LiveDiffAddress": values.liveDifAddress ? "Y" : "N",
                "Parent2_StreetNo": values.liveDifAddress ? values.streetNo : household.Parent1_StreetNo,
                "Parent2_City": values.liveDifAddress ? values.city : household.Parent1_City,
                "Parent2_Street": values.liveDifAddress ? values.street : household.Parent1_Street,
                "Parent2_MaritalStatus": values.maritalStatus,
                "Parent2_ID_Type": values.idType,
                "Parent2_ApprInfo": 'N',
                "Parent1_ID": household.Parent1_ID,
                "Parent1_FirstName": household.Parent1_FirstName,
                "Parent1_LastName": household.Parent1_LastName,
                "Parent1_MaritalStatus": household.Parent1_MaritalStatus,
                "Parent1_Profession": household.Parent1_Profession,
                "Parent1_Mail": household.Parent1_Mail,
                "Parent1_PlaceOfBirth": household.Parent1_PlaceOfBirth,
                "Parent1_EmigrationYear": household.Parent1_EmigrationYear,
                "Parent1_PastScout": household.Parent1_PastScout,
                "Parent1_IsVolunteered": household.Parent1_IsVolunteered,
                "Parent1_ID_Type": household.Parent1_ID_Type,
                "Parent1_MedicalQalifications": household.Parent1_MedicalQalifications || [],
                "Parent2_Profession": household.Parent2_Profession,
                "Parent2_PlaceOfBirth": household.Parent2_PlaceOfBirth,
                "Parent2_EmigrationYear": household.Parent2_EmigrationYear,
                "Parent2_PastScout": household.Parent2_PastScout,
                "Parent2_IsVolunteered": household.Parent2_IsVolunteered,
                "Parent2_MedicalQalifications": household.Parent2_MedicalQalifications || [],
                "Parent1_City": household.Parent1_City,
                "Parent1_Street": household.Parent1_Street,
                "Parent1_Country": household.Parent1_Country,
                "Parent1_StreetNo": household.Parent1_StreetNo,
                "Parent1_ZipCode": household.Parent1_ZipCode,
                "Parent2_Country": household.Parent2_Country,
                "Parent2_ZipCode": household.Parent2_ZipCode,
                "Parent1_Tel1": household.Parent1_Tel1,
                "Parent1_Tel1Type": household.Parent1_Tel1Type,
                "Parent1_Tel2": household.Parent1_Tel2,
                "Parent1_Tel2Type": household.Parent1_Tel2Type,
                "Parent1_ApprInfo": household.Parent1_ApprInfo,
                "Parent2_Tel1Type": household.Parent2_Tel1Type,
                "Parent2_Tel2": household.Parent2_Tel2,
                "Parent2_Tel2Type": household.Parent2_Tel2Type,
                "Parent1_Language": household.Parent1_Language,
                "Parent2_Language": household.Parent2_Language
            }
            let updateResult = await updateUserData.requestPromise(data);
            if (updateResult.Status) {
                if (user.role === "tribe") {
                    setFamilyUpdated(familyUpdated + 1);
                    enqueueSnackbar(`${t('parentDetails')} ${household.Parent2_FirstName} ${t("updateSuccssefuly")}`, { className: classes.orangeBG });
                    navigate(`/tribeMyFamily/${household.CardCode}`);
                    return
                }
                else {
                    setIsUpdated(isUpdated + 1);
                }
                enqueueSnackbar(`${t('parentDetails')} ${household.Parent2_FirstName} ${t("updateSuccssefuly")}`, { className: classes.orangeBG });
            }
            else {
                let sMsg;
                try {
                    sMsg = updateResult.ErrorMessage.split("Message: ")[1];
                } catch (error) {
                    sMsg = updateResult.ErrorMessage;
                }
                enqueueSnackbar(`${sMsg} SAP error`, { className: classes.redBG });
            }
        }
    })
    const openAddress = () => {
        setAddressFieldEdit(!addressFieldEdit);
        formik.setValues({ ...formik.values, streetNo: "" });
    }

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }
    const resetPassword = async () => {
        const email = formik.values.email;
        try {
            const data = { email };
            await sendResetPasswordEmail(data);
            enqueueSnackbar(`${t("resetPassMail")} ${email}`, { className: classes.orangeBG });
        } catch (error) {
            enqueueSnackbar(error, { className: classes.redBG });
        }
    }

    return (
        <VBox className={`${classes.padding1} ${classes.pageStyle}`} sx={{ padding: { xs: "3.5rem", md: "1rem" } }} justifyContent="center">
            <form onSubmit={formik.handleSubmit}>
                <HBox justifyContent='space-between'>
                    <Typography fontWeight={'600'}>{household.Parent2_FirstName ? `${t("parent2")} : ${household.Parent2_FirstName} ${household.Parent2_LastName}` : `${t("parent2")}`}</Typography>
                    <IconButton onClick={e => setTopFieldsEdit(!topFieldsEdit)} color="inherit" aria-label="menu" >
                        <EditIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <Typography
                        className={`${classes.textFieldStart}`}
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        fontWeight={'600'}>{t("firstName")}</Typography>
                    <TextField width={'50%'}
                        id="firstName"
                        name="firstName"
                        className={`${classes.width50} `}
                        variant="outlined"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={topFieldsEdit}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        InputProps={{
                            classes: {
                                notchedOutline: topFieldsEdit && classes.notchedOutline
                            }
                        }}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={`${classes.textFieldStart}`}
                        fontWeight={'600'}>{t("lastName")}</Typography>
                    <TextField width={'50%'}
                        id="lastName"
                        name="lastName"
                        className={classes.width50}
                        variant="outlined"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={topFieldsEdit}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                        InputProps={{
                            classes: {
                                notchedOutline: topFieldsEdit && classes.notchedOutline
                            }
                        }}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <ButtonGroup disabled={topFieldsEdit} color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                        <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}>{t("israelId")}</Button>
                        <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{t("passport")} </Button>
                        <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{t("journeyId")} </Button>
                    </ButtonGroup>
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`}>
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}>{t("id")}</Typography>
                    <TextField width={'50%'}
                        type="text"
                        id="id"
                        name="id"
                        className={`${classes.width50} ${classes.RemoveTextFieldNumberArrow}`}
                        variant="outlined"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={topFieldsEdit}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        InputProps={{
                            inputRef: inputRef,
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            },
                            classes: {
                                notchedOutline: topFieldsEdit && classes.notchedOutline
                            }
                        }}
                        onWheel={(e) => {
                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                inputRef.current.blur();
                            }

                        }}
                    />
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} >
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}>{t("maritalStatus")}</Typography>
                    <Autocomplete
                        id="maritalStatus"
                        name="maritalStatus"
                        style={{ width: "50%" }}
                        disabled={topFieldsEdit}
                        disableClearable={true}
                        defaultValue={parent2Material}
                        className={classes.marginBottom1}
                        options={maritalStatuss}
                        onBlur={formik.handleBlur}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        onChange={(e, value) => valueChanged(value)}
                        getOptionLabel={(option) => option.text}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.key}>
                                {!option.old && option.text}
                            </Box>
                        )}
                        renderInput={(params) =>
                            <TextField {...params}
                                error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                                helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        notchedOutline: topFieldsEdit && classes.notchedOutline
                                    }
                                }} />}
                    />
                </HBox>
                <Box marginBottom={"1.25rem"}>
                    <HBox className={classes.switchAndName}>
                        <Typography className={` ${classes.textFieldStart}`}
                            sx={{ width: { xs: "30%", md: "50%" } }}
                            fontWeight={'600'} > {t("liveDiffAddress")} </Typography>
                        <GreenSwitch
                            id="liveDifAddress"
                            name="liveDifAddress"
                            onChange={formik.handleChange}
                            checked={formik.values.liveDifAddress || false}
                            value={formik.values.liveDifAddress}
                            checkedIcon={<CheckCircleIcon />}
                            icon={<RemoveCircleIcon sx={{ color: " #797979" }} />}
                            disabled={topFieldsEdit} />
                    </HBox>
                </Box>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    {formik.values.liveDifAddress && <>  <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}> {t("address")} </Typography>
                        <Typography
                            className={`${classes.width50} ${classes.textFieldStart} ${classes.alignItemsBaseline} `}
                            color={"#00000061"}>{`${household.Parent2_Street} ${household.Parent2_StreetNo ? household.Parent2_StreetNo : ""}, ${household.Parent2_City}`} </Typography>
                        <IconButton onClick={e => openAddress()} disabled={!formik.values.liveDifAddress} color="inherit" aria-label="menu" >
                            <EditIcon sx={{ color: "#3a7735" }} />
                        </IconButton>
                    </>}
                </HBox>
                {addressFieldEdit && formik.values.liveDifAddress &&
                    <HBox width={'100%'} className={`${classes.marginBottom1}`} alignItems="center">
                        <AutocompleteGooglePlaces
                            id="address"
                            name="address"
                            onPlaceSelected={(placeDetails) => addressChanged(placeDetails.addressDetails)}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                            noOptionsText={t("addressNoOption")}
                            onBlur={formik.handleBlur}
                            inputLabel={t('cityStreetAndHouseNum')}
                            inputPlaceholder={t("addressSearchPlaceholder")}
                            inputError={formik.touched.address && Boolean(formik.errors.streetNo)}
                            inputHelperText={formik.touched.address && formik.errors.streetNo}
                        />
                        {/* <Autocomplete
                            disableClearable={true}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                            id="street"
                            name="street"
                            inputValue={streetValue}
                            open={streetValue.length >= 3 && streetOpen}
                            onInputChange={(event, newInputValue) => {
                                setStreetValue(newInputValue);
                                setStreetOpen(true)
                            }}
                            noOptionsText={t("addressNoOption")}
                            onChange={(e, value) => streetValueChanged(value)}
                            onBlur={formik.handleBlur}
                            options={streetsList}
                            getOptionLabel={(option) => `${option.StreetName} ${option.CityName}`}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.Code}>
                                    {option.StreetName}, <div style={{ fontWeight: "bold", marginRight: "3px" }}>{option.CityName}</div>
                                </Box>
                            )}
                            isOptionEqualToValue={(option, value) => option.Code === value.Code}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label={t('cityAndStreet')}
                                    placeholder={t("searchPlaceHolder1")}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {!streetsList.length && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>)
                                    }} />}
                        />
                        <TextField
                            label={t('number')}
                            sx={{ width: "25%" }}
                            className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                            id="streetNo"
                            name="streetNo"
                            value={formik.values.streetNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.streetNo && Boolean(formik.errors.streetNo)}
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    inputMode: 'tel',
                                    pattern: "[0-9]*"
                                }
                            }}
                        /> */}
                    </HBox>
                }
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={`${classes.textFieldStart}`}
                        fontWeight={'600'}>{t("phoneNumber1")}</Typography>
                    <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        variant="outlined"
                        disabled={phoneFieldEdit || updateUserData.loading}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        type="tel"
                        InputProps={{
                            inputRef: inputRef1,
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            },
                            classes: {
                                notchedOutline: phoneFieldEdit && classes.notchedOutline
                            }
                        }}
                        onWheel={(e) => {
                            if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                inputRef1.current.blur();
                            }

                        }}
                    />
                    <IconButton onClick={e => setPhoneFieldEdit(false)} color="inherit" aria-label="menu" >
                        <EditIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </HBox>
                <HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                    <Typography
                        sx={{ width: { xs: "30%", md: "50%" } }}
                        className={` ${classes.textFieldStart}`}
                        fontWeight={'600'}> {t("emaillong")}</Typography>
                    <TextField width={'50%'}
                        id="email"
                        name="email"
                        className={`${classes.width50} `}
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={emailFieldEdit || updateUserData.loading}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        InputProps={{
                            inputProps: {
                                inputMode: 'url'

                            },
                            classes: {
                                notchedOutline: emailFieldEdit && classes.notchedOutline
                            }
                        }}

                    />
                    <IconButton onClick={e => setEmailFieldEdit(false)} color="inherit" aria-label="menu" >
                        <EditIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </HBox>
                {user.email === household.Parent2_Mail || user.role === "tribe" ?
                    < HBox width={'100%'} className={`${classes.alignItemsBaseline} ${classes.marginBottom2}`} justifyContent='space-between'>
                        <Typography
                            sx={{ width: { xs: "30%", md: "50%" } }}
                            className={` ${classes.textFieldStart}`}
                            fontWeight={'600'}> {t("password")} </Typography>
                        <TextField width={'50%'}
                            className={`${classes.width50}`}
                            disabled={true}
                            value={"********"}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                        <Tooltip title={t("mailToResetPass")}>
                            <IconButton onClick={e => setPasswordField(true)} color="inherit" aria-label="menu" >
                                <EditIcon sx={{ color: "#3a7735" }} />
                            </IconButton>
                        </Tooltip>
                    </HBox> : <></>}
                {passwordField && <Typography style={{ fontSize: "0.9rem", textDecoration: "underLine", cursor: "pointer", marginBottom: "1rem", color: "#3a7735" }} onClick={e => resetPassword()}>{t("mailToResetPass")}</Typography>}
                {(!topFieldsEdit || !phoneFieldEdit || addressFieldEdit || !emailFieldEdit) && <Button className={classes.buttonGreen} type="submit"  >
                    {t("update")}  <ArrowBackIosNewOutlinedIcon />
                </Button>}
                {updateUserData.loading && <CircularProgress sx={{ marginTop: "2rem" }} />}
            </form>
        </VBox >)
}