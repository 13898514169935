import { Stepper, StepLabel, Step, Box } from '@mui/material';
import { styled } from '@mui/system';

export const RegStepStyle = styled(Step)(({ theme, ...props }) => ({
    "& circle": {
        color: props.active === "true" && '#f9bc45 !important',
    },
    '& .MuiStepIcon-text': {
        fill: "#3a7735 !important",
    },
    '& .MuiStepLabel-labelContainer': {
        color: props.active === "true" ? "#f9bc45 !important" : "#ffffff!important"
    },
    '& .MuiStepLabel-label.Mui-active': {
        color: '#f9bc45 !important',
    },
    '& .MuiStepLabel-label.Mui-completed': {
        color: '#f9bc45 !important',
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: '0.5rem !important',
    },
    '& .MuiStepIcon-root.Mui-active': {
        color: '#f9bc45 !important',
        width: '40px',
        height: '40px',
    },
    '& .MuiStepIcon-root.Mui-completed': {
        color: '#f9bc45 !important',
        width: '40px',
        height: '40px',
    },
    '& .MuiStepIcon-root': {
        color: '#ffffff !important',
        width: '40px',
        height: '40px',
    },
    '& .MuiStepConnector-root': {
        top: "20px !important"
    }
}));

export const RegisterStepper = ({ aSteps, activeStep, guest = false }) => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Stepper alternativeLabel activeStep={activeStep} sx={{ marginTop: guest ? '0' : '1rem', width: { xs: 'auto', lg: guest ? '100 % ' : '50% ' }, direction: "ltr" }} >
                {aSteps.map((label, index) => (
                    <RegStepStyle completed={activeStep > (index + 1)} active={activeStep === index + 1} key={index}>
                        <StepLabel>{label}</StepLabel>
                    </RegStepStyle>
                ))}
            </Stepper>
        </Box >
    );
};
