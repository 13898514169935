import React from 'react'
import { Slide, Box } from '@mui/material';

export const MySlider = ({ hideTimeout, CurrentLevel, children, container }) => {
    return (
        <Slide mountOnEnter unmountOnExit direction='right' in={CurrentLevel} style={{ transformOrigin: '300 300 300' }}
            timeout={!hideTimeout && CurrentLevel ? 500 : 0} container={container}>
            <Box height={CurrentLevel ? '100%' : '0%'} sx={{ display: 'flex' }}>
                {children}
            </Box>
        </Slide>

    );
}