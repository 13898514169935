import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//mui
import { Card, TextField, Autocomplete, Typography, Container, LinearProgress, Checkbox, Box, Table, TableRow, TableHead, TableBody, TableCell, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
//components
import { HBox } from '../../../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    foodCard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important',
    },
    statmentAutoComplite: {
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: "0 !important",
            minWidth: "0px !important"
        }
    },
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important',
        alignItems: 'center !important',
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    foodButtons: {
        "&:hover": {
            background: '#e0e0e0 !important',
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important ',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    },

    foodButtons1: {
        "&:hover": {
            background: '#b6f3b5 !important',
        },
        backgroundColor: '#b6f3b5 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        border: '1px solid #007f00 !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    },
    foodIinput: {

        '&.MuiInput-underline:before': {
            'borderBottom': 'none !important',
            'position': "unset !important"
        },
        '&.MuiInput-underline:after': {
            'borderBottom': 'none !important',
            'position': "unset !important"
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.25rem !important',
        borderRadius: '2rem !important',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        width: '5rem !important'

    },
    foodIinput1: {
        '&.MuiInput-underline:before': {
            'borderBottom': 'none !important',
        },
        '&.MuiInput-underline:after': {
            'borderBottom': 'none !important',
        },
        backgroundColor: '#b6f3b5 !important',
        height: '1.25rem !important',
        borderRadius: '2rem !important',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        width: '5rem !important',
        border: '1px solid #007f00 !important'
    },
    tc: {
        "&.MuiTableCell-body": {
            padding: '0 !important'
        }
    }
}));

const NO_FOODPREF = "01";

const FoodPreference = ({ household, foodPreferencesList, cardCode, foodListApi, foodValidator, setFoodValidator, clearSignature }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [foodList, setFoodList] = useState([]);
    const childFoodPrefs = [];

    // insert to household for every change
    useEffect(() => {
        clearSignature();
        const validator = foodList.length;
        setFoodValidator(validator);
        if (!!foodList.length)
            insertToHousehold();
    }, [foodList]);

    //initialize food prefrence 
    useEffect(() => {
        if (foodPreferencesList.length > 0 && !!foodListApi) {
            // get children food prefs from sap and check they also in the foodListApi
            foodPreferencesList.forEach((food) => {
                const foodFound = foodListApi.find((foodPref) => food.FoodPrefCode === foodPref.FoodCode);
                if (foodFound) {
                    childFoodPrefs.push({ FoodCode: food.FoodPrefCode, FoodName: foodFound.FoodName });
                }
            })
            setFoodList(childFoodPrefs);
        }
    }, [foodListApi])

    //code list creation to update child food prefer
    const insertToHousehold = () => {
        const child = household.ChildrenList?.results.find((child) => child.CardCode === cardCode);

        if (foodList.every(({ FoodCode }) => FoodCode !== NO_FOODPREF)) {
            const codeList = foodList.map((food) => {
                return { FoodPrefCode: food.FoodCode }
            })
            child.FoodPreferencesList.results = codeList;
        }
        // no food
        else {
            child.FoodPreferencesList.results = [{ FoodPrefCode: NO_FOODPREF }];
        }
    }

    const disabledOptions = (option) => {
        const isChosen = foodList.every(({ FoodCode }) => FoodCode !== '01');
        const isFound = foodList.find(({ FoodCode }) => FoodCode === option.FoodCode);

        if (option.FoodCode === '01') {
            return foodList.length > 0 && isChosen;
        }
        else
            return !isChosen || isFound;
    }
    let names = []
    foodList.length && foodList.map((di) => { names.push(di.FoodName) });
    return (
        <>
            <Card className={classes.foodCard} data-html2canvas-ignore="true">
                {!!foodListApi ?
                    <Container>
                        <HBox className={classes.cardTitleAndIcon}>
                            <RestaurantIcon sx={{ color: "#3a7735" }} />
                            <Typography className={classes.cardTitle}>{t('foodPreference')} </Typography>
                        </HBox>
                        <Box flex={1} display='flex' flexDirection='column' alignItems="flex-start" padding='1rem'>
                            <Autocomplete
                                defaultValue={childFoodPrefs}
                                fullWidth={true}
                                limitTags={3}
                                disableClearable={true}
                                getOptionDisabled={disabledOptions}
                                multiple={true}
                                options={foodListApi.sort((a, b) => {
                                    if (a.FoodName === t("otherCompareString")) {
                                        return 1;
                                    }
                                    if (b.FoodName === t("otherCompareString")) {
                                        return -1;
                                    }
                                    if (a.FoodName === t("noFoodCompareString")) {
                                        return -1;
                                    }
                                    if (b.FoodName === t("noFoodCompareString")) {
                                        return 1;
                                    }
                                    return a.FoodName.toString().localeCompare(b.FoodName.toString());
                                }
                                )}
                                renderOption={(props, food, { selected }) => (
                                    <li {...props} key={food.FoodCode}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} />}
                                            checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <Typography>{food.FoodName}</Typography>
                                    </li>

                                )}
                                isOptionEqualToValue={(option, value) => option.FoodCode === value.FoodCode}
                                className={classes.statmentAutoComplite}
                                noOptionsText={t('notOption')}
                                getOptionLabel={(option) => option.FoodName || ""}
                                onChange={(event, value) => setFoodList(value)}
                                style={{ minWidth: '10rem' }}
                                renderInput={(params) => (
                                    <TextField {...params} variant='standard' label={t('choose')} />
                                )}
                            />
                            {/* error msg */}
                            {!foodValidator &&
                                <FormHelperText error={true} style={{ fontSize: "0.8rem" }} >
                                    {t('foodError')}
                                </FormHelperText>}
                        </Box>
                    </Container> :
                    <LinearProgress color="primary" />
                }
            </Card>
            <Box justifyContent='center' className={`${"showOnPrint"}_${cardCode}`} sx={{ display: "none" }} >
                <HBox className={classes.cardTitleAndIcon} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }}>{`${t('foodPreference')} `}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {foodList.every((el) => el === NO_FOODPREF) ?
                                    <TableCell className={classes.tc} >{t('noFoodPref')}</TableCell> :
                                    <TableCell className={classes.tc} >
                                        {names.join(", ")}
                                    </TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </HBox>
            </Box>
        </>
    )
}
export default FoodPreference;