import { Accessibility } from 'accessibility';
import { useTranslation } from 'react-i18next';
import useGlobal from 'store/store';


export default () => {
    
    const { t } = useTranslation();
    const [accessibiltyTermsDialog, setAccessibiltyTermsDialog] = useGlobal(
        (state) => state.accessibiltyTermsDialog,
        (actions) => actions.setAccessibiltyTermsDialog);

    const initialize = () => {
        const labels = {
            resetTitle: t('resetTitle'),
            closeTitle: t('closeTitle'),
            menuTitle: t('accessibilty'),
            increaseText: t('increaseText'),
            decreaseText: t('decreaseText'),
            increaseTextSpacing: t('increaseTextSpacing'),
            decreaseTextSpacing: t('decreaseTextSpacing'),
            increaseLineHeight: t('increaseLineHeight'),
            decreaseLineHeight: t('decreaseLineHeight'),
            invertColors: t('invertColors'),
            grayHues: t('grayHues'),
            underlineLinks: t('underlineLinks'),
            bigCursor: t('bigCursor'),
            readingGuide: t('readingGuide'),
            textToSpeech: t('textToSpeech'),
            speechToText: t('speechToText'),
            disableAnimations: t('disableAnimations'),
            screenReader: t('screenReader')
        };

        const icons = {
            circular: true,
            img: t('accessibiltyMenu'),
            position: { top: { size: 3, units: 'rem' }, right: { size: 0, units: 'rem' }, type: 'fixed' }
        }

        const options = {
            customFunctions: [{
                method: (cf, state) => {
                    setAccessibiltyTermsDialog(true)
                    const ele = document.getElementsByClassName('_menu-close-btn _menu-btn material-icons')[0];
                    if (ele) { ele.click(); }

                },
                buttonText: t("accessibiltyStatment"),
                id: 1,
                icon: 'assignment_outlined'
            }],
            labels: labels,
            icon: icons
        };

        options.textToSpeechLang = 'he-IL';
        options.speechToTextLang = 'he-IL';

        window.addEventListener('load', function () {
            try {
                new Accessibility(options);
            } catch (error) { }

        }, false);

    }

    return {
        initialize
    };
};


