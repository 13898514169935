import { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LanguagePicker } from '../Common/LanguagePicker'
import Logo from '../../assets/Logo.png'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'raviger';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { VIconAndText } from '../Login/LoginPageHeadr'
import ContactDialog from 'Components/Common/ContactDialog';
import GenericDialog from 'Components/Common/GenericDIalog';

const useStyles = makeStyles(() => ({
    RegToolbar: {
        justifyContent: 'space-between',
        display: 'flex',
        boxShadow: 'none',
        padding: '1rem'
    },
    whiteButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#ffffff !important',
        margin: '1rem !important',
        border: '1px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '10.75rem !important',
        textTransform: "capitalize !important"
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        backgroundColor: '#3a7735 !important',
        color: "#ffffff !important",
        alignItems: 'center !important',
        justifyContent: 'center !important',
        margin: '1rem !important',
        borderRadius: '50px !important',
        width: '10.75rem !important',
        textTransform: "capitalize !important"
    },
    hiddenXs: {
        '@media (max-width: 576px)': {
            display: "none"
        }
    }
}));

export const RegisterBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openContact, setOpenContact] = useState(false);
    const [openGoBack, setOpenGoBack] = useState(false);

    return (
        <AppBar position="static" sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
            <Toolbar className={`${classes.RegToolbar}`}>
                <Typography sx={{ color: "#ffffff", cursor: "pointer", fontSize: { xs: "0.8rem", md: "1rem" } }} onClick={e => setOpenGoBack(true)}>{t("backToLogin")}</Typography>
                {openGoBack &&
                    <GenericDialog open={openGoBack} setOpen={setOpenGoBack} showRopes={true} height="16rem">
                        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.9rem", md: "1.5rem" } }}>{t('formDetailsDeleted')}</Typography>
                        <Typography sx={{ fontSize: "1.1rem", color: "#3a7735", textAlign: "center" }}>{t('areYouSure')}</Typography>
                        <Box alignItems={"center"} display="flex" flexDirection={{ xs: "column", md: "row" }} marginTop="3rem">
                            <Button className={classes.greenButton} onClick={e => navigate('/')}>{t('yesGoToLogin')}</Button>
                            <Button className={classes.whiteButton} onClick={e => setOpenGoBack(false)}>{t('noStayReg')}</Button>
                        </Box>
                    </GenericDialog>}
                <Box flexDirection="column" display={"flex"} alignItems={"center"}>
                    <img
                        width='auto'
                        height='44px'
                        alt="..."
                        src={Logo}
                        loading="lazy"

                    />
                    <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1.35rem" } }}>
                        {t("regToParentsPortal")}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="row" >
                    <VIconAndText func={e => setOpenContact(true)} icon={<SupportAgentIcon sx={{ color: "#ffffff", cursor: 'pointer' }} />} text={t("anyProblem")} />
                    <LanguagePicker />
                </Box>
            </Toolbar>
            {openContact &&
                <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}
        </AppBar>
    );
}