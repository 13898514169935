import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import SearchFamily from "./SearchFamily";
import { useTranslation } from "react-i18next";
import { AddChildren } from "Components/Register/AddChildren";
import { RegisterContext } from 'context/registerContext';
import { AddNewFamily } from "./AddNewFamily";


const TribeSearchFamily = ({ tribe, addPupilToFamily, addNewFamily, setFamily, setFamilyUpdated, familyUpdated }) => {
    const { t } = useTranslation();
    const [lastName, setLastName] = useState("");
    const [cardCode, setCardCode] = useState("");
    const [disabledLevel1, setDisabledLevel1] = useState(false);
    const [disabledLevel2, setDisabledLevel2] = useState(true);
    const [disabledLevel3, setDisabledLevel3] = useState(true);
    const [updateParent, setUpdateParent] = useState({});

    const addNewPupil = (cardCode, lastName) => {
        if (cardCode && lastName) {
            setCardCode(cardCode);
            setLastName(lastName);
        }
    }
    useEffect(() => {
        if (addPupilToFamily.pupil) {
            setDisabledLevel1(true);
            setDisabledLevel2(true);
            setDisabledLevel3(false);
            addNewPupil(addPupilToFamily.CardCode, addPupilToFamily.lastName);
        }
        if (addNewFamily.family) {
            setDisabledLevel1(true);
            setDisabledLevel2(false);
            setDisabledLevel3(true);
            setCardCode("");
            setLastName("");
        }
    }, [addPupilToFamily, addNewFamily])


    return (
        <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: "1.5rem", paddingLeft: "1rem" }}>{t("addStudent")}</Typography>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
                <LevelBox title={t("searchLevel1")} level={1} disabled={disabledLevel1}>
                    <Typography component="span" >{`${t("beforeSearch")} `}<b>{t("theStudent")}</b> {t("or")} <b>{t("studentFamily")}</b>{` ${t("beforeSearch1")}`}</Typography>
                    <SearchFamily tribe={tribe} addNewPupil={addNewPupil} setDisabledLevel1={setDisabledLevel1} setDisabledLevel2={setDisabledLevel2} setDisabledLevel3={setDisabledLevel3} setFamily={setFamily} />
                </LevelBox>
                <LevelBox title={t("searchLevel2")} level={2} disabled={disabledLevel2}>
                    <AddNewFamily addNewPupil={addNewPupil} setDisabledLevel2={setDisabledLevel2} setDisabledLevel3={setDisabledLevel3} />
                </LevelBox>
                <LevelBox title={lastName ? `${t("searchLevel3")} ${t("familyOf")} ${lastName}` : t("searchLevel3")} level={3} disabled={disabledLevel3} >
                    <RegisterContext.Provider value={setUpdateParent}>
                        {cardCode && lastName && <AddChildren cardCode={cardCode} tribe={true} lastName={lastName} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />}
                    </RegisterContext.Provider>
                </LevelBox>
            </Box>
        </Box >
    )
}
export default TribeSearchFamily;

const LevelBox = ({ children, title, level, disabled }) => {

    return (

        <Box display="flex" flexDirection="column" alignItems="center" sx={{
            height: { xs: "32rem", md: "38rem" },
            width: { xs: "22rem", md: "34.5rem" },
            borderRadius: "20px", margin: "1rem", padding: "1rem", overflow: "auto",
            border: disabled ? "2px solid #0000001c" : "2px solid #3a7735",
            backgroundColor: disabled ? '#0000001c' : 'transparent',
            opacity: disabled ? 0.5 : 1,
            pointerEvents: disabled ? 'none' : 'auto'
        }}>
            <Box display="flex" flexDirection="row" alignItems="center" alignSelf="start">
                <Typography sx={{
                    backgroundColor: "#ffbb41",
                    color: '#333333',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    borderRadius: "100%",
                    width: "2rem",
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0.5rem"
                }}>{level}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
            </Box>
            {children}
        </Box>

    )
}