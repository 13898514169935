import { Auth, Public } from './Routes';
import { useRoutes } from 'raviger';
import { useTranslation } from 'react-i18next';
import useFeathers from "../hooks/useFeathers";
import { useEffect } from "react";
import useGlobal from 'store/store';
import TermsDialog from 'Components/Common/TermsDialog';
import { system } from "../api/client"

const Router = () => {
    // const hookAccessibility = useAccessibility();
    const publicRoutes = useRoutes(Public)
    const protectedRoutes = useRoutes({ ...Auth, ...Public })
    const feathers = useFeathers();
    const { t } = useTranslation()
    let routes;

    const [accessibiltyTermsDialog, setAccessibiltyTermsDialog] = useGlobal(
        (state) => state.accessibiltyTermsDialog,
        (actions) => actions.setAccessibiltyTermsDialog);

    useEffect(async () => {
        try {
            await feathers.reAuthenticate();
            routes = protectedRoutes;
        } catch (error) {
            routes = publicRoutes;
        }

    }, []);
    //this function and effect will chek if token expierd after 60min of user session if true user force logout
    const parseJwt = (token) => {
        try {
            return JSON.parse(window.atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const AuthVerify = () => {
        const token = system === "prod" ? localStorage.getItem(`zofim-parents-portal-token`) : localStorage.getItem(`zofim-parents-portal-token-${system}`)
        if (token) {
            const decodedJwt = parseJwt(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                feathers.logOut();
            }
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            if (system !== "dev") {
                AuthVerify()
            }
        }, 3600000);
        return () => clearTimeout(timer);
    }, []);

    routes = feathers?.isLoggedIn === undefined ? null : feathers.isLoggedIn ? protectedRoutes : publicRoutes;

    return (
        <>
            {routes}
            <TermsDialog
                openDialog={accessibiltyTermsDialog}
                setOpenDialog={setAccessibiltyTermsDialog}
                title={t("accessibiltyStatment")}
                src={process.env.REACT_APP_ACCESSIBILITY_STATEMENT_URL}
            />
        </>
    );
}
export default Router;
