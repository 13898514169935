import { makeStyles } from '@mui/styles';

import { IconButton, Typography } from '@mui/material';
import { VBox, HBox } from '../../../assets/SharedComponents';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const useStyles = makeStyles(() => ({
    cartTop: {
        background: '#f0f3f0 !important'
    },
    title: {
        color: '#000000 !important',
        textAlign: 'right !important',
        fontSize: '1.5rem !important',
    }
}));


const ParentCartHeader = ({ setLevel, title, prevLevel, onCloseCart }) => {
    const classes = useStyles();
    return (
        <VBox className={classes.cartTop}>
            <HBox justifyContent={!!onCloseCart ? 'space-between' : 'flex-start'}>
                {!!prevLevel || !!setLevel ? <IconButton size="medium" onClick={e => prevLevel ? prevLevel() : setLevel(1)}>
                    <ArrowForwardIosOutlinedIcon sx={{ color: '#3a7735' }} />
                </IconButton> : null}
                <Typography className={classes.title}>{title}</Typography>
                {!!onCloseCart && <IconButton onClick={e => onCloseCart(e)} >
                    <CloseIcon sx={{ color: "#3a7735" }} />
                </IconButton>}
            </HBox>
        </VBox >)
}
export default ParentCartHeader