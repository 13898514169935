// Mui
import { Chip, useTheme, styled } from "@mui/material";
// i18n
import { useTranslation } from "react-i18next";

const MuiChip = styled(Chip)(({ theme, boxcolor, size }) => ({
    height: size === "medium" ? '100%' : '1rem',
    fontWeight: 'bold',
    display: 'flex',
    boxShadow: ` 0px 0px 6px 0px ${boxcolor}`,
    [theme.breakpoints.down('sm')]: {
        height: '0.5rem',
        fontSize: "0.7rem"

    }
}));

const StatusChip = ({ status, size = "medium" }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    let statusColor;
    let statusText;
    let shadowBoxColor;
    switch (status) {
        case '1':
            statusText = t("notReg")
            statusColor = theme.palette.disable.lighter
            shadowBoxColor = '#fdfdfd'
            break
        case '2':
            statusText = t("unpaidYet");
            statusColor = theme.palette.error.light
            shadowBoxColor = '#f58269'
            break;
        case '3':
            statusText = t("partialPay")
            statusColor = theme.palette.warning.light;
            shadowBoxColor = '#fcd69e'

            break;
        case '4':
            statusText = t("registered")
            statusColor = theme.palette.success.light
            shadowBoxColor = '#41c146'

            break
        case '5':
            statusText = t("cancelled")
            statusColor = theme.palette.grey
            shadowBoxColor = '#fdfdfd'
            break
        case '6':
            statusText = t("refunded")
            statusColor = theme.palette.grey
            shadowBoxColor = '#fdfdfd'
            break
        case '7':
            statusText = t("payWaitToReg")
            statusColor = theme.palette.success.light
            shadowBoxColor = '#41c146'
            break
        case '99':
            statusText = t("notReg")
            statusColor = theme.palette.disable.lighter
            shadowBoxColor = '#fdfdfd'
            break
        default:
            break;
    }
    return <MuiChip label={statusText} boxcolor={shadowBoxColor} sx={{ background: statusColor }} size={size} />
}

export default StatusChip;