import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../assets/LoginStyle.jsx'
import { Box, Card, Typography } from '@mui/material';
import Logo from '../assets/Logo.png'
import NoRegRequests from '../assets/NoRegRequests.svg';
import { useQueryParams } from 'raviger'


export const QuackLandingPage = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const urlParams = useQueryParams();
    const [handleIssue, setHandleIssue] = useState(false);

    useEffect(() => {
        const quackUrl = urlParams[0].quackUrl;
        if (quackUrl.slice(-1).toString() === "1") {
            setHandleIssue(true);
        }
        fetch(quackUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status}`);
                }
                return response;
            })
            .then(data => {
                console.log('Data fetched:', data);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }, []);

    return (
        <Box className={`${classes.height100} ${classes.AppBackground}`} style={{ minHeight: '50rem' }}>
            <Box alignItems="center" display="flex" flexDirection="column"  >
                <Box display="flex" flexDirection="column" alignItems="center" mt="5rem">
                    <img
                        alt={"..."}
                        className={classes.LogoSize}
                        src={Logo}
                        loading="lazy"
                    />
                    <Typography sx={{ fontWeight: "bold", color: "#ffffff", fontSize: "1.5rem" }}>{t("theParentsPortal")}</Typography>
                </Box>
                <Card className={classes.loginCard} sx={{ marginTop: '2rem !important' }}>
                    <Box sx={{ height: { xs: '55%', lg: '50%', xl: '50%' } }} className={`${classes.margin0_1} ${classes.SmsMinHeight}`} >
                        <Box className={classes.FatherTabsStyle} alignItems='center' display='flex' flexDirection='column' sx={{ marginTop: '2rem !important' }}>
                            {handleIssue ? <>
                                <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.2rem", textAlign: "center" }}>{t("goodJob")}</Typography>
                                <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.2rem" }}>{t("goodSpirit")}</Typography>
                            </> :
                                <>
                                    <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.2rem", textAlign: "center" }}>{t("moveToSupport")}</Typography>
                                    <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.2rem" }}>{t("moveToSupport1")}</Typography>
                                </>}
                            <img
                                alt={"..."}
                                src={NoRegRequests}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem", fontStyle: "italic" }}>{t("TheScouts")}</Typography>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box >
    );
}