import { makeStyles } from '@mui/styles';

import { Typography, Card, Container } from '@mui/material';
import { VBox, HBox } from '../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    cardBox: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginTop: '2rem !important',
        height: "21rem !important"
    },
    splitCard: {
        height: '21rem',
        width: '20rem',
        justifyContent: 'center !important',
        borderRadius: '50px !important',
        alignItems: 'center !important'
    }
}));

const TransactionLayout = ({ children, bodyText }) => {
    const classes = useStyles();
    return (
        <Container>
            <VBox className={classes.cardBox}>
                <Card className={classes.splitCard}>
                    <Container style={{ marginTop: '1rem', textAlign: 'center' }}>
                        <HBox >
                            {children}
                        </HBox>
                        <Typography >{bodyText}</Typography>
                    </Container>
                </Card>
            </VBox>
        </Container>
    );
}

export default TransactionLayout;