import { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import useApi from '../../hooks/useApi'
//libs
import useGlobal from 'store/store';
import { useNavigate, Redirect } from "raviger";
import { getAuth } from "firebase/auth";
import ReactCodeInput from 'react-verification-code-input';
import * as yup from 'yup';
//mui
import { TextField, Select, Autocomplete, MenuItem, InputLabel, Card, FormControl, Box, FormHelperText, CircularProgress, ButtonGroup, Dialog, Button, Tooltip, Typography, DialogContent, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
//components
import { HBox, VBox } from '../../assets/SharedComponents';
import GenericDialog from 'Components/Common/GenericDIalog';
//api
import { getSchoolsList, getTribes, updatePupilStatment, getParentCardCode, updateUser, sendMailToTribe } from '../../api/api'
import { permanentInfo } from '../../data/permanentInfo';
//assets
import Logo from '../../assets/Logo.png'
import childError from '../../assets/childError.svg'
import PopUpImage2 from '../../assets/PopUpImage2.png'
//context
import { RegisterContext } from 'context/registerContext';

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    paddingBottom2: {

        paddingBottom: "2rem"
    },
    fontBold: {
        fontWeight: "600"
    },
    marginBottom1: {
        marginBottom: "1rem !important"
    },
    width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    },
    buttonWhite: {
        '& .MuiButton-label': {
            color: '#3a7735 !important',
            fontSize: '1rem !important',
            fontWeight: 'bold !important'
        },
        '& .MuiButton-startIcon': {
            marginLeft: '2px !important',
            marginRight: '-3px !important'
        },
        backgroundColor: 'white !important',
        border: '2px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '12rem !important',
        textTransform: "capitalize !important",
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)'
    },
    buttonGreen: {
        color: 'white !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '50px !important',
        textTransform: "capitalize !important",
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)'
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    selectedBtn: {
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important",
        textTransform: "capitalize !important",
    },
    paperStyle: {
        '@media (min-width:320px)': {
            width: "21.75rem",
            height: "34.5rem"
        },
        '@media (min-width:600px)': {
            width: "28.5rem",
            height: "34.5rem",
        },
        '@media (min-width:1025px)': {
            width: "40.5rem",
            height: "38.5rem"
        },
        '@media (min-width:1281px)': {
            width: "47.5rem",
            height: "42.5rem"
        },
        padding: "2px 2px 2px 2px",
        borderRadius: "50px !important",
        backgroundColor: "#ffffff !important",
        boxShadow: "15px 15px 30px 0px rgba(0, 0, 0, 0.26) !important",
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem !important"
    },
    paperTribeStyle: {
        width: "23.5rem",
        height: "56rem",
        padding: "2px 2px 2px 2px",
        borderRadius: "50px !important",
        backgroundColor: "#ffffff !important",
        boxShadow: "none !important",
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem !important"
    },
    IdnBtn: {
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important"
    },
    IdentificationBtn: {
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        textTransform: "capitalize !important",
        width: "17rem !important",
        height: "2rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    LogoSize: {
        width: '2.8rem'
    },
    textAlignCenter: {
        textAlign: "center"
    },
    dialogTextGreen: {
        color: '#24671e'
    },
    paddingWelcomeButton: {
        padding: '0.2rem 1.5rem'
    },
    dialogStyle: {
        '& .MuiDialog-paper': {
            borderRadius: '40px !important'
        },
    },
    paddingDialog: {
        padding: '0rem 1.5rem'
    },
    birthdayInput: {
        alignItems: "flex-end",
        width: "max-content",
        direction: "ltr"
    },
    datePinInputStyle: {
        width: 'auto !important;',
        "& input": {
            borderBottom: '3px solid #3a7735 !important;',
            border: 'none !important',
            width: '2rem !important;',
            height: 'auto !important;',
            fontSize: '1rem !important;',
            textAlign: "center !important;",
            outlineWidth: "0px !important;",
            borderRadius: "0px !important;",
            fontFamily: "Alef,sans-serif !important",
            caretColor: "auto !important;",
            backgroundColor: "transparent !important",
            marginRight: '2px !important'
        }
    }
}));

export const AddChildren = ({ cardCode = '', tribe = false, myFamily = false, setIsUpdated, isUpdated, lastName = "", setFamilyUpdated, familyUpdated }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const registerCont = useContext(RegisterContext);
    const { setUpdateParent } = useContext(RegisterContext);
    const { t } = useTranslation();
    const schools = useApi(getSchoolsList);
    const tribeOption = useApi(getTribes);
    const updateUserData = useApi(updateUser);
    const updateStatmentApi = useApi(updatePupilStatment);
    const [addChild, setAddChild] = useState(false);
    const [tribesList, setTribesList] = useState([]);
    const [schoolsList, setSchoolList] = useState([]);
    const [schoolValue, setSchoolValue] = useState('');
    const [schoolOpen, setSchoolOpen] = useState(false);
    const [regDone, setRegDone] = useState(false);
    const [sentMail, setSentMail] = useState(false);
    const [parentCardCode, setParentCardCode] = useState(tribe || myFamily ? cardCode : registerCont.updateParent?.CardCode);
    const [index, setIndex] = useState(1);
    const [openWelcome, setOpenWelcome] = useState(tribe || myFamily ? false : true);
    const [birthdayError, setBirthdayError] = useState(false);
    const [openPupilExsist, setOpenPupilExsist] = useState(false);
    const [defaultTribe, setDefaultTribe] = useState('');
    const auth = getAuth();
    const [deepLinkTribe, setDeepLinkTribe] = useState(null);
    const autoLastName = tribe || myFamily ? lastName : registerCont.updateParent?.Parent1_LastName;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputIDRef = useRef();
    let inputRef;
    const schoolRef = useRef(null);
    const yearRef = useRef(null);
    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const IdTextByType = { "1": t("israelId"), "2": t("passport"), "3": t("journeyId") };
    const [userAccess] = useGlobal((state) => state.userAccess);
    const [user] = useGlobal(state => state.user);

    const [deepLink, setDeepLink] = useGlobal(
        (state) => state.deepLink,
        (actions) => actions.setDeepLink);
    const [birthday, setBirthday] = useState({});


    useEffect(async () => {
        let isMounted = true;
        const res = await schools.requestPromise();
        isMounted && setSchoolList(res.d.results);

        const res1 = await tribeOption.requestPromise();
        let results = res1.d.results;
        if (userAccess.length > 0) // came from tribe user
            results = results.filter(el => !!userAccess.find(tribeEl => el.Code === tribeEl.UnitCode));
        isMounted && setTribesList(results);
        if (parentCardCode === undefined) {
            const res2 = await getParentCardCode(user.username);
            if (res2.d?.results[0]) {
                isMounted && setUpdateParent(res2.d?.results[0]);
                isMounted && setParentCardCode(res2.d?.results[0].CardCode);
            }
            else {
                const userPhone = user.phone_number ? `+972${user.phone_number}` : `+972${user.phone}`
                const userToAdd =
                {
                    CardCode: "",
                    Parent1_ApprInfo: user.ApprInfo,
                    Parent1_City: user.city,
                    Parent1_Street: user.street,
                    Parent1_StreetNo: user.streetNo,
                    Parent1_Tel1: userPhone,
                    Parent1_FirstName: user.firstName,
                    Parent1_LastName: user.lastName,
                    Parent1_ID: user.username,
                    Parent1_ID_Type: user.usernameType,
                    Parent1_Mail: user.email,
                    Parent1_MaritalStatus: user.maritalStatus,
                    Parent1_MedicalQalifications: [],
                    Parent1_IsVolunteered: "N",
                    Parent1_PastScout: "N",
                    Parent1_PlaceOfBirth: "",
                    Parent1_Profession: "",
                    Parent1_CodeFirstHelp: "",
                    Parent1_Country: "",
                    Parent1_EmigrationYear: "",
                    Parent1_FirstHelpName: "",
                    Parent1_Tel1Type: "1",
                    Parent1_Tel2: "",
                    Parent1_Tel2Type: "1",
                    Parent1_TribeName: "",
                    Parent1_WeaponType: "",
                    Parent1_WorkPlace: "",
                    Parent1_ZipCode: "",
                    Parent2_ApprInfo: "N",
                    Parent2_City: "",
                    Parent2_Street: "",
                    Parent2_StreetNo: "",
                    Parent2_Tel1: "",
                    Parent2_FirstName: "",
                    Parent2_LastName: "",
                    Parent2_ID: "",
                    Parent2_ID_Type: "1",
                    Parent2_Mail: "",
                    Parent2_MaritalStatus: "",
                    Parent2_MedicalQalifications: [],
                    Parent2_PastScout: "Y",
                    Parent2_PlaceOfBirth: "",
                    Parent2_Profession: "",
                    Parent2_CodeFirstHelp: "",
                    Parent2_Country: "",
                    Parent2_EmigrationYear: "",
                    Parent2_FirstHelpName: "",
                    Parent2_IsVolunteered: "N",
                    Parent2_Tel1Type: "1",
                    Parent2_Tel2: "",
                    Parent2_Tel2Type: "1",
                    Parent2_TribeName: "",
                    Parent2_WeaponType: "",
                    Parent2_WorkPlace: "",
                    Parent2_ZipCode: "",
                    Source: tribe ? "ASH" : "APH",
                    Tel: "",
                    TelEmergency: ""
                };
                const newParent = await updateUserData.requestPromise(userToAdd);
                if (newParent.Status) {
                    isMounted && setParentCardCode(newParent.CardCode);
                    const res3 = await getParentCardCode(user.username);
                    if (res3.d?.results[0]) {
                        isMounted && setUpdateParent(res3.d?.results[0]);
                    }
                }
                else {
                    enqueueSnackbar(t("updateError"), { className: classes.redBG });
                    return;
                }
            }
        }
        return () => { isMounted = false; }
    }, [])

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }
    const schoolValueChanged = (value) => {
        formik.setValues({ ...formik.values, school: value?.SchoolCode });
        setSchoolOpen(false);
    }
    const isValidYear = (value) => {
        const currentYear = new Date().getFullYear();
        const limitMinYear = currentYear - 19;
        const limitMaxYear = currentYear - 7;
        if (value >= limitMinYear && value <= limitMaxYear) {
            formik.setValues({ ...formik.values, birthday: { ...formik.values.birthday, year: value } })
            setBirthdayError(false);
        } else
            setBirthdayError(true);
    }

    const isValidMonth = (value) => {
        if (value >= 1 && value <= 12) {
            formik.setValues({ ...formik.values, birthday: { ...formik.values.birthday, month: value } })
            setBirthdayError(false);
            yearRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }

    const isValidDay = (value) => {
        if (value >= 1 && value <= 31) {
            formik.setValues({ ...formik.values, birthday: { ...formik.values.birthday, day: value } })
            setBirthdayError(false);
            monthRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }

    const onBirthdayDayChange = day => {

        setBirthday({ ...birthday, day });
    }
    const onBirthdayMonthChange = month => {

        setBirthday({ ...birthday, month });
        if (month === "") {
            dayRef.current.iRefs[1].current.focus();
        }
    }
    const onBirthdayYearChange = year => {

        setBirthday({ ...birthday, year });
        if (year === "") {
            monthRef.current.iRefs[1].current.focus();
        }
    }

    //Zofim request to check if birthday year is valid about 3 years difference to child current class (not on Zamid pupils)
    const rangeValidation = (year, childClass) => {
        const classOptions = [];
        const currentYear = new Date().getFullYear();
        const range = currentYear - year;
        const classSapNumber = Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === childClass);
        Object.keys(permanentInfo.childClasses).forEach((c) => {
            if (range - c >= 6 && range - c <= 9) { classOptions.push(c); }
        })
        if (classOptions.includes(classSapNumber)) { return true; }
        else { return false; }
    }

    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t('mandatoryField'))
            .min(2, t('enterValidValue'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('enterValidValue')),
        lastName: yup
            .string(t("lastName"))
            .required(t('mandatoryField'))
            .min(2, t('enterValidValue'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('enterValidValue')),
        tribe: yup
            .string(t("tribe"))
            .required(t('mandatoryField')),
        school: yup
            .string(t("school"))
            .required(t('mandatoryField')),
        gender: yup
            .string(t("gender"))
            .required(t('mandatoryField')),
        id: yup
            .string(t("id"))
            .required(t('mandatoryField'))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue')),
        class: yup
            .string(t('class'))
            .required(t('mandatoryField')),
        genderAppeal: yup
            .string(t('genderAppeal'))
            .max(30, t('enterValidValue'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('enterValidValue'))
            .nullable()

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tribe: tribesList.find(tribe => tribe.Code === deepLink?.tribeCode)?.Code || '',
            firstName: '',
            lastName: autoLastName,
            id: '',
            idType: "1",
            school: '',
            genderAppeal: '',
            gender: '',
            class: permanentInfo.childClasses[deepLink?.classCode] || '',
            birthday: { year: '', month: '', day: '' },

        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!rangeValidation(values.birthday.year, values.class)) {
                const key = enqueueSnackbar(t("classToAgeError"), { className: classes.redBG });
                setTimeout(() => { closeSnackbar(key) }, 1300)
                return
            }
            if (birthdayError) {
                enqueueSnackbar(t('validDate'), { className: classes.redBG });
                return
            }
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            const data = {
                "Source": tribe ? "ASH" : "APH",
                "CardCode": '',
                "TribeNum": values.tribe,
                "Pupil_LastName": values.lastName,
                "Pupil_FirstName": values.firstName,
                "Pupil_ID": (values.id).toString(),
                "ID_Type": values.idType,
                "Pupil_BirthdayDate": `${values.birthday.year}-${values.birthday.month}-${values.birthday.day}`,
                "Pupil_Gender": Object.keys(permanentInfo.childGender).find(key => permanentInfo.childGender[key] === values.gender),
                "Pupil_Class": Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === values.class),
                "Pupil_FatherCard": parentCardCode,
                "Pronounce": values.genderAppeal,
                "Zamid": "N",
                "School": values.school,
                "PermPartWater": 'N',
                "LiveDifAdress": 'N',
                "LiveOutsideIsrael": "N",
                "PerPhotAndPubl": "N",
                "Group": '',
                "LiveWithParent2": '',
                "Pupil_PlaceOfBirth": '',
                "Pupil_EmigrationDate": '',
                "EmerDoctor": '',
                "City": '',
                "Street": '',
                "Country": '',
                "StreetNo": '',
                "ZipCode": '',
                "OtherAllergyName": '',
                "OtherDiseaseName": '',
                "OtherFoodName": '',
                "PermPart": '',
                "Troops": '',
                "Pupil_Kupa": '',
                "Tel1Type": "1",
                "Tel2Type": "1",
                "Phone1": '',
                "Phone2": '',
                "PupilStatus": "Y",
                "Pupil_Diseases": [],
                "Pupil_Allergies": [],
                "Pupil_FoodPreferences": []
            }

            const res = await updateStatmentApi.requestPromise(data);
            if (res.Status) {
                if (tribe && !addChild) {
                    enqueueSnackbar(t("studentCreated"), { className: classes.orangeBG });
                    setFamilyUpdated(familyUpdated + 1);
                    navigate(`/tribeMyFamily/${parentCardCode}`);
                    return
                }
                if (myFamily && !addChild) {
                    enqueueSnackbar(t("studentCreated"), { className: classes.orangeBG });
                    setIsUpdated(isUpdated + 1);
                    navigate(`/MyFamily`);
                    return
                }
                if (addChild) {
                    try {
                        const monthElement = monthRef.current;
                        monthElement.__clearvalues__();
                        const yearElement = yearRef.current;
                        yearElement.__clearvalues__();
                        const dayElement = dayRef.current;
                        dayElement.__clearvalues__();
                    } catch (error) {
                    }
                    const ele = schoolRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                    if (ele) ele.click()
                    formik.resetForm({
                        tribe: tribesList.find(tribe => tribe.Code === deepLink?.tribeCode)?.Code || '',
                        school: schoolsList.filter((school) => school?.SchoolCode === values.school) || '',
                        firstName: '',
                        lastName: autoLastName,
                        id: '',
                        idType: "1",
                        genderAppeal: '',
                        gender: '',
                        class: permanentInfo.childClasses[deepLink?.classCode] || ''
                    })
                    setIndex(prev => prev + 1);
                    enqueueSnackbar(t("studentCreated"), { className: classes.orangeBG });
                    setAddChild(false);
                    if (!tribe) {
                        setIsUpdated(isUpdated + 1);
                    }
                    return
                }
                enqueueSnackbar(t("studentCreated"), { className: classes.orangeBG });
                if (!tribe && !myFamily) { setRegDone(true); }
            }
            else {
                let sMsg;
                try {
                    sMsg = res.ErrorMessage.split("System.Exception: ")[1].split("at XIS")[0];
                } catch (error) {
                    sMsg = res.ErrorMessage;
                }
                if (sMsg.includes("כבר משוייכת") && !tribe && !myFamily) {
                    setOpenPupilExsist(true);
                } else {
                    enqueueSnackbar(sMsg, { className: classes.redBG });
                }
            }
        },

    });

    const getTribeLabel = (option) => {
        if (option.Street)
            return (`${option.Name} , ${option.Street} ${option.City}`);
        else
            return (`${option.Name} ,  ${option.City}`);
    }

    const classBlur = () => {
        if (formik.values.birthday.year) {
            if (!rangeValidation(formik.values.birthday.year, formik.values.class)) {
                const key = enqueueSnackbar(t("classToAgeError"), { className: classes.redBG });
                setTimeout(() => { closeSnackbar(key) }, 1200);
                return;
            }
        }
    }
    const supportHandler = async () => {
        const tribeName = tribesList.find(tribe => tribe.Code === formik.values.tribe)?.Name;
        const tribeCode = formik.values.tribe;
        const parentName = `${registerCont.updateParent?.Parent1_FirstName} ${registerCont.updateParent?.Parent1_LastName}`
        const childName = `${formik.values.firstName} ${formik.values.lastName} `
        const childID = formik.values.id;
        const userCardCode = parentCardCode;
        const userPhoneNumber = registerCont.updateParent?.Parent1_Tel1;
        const userEmail = registerCont.updateParent?.Parent1_Mail;
        const data = { tribeName, tribeCode, parentName, childName, childID, userCardCode, userPhoneNumber, userEmail }
        try {
            await sendMailToTribe(data);
            setSentMail(true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" flexWrap="wrap" margin="1rem" >
            <Card className={tribe || myFamily ? classes.paperTribeStyle : classes.paperStyle}>
                <Container sx={{ overflow: tribe || myFamily ? "hidden" : "auto", padding: "24px" }}>
                    <Box display="flex" flexDirection="column" alignItems="center" >
                        {!tribe && <Typography style={{ marginBottom: "1rem", fontSize: "1.125rem", fontWeight: "bold", alignSelf: "flex-start" }} >{`${t('studentDetails')} ${index}`}</Typography>}
                        <form onSubmit={formik.handleSubmit}>
                            <Box display="flex" flexDirection="column" padding="1rem" >
                                <Autocomplete
                                    id="tribe"
                                    name="tribe"
                                    getOptionDisabled={() => (index === 1 && !!deepLink?.tribeCode)}
                                    disableClearable={true}
                                    onBlur={formik.handleBlur}
                                    className={classes.marginBottom1}
                                    isOptionEqualToValue={(option, value) => option.Code === value.Code}
                                    noOptionsText={t('tribeNoOption')}
                                    options={tribesList}
                                    getOptionLabel={(option) => getTribeLabel(option)}
                                    onChange={(e, value) => formik.setValues({ ...formik.values, "tribe": value.Code })}
                                    defaultValue={tribesList.find(tribe => tribe.Code === formik.values.tribe) || null}
                                    value={tribesList.find(tribe => tribe.Code === formik.values.tribe) || null}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.Code}>
                                            <div style={{ color: "#3a7735", fontSize: "0.8rem" }}>{option.Name}</div>,<div style={{ fontWeight: "bold", fontSize: "0.8rem", marginRight: "3px" }}>{option.Street}</div><div style={{ fontSize: "0.9rem", marginRight: "3px" }}>{option.City}</div>
                                        </Box>
                                    )}
                                    renderInput={(params) =>
                                        <TextField {...params} label={t('tribe')} disabled={index === 1 && !!deepLink?.tribeCode}
                                            inputRef={input => {
                                                inputRef = input;
                                            }}
                                            id="tribe"
                                            name="tribe"
                                            placeholder={t("tribeSearch")}
                                            error={formik.touched.tribe && Boolean(formik.errors.tribe)}
                                            helperText={formik.touched.tribe && formik.errors.tribe}
                                        />}

                                    disabled={updateStatmentApi.loading}
                                />
                                <TextField
                                    className={classes.marginBottom1}
                                    label={t("studentFirstName")}
                                    id="firstName"
                                    name="firstName"
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    value={formik.values.firstName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    disabled={updateStatmentApi.loading}
                                />
                                <TextField
                                    className={classes.marginBottom1}
                                    label={t("studentLastName")}
                                    id="lastName"
                                    name="lastName"
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    value={formik.values.lastName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    disabled={updateStatmentApi.loading}
                                />
                                <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                                    <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}>{IdTextByType[1]}</Button>
                                    <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{IdTextByType[2]}</Button>
                                    <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{IdTextByType[3]} </Button>
                                </ButtonGroup>
                                <TextField
                                    type="number"
                                    className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                    label={IdTextByType[formik.values.idType]}
                                    id="id"
                                    name="id"
                                    error={formik.touched.id && Boolean(formik.errors.id)}
                                    helperText={formik.touched.id && formik.errors.id}
                                    value={formik.values.id}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        inputRef: inputIDRef,
                                        inputProps: {
                                            inputMode: 'tel',
                                            pattern: "[0-9]*"
                                        },
                                        tabIndex: "1"
                                    }}

                                    onWheel={(e) => {
                                        if (inputIDRef && inputIDRef.current && inputIDRef.current.blur) {
                                            inputIDRef.current.blur();
                                        }

                                    }}
                                    disabled={updateStatmentApi.loading}
                                />
                                <Typography variant="caption" sx={{ textAlign: "left" }}>{t('studentBirthday')}</Typography>
                                <FormControl disabled={updateStatmentApi.loading} sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                                    <HBox className={`${classes.marginBottom1} ${classes.birthdayInput}`}>
                                        <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidDay} autoFocus={false} onChange={onBirthdayDayChange} values={birthday.day} fields={2}
                                            className={classes.datePinInputStyle} ref={dayRef} />
                                        <Typography align="center" component="h2" variant="h6">
                                            /
                                        </Typography>
                                        <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidMonth} autoFocus={false} onChange={onBirthdayMonthChange} values={birthday.month} fields={2}
                                            className={classes.datePinInputStyle} ref={monthRef} />
                                        <Typography align="center" component="h2" variant="h6">
                                            /
                                        </Typography>
                                        <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidYear} autoFocus={false} onChange={onBirthdayYearChange} values={birthday.year} fields={4}
                                            className={classes.datePinInputStyle} ref={yearRef} />
                                    </HBox>
                                    <FormHelperText error={true} className={birthdayError ? classes.marginBottom1 : ''}>{birthdayError && t('validDate')}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.marginBottom1}>
                                    <InputLabel>{t('class')}</InputLabel>
                                    <Select
                                        sx={{ textAlign: "left" }}
                                        id="class"
                                        name="class"
                                        label={t('class')}
                                        value={formik.values.class}
                                        onBlur={classBlur}
                                        onChange={formik.handleChange}
                                        onClose={() => {
                                            setTimeout(() => {
                                                document.activeElement.blur();
                                            }, 0);
                                        }}
                                        error={formik.touched.class && Boolean(formik.errors.class)}
                                        disabled={updateStatmentApi.loading || (index === 1 && !!deepLink?.tribeCode)}
                                    >
                                        {Object.keys(permanentInfo.childClasses).map((childClass, i) => {
                                            return (
                                                <MenuItem value={permanentInfo.childClasses[i + 1]} key={i} >{permanentInfo.childClasses[i + 1]}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText error={formik.touched.class && Boolean(formik.errors.class)} >{formik.touched.class && formik.errors.class}</FormHelperText>
                                </FormControl>
                                <Autocomplete
                                    ref={schoolRef}
                                    className={classes.marginBottom1}
                                    disabled={updateStatmentApi.loading}
                                    id="school"
                                    name="school"
                                    open={schoolValue.length >= 3 && schoolOpen}
                                    inputValue={schoolValue}
                                    onBlur={formik.handleBlur}
                                    onInputChange={(event, newInputValue) => {
                                        setSchoolValue(newInputValue);
                                        setSchoolOpen(true)
                                    }}
                                    noOptionsText={t('schoolNoOption')}
                                    onChange={(e, value) => schoolValueChanged(value)}
                                    options={schoolsList.sort((a, b) =>
                                        b.CityName.toString().localeCompare(a.CityName.toString())
                                    )}
                                    isOptionEqualToValue={(option, value) => option.SchoolCode === value.SchoolCode}
                                    getOptionLabel={(option) => `${option.SchoolName} , ${option.CityName}`}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={`${option.SchoolCode}`}>
                                            <div style={{ color: "#3a7735", fontSize: "0.8rem" }}>{option.SchoolName}</div>,<div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>{option.CityName}</div>
                                        </Box>
                                    )}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label={t('schoolSearch')}
                                            placeholder={t("searchPlaceHolder1")}
                                            error={formik.touched.school && Boolean(formik.errors.school)}
                                            helperText={formik.touched.school && formik.errors.school}
                                            InputProps={{
                                                ...params.InputProps,
                                                inputMode: "search",
                                                endAdornment: (
                                                    <>
                                                        {schools.loading && <CircularProgress color="inherit" size={20} />}
                                                        {params.InputProps.endAdornment}
                                                    </>)
                                            }} />}
                                />
                                <FormControl fullWidth className={classes.marginBottom1} error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                    <InputLabel>{t('gender')}</InputLabel>
                                    <Select
                                        id="gender"
                                        name="gender"
                                        label={t('gender')}
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={updateStatmentApi.loading}
                                    >
                                        {Object.values(permanentInfo.childGender).map((gender, i) => {
                                            return (
                                                <MenuItem value={gender} key={i} >{gender}</MenuItem>
                                            )
                                        })
                                        }
                                    </Select>
                                    {formik.touched.gender && Boolean(formik.errors.gender) && <FormHelperText>{formik.touched.class && formik.errors.gender}</FormHelperText>}
                                </FormControl>
                                <HBox width={'100%'} >
                                    <Typography variant="caption" alignSelf="flex-start">{t("genderAppeal1")} </Typography>
                                    <Tooltip title={t("genderAppealText")}>
                                        <InfoOutlinedIcon sx={{ color: "#000000", fontSize: '1rem', marginLeft: "4px" }} />
                                    </Tooltip>
                                </HBox>
                                <TextField
                                    className={classes.marginBottom1}
                                    label={t('genderAppeal')}
                                    onBlur={formik.handleBlur}
                                    id="genderAppeal"
                                    name="genderAppeal"
                                    value={formik.values.genderAppeal}
                                    onChange={formik.handleChange}
                                    error={formik.touched.genderAppeal && Boolean(formik.errors.genderAppeal)}
                                    helperText={formik.touched.genderAppeal && formik.errors.genderAppeal}
                                    variant={"outlined"}
                                    disabled={updateStatmentApi.loading}
                                />
                                {!updateStatmentApi.loading ? <>
                                    <HBox justifyContent="space-between" style={{ marginTop: "1rem" }}>
                                        <Button className={classes.buttonWhite} type="submit" onClick={e => setAddChild(true)}  >
                                            {t('regAndAddStudent')}
                                        </Button>
                                        <Button className={classes.buttonGreen} type="submit" style={{ fontSize: "1rem !important", fontWeight: "bold" }} >
                                            {t('regAndContinue')}
                                        </Button>

                                    </HBox> </> :
                                    <HBox style={{ marginTop: "1rem", justifyContent: "center" }} >
                                        <CircularProgress color="primary" />
                                    </HBox>
                                }
                            </Box>
                        </form>
                    </Box>
                </Container>
            </Card>
            <WelcomeDialog
                openWelcome={openWelcome}
                setOpenWelcome={setOpenWelcome}
                lastName={formik.values.lastName ? formik.values.lastName : user.lastName} />
            <GenericDialog open={openPupilExsist} setOpen={setOpenPupilExsist} showRopes={true} showExit={true} iconSVG={childError} height={"23rem"}>
                <Typography sx={{ fontSize: "1.3rem", color: "#bb0000", textAlign: "center", fontWeight: "bold" }} >{t('notCorrectDetails')}</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#bb0000", textAlign: "center" }} >{t('notCorrectDetails')}</Typography>
                {sentMail ?
                    <Typography sx={{ fontSize: "1rem", color: "#bb0000", textAlign: "center" }} >{t('theMailWasSent')}</Typography>
                    :
                    <Button className={classes.buttonGreen} onClick={e => supportHandler()} style={{ fontSize: "1rem !important", fontWeight: "bold", marginTop: "4rem" }} >
                        {t('support')}
                    </Button>}
            </GenericDialog>
            {regDone && <Redirect to='/parent/homepage' />}
        </Box>
    );
}

const WelcomeDialog = ({ openWelcome, setOpenWelcome, lastName }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog
            className={`${classes.dialogStyle} `}
            open={openWelcome}
            onClose={e => { setOpenWelcome(false) }}
        >
            <DialogContent style={{ backgroundImage: `url(${PopUpImage2})`, backgroundSize: "cover" }}>
                <VBox alignItems={"center"} justifyContent="center" sx={{ marginBottom: { xs: "7.5rem", lg: "10rem" } }} >
                    <VBox className={classes.paddingDialog} alignItems={"center"} justifyContent="center">
                        <img
                            className={classes.LogoSize}
                            src={Logo}
                            loading="lazy"
                            alt={"..."}
                        />
                        <Typography component="h2" variant="h6" className={`${classes.textAlignCenter} ${classes.dialogTextGreen}`}>
                            {`${t("family1")} ${lastName} ${t("sucessRegisterd")} `}
                        </Typography>
                        <Typography className={`${classes.textAlignCenter} ${classes.fontBold} ${classes.paddingBottom2} ${classes.dialogTextGreen}`} variant="h5">
                            {t("welcomeToPortal")} <br />
                            {t("parents1")} <mark style={{ color: "#3a7735", borderRadius: "50px", backgroundColor: "#fff496", paddingLeft: '5px', paddingRight: '5px' }}>{t("theNew")}</mark> {t("ofThe")}<br /> {t("theScouts")}
                        </Typography>
                        <Typography className={`${classes.textAlignCenter} ${classes.paddingBottom2} ${classes.dialogTextGreen}`}>
                            {t("aMomentBefore")} <br /> {t("neddToAdd")} <br />{t("students1")}
                        </Typography>
                        <Button className={`${classes.buttonGreen} ${classes.paddingWelcomeButton}`} onClick={e => { setOpenWelcome(false) }}>
                            {t("addFamilyMember")}   <ArrowBackIosNewOutlinedIcon />
                        </Button>
                    </VBox>
                </VBox>
            </DialogContent>

        </Dialog>
    )
}
