
import { IconButton, Dialog, DialogActions } from '@mui/material';

import ParentContact from 'pages/ParentContact';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ContactDialog = ({ openContact, setOpenContact }) => {
    return (
        <Dialog fullWidth
            open={openContact}
            onClose={e => setOpenContact(false)}
            PaperProps={{
                sx: { borderRadius: "40px !important", backgroundColor: "#f0f3f0" }
            }}>
            <DialogActions>
                <IconButton onClick={e => { setOpenContact(false) }} disableRipple={true}>
                    <CloseOutlinedIcon sx={{ fontSize: "2rem", color: "#3a7735" }} />
                </IconButton>
            </DialogActions>
            <ParentContact anonymous={true} setDialogOpen={setOpenContact} />
        </Dialog>)
}
export default ContactDialog;