import { makeStyles } from '@mui/styles';
import appBG from '../assets/appBG.jpg';

const useStyles = makeStyles((theme) => ({
    datePinInputStyle: {
        width: 'auto !important;',
        "& input": {
            borderBottom: '3px solid #3a7735 !important;',
            border: 'none !important',
            width: '2rem !important;',
            height: 'auto !important;',
            fontSize: '1rem !important;',
            textAlign: "center !important;",
            outlineWidth: "0px !important;",
            borderRadius: "0px !important;",
            fontFamily: "Alef,sans-serif !important",
            caretColor: "auto !important;",
            backgroundColor: "transparent !important",
            marginRight: '2px !important'
        }
    },
    buttonGreen: {
        color: 'white !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        marginTop: "1rem !important"
    },
    buttonWhite: {
        marginTop: "1rem !important",
        color: '#3a7735 !important',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        backgroundColor: 'white !important',
        border: '2px solid #3a7735 !important',
        borderRadius: '50px !important',
        textTransform: "capitalize !important",
    },
    alignItemsCenter: {
        alignItems: "center !important"

    },
    LogoSize: {
        width: '60px'
    },
    SmsMinHeight: {
        minHeight: '24rem'
    },
    buttonGreenLight: {
        color: 'white !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#97b595 !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%) !important',
        textTransform: "capitalize !important",
    },

    fontBold: {
        fontWeight: 'bold'
    },
    SmsVerStyle: {
        minWidth: '25rem',
        maxWidth: '30rem',
        background: 'white',
        padding: '0 16px',
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important'
    },
    SmsVerStyle2: {
        display: 'inline-grid',
        justifyContent: 'center'
    },
    TextfieldILabelStyle: {
        '& .MuiInputLabel-root': {
            color: 'black !important',
            fontSize: '1rem !important',
            alignItems: "center"
        },
        '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 'bold !important',
            color: 'black !important',
            fontSize: '1rem !important',
        },
        '& .Muirtl-1in441m': {
            fontSize: '1.2rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        }
    },
    GreenColor: {
        color: '#007f00'
    },
    RedColor: {
        color: '#da051f'
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    padding0_7: {
        padding: '0.7rem'
    },
    CheckBoxStyle: {
        marginRight: '0px !important',
        marginLeft: '0px !important',
        '& .MuiIconButton-root': {
            padding: '0 0 0 6px !important'
        },
        '& .MuiCheckbox-root': {
            color: theme.palette.green.main
        },
        '& .MuiTypography-body1': {
            fontSize: '1.1rem !important',
            color: theme.palette.green.main
        }
    },

    SelectedTab: {
        '& .MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected:first-child': {
            right: '0.5rem'
        },
        '& .MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected:last-child': {
            left: '0.5rem'

        }
    },
    TabStyle: {
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%) !important',
        borderRadius: '2rem !important',
        backgroundColor: '#e0e9e0 !important',
        position: "relative",
        marginBottom: "-1.5rem",
        '& .MuiTab-root': {
            background: '#e0e9e0 !important',
            color: '#007f00 !important',
            width: '50% !important',
            textTransform: "capitalize !important",
            fontSize: '0.95rem !important',
            fontWeight: 'bold !important',

        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            fontWeight: 'bold !important',
            backgroundColor: '#3a7735 !important',
            color: 'white !important',
            borderRadius: '1.5rem !important',
            margin: '0.5rem',
        },
        '& .PrivateTabIndicator-root-8': {
            visibility: 'hidden !important'
        },
        '@media (min-width:1281px)': {
            width: "28.75rem",
        },

    },
    forgotTitle: {
        fontSize: "1.25rem !important",
        fontWeight: "700 !important"
    },
    RadioButtonColor: {
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.green.main
        }
    },
    margin0_1: {
        margin: '0 1rem !important'
    },
    margin0_3: {
        margin: '0 3rem !important'
    },

    CardStyle: {
        maxWidth: '30rem',
        height: '95%',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px'
        },
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important'
        // margin: '0 1rem'

    },
    FatherTabsStyle: {
        maxWidth: '30rem !important',
        height: '95% !important',
        margin: 'auto !important',
        // margin: '0 1rem'

    },
    fontSize1_5: {
        fontSize: '1.5em'
    },
    AppBackground: {
        backgroundImage: `url(${appBG})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        '@media (max-width:500px)': {
            backgroundSize: 'initial',
            backgroundPosition: '-64rem -10rem'
        },

    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    loginCard: {
        '@media (min-width:320px)': {
            width: "21.75rem",
            height: "29.75rem",
            borderRadius: "50px !important",
        },
        '@media (min-width:600px)': {
            width: "24.75rem",
            height: "29.75rem",
            borderRadius: "100px !important",
        },
        '@media (min-width:1025px)': {
            width: "25.75rem",
            height: "29.75rem",
            borderRadius: "100px !important",
        },
        '@media (min-width:1281px)': {
            width: "26.75rem",
            height: "33.75rem",
            borderRadius: "100px !important",
        },
        padding: "2px 2px 2px 2px",
        backgroundColor: "#ffffff !important",
        boxSizing: "border-box !important",
        boxShadow: "15px 15px 30px 0px rgba(0, 0, 0, 0.26) !important",
        display: "flex",
        justifyContent: "center"
    },

    height100: {
        height: '100%'
    },
    TabHeigth: {
        height: 'calc(100% - 148px)!important'
    },
    BoxHeigth: {
        '& .MuiBox-root': {
            height: '100% !important'
        }
    },
    Boxcalc100M16px: {
        height: 'calc(100% - 16px)!important'
    },
    BoxNoPaddingTopBottom: {
        padding: '0 16px'
    },
    width100: {
        width: "100%"
    },
    CenterItems: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center'
    },
    EndItems: {
        alignItems: 'end'
    },
    marginBottom3: {
        marginBottom: '3rem'
    },
    marginBottom1: {
        marginBottom: '1rem'
    },
    marginTop1: {
        marginTop: '1rem'
    },
    justifyContentEnd: {
        justifyContent: 'end'
    },
    paddingBottom1rem: {
        paddingBottom: '1rem !important'
    },
    paddingTop2_5rem: {
        paddingTop: '2.5rem'
    },
    paddingInlineStart0_3: {
        paddingInlineStart: '0.3rem !important',

    },
    LinkStyle: {
        paddingInlineStart: '0.3em',
        textDecoration: 'underline !important',
        color: '#007f00',
        fontSize: '1.1rem',
        cursor: 'pointer',
        fontFamily: "Alef,sans-serif !important"
    },
    paddingTop3rem: {
        paddingTop: '3rem'
    },
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    alignItemsBaseline: {
        alignItems: "baseline"
    },
    width50: {
        width: "50%"
    },
    marginEnd5p: {
        marginEnd: "5% !important"
    },
    width65: {
        width: "60%",
    },
    width30: {
        width: "35%",
    },
    phoneDir: {
        direction: 'ltr'
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    LogoSize1: {
        width: '30px',
        height: '45px'
    },
    dialog: {
        borderRadius: '25px !important',
        "& .embeddedDoc div, & .embeddedDoc span, & .embeddedDoc p, & .embeddedDoc *": {
            margin: "0px !important;",
            padding: "0px !important;",
            textAlign: "-webkit-match-parent !important;"
        },
        "& .embeddedDoc img": {
            display: "none !important;"
        },
        "& .embeddedDoc span": {
            height: '0 !important;',
            width: '0 !important;'
        }
    },
    dialogTitle: {
        alignItems: 'center !important',
        justifyContent: "space-between"
    },
    dialogTitleText: {
        marginRight: '0.3rem !important',
        fontWeight: 'bold !important'
    },
    contactDialog: {
        '& .MuiPaper-root': {
            background: 'transparent !important;'
        }
    },
    codePinInputStyle: {
        width: 'auto !important;',
        "& input": {
            borderBottom: '3px solid #3a7735 !important;',
            border: 'none !important',
            width: '2rem !important;',
            height: 'auto !important;',
            fontSize: '1.3rem !important;',
            textAlign: "center !important;",
            outlineWidth: "0px !important;",
            borderRadius: "0px !important;",
            fontFamily: "Alef,sans-serif !important",
            caretColor: "auto !important;",
            backgroundColor: "transparent !important",
            marginRight: '7.5px !important'
        }
    },
    paperStyle: {
        '@media (min-width:320px)': {
            width: "21.75rem",
            height: "34.5rem"
        },
        '@media (min-width:600px)': {
            width: "28.5rem",
            height: "34.5rem",
        },
        '@media (min-width:1025px)': {
            width: "40.5rem",
            height: "38.5rem"
        },
        '@media (min-width:1281px)': {
            width: "47.5rem",
            height: "42.5rem"
        },
        padding: "2px 2px 2px 2px",
        borderRadius: "50px !important",
        backgroundColor: "#ffffff !important",
        boxShadow: "15px 15px 30px 0px rgba(0, 0, 0, 0.26) !important",
        display: "flex",
        justifyContent: "center"
    }
}));


export { useStyles };