import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
//mui
import { IconButton, Typography, AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import { VBox, HBox } from '../../../../assets/SharedComponents';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
//components
import Allergies from './Allergies';
import FoodPreference from './FoodPreferance';
import Diseases from './Diseases';
import WaterActivity from './WaterActivity';
import EmerContact from './EmerContact';
import PhotoPermission from './PhotoPermission';

const useStyles = makeStyles(() => ({
    accordion: {
        width: '100%',
        boxShadow: 'none !important'
    },
    accordionBackground1: {
        backgroundColor: '#e6efe6 !important',
    },
    accordionBackground: {
        backgroundColor: '#f6f9f6fc !important'
    },
    accordionSummary: {
        width: '100% !important',
        height: '2.5rem !important',
        minHeight: "0px !important"
    },
    accordionTitle: {
        alignItems: 'center',
        justifyContent: 'space-between !important',
        alignContent: 'center !important',
        width: '100% !important',
    },
    firstName: {
        color: '#3a7735 !important',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        alignContent: 'right !important'
    },
    childContent: {
        textAlign: 'center !important',
        alignItems: "center"
    },
    childActivities: {
        alignContent: 'center !important',
        alignItems: 'center !important',
        marginTop: '0.3rem !important'
    },
    activityName: {
        fontWeight: 'bold !important',
        marginBottom: '0.3rem !important'
    }
}));

const ChildStatment = ({ child, household, products, currentTab, isChildValid, allergiesListApi, foodListApi, diseasesListApi, setIsStatmentValid, length, index, clearSignature, openForPdf }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(true);
    const [childValidator, setChildValidator] = useState({ foodPreference: true, allergy: true, diseases: true, water: true, contact: true });
    const [foodValidator, setFoodValidator] = useState(false);
    const [allergiesValidator, setAllergiesValidator] = useState(false);
    const [diseasesValidator, setDiseasesValidator] = useState(false);
    const [waterValidator, setWaterValidator] = useState(true);
    const [waterActivity, setWaterActivity] = useState(false);
    const [needEmerContact, setNeedEmerContact] = useState(false);
    const [contactValidator, setContactValidator] = useState(true);

    const childActivities = products.filter((activity) => activity.LicTradNum === child.LicTradNum);

    useEffect(() => {
        isChildValid[index] = childValidator;
        checkValidation();
    }, [childValidator]);

    useEffect(() => {
        setChildValidator({ ...childValidator, foodPreference: foodValidator, allergy: allergiesValidator, diseases: diseasesValidator, water: waterValidator, contact: contactValidator })
    }, [foodValidator, allergiesValidator, diseasesValidator, waterValidator, contactValidator]);

    useEffect(() => {
        childActivities.map((activity) => {
            if (activity.SwimActive === 'Y') {
                setWaterActivity(true);
                setWaterValidator(false);
            }
        })
    }, []);

    const checkValidation = () => {
        let counter = 0;
        isChildValid.map((child) => {
            if (child.foodPreference && child.allergy && child.diseases && child.water && child.contact)
                counter++;
        })
        if (counter === length) {
            setIsStatmentValid(true);
        } else
            setIsStatmentValid(false);
    }

    return (
        <VBox>
            <Accordion square expanded={expanded || index + 1 === currentTab || openForPdf} className={`${classes.accordion} ${index % 2 === 0 ? classes.accordionBackground : classes.accordionBackground1}`}>
                <AccordionSummary className={classes.accordionSummary} >
                    <HBox className={classes.accordionTitle}>
                        <HBox>
                            <PersonOutlineIcon sx={{ color: '#3a7735' }} />
                            <Typography className={classes.firstName}>{child.Pupil_FirstName}</Typography>
                        </HBox>
                        <IconButton
                            expand={expanded.toString()}
                            onClick={e => setExpanded(!expanded)}
                            data-html2canvas-ignore="true">
                            {expanded || index + 1 === currentTab ? <RemoveIcon sx={{ color: '#3a7735' }} /> : <AddIcon sx={{ color: '#3a7735' }} />}
                        </IconButton>
                    </HBox>
                </AccordionSummary>
                <AccordionDetails>
                    <VBox>
                        <Typography className={classes.childContent} >{t('statment')} <b>{`${child.Pupil_FirstName} ${child.Pupil_lastName}`}</b> {t('idNumber')} <b>{`${child.LicTradNum}`}</b> {t('inActivity')} </Typography>
                        <VBox className={classes.childActivities}>
                            {childActivities.map((item, i) => {
                                return (
                                    <Typography key={i} className={classes.activityName}>{item.ActivName}</Typography>)
                            })}
                        </VBox>
                        <FoodPreference
                            household={household}
                            foodPreferencesList={child.FoodPreferencesList.results}
                            foodListApi={foodListApi}
                            cardCode={child.CardCode}
                            foodValidator={foodValidator}
                            setFoodValidator={setFoodValidator}
                            clearSignature={clearSignature}
                        />
                        <Allergies
                            household={household}
                            allergies={child.AllergiesList.results}
                            allergiesListApi={allergiesListApi}
                            otherAllergyName={child.OtherAllergyName}
                            allergiesValidator={allergiesValidator}
                            setAllergiesValidator={setAllergiesValidator}
                            cardCode={child.CardCode}
                            setNeedEmerContact={setNeedEmerContact}
                            clearSignature={clearSignature}
                        />
                        <Diseases
                            diseasesValidator={diseasesValidator}
                            household={household}
                            diseases={child.DiseasesList.results}
                            diseasesListApi={diseasesListApi}
                            otherDiseaseName={child.OtherDiseaseName}
                            emerDoctor={child.EmerDoctor}
                            setDiseasesValidator={setDiseasesValidator}
                            cardCode={child.CardCode}
                            setNeedEmerContact={setNeedEmerContact}
                            clearSignature={clearSignature}
                        />
                        <PhotoPermission
                            household={household}
                            childPermission={child.PerPhotAndPubl || "Y"}
                            cardCode={child.CardCode}
                            clearSignature={clearSignature}
                        />
                        {needEmerContact && <EmerContact
                            household={household}
                            cardCode={child.CardCode}
                            childContactName={child.ContactName}
                            childContactPhone={child.ContactPhone}
                            contactValidator={contactValidator}
                            setContactValidator={setContactValidator}
                            clearSignature={clearSignature}
                        />}
                        {waterActivity && <WaterActivity
                            household={household}
                            setWaterValidator={setWaterValidator}
                            cardCode={child.CardCode}
                            permPartWater={child.PermPartWater}
                            clearSignature={clearSignature} />
                        }
                    </VBox>
                </AccordionDetails>
            </Accordion>
        </VBox>
    )
}
export default ChildStatment;