import './App.css';

import React from "react";
import { initializeApp } from "firebase/app";
import Router from './router/Router';
import ThemeProvider from './theme';
import { SnackbarProvider } from 'notistack';
import { firebaseConfig } from "./api/client"
import useAnalytics from './hooks/useAnalytics'
import useAccessibility from 'hooks/useAccessibility';
import { system } from "./api/client"
// import Quack from '@thequack/sdk'

function App() {

  const hookAnalytics = useAnalytics();
  const hookAccessibility = useAccessibility();

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl");

    // //Quack tokens for production and for qa env
    // try {
    //   if (window.self === window.top) {
    //     if (system === "prod") {
    //       Quack.init("0112614f-9b29-44d7-8005-947b598ebb98");
    //     } else {
    //       Quack.init("9daefdd0-075d-4c2f-b633-e696c7b9c046");
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }, []);

  let gaTrackingCode = process.env[`REACT_APP_GA_TRACKING_${system.toUpperCase()}`];
  if (gaTrackingCode) {
    hookAnalytics.initialize(gaTrackingCode);
  }

  try {
    window.self === window.top && hookAccessibility.initialize();
  } catch (error) {
    console.log(error)
  }

  initializeApp(firebaseConfig);

  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Router />
      </SnackbarProvider>
    </ThemeProvider >

  );

}
export default App;

