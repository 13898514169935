import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Container, Box, Autocomplete, TextField, Input, FormHelperText } from '@mui/material';

import { VBox, HBox } from '../../../../assets/SharedComponents';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import { margin } from '@mui/system';

const useStyles = makeStyles(() => ({
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important'
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    statmentcard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important'
    },
    statmentAutoComplite: {
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: "0 !important",
            minWidth: "0px !important"
        }
    },
    select: {
        '&.MuiInput-underline:after': {
            'borderBottom': '#e0e0e0 !important',
        },
        height: '2rem !important',
        width: '10rem !important',
        borderRadius: '1.7rem !important',
        margin: "0.8rem !important"
    }
}));

const EmerContact = ({ household, cardCode, childContactName, childContactPhone, contactValidator, setContactValidator, clearSignature }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [contactPhone, setContactPhone] = useState();
    const [contactName, setContactName] = useState();
    const [showOther, setShowOther] = useState(childContactName !== `${household.Parent1_FirstName} ${household.Parent1_LastName}`);
    const [otherContactPhone, setOtherContactPhone] = useState();
    const [otherContactName, setOtherContactName] = useState();
    const [parentList, setParentsList] = useState([]);
    const [parentSelected, setParentSelected] = useState(null);

    useEffect(() => {
        initParentsList();
        getChildDefaultContact();
    }, [])
    const initParentsList = () => {
        const parents = [];
        if (household.Parent1_ID) {
            parents.push({
                phone: household.Parent1_Tel1.slice(-9),
                label: `${household.Parent1_FirstName} ${household.Parent1_LastName}`
            });
        }
        if (household.Parent2_ID) {
            parents.push({
                phone: household.Parent2_Tel1.slice(-9),
                label: `${household.Parent2_FirstName} ${household.Parent2_LastName}`
            });
        }
        parents.push({
            phone: '',
            label: 'אחר',
            other: true
        });
        setParentsList(parents);
    }
    const getChildDefaultContact = () => {
        let selectedDefault = {};
        if (childContactName === `${household.Parent1_FirstName} ${household.Parent1_LastName}`) {
            selectedDefault = {
                phone: household.Parent1_Tel1.slice(-9),
                label: `${household.Parent1_FirstName} ${household.Parent1_LastName}`
            };
        }
        else if (childContactName === `${household.Parent2_FirstName} ${household.Parent2_LastName}`) {
            selectedDefault = {
                phone: household.Parent2_Tel1.slice(-9),
                label: `${household.Parent2_FirstName} ${household.Parent2_LastName}`
            };
        }
        else {
            selectedDefault = {
                phone: '',
                label: 'אחר',
                other: true
            };
            setOtherContactPhone(childContactPhone);
            setOtherContactName(childContactName);
        }
        setParentSelected(selectedDefault);
        setContactName(selectedDefault.label);
        setContactPhone(selectedDefault.phone);
    }

    useEffect(() => {
        clearSignature();
        let otehr = parentSelected?.other;
        let validator = (otherContactPhone && otherContactName && parentSelected?.other) || (contactPhone && contactName);
        setShowOther(otehr);
        setContactValidator(validator);

        if (validator) {
            insertToHousehold();
        }
    }, [parentSelected, otherContactPhone, otherContactName]);


    const setAnotherDetails = (e, type) => {
        if (type === "name") {
            const regex = /^[a-z\s\u0590-\u05fe]+$/i
            const value = e.target.value;
            if (regex.test(value) || value === '') {
                setOtherContactName(value)
            }
        }
        if (type === "phone") {
            const regex = /^[0-9]*$/
            const value = e.target.value;
            if (regex.test(value) || value === '') {
                setOtherContactPhone(value)
            }
        }
    }

    const insertToHousehold = () => {
        let pupil = household.ChildrenList?.results.find((child) => child.CardCode === cardCode);
        pupil.ContactPhone = parentSelected.other ? otherContactPhone : contactPhone
        pupil.ContactName = parentSelected.other ? otherContactName : contactName
    }


    return (
        <>
            <Card className={classes.statmentcard} data-html2canvas-ignore="true">
                <Container>
                    <VBox>
                        <HBox alignItems="center" className={classes.cardTitleAndIcon}>
                            <ContactEmergencyOutlinedIcon sx={{ color: "#3a7735" }} />
                            <Typography className={classes.cardTitle} >{t('emerContact')} </Typography>
                        </HBox>
                        <Autocomplete
                            disablePortal
                            disableClearable={true}
                            options={parentList}
                            value={parentSelected}
                            noOptionsText={t('notOption')}
                            onChange={(event, newValue) => {
                                setParentSelected(newValue);
                                setContactName(newValue && newValue.label);
                                setContactPhone(newValue && newValue.phone);
                            }}
                            readOnly={!parentList.length}
                            className={classes.statmentAutoComplite}
                            sx={{ margin: "1rem", width: '13rem' }}
                            renderInput={(params) => <TextField {...params} variant='standard' placeholder={t("choose")} />}
                        />
                        {
                            showOther &&
                            <HBox alignItems="center">
                                <Input
                                    placeholder={t("emerName")}
                                    className={classes.select}
                                    value={otherContactName}
                                    inputProps={{ maxLength: 15 }}
                                    onChange={e => setAnotherDetails(e, "name")} />
                                <Input
                                    placeholder={t("phoneNumber")}
                                    className={classes.select}
                                    value={otherContactPhone}
                                    inputProps={{
                                        maxLength: 10, inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    }}
                                    onChange={e => setAnotherDetails(e, "phone")} />
                            </HBox>
                        }

                    </VBox>
                    {/* error msg */}
                    {!contactValidator &&
                        <FormHelperText error={true} style={{ fontSize: "0.8rem" }} >
                            {t('requierdContact')}
                        </FormHelperText>}
                </Container>
            </Card >
            <Box justifyContent='center' className={`${"showOnPrint"}_${cardCode}`} sx={{ display: "none" }} >
                <HBox alignItems="center">
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>{`${t('emerContact')} :`}</Typography>
                    <Typography sx={{ fontSize: "1rem !important" }}>{`${contactName} - ${contactPhone}`}</Typography>
                </HBox>
            </Box>
        </>
    )
}
export default EmerContact;