import feathers from "@feathersjs/client";
import axios from "axios";
import useFeathers from "hooks/useFeathers";

const client = feathers();

const hostname = window.location.hostname.toLocaleLowerCase();
let url = "";
let sys = "";
let fbConfig;

const firebaseConfigDev = {
    apiKey: "AIzaSyATJvMaht_IKLZglQm-PFhCGpVo8u1hjO0",
    authDomain: "parents-portal-dev.firebaseapp.com",
    projectId: "parents-portal-dev",
    storageBucket: "parents-portal-dev.appspot.com",
    messagingSenderId: "596453923284",
    appId: "1:596453923284:web:6c0fe0fb1e55518fa0de41"
};

const firebaseConfigQA = {
    apiKey: "AIzaSyBPeLu28wqIYuIhm6iBeNlznOVH3aSt5u8",
    authDomain: "parents-portal-test.firebaseapp.com",
    projectId: "parents-portal-test",
    storageBucket: "parents-portal-test.appspot.com",
    messagingSenderId: "259210073779",
    appId: "1:259210073779:web:2433d5e84bf7b46d61c2c6"
};

const firebaseConfigProd =
{
    apiKey: "AIzaSyAfLPvtHrtPRDBSUZQNfqWkNdlMhz4yDck",
    authDomain: "parents-portal-prod.firebaseapp.com",
    projectId: "parents-portal-prod",
    storageBucket: "parents-portal-prod.appspot.com",
    messagingSenderId: "367654459114",
    appId: "1:367654459114:web:f1d6b650c77550e317a993"
};

if (hostname === "portal.zofim.org.il" || hostname === "parents-portal-prod.web.app" || hostname === "parents-portal-prod.firebaseapp.com") {
    url = process.env.REACT_APP_BACKEND_URL_PROD;
    sys = "prod";
    fbConfig = firebaseConfigProd;
}
else if (hostname.includes("portal-test") || hostname.includes("portal-qa")) {
    url = process.env.REACT_APP_BACKEND_URL_QA;
    sys = "qa";
    fbConfig = firebaseConfigQA;
}
else {
    url = process.env.REACT_APP_BACKEND_URL_DEV;
    sys = "dev";
    fbConfig = firebaseConfigDev;
}

const restClient = feathers.rest(url);
let sStorageKey = "zofim-parents-portal-token";

if (sys !== "prod") {
    sStorageKey += `-${sys}`;
}

const getRecaptchaToken = () => {

    return new Promise((resolve, reject) => {

        window.grecaptcha.enterprise.ready(function () {
            window.grecaptcha.enterprise.execute(undefined, { action: 'submit' }).then(function (recaptchaToken) {
                resolve(recaptchaToken);
            }).catch(e => {
                reject(e);
            });
        });

    })

}

// Add a request interceptor
axios.interceptors.request.use(async (config) => {

    const url = config.url;
    if (url.includes("/contact") ||
        url.includes("/send-email") ||
        url.includes("/send-parent-mail") ||
        url.includes("/send-reset-password") ||
        url.includes("/generate-verification-email") ||
        url.includes("/check-matching-parent") ||
        url.includes("/check-user")) {

        try {
            const recaptchaToken = await getRecaptchaToken();
            config.headers.recaptchaToken = recaptchaToken;
        } catch (error) {
            throw error;
        }
    }
    return config;

}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

// Add a respone interceptor
axios.interceptors.response.use(async (response) => {
    return response
}, (error) => {
    // Do something with request error
    if (error.response.data.data?.name === "TokenExpiredError") {
        window.location.reload()
    }
    return Promise.reject(error);
});


client.configure(restClient.axios(axios));
client.configure(feathers.authentication({
    storage: window.localStorage,
    storageKey: sStorageKey,
}));


// using export function which recieve a service name and return
// an instance of is more PRIVATE
export function getService(name) {
    return client.service(name);
}

export const baseUrl = url;

export const feathersClient = client;

export const httpClient = axios;

export const storageKey = sStorageKey;

export const system = sys;

export const firebaseConfig = fbConfig;
