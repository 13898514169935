import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { system } from 'api/client';

const GOOGLE_PLACES_API_KEY = process.env[`REACT_APP_GOOGLE_PLACES_API_KEY_${system.toUpperCase()}`]; //'AIzaSyATJvMaht_IKLZglQm-PFhCGpVo8u1hjO0';

let autocompleteService;
let autocompleteSessionToken;
let placesService;
let mapDomRef;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}



export default function AutocompleteGooglePlaces(props) {

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const getPlaceDetails = async (prediction) => {

    return new Promise((resolve, reject) => {
      placesService.getDetails({
        placeId: prediction.place_id,
        language: "iw",
        sessionToken: autocompleteSessionToken //Unique reference used to bundle the details request with an autocomplete session.
      }, (placeServiceResult, placeServiceStatus) => {

        const addressDetails = {
          StreetNum: "",
          StreetName: "",
          CityName: "",
          Country: ""
        };

        if (placeServiceStatus === "OK") {
          for (let addressComp of placeServiceResult.address_components) {
            if (addressComp.types.includes("street_number")) {
              addressDetails.StreetNum = addressComp.long_name;
            }
            if (addressComp.types.includes("route")) {
              addressDetails.StreetName = addressComp.long_name;
            }
            if (addressComp.types.includes("locality")) {
              addressDetails.CityName = addressComp.long_name;
            }
            if (addressComp.types.includes("administrative_area_level_2") && !addressDetails.CityName) {
              addressDetails.CityName = addressComp.long_name;
            }
            if (addressComp.types.includes("administrative_area_level_1") && !addressDetails.CityName) {
              addressDetails.CityName = addressComp.long_name;
            }
            if (addressComp.types.includes("country")) {
              addressDetails.Country = addressComp.long_name;
            }
          }
        }

        resolve({ placeServiceResult, placeServiceStatus, addressDetails });
      });
    });
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService && window.google) {
      mapDomRef = document.createElement('div'); //dummy DOM element, actual map is not needed in this case, but is mandatory for constructor of google.maps.places.PlacesService()
      autocompleteSessionToken = new window.google.maps.places.AutocompleteSessionToken(); // Create a new session token
      autocompleteService = new window.google.maps.places.AutocompleteService(); //create google maps autocomplete service
      placesService = new window.google.maps.places.PlacesService(mapDomRef);  //create google maps places service
    }

    if (!autocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({
      input: inputValue,
      language: "iw",
      componentRestrictions: { country: "il" }, //perform request and limit results to Israel
      sessionToken: autocompleteSessionToken,
      types: ["geocode"]
    }, (results) => {

      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth={true}
      id={props.id}
      name={props.name}
      disableClearable={true}
      disabled={props.disabled}
      noOptionsText={props.noOptionsText}
      onBlur={props.onBlur}
      className={props.className}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue.place_id) {
          const placeDetails = await getPlaceDetails(newValue);
          props.onPlaceSelected(placeDetails);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.inputLabel} placeholder={props.inputPlaceholder} error={props.inputError} helperText={props.inputHelperText} fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
