//mui
import { Box, Typography } from "@mui/material";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
// components
import OpenActivities from "../Components/TribeComponents/OpenActivities";
import OpenSignReqs from "../Components/TribeComponents/OpenSignReqs";
import BusyIndicator from "../Components/Common/BusyIndicator";
import { TribeAccordion } from "../Components/TribeComponents/TribePageHandler";
// i18n
import { useTranslation } from "react-i18next";
// store
import useGlobal from "../store/store";
//imgs
import NoRegRequests from '../assets/NoRegRequests.svg';
import noActivity from '../assets/noActivity.svg';


export const Activities = ({ factoriesRequests, registrationRequests, getOpenRequsts, tribe, setChildrenList }) => {
    const { t } = useTranslation();
    const [openActivities, setOpenActivities] = useGlobal((state) => state.openActivities, (actions) => actions.setOpenActivities);
    const [openRequests, setOpenRequests] = useGlobal((state) => state.openRequests, (actions) => actions.setOpenRequests);
    const [totalOpenAct, setTotalOpenAct] = useGlobal((state) => state.totalOpenAct, (actions) => actions.setTotalOpenAct);

    const [cartFamily, setCartFamily] = useGlobal(
        state => state.cartFamily,
        actions => actions.setCartFamily
    );

    const [products, [removeProduct, addProduct, clearProducts]] = useGlobal(
        (state) => state.products,
        (actions) => [actions.removeProduct, actions.addProduct, actions.clearProducts]
    );

    return (
        <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>

            <TribeAccordion titleIcon={<EventAvailableIcon />} title={`${t('openActivities')} (${totalOpenAct})`} expended={false} first={true}>
                <BusyIndicator request={factoriesRequests} linear={true}>
                    {!!openActivities.length ?
                        <OpenActivities activities={openActivities} /> :
                        <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>
                            <img
                                alt={"..."}
                                src={noActivity}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }} >{t("noActivs")}</Typography>
                        </Box>
                    }
                </BusyIndicator>
            </TribeAccordion>

            <TribeAccordion titleIcon={<FollowTheSignsIcon />} title={`${t('openRegReq')} (${openRequests.length})`}>
                <BusyIndicator request={registrationRequests} linear={true}>
                    {!!openRequests.length ?
                        <OpenSignReqs
                            openRequests={openRequests}
                            cartFamily={cartFamily}
                            setCartFamily={setCartFamily}
                            products={products}
                            addProduct={addProduct}
                            removeProduct={removeProduct}
                            clearProducts={clearProducts}
                            getOpenRequsts={getOpenRequsts}
                            tribe={tribe}
                            setChildrenList={setChildrenList}
                        /> :
                        <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>
                            <img
                                alt={"..."}
                                src={NoRegRequests}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }} >{t("noRegReqForYou")}</Typography>
                        </Box>}
                </BusyIndicator>
            </TribeAccordion>

        </Box>)
}