import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
//mui
import { Card, Typography, Container, Button, IconButton, Tooltip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//components
import { VBox, HBox } from '../../../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important',
        alignItems: 'center !important',
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    statmentcard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important'
    },
    select: {
        '&.MuiInput-underline:after': {
            'borderBottom': '#e0e0e0 !important',
        },
        height: '2rem !important',
        width: 'fit-contant !important',
        borderRadius: '1.7rem !important',
        marginLeft: '0.5rem !important'

    }, text: {
        fontSize: "0.8rem !important"
    },
    buttons: {
        "&:hover": {
            background: '#e0e0e0 !important',
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important ',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    }, buttons1: {
        "&:hover": {
            background: '#b6f3b5 !important',
        },
        backgroundColor: '#b6f3b5 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important',
        marginRight: '0.5rem !important',
        marginBottom: '0.2rem !important',
        border: '1px solid #007f00 !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    }
}));

const WaterActivity = ({ household, setWaterValidator, cardCode, clearSignature, permPartWater }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [permission, setPermission] = useState({ isAgree: permPartWater !== "N" && permPartWater !== "", knowToSwim: (permPartWater === "D" || permPartWater === "Y") && permPartWater !== "", nightSwim: (permPartWater === "H" || permPartWater === "Y") && permPartWater !== "" });
    const [letterValue, setLetterValue] = useState("");
    let permissionValue;
    const { enqueueSnackbar } = useSnackbar();

    let swimPermissionTextBySap = { "Y": t('YpermissionText'), "D": t('DpermissionText'), "H": t('HpermissionText'), "Z": t('ZpermissionText'), "N": t('disagreeSwim') }

    useEffect(() => {
        clearSignature();
        insertToHousehold();
        if (permissionValue)
            setWaterValidator(true);
        else
            setWaterValidator(false);
    }, [permission]);

    //update permission for swim activity to parent selection  
    const insertToHousehold = () => {
        if (!permission.isAgree) {
            permissionValue = "N";
        }
        if (permission.isAgree && permission.knowToSwim && permission.nightSwim) {
            permissionValue = "Y";
        }
        if (permission.isAgree && !permission.knowToSwim && !permission.nightSwim) {
            permissionValue = "Z";
        }
        if (permission.isAgree && !permission.knowToSwim && permission.nightSwim) {
            permissionValue = "H";
        }
        if (permission.isAgree && permission.knowToSwim && !permission.nightSwim) {
            permissionValue = "D";
        }
        setLetterValue(permissionValue);
        household.ChildrenList?.results.filter((child) => child.CardCode === cardCode).forEach((pupil) => {
            pupil.PermPartWater = permissionValue
        })
    }
    const setNo = (type) => {
        if (type === "agree") {
            if (permission.isAgree) { clearSignature(); }
            setPermission({ ...permission, isAgree: false });

        }
        if (type === "knowToSwim") {
            if (permission.isAgree) { clearSignature(); }
            setPermission({ ...permission, knowToSwim: false });

        }
        if (type === "nightSwim") {
            if (permission.isAgree) { clearSignature(); }
            setPermission({ ...permission, nightSwim: false });
        }
    }
    return (
        <>
            <Card className={classes.statmentcard} data-html2canvas-ignore="true">
                <Container>
                    <HBox className={classes.cardTitleAndIcon}>
                        <VBox>
                            <HBox alignItems="center">
                                <PoolOutlinedIcon sx={{ color: "#3a7735" }} />
                                <Typography className={classes.cardTitle} >{t('waterActivity')} </Typography>
                            </HBox>
                            <VBox alignItems="center">
                                <NotificationImportantIcon sx={{ color: "#ffbb41" }} />
                                <Typography style={{ fontSize: "0.9rem" }}>{t("waterActivityWarning")} </Typography>
                            </VBox>
                            <VBox alignItems={"center"}>
                                <VBox marginTop={"1rem"} alignItems="center">
                                    <Typography className={classes.text} >{t('waterActivityText')} </Typography>
                                    <Typography className={classes.text}>{t('waterActivityText1')} </Typography>
                                </VBox>
                                <HBox marginTop={"1rem"} alignItems="center">
                                    <Button className={!permission.isAgree ? classes.buttons1 : classes.buttons} onClick={e => setNo("agree")}>{t('no')}</Button>
                                    <Button className={permission.isAgree ? classes.buttons1 : classes.buttons} onClick={e => setPermission({ ...permission, isAgree: true })}>{t('yes')}</Button>
                                    <Tooltip title={t("waterActivityTooltip")}>
                                        <IconButton style={{ marginLeft: "-2.2rem" }} onTouchEnd={e => enqueueSnackbar(t("waterActivityTooltip"), { className: classes.orangeBG })}>
                                            <InfoOutlinedIcon sx={{ color: "#3a7735" }} />
                                        </IconButton>
                                    </Tooltip>
                                </HBox>

                                {permission.isAgree && <>
                                    <Typography className={classes.text} >{t("myChildKnowsToSwim")} </Typography>
                                    <HBox marginTop={"1rem"} alignItems="center">
                                        <Button className={!permission.knowToSwim ? classes.buttons1 : classes.buttons} onClick={e => setNo("knowToSwim")}>{t('no')}</Button>
                                        <Button className={permission.knowToSwim ? classes.buttons1 : classes.buttons} onClick={e => setPermission({ ...permission, knowToSwim: true })}>{t('yes')}</Button>
                                    </HBox>
                                    <Typography className={classes.text} >{t("approveNightSwim")}  </Typography>
                                    <HBox marginTop={"1rem"} marginBottom={"1rem"} alignItems="center">
                                        <Button className={!permission.nightSwim ? classes.buttons1 : classes.buttons} onClick={e => setNo("nightSwim")}>{t('no')}</Button>
                                        <Button className={permission.nightSwim ? classes.buttons1 : classes.buttons} onClick={e => setPermission({ ...permission, nightSwim: true })}>{t('yes')}</Button>
                                    </HBox>
                                </>}
                            </VBox>
                        </VBox>
                    </HBox>
                </Container>
            </Card >
            <Box justifyContent='center' className={`${"showOnPrint"}_${cardCode}`} sx={{ display: "none" }} >
                <HBox alignItems="center">
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>{`${t('waterActivity')} :`}</Typography>
                    <Typography sx={{ fontSize: "1rem !important" }}>{` ${swimPermissionTextBySap[letterValue]}`}</Typography>
                </HBox>
            </Box>
        </>
    )
}
export default WaterActivity;