import { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Link, InputAdornment, IconButton, TextField, Container } from '@mui/material';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { VBox, HBox } from '../../assets/SharedComponents';
import { useTranslation } from 'react-i18next';
import { CustomBusy } from '../Common/CustomBusy.jsx';
import useFeathers from 'hooks/useFeathers.js';
import useVerification from 'hooks/useVerification.js';
import { useSnackbar } from 'notistack';

export const IdPassTab = ({ setLoginIdProcess }) => {
    const [showPassword, setShowPassword] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState("");
    const [password, setPass] = useState("");
    const feathers = useFeathers();
    const hookVerification = useVerification();
    const [busy, setBusy] = useState(false);
    const inputRef = useRef()

    const loginIdPassHandler = async (e) => {
        setBusy(true);
        try {
            const response = await feathers.getEmailByCredentials(id, password);
            const result = await hookVerification.loginWithEmailAndPassword(response.email, password);
            const { accessToken, emailVerified } = result.user;
            await feathers.authenticationWithToken('firebase', accessToken, emailVerified);
        } catch (error) {
            enqueueSnackbar(t("UserNameOrPassError"), { variant: "error" });
        } finally {
            setBusy(false);
        }
    };

    const handleId = (e) => {
        let temp = e.target.value.slice(0, 30);
        setId(temp);
    }

    const toggleShowPass = () => setShowPassword(curr => !curr);
    // ui logic
    const disbledButton = (!password) || (!id) || (id.length <= 7);

    return (
        <Box height={'100%'} className={classes.width100} display='inline-grid' flexDirection='column'>
            <Container>
                <VBox className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} `} width="17rem">
                    <TextField
                        type="number"
                        name="Id"
                        label={t("parentIdDoc")}
                        placeholder={t("idPlaceholder")}
                        style={{ width: "100% !important" }}
                        value={id}
                        className={`${classes.paddingBottom1rem} ${classes.RemoveTextFieldNumberArrow} ${classes.TextfieldILabelStyle}`}
                        onChange={e => handleId(e)}
                        variant="outlined"
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !disbledButton) {
                                e.preventDefault();
                                loginIdPassHandler(e);
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                inputMode: 'tel',
                                pattern: "[0-9]*"
                            },
                            inputRef: inputRef
                        }}
                        onWheel={(e) => {
                            if (inputRef && inputRef.current && inputRef.current.blur) {
                                inputRef.current.blur();
                            }
                        }} />
                    <TextField
                        type={showPassword ? "none" : "password"}
                        name="Password"
                        value={password}
                        className={`${classes.paddingBottom1rem} ${classes.TextfieldILabelStyle}`}
                        onChange={e => setPass(e.target.value)}
                        style={{ width: "100% !important" }}
                        label={t("password")}
                        variant="outlined"
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !disbledButton) {
                                e.preventDefault();
                                loginIdPassHandler(e);

                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleShowPass}
                                        onMouseDown={toggleShowPass}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <HBox className={classes.spaceBetween}>
                        <Typography>
                            <Link component="button" color={'#007f00'} className={`${classes.paddingInlineStart0_3} ${classes.LinkStyle} `}
                                onClick={e => { setLoginIdProcess(2) }}>{t("forgotPassword")}</Link>
                        </Typography>
                    </HBox>


                    {busy ? <CustomBusy text={t("loginBusy")} /> :
                        <Button
                            disabled={disbledButton}
                            sx={{ marginTop: "1rem" }}
                            className={`${disbledButton ? classes.buttonGreenLight : classes.buttonGreen} `}
                            variant="contained"
                            onClick={loginIdPassHandler}>
                            {t("login")}
                        </Button>}
                </VBox>
            </Container>
        </Box>

    );
}