import { Stack, Typography, CircularProgress, LinearProgress } from "@mui/material";

const BusyIndicator = ({ request, children, linear = false }) => {

    const UIswitch = linear ? <LinearProgress /> : <CircularProgress />
    const loading = request.loading &&
        <Stack spacing={0}>
            {UIswitch}
        </Stack>
    const error = request.error && <Typography color={'black'}>{request.error}</Typography>;
    const data = request.data && children;
 
    return (
        <>
            {loading}
            {error}
            {data}
        </>
    )
}

export default BusyIndicator
