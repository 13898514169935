import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material"
import { styled } from '@mui/system';

import { useTranslation } from "react-i18next";
import notInPilotIcon from "../../assets/notInPilot.svg"
import GenericDialog from "Components/Common/GenericDIalog";
import { useRef, useState } from "react";
import { isEmail } from 'validator';
import { updateParentEmail } from "api/api";
import useFeathers from "hooks/useFeathers";
import { getAuth } from "firebase/auth";
import { useSnackbar } from 'notistack';
import ContactDialog from "Components/Common/ContactDialog";

export const GreenButton = styled(Button)(({ theme, negative }) => ({
    "&:hover": {
        background: '#cee2cd !important'

    },
    backgroundColor: negative == 'true' ? '#fff !important' : '#3a7735 !important',
    color: negative == 'true' ? '#3a7735 !important' : "#ffffff !important",
    border: negative == 'true' ? '1.5px solid #3a7735' : 'none',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    margin: '1rem !important',
    borderRadius: '50px !important',
    width: '10.75rem !important',
    textTransform: "capitalize !important"

}));
const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "15rem !important",
    "& .MuiInputBase-root": { height: '2rem' },
    '& .MuiInputLabel-root': {
        color: 'black !important',
        fontSize: '1rem !important',
        alignItems: "center"
    },
    '& .MuiInputLabel-root.Mui-focused': {
        fontWeight: 'bold !important',
        color: 'black !important',
        fontSize: '1rem !important',
    },
    '& .Muirtl-1in441m': {
        fontSize: '1.2rem !important'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '0.7rem'
    }
}));
const mailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const EmailEmptyModal = ({ open, setOpen, username, PhoneNumber, backToSMS }) => {
    const { t } = useTranslation();
    const [stage, setStage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const emailRef = useRef(null);
    const hookFeathers = useFeathers();
    const { enqueueSnackbar } = useSnackbar();
    const auth = getAuth();

    const sendNewMail = async (e) => {
        const email = emailRef.current.value;
        const query = { email, username, phonenumber: PhoneNumber.phone }
        try {
            setLoading(true)
            if (isEmail(email) && mailReg.test(email)) {
                await hookFeathers.loginAnonymously();
                const res = await updateParentEmail(query);
                if (res) {
                    enqueueSnackbar(t("updateMailSuccess"), { variant: "success" });
                    setOpen(false);
                    backToSMS(e);
                } else {
                    enqueueSnackbar(t("updateError"), { variant: "error" });
                    setLoading(false);
                }
            }
        } catch (error) {
            if (error.data && error.data.emailExsits) {
                setStage(2);
            }
            console.log(error)
        }
        finally {
            await hookFeathers.logoutAnonymously();
            setLoading(false);
        }

    }

    return (
        <>
            <GenericDialog open={open} setOpen={setOpen} iconSVG={notInPilotIcon} showRopes={true} height="23rem">
                {stage === 1 && <>
                    <Typography color="#064601" variant="mediumBoldText">{t('emptyEmailTitle')}</Typography>
                    <Typography color="#064601" mb="1rem">{t('verifyEmailText')} </Typography>
                    <StyledTextField focused disabled={loading}
                        label={t("emailAdress")}
                        style={{ width: "100%" }}
                        id="email"
                        inputRef={emailRef}
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                inputMode: 'url'
                            },

                        }}
                    />
                    {loading ?
                        <CircularProgress color="inherit" size={20} sx={{ margin: '1rem' }} /> :
                        <GreenButton onClick={e => sendNewMail(e)}>{t('verifyEmail')}</GreenButton>}

                </>
                }
                {stage === 2 &&
                    <>
                        <Typography color="#064601" variant="mediumBoldText" mb="3rem">{t('EmailTaken')}</Typography>
                        <Box display="flex" >
                            <GreenButton negative="true" onClick={e => setOpenContact(true)}>{t('support')}</GreenButton>
                            <GreenButton onClick={() => setStage(1)}>{t('backToAnotherEmail')}</GreenButton>
                        </Box>
                    </>
                }


            </GenericDialog>
            {openContact &&
                <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}
        </>
    )
}
export default EmailEmptyModal;
