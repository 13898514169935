import { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import useGlobal from "../../../store/store"
//libs
import { format } from 'date-fns'
import { system } from '../../../api/client'
//mui
import { Typography, Button } from '@mui/material'
//api
import { addPaymentHana } from '../../../api/api'
//assets
import approvalPay from '../../../assets/approvalPay.png'
import failedTransc from '../../../assets/failedTransc.svg'
//Components
import { VBox } from '../../../assets/SharedComponents'
import MakeTransaction from "../../Payments/MakeTransaction";
import GenericDialog from "Components/Common/GenericDIalog";

const useStyles = makeStyles(() => ({
    underLineText: {
        "&:hover": {
            fontWeight: 'bold !important',
            textDecoration: 'underline !important',
            cursor: 'pointer !important'
        },
        textAlign: 'center !important',
        textDecoration: 'underline !important',
        fontSize: '1.1rem !important',
        color: '#007f00 !important'
    },
    smallText: {
        textAlign: 'center !important',
        fontSize: '0.875rem !important',
        color: '#007f00 !important'
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#3a7735 !important',
        marginTop: '2rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#ffffff !important',
        fontSize: '1rem !important'
    }
}));

const CreditCardLevel = ({ setLevel, onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [sapErrorPayment, setSapErrorPayment] = useState(false);
    const [household] = useGlobal((state) => state.household);
    const [products] = useGlobal((state) => state.products);
    const [payByExtra] = useGlobal(state => state.payByExtra);
    const [payWithAllExtra] = useGlobal((state) => state.payWithAllExtra);

    const approvalLevel = 8;
    const today = format(new Date(), 'yyyy-MM-dd');

    //listener for credit guard ifram status ,after user paid collecting data from transaction and post it to SAP 
    useEffect(() => {
        const handler = async (...args) => {
            if (args?.length) {
                try {
                    if (payByExtra && !payWithAllExtra && args[0].data.response?.Status) {
                        const extraPaymentDetails =
                        {
                            Guid: args[0].data.response?.Guid,
                            ParentCardCode: household.CardCode,
                            UnitCode: products[0].TribeCode,
                            PaymentDate: today,
                            PaymentExecutionType: "10"
                        }
                        if (system !== "prod") {
                            extraPaymentDetails.PaymentPercent = 100;
                            extraPaymentDetails.InvoiceNum = "0";
                        }
                        const res1 = await addPaymentHana(extraPaymentDetails);
                    }
                    if (args[0].data.error?.CGError) {
                        setOpenErrorDialog(true);
                        return;
                    }
                    if (args[0].data.error?.sapInvoiceError || args[0].data.error?.sapRegError || args[0].data.error?.mongoDBError || args[0].data.error?.transactionMongoDBError) {
                        console.log(args[0].data?.error);
                        setSapErrorPayment(true);
                        setOpenErrorDialog(true);
                        return;
                    }
                    if (args[0].data.response?.Status) {
                        setLevel(approvalLevel);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        window.addEventListener("message", handler);
        return () => {

            window.removeEventListener("message", handler);
        }
    }, [])
    const handleCloseButton = (e) => {
        setIsPaid(true);
        setSapErrorPayment(false);
        setOpenErrorDialog(false);
        onCloseCart(e);
    }
    const handleGoToButton = () => {
        setOpenErrorDialog(false);
        setLevel(3);
    }

    return (
        <VBox marginTop="3rem">
            <Typography className={classes.underLineText} onClick={e => setLevel(3)}>{t('otherPaymentMethod')}</Typography>
            <MakeTransaction />
            <GenericDialog open={openErrorDialog} setOpen={setOpenErrorDialog} showRopes={false} height={"32rem"} showExit={false} >
                {sapErrorPayment ?
                    <>
                        <img
                            alt={"..."}
                            src={approvalPay}
                            width="150px"
                            height="150px"
                        />
                        <Typography color="#064601" mt="1rem" variant="mediumBoldText">{t('paymetSuccess')}</Typography>
                        <Typography color="#064601" mt="1rem" sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>{t('regInNextHours')} </Typography>
                        <Button className={classes.greenButton} onClick={e => handleCloseButton(e)}>{t('whatAfun')}</Button>
                        <Typography mt="3rem" className={classes.smallText}>{t('emailToPayment')} </Typography>

                    </>
                    : <>
                        <img
                            alt={"..."}
                            src={failedTransc}
                            width="150px"
                            height="150px"
                        />
                        <Typography color="#bb0000" mt="1rem" variant="mediumBoldText">{t('cardFaild')}</Typography>
                        <Typography color="#bb0000" mt="1rem" sx={{ textAlign: "center", fontWeight: "400" }}>{t('notCharged')}</Typography>
                        <Typography mt="3rem" className={classes.smallText}>{t('tryAgainInCard')} </Typography>
                        <Button className={classes.greenButton} onClick={e => handleGoToButton()}>{t('goToOtherPayment')}</Button>

                    </>}
            </GenericDialog>
        </VBox >
    );
}
export default CreditCardLevel;