import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Card, Typography } from '@mui/material';
import { VBox, HBox } from 'assets/SharedComponents';
import ChildDetails from './ChildDetails';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    cardBox: {
        alignItems: 'center !important',
        marginTop: '3rem !important',
        height: "fit-content"
    },
    card: {
        alignItems: 'center !important',
        width: '18.5rem !important',
        direction: 'rtl !important',
        borderRadius: '1.5rem !important'
    },
    cardInfo: {
        height: "3rem",
        alignItems: "center",
        justifyContent: "center"
    },
    cardTitle: {
        background: "#cee2cd",
    },
    cardBottom: {
        justifyContent: "space-evenly"
    },
    orangeText: {
        color: "#ff9b3e"
    },
    whiteText: {
        color: "#ffffff"
    }
}));

const ChildrenCard = ({ household, tribe, balancePerTribe, schoolList, isFromTribe, setAppBarTitle }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const childrenArray = household.ChildrenList.results.filter((child) => (child.BPLName.slice(5) === tribe));
    const [childDetailsArray, setChildDetailsArray] = useState([]);

    useEffect(() => {
        if (schoolList.length) {
            childrenArray.forEach((child) => {
                if (child.School) {
                    child.SchoolObject = schoolList.filter((el) => el.SchoolCode === child.School)[0];
                }
                else {
                    child.SchoolObject = { SchoolName: t("cantFindSchool"), CityName: "" };
                }
            })
            setChildDetailsArray(childrenArray);
        } else {
            childrenArray.forEach((child) => {
                child.SchoolObject = { SchoolName: t("cantFindSchool"), CityName: "" };
            })
            setChildDetailsArray(childrenArray);
        }
    }, [schoolList]);

    return (
        <VBox className={classes.cardBox} sx={{ marginRight: { xs: "0.3rem", lg: "2.5rem" }, marginBottom: { xs: "0.5rem", md: "1rem", lg: "2.5rem" } }}>
            <Card className={classes.card} sx={{ marginLeft: { xs: "0.5rem", md: "1rem", lg: "2.5rem" } }}>
                <HBox className={`${classes.cardInfo} ${classes.cardTitle}`}>
                    <Typography fontSize="1.25rem" fontWeight={'bold'}>{t("tribe")} {tribe}</Typography>
                </HBox>
                {childDetailsArray.map((child, i) =>
                    <ChildDetails children={child} index={i} key={i} school={child.SchoolObject} isFromTribe={isFromTribe} setAppBarTitle={setAppBarTitle} />)}
                <HBox className={`${classes.cardInfo} ${classes.cardBottom}`} sx={{ background: (balancePerTribe > 0) ? "#f06752" : "#3a7735" }}>
                    <Typography className={classes.whiteText}>{(balancePerTribe > 0) ? t("debtBalance") : t("creditBalance")}</Typography>
                    <Typography className={classes.whiteText}>{`${"\u20AA"}${isNaN(balancePerTribe) ? "0" : (balancePerTribe > 0) ? balancePerTribe : ((balancePerTribe) * -1)}`} </Typography>
                </HBox>
            </Card>
        </VBox>
    );
}

export default ChildrenCard;