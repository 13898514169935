import { useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from "react-to-print";
import { format } from 'date-fns'

import { Button, Typography, Card, Container } from '@mui/material';
import { VBox, HBox } from '../../../assets/SharedComponents';
import Logo from '../../../assets/Logo.png'

import PrintIcon from '@mui/icons-material/Print';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import useGlobal from '../../../store/store';
import { UpdateRegAPI } from '../../../api/api';
import { BalanceContext } from 'context/balanceContext';
import { DiscountContext } from 'context/discountContext';
const useStyles = makeStyles(() => ({
    head: {
        alignItems: 'center !important',
    },
    subTitle: {
        color: '#3a7735 !important',
        textAlign: 'center !important',
        fontSize: '1rem !important',
        marginBottom: '2.2rem !important'
    },
    orangeText: {
        color: '#fd8f2a !important',
        fontSize: '1.1rem !important',
    },
    cardBox: {
        alignItems: 'center !important',
        marginTop: '2rem !important'
    },
    kibbutzCard: {
        alignItems: 'center !important',
        height: 'fit-contant !important',
        width: '18.5rem !important',
        direction: 'rtl !important',
        borderRadius: '20px !important'
    },
    rtlBox: {
        direction: 'rtl !important'
    },
    printIcon: {
        justifyContent: 'left !important',
        margin: '0.2rem !important',
        cursor: 'pointer !important'
    },
    cardTop: {
        justifyContent: 'center !important'
    },
    LogoSize: {
        width: '50px !important'
    },
    orangeBoldText: {
        fontWeight: 'bold !important',
        color: '#fd8f2a !important',
        fontSize: '1.1rem !important'
    },
    bottomContent: {
        alignItems: 'center !important',
        margin: '2.5rem !important'
    },
    contentTitle: {
        textAlign: 'center !important',
        fontWeight: 'bold !important'
    },
    childActivities: {
        margin: '1rem !important'
    },
    boldName: {
        fontWeight: 'bold !important'
    },
    nameAndDate: {
        justifyContent: 'space-between !important'
    },
    method: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#3a7735 !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important'
    },
    methodText: {
        color: '#ffffff !important',
        fontSize: '1rem !important',
        borderRadius: '50px !important',

    },
    underLineText: {
        "&:hover": {
            fontWeight: 'bold !important',
            textDecoration: 'underline !important'
        },
        textDecoration: 'underline !important',
        fontSize: '1rem !important',
        color: '#007f00 !important'
    },
    totalContent: {
        justifyContent: 'space-between !important',
        paddingBottom: '1rem !important',
        backgroundColor: '#0000001c !important',
        marginTop: '0.5rem !important'
    },
    print: {
        '@media print': {
            '.page-break': {
                marginTop: "1rem !important",
                display: "block !important",
                pageBreakBefore: "auto"
            }
        }
    }
}));

const KibbutzApproval = ({ products, onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const componentRef = useRef();
    const { discount, setDiscount } = useContext(DiscountContext);
    const { balance, setBalance } = useContext(BalanceContext);
    const [household] = useGlobal((state) => state.household);
    const [total] = useGlobal((state) => state.total);
    const [finalPrice] = useGlobal((state) => state.finalPrice);
    const results = []
    const childrenArray = {};

    useEffect(() => {
        let isMounted = true;
        products.map(async (product) => {
            await UpdateRegAPI({ _id: product._id, data: { Status_request: "2" } })
        })
        isMounted && setIsPaid(true)
        return () => { isMounted = false }
    }, [])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const results1 = products.filter(el => {
        if (!childrenArray[el.Pupil_FirstName]) {
            childrenArray[el.Pupil_FirstName] = el;
            return true
        }
        return false;
    }).map(el => (household.ChildrenList?.results.filter((item) => item.Pupil_FirstName === el.Pupil_FirstName).map(child => results.push(child))))

    return (
        <Container>
            <VBox className={classes.head}>
                <FactCheckOutlinedIcon sx={{ color: '#3a7735' }} fontSize='large' />
                <Typography className={classes.subTitle}>{t('regReqSent')}</Typography>
            </VBox>
            <VBox>
                <Typography className={classes.orangeText}>{t('payAttention')}</Typography>
                <Typography className={classes.orangeBoldText}>{t('kibbutzText')}</Typography>
                <Typography className={classes.orangeBoldText}>{t('kibbutzText1')}</Typography>
            </VBox>
            <VBox className={classes.cardBox}>
                <Card className={`${classes.kibbutzCard} ${classes.print}`} ref={componentRef}  >
                    <HBox className={classes.printIcon}>
                        <PrintIcon sx={{ color: '#3a7735' }} className={classes.printIcon} onClick={handlePrint} /></HBox>
                    <VBox className={`${classes.rtlBox} `}  >
                        <HBox className={classes.cardTop}>
                            <img
                                className={classes.LogoSize}
                                alt={'...'}
                                src={Logo}
                            />
                        </HBox>

                        <Typography className={classes.contentTitle}>{t('registrationReq')}</Typography>
                        <VBox className={classes.childActivities}>
                            {results.map((child) =>

                                <VBox>
                                    <Typography className={classes.boldName}>{`${child.Pupil_FirstName} ${child.Pupil_lastName}`}</Typography>
                                    {products.filter((activity) => activity.StudentCardCode === child.CardCode).map((item) => {
                                        return (
                                            <HBox className={classes.nameAndDate}>
                                                <Typography>{item.ActivName}</Typography>
                                                <Typography>  {format(new Date((item.EndRegDate)), 'dd/MM/yyyy')}</Typography>
                                            </HBox>)
                                    })}
                                </VBox>)}
                        </VBox>
                        <HBox className={classes.totalContent}>
                            <VBox paddingLeft={'0.5rem'}>
                                <Typography style={{ fontSize: '0.7rem' }} >  {t('sum')} ({products.length} {t('activities')})</Typography>
                                <Typography style={{ fontSize: '0.7rem' }} > {t('discounts')}</Typography>
                                <Typography style={{ fontSize: '0.7rem' }} > {t('familyBalance')}</Typography>
                                <Typography style={{ fontSize: '1.2rem' }} > {t('totalPay')}</Typography>
                            </VBox>
                            <VBox paddingRight={'0.5rem'} alignItems='end'>
                                <Typography style={{ fontSize: '0.7rem' }}>{total} {"\u20AA"}</Typography>
                                <Typography style={{ fontSize: '0.7rem' }} > {"\u20AA"}{discount.sum}</Typography>
                                <Typography style={{ fontSize: '0.7rem' }} > {"\u20AA"}{-balance.amount}</Typography>
                                <Typography style={{ fontSize: '1.2rem' }} >{finalPrice} {"\u20AA"}</Typography>

                            </VBox>
                        </HBox>
                    </VBox>
                </Card>
            </VBox>
            <VBox className={classes.bottomContent}>
                <HBox className={classes.method}>
                    <Button className={classes.methodText} onClick={e => onCloseCart(e)}>{t('understandGoHomePage')}</Button>
                </HBox>
            </VBox>
        </ Container>
    );
}
export default KibbutzApproval;