import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
// Mui
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TablePagination, TableRow, Paper, Checkbox } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import StatusChip from 'Components/Common/StatusChip';
// data
import { permanentInfo } from '../../data/permanentInfo'

const useStyles = makeStyles(() => ({
    sortTitle: {
        '&:hover': {
            color: 'black !important'
        },
        '& .MuiTableSortLabel-icon': {
            color: "#3a7735 !important"
        }
    },
}))
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const headCells = [
        { id: 'Pupil_FirstName', label: t("pupilName"), toSort: true },
        { id: 'PupilID', label: t("id"), toSort: false },
        { id: 'Troops', label: t("troopGroup"), toSort: true },
        { id: 'School', label: t("school"), toSort: false },
        { id: 'Phone1', label: t("myPhone"), toSort: false },
        { id: 'payTax', label: t("payTax"), toSort: false }];

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" />
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={headCell.toSort && orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!headCell.toSort}
                            className={classes.sortTitle}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default function PupilsTable({ rows, sorter, selected, setSelected }) {
    const { t } = useTranslation();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [isGradeSelected, setIsGradeSelected] = useState(false);


    useEffect(() => {
        switch (sorter) {
            case 1:
                handleRequestSort('click', 'Pupil_FirstName');
                break;
            case 2:
                handleRequestSort('click', 'Troops');
                break;
            default:
                break;
        }
    }, [sorter]);



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Create a flat array for rendering
    const flatData = rows.reduce((accumulator, item) => {
        accumulator.push({ isClassRow: true, Class: item.classNumber });
        accumulator.push(...item.pupils);
        return accumulator;
    }, []);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - flatData.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(flatData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );
    const handleClick = (event, row) => {

        const selectedIndex = selected.indexOf(row);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        if (newSelected.length === flatData.length) {
            setIsGradeSelected(true);
        } else {
            setIsGradeSelected(false);
        }

    };

    const handleClickAll = (event, row) => {
        let newSelected = flatData.filter((r) => r.Class === row.Class);
        if (newSelected.length === flatData.length && !isGradeSelected) {
            setIsGradeSelected(true);
        } else {
            setIsGradeSelected(false);
            newSelected = [];
        }
        setSelected(newSelected);
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Paper sx={{ width: '90%', m: 2, boxShadow: 'none' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isClassRow = row.isClassRow;
                                const isItemSelected = isSelected(row);
                                if (isClassRow) {
                                    return (
                                        <TableRow key={`class-${row.Class}`}>
                                            <TableCell padding="checkbox" >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isGradeSelected}
                                                    onChange={(event) => handleClickAll(event, row)}
                                                    disabled={flatData?.length === 1}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={7} align="left" sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                                                {`${t("classOf")} ${permanentInfo.childClasses[row.Class]}' ${flatData?.length === 1 ? t("noPupilsForGrade") : ""}`}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.PupilID}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox" >
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none" width="15%">{`${row.Pupil_FirstName} ${row.Pupil_LastName}`}</TableCell>
                                        <TableCell align="center" width="15%">{row.PupilID}</TableCell>
                                        <TableCell align="center" width="15%">{`${row.Troops || ""} - ${row.Groups || ""}`}</TableCell>
                                        <TableCell align="center" width="30%">{row.SchoolDetails?.SchoolName || "-"}</TableCell>
                                        <TableCell align="center" width="15%">{row.Phone1 || "-"}</TableCell>
                                        <TableCell align="center" width="10%"><StatusChip status={row.taxStatus} size='small' /></TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={flatData.length - 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}