
import { useEffect, useState } from 'react';
import appBG from '../../assets/appBG.jpg';
import { Animated } from 'react-animated-css'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, Card, Container } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { getAuth, applyActionCode } from "firebase/auth";
import { useQueryParams, useNavigate } from 'raviger'

import { MessageBox } from '../../Components/Common/MessageBox';
import line from '../../assets/line.svg'
import zofimMobile from '../../assets/zofimMobile.svg'
import mobileAndChild from '../../assets/mobileAndChild.svg'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const useStyles = makeStyles(() => ({
    backgruond: {
        backgroundImage: `url(${appBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: "100%"
    },
    buttonGreen: {
        color: '#3a7735 !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#ffffff !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        width: "15rem !important",
        border: "1px solid !important",
        marginTop: "1.5rem !important"
    }, card: {
        alignItems: 'center !important',
        height: '22rem !important',
        width: '20rem !important',
        direction: 'rtl !important',
        borderRadius: '20px !important',
        background: "#e6efe6 !imporatnt"
    }, text: {
        color: '#3a7735 !important',
        fontWeight: "bold !important"
    },
    buttonGreen1: {
        color: '#3a7735 !important',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#ffffff !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        width: "13rem !important",
        border: "1px solid !important",
        marginTop: "1rem !important",
        marginBottom: "1rem !important"
    }
}));


const ConfirmEmailPage = () => {
    const classes = useStyles();
    const { t } = useTranslation()
    const urlParams = useQueryParams()
    const navigate = useNavigate()
    const [messageBoxState, setMessageBox] = useState({ open: false });
    const [error, setError] = useState(false);
    const auth = getAuth()


    useEffect(() => {
        // Get the one-time code from the query parameter.
        const actionCode = urlParams[0].oobCode;
        // (Optional) Get the continue URL from the query parameter if available.
        const continueUrl = urlParams[0].continueUrl;
        // (Optional) Get the language code if available.
        const lang = urlParams[0].lang || 'en';
        handleVerifyEmail(auth, actionCode, continueUrl, lang);
    }, [])
    const handleVerifyEmail = async (auth, actionCode, continueUrl, lang) => {
        try {
            await applyActionCode(auth, actionCode);
            setError(false);
        } catch (error) {
            setError(true);
            setMessageBox({
                open: true,
                fullWidth: false,
                maxWidth: "5rem",
                title:
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <ErrorRoundedIcon />
                            <Typography variant="h5" paddingLeft={"1rem"}> {t("note")}</Typography>
                        </Box>
                    </Box >,
                content:
                    <>
                        <Box display="flex" flexDirection="column" textAlign="center">
                            <Typography className={` ${classes.text}`} > {t("expLink")}</Typography>
                            <Typography className={` ${classes.text}`} > {t("expLink1")}</Typography>
                        </Box>
                    </>,
                actions:
                    <>
                        <Button className={classes.buttonGreen1} onClick={e => navigate("/")}>{t("backToLogin1")}</Button>
                    </>
            });
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.backgruond}>
            {!error ? <>     <Animated animationIn="fadeIn" animationInDuration={1200} isVisible={true}>
                <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("confirmSuccess")}</Typography></Animated>
                <Animated animationIn="slideInUp" animationInDuration={1200} isVisible={true}>
                    <img
                        style={{ marginTop: "1rem", paddingLeft: "1.5rem" }}
                        width="fit-contact"
                        height="auto"
                        alt={"..."}
                        src={mobileAndChild} />
                </Animated>
                <Animated animationIn="fadeIn" animationInDuration={800} isVisible={true} >
                    <Animated animationIn="slideInUp" animationInDuration={1200} animationInDelay={1202} isVisible={true}>
                        <img
                            style={{ marginTop: "1rem" }}
                            width="fit-contact"
                            height="auto"
                            alt={"..."}
                            src={line} />
                    </Animated>
                </Animated>
                <Animated animationIn="fadeIn" animationInDuration={1200} animationInDelay={1204} isVisible={true}>
                    <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("nowGoBack")}</Typography>
                </Animated>
                <Animated animationIn="fadeIn" animationInDuration={800} animationInDelay={1201} isVisible={true} >
                    <Animated animationIn="slideInUp" animationInDuration={1200} animationInDelay={1202} isVisible={true}>
                        <img
                            style={{ marginTop: "1rem" }}
                            width="fit-contact"
                            height="auto"
                            alt={"..."}
                            src={zofimMobile} />

                    </Animated>
                </Animated>
                <Animated animationIn="fadeIn" animationInDuration={800} animationInDelay={1201} isVisible={true} >
                    <Animated animationIn="slideInUp" animationInDuration={1200} animationInDelay={2405} isVisible={true}>
                        <img
                            style={{ marginTop: "1rem" }}
                            width="fit-contact"
                            height="auto"
                            alt={"..."}
                            src={line} />
                    </Animated>
                </Animated>
                <Animated animationIn="fadeIn" animationInDuration={1200} animationInDelay={2405} isVisible={true}>
                    <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("continueReg")}</Typography>
                </Animated>
            </> :
                <Card className={classes.card}>
                    <Container sx={{ marginTop: "1rem", padding: "1.5rem !important", textAlign: "center" }}>
                        <Typography className={` ${classes.text}`} > {t("expLink")}</Typography>
                        <Typography className={` ${classes.text}`} >  {t("expLink1")} </Typography>
                    </Container>
                </Card>
            }
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
        </Box >
    )
}
export default ConfirmEmailPage;