import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'store/store';
import useApi from 'hooks/useApi';
// mui
import { Box, styled, List, ListItem, Checkbox, Typography, Button, CircularProgress } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
// components
import ActivityReq from './ActivityReq';
import { InputStyle } from 'pages/ActivitiesReport';
//api
import { searchInParent } from '../../api/api';

export const MyInputStyle = styled(InputStyle)(({ theme }) => ({
  padding: '0 1rem',
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    height: '1.5rem',
    width: '25vw',
    minWidth: '27rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '18rem',
      width: '100%',
      "& .MuiInputBase-input": {
        fontSize: '.8rem'
      }
    }
  }
}));
const GreenButton = styled(Button)(({ ...props }) => ({
  "&:hover": {
    background: '#cee2cd !important',
  },
  backgroundColor: props.disabled ? '#cee2cd !important' : '#3a7735 !important',
  color: "#ffffff !important",
  alignItems: 'center !important',
  justifyContent: 'center !important',
  margin: '1rem !important',
  borderRadius: '50px !important',
  width: '10.75rem !important',
  textTransform: "capitalize !important"

}));

const OpenSignReqs = ({ openRequests, cartFamily, setCartFamily, products, addProduct, removeProduct, getOpenRequsts, tribe, setChildrenList }) => {
  const { t } = useTranslation();
  const search = useApi(searchInParent);
  const [searchValue, setSearchValue] = useState("");
  const [reqs, setReqs] = useState([]);
  const [tribeUserSelection, setTribeUserSelection] = useState([]);
  const [cartState, setCartState] = useGlobal(
    state => state.cartState,
    actions => actions.setCartState);
  const [household, setHousehold] = useGlobal(
    state => state.household,
    actions => actions.setHousehold);


  const filteredActivities = !searchValue ? openRequests :
    openRequests.filter(reg =>
      (reg.Pupil_FirstName.concat(' ', reg.Pupil_lastName)).includes(searchValue) ||
      reg.ActivName.includes(searchValue) ||
      reg.Pupil_FirstName.includes(searchValue) ||
      reg.Pupil_lastName.includes(searchValue) ||
      reg.LicTradNum.includes(searchValue));

  useEffect(() => {
    setReqs(arrayToTree(filteredActivities));
  }, [products, openRequests, searchValue]);

  useEffect(() => {
    const newList = tribeUserSelection.filter(el => {
      const found = products.find(prod => el._id === prod._id);
      return found;
    })
    setTribeUserSelection(newList);
  }, [products]);

  const handleFilter = (searchValue) => {
    setSearchValue(searchValue);
  }

  const handleSelection = (request, action) => {
    if (action === "add") {
      request.selStatus = "1";
      setTribeUserSelection(prev => [...prev, request]);
      setCartFamily(request.ParentCardCode);
    }
    else if (action === "sub") {
      const listAfterRemove = tribeUserSelection.filter(regReq => regReq._id !== request._id)
      setTribeUserSelection(listAfterRemove);
      if (cartFamily && !listAfterRemove.length)
        setCartFamily("");


    }
  }
  const handleAddToCart = async () => {
    const addToCart = tribeUserSelection.filter(el => el.selStatus === "1");
    addProduct(addToCart);
    tribeUserSelection.forEach(el => {
      el.selStatus = "2";
    })
    const toSearch = tribeUserSelection[0].Parent1_ID;
    const result = await search.requestPromise(tribe?.UnitCode, toSearch);
    setHousehold(result.d.results[0]);
    setChildrenList(result.d.results[0].ChildrenList?.results);
    setTribeUserSelection(tribeUserSelection);
    setCartState(true);
  }

  return (
    <>
      <Box>
        <MyInputStyle
          variant="outlined"
          placeholder={t('searchPlaceholderReq')}
          onChange={e => handleFilter(e.target.value)}
          InputProps={{
            endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
          }}
        />
      </Box>
      <List sx={{ width: '100%' }} disablePadding>
        {reqs.map((familyReqs) => (
          <List dir='rtl' key={familyReqs.familyId} disablePadding>
            <Box p="0 1rem" display="flex" flexDirection='row' alignItems="center" justifyContent="space-between" mt={"1rem"}>
              <FamilyHeader
                familyReqs={familyReqs}
                setCartFamily={setCartFamily}
                cartFamily={cartFamily}
                familyId={familyReqs.familyId}
                products={products}
                setTribeUserSelection={setTribeUserSelection}
                tribeUserSelection={tribeUserSelection}
              />
            </Box>
            <List sx={{ paddingBottom: '1.5rem' }}>
              {familyReqs.reqs.filter(el => (el.ItemPurposeType !== '4') || !el.Father).map((regRequest) => (
                <ListItem key={regRequest._id}>
                  <ActivityReq
                    request={regRequest}
                    cartFamily={cartFamily}
                    familyId={familyReqs.familyId}
                    products={products}
                    removeProduct={removeProduct}
                    getOpenRequsts={getOpenRequsts}
                    handleSelection={handleSelection}
                    tribeUserSelection={tribeUserSelection}
                    nodesActivities={familyReqs.reqs.filter(el => (el.ItemPurposeType === '4') && !!el.Father)}
                  />
                </ListItem>
              ))}
            </List>
            <GreenButton onClick={e => handleAddToCart()}
              disabled={
                (cartFamily && (familyReqs.familyId !== cartFamily)
                  || tribeUserSelection.every(el => el.selStatus === "2"))
                || !tribeUserSelection.length}>
              {t("addToCart")}
              {search.loading && familyReqs.familyId === cartFamily && <CircularProgress size={25} />}
            </GreenButton>
          </List>
        ))}
      </List >
    </>
  );
}

export default OpenSignReqs

const FamilyHeader = ({ familyReqs, setCartFamily, cartFamily, familyId, products, setTribeUserSelection, tribeUserSelection }) => {
  const { t } = useTranslation();

  const [visibleCheckBox, setVisibleCheckBox] = useState(false);

  useEffect(() => {
    if (products?.length) {
      setVisibleCheckBox(familyReqs?.familyId === products[0].ParentCardCode);
    }
    else {
      setVisibleCheckBox(true);
    }
  }, [products]);

  const handleSelectionAll = (checked, requests) => {
    if (checked) {
      const newList = requests.filter(req => {
        return !tribeUserSelection.find(el => el._id === req._id)
      })
      newList.forEach(el => { el.selStatus = "1" })
      setTribeUserSelection([...tribeUserSelection, ...newList]);
      setCartFamily(familyReqs.familyId);
    }
    else {
      const newList = tribeUserSelection.filter(req => req.selStatus === "2");
      setCartFamily(newList.length ? familyReqs.familyId : "");
      setTribeUserSelection(newList);
    }
  };

  const calculateTotalPay = (array) => {
    const total = array.reduce((accum, regRequest) => accum + (parseFloat(regRequest.FinalPrice) - parseFloat(regRequest.paid || 0)), 0)
    return total > 0 ? total.toFixed(2) : 0
  }

  const familyDisplay = cartFamily && (familyId !== cartFamily);
  const disableSelect = familyReqs.reqs.length === products.length;
  const allSelected = familyReqs.reqs.every(req =>
    tribeUserSelection.find(el => el._id === req._id)
  );
  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box display='flex' flexDirection='row' alignItems='center' width='3rem' height='2rem'>
          {!familyDisplay &&
            <Checkbox
              checked={allSelected}
              disabled={disableSelect}
              onChange={e => handleSelectionAll(e.target.checked, familyReqs.reqs)}
              icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: disableSelect ? 'gray' : '#3a7735' }} />}
              checkedIcon={<CheckBoxOutlinedIcon sx={{ color: disableSelect ? 'gray' : '#3a7735' }} />}
            />}
        </Box>
        <Typography fontSize={{ xs: '0.8rem', md: "1.1rem" }}> {`${t("familyOf")} ${familyReqs.family}`}</Typography>
      </Box>
      <Typography fontSize={{ xs: '0.8rem', md: "1.1rem" }}>{(t('totalPay'))}<b> {calculateTotalPay(familyReqs.reqs)} ₪ </b> </Typography>
    </>
  )
}
const arrayToTree = (array) => {
  let families = array.reduce((obj, curr) => {      // take all reqs and groupby parentID

    obj[curr.ParentCardCode] = obj[curr.ParentCardCode] || [];
    obj[curr.ParentCardCode].push(curr);
    return obj;
  }, Object.create(null));
  return Object.keys(families).map(el => { return { familyId: families[el][0].ParentCardCode, family: families[el][0].Parent1_LastName, reqs: families[el] } });
}
