import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//mui
import { makeStyles } from '@mui/styles';
import { Card, Typography, Container, Box, Button } from '@mui/material';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
//components
import { VBox, HBox } from '../../../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important',
        alignItems: 'center !important',
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    statmentcard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important',
    },
    statmentCardContent: {
        textAlign: 'right !important',
        justifyContent: 'flex-start !important',
        alignItems: "center !important"
    },
    buttons: {
        "&:hover": {
            background: '#e0e0e0 !important',
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important ',
        marginRight: '0.2rem !important',
        marginBottom: '0.2rem !important',
        color: 'black !important',
        textTransform: "capitalize !important"
    },
    buttons1: {
        "&:hover": {
            background: '#b6f3b5 !important',
        },
        backgroundColor: '#b6f3b5 !important',
        border: '1px solid #007f00 !important',
    },
}));

const PhotoPermission = ({ household, childPermission, cardCode, clearSignature }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [allowPhoto, setAllowPhoto] = useState(childPermission === "Y" ? true : false);

    useEffect(() => {
        clearSignature();
        insertToHousehold();
        return () => { }
    }, [allowPhoto]);

    const insertToHousehold = () => {
        const permissionValue = allowPhoto ? "Y" : "N";
        household.ChildrenList?.results.forEach(child => {
            if (child.CardCode === cardCode) {
                child.PerPhotAndPubl = permissionValue;
            }
        });
    };

    return (
        <>
            <Card className={classes.statmentcard} data-html2canvas-ignore="true" >
                <Container>
                    <HBox className={classes.statmentCardContent} width="100%">
                        <VBox width="100%">
                            <HBox className={classes.cardTitleAndIcon} >
                                <PhotoCameraOutlinedIcon sx={{ color: "#3a7735" }} />
                                <Typography className={classes.cardTitle} >{t('photoPermission')} </Typography>
                            </HBox>
                            <Typography variant='smallText'>{t("photoPermissionText")} </Typography>
                            <HBox mb={"1rem"} alignItems="center">
                                <Button sx={{ minWidth: '3rem', mr: '0.5rem' }} className={`${!allowPhoto && classes.buttons1} ${classes.buttons}`} onClick={e => setAllowPhoto(false)}>{t('no')}</Button>
                                <Button sx={{ minWidth: '3rem' }} className={`${allowPhoto && classes.buttons1} ${classes.buttons}`} onClick={e => setAllowPhoto(true)}>{t('yes')}</Button>
                            </HBox>
                        </VBox>
                    </HBox>
                </Container>
            </Card>
            {/* for print only */}
            <Box justifyContent='flex-start' className={`${"showOnPrint"}_${cardCode}`} width={"100%"} sx={{ display: "none" }}>
                <HBox alignItems="center">
                    <Typography className={classes.cardTitle} >{t('photoPermission')} : </Typography>
                    <Typography>{allowPhoto ? t("allowPhotoPermission") : t("disallowPhotoPermission")}</Typography>
                </HBox>
            </Box>
        </>
    )
}
export default PhotoPermission;