import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import loaderClock from '../../assets/loaderClock.svg'

export const PaymentLoader = () => {
    const { t } = useTranslation();

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <img
                alt={"..."}
                src={loaderClock}
                width="290px"
                height="340px"
            />
            <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.5rem", textAlign: "center", marginTop: "2rem" }}>{t("inFewSeconds")}</Typography>
        </Box>
    )
}