import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { TextField, IconButton, Box, Typography } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    }
}));

const PaymentByCash = ({ setPaymentDetails, paymentDetails, overPay, setOverPay, setCash, finalPrice, totalPayed, cashDisabled, setCashDisabled, redayForPayment, setCheckDialog }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sum, setSum] = useState("");


    const confirmCash = () => {
        setCashDisabled(true);
        setPaymentDetails({ ...paymentDetails, cash: parseFloat(sum) });
        setCash({ CashSum: parseFloat(sum) });
        const key = enqueueSnackbar(t("cashNote"), { className: classes.orangeBG })
        setTimeout(() => { closeSnackbar(key) }, 2500)
        if (!paymentDetails.checks[0]?.CheckSum) {
            setCheckDialog(true);
        }
    }
    const handleChange = (e) => {
        const paid = finalPrice - totalPayed
        if (e.target.value >= 0) { setSum(e.target.value) }
        if (e.target.value > paid) {
            setOverPay(true)
        }
        else {
            setOverPay(false)
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="row" alignSelf="flex-start" alignItems="center">
                <PaymentsOutlinedIcon sx={{ color: "#3a7735", fontSize: { xs: "1.1rem", md: "1.5rem" } }} />
                <Typography sx={{ marginLeft: "1rem", color: "#3a7735", fontSize: { xs: '1rem', md: '1.5rem' } }}>{t("cash")}</Typography>
            </Box>
            <TextField
                name='cashSum'
                autoFocus={true}
                className={`${classes.RemoveTextFieldNumberArrow}`}
                placeholder={t("enterAmount")}
                disabled={cashDisabled || redayForPayment}
                size="small"
                type="number"
                sx={{ width: "14rem", marginTop: "1rem", background: "#ffffff", borderRadius: "10px " }}
                InputProps={{
                    inputProps: {
                        inputMode: 'decimal',
                        pattern: "[0-9]*\.?[0-9]*"
                    },
                    sx: { borderRadius: "10px" },
                    endAdornment: (
                        <IconButton onClick={e => confirmCash()} disabled={sum === "" || overPay || cashDisabled || redayForPayment}>
                            <ArrowCircleLeftOutlinedIcon sx={{ color: sum === "" || overPay || cashDisabled || redayForPayment ? '#a2a2a2' : "#ffbb41" }} />
                        </IconButton>)
                }}
                error={overPay}
                helperText={overPay ? t('overAmountError') : ''}
                value={sum}
                onChange={e => handleChange(e)}
                variant="outlined"
            />
        </Box>
    )
}


export default PaymentByCash