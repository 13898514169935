import { Box, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';

export const CustomBusy = ({ text, small = false }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {text && <Typography sx={{ alignItems: "center", justifyContent: "center", fontSize: "1.2rem", color: "black", fontWeight: "bold" }}>{text}</Typography>}
            <CircularProgress sx={{ width: small && "25px !important", height: small && "25px !important" }} />
        </Box>
    )
}