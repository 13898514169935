import { Login } from '../pages/Login';
import { Register } from '../pages/Register';
import ParentHomePage from '../pages/Parent';
import { TribeHomePage } from '../pages/TribeHomePage';
import SplitPaymentPage from '../pages/SplitPaymentPage';
import { Redirect } from 'raviger'
import { AddChildren } from '../Components/Register/AddChildren';
import SuccessPage from '../pages/transactionalPages/SuccessPage';
import ErrorPage from '../pages/transactionalPages/ErrorPage';
import CancelPage from '../pages/transactionalPages/CancelPage';
import { SmsVerification } from 'Components/Register/SmsVerification';
import { MailVerification } from 'Components/Register/MailVerification';
import { FirstParent } from 'Components/Register/FirstParent';
import DeepLinkHandle from 'pages/DeepLinkHandle';
import FirebaseLandingPage from 'pages/FirebaseLandingPages/FirebaseLandingPage';
import { ParentsSettings } from 'Components/ParentComponents/MyFamilyComponents/ParentsSetting';
import { MaintenancePage } from 'pages/MaintenancePage';
import { QuackLandingPage } from 'pages/QuackLandingPage';

export const Auth = {
  '/parent*': () => <ParentHomePage />,
  '/tribe*': () => <TribeHomePage />,
  '/parentuserSettings': () => <ParentsSettings />,
  '/successPage': () => <SuccessPage />,
  '/errorPage': () => <ErrorPage />,
  '/cancelPage': () => <CancelPage />,
}
export const Public = {
  '/': () => <Login />,
  '/MaintenanceMode': () => <MaintenancePage />,
  '/QuackPage': () => <QuackLandingPage />,
  '/register/*': () => <Register />,
  '/spl/:regReqId': () => <SplitPaymentPage />,
  '/dl/:dlCode': () => <DeepLinkHandle />,
  '/fbUserAction': () => <FirebaseLandingPage />,
  '*': () => <Redirect to='/' />
}

export const registerRouteWithAuth = {
  '/addchild': () => <AddChildren />
}
export const registerPublicRoute =
{
  '/fparent': () => <FirstParent />,
  '/smsver': () => <SmsVerification />,
  '/mailver/:email': ({ email }) => <MailVerification email={email} />,
  '/addchild': () => <AddChildren />,
  '/*': () => <Redirect to='/register/fparent' />
}
