import React from 'react'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../assets/LoginStyle.jsx'
import { Box, Button, Typography } from '@mui/material';
import sentEmail from '../../assets/sentEmail.svg'

export const ResetPassword = ({ handleLoginIdProcess, email }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const maskEmail = (email) => {
        // Split email into local part and domain part
        const parts = email.split('@');
        const localPart = parts[0];
        const domainPart = parts[1];
        // Replace characters in local part with asterisks
        const maskedLocalPart = localPart.charAt(0) + '*'.repeat(localPart.length - 1);
        // Concatenate masked local part, '@' symbol, and domain part
        const maskedEmail = maskedLocalPart + '@' + domainPart;

        return maskedEmail;
    }
    const maskedEmail = maskEmail(email);
    return (
        <Box height={'100%'} className={classes.width100} display='inline-grid' flexDirection='column'>
            <Box display='flex' flexDirection='column' alignItems="center" >
                <Typography align="center" className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} ${classes.fontSize1_5} ${classes.forgotTitle}`}>
                    {t("forgotPassword")}
                </Typography>
                <img
                    alt={"..."}
                    width="200px"
                    height="120px"
                    src={sentEmail}
                />
                <Typography sx={{ textAlign: "center" }} >{t("sendingToEmailNow")} {maskedEmail} {t("resetPasswordLink")}</Typography>
                <Button onClick={handleLoginIdProcess} className={classes.buttonGreen} variant="contained">
                    {t("backToLogin")}
                </Button>
            </Box>
        </Box>
    );
}