import { useTranslation } from 'react-i18next';
//libs
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
//mui
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
//assets
import noActivity from '../../assets/noActivity.svg'
import { permanentInfo } from '../../data/permanentInfo';
//componentes
import OpenActivities from "../TribeComponents/OpenActivities";

const useStyles = makeStyles(() => ({
    limitedWidth: {
        maxWidth: '70rem'
    },
    parentLightStyle: {
        backgroundColor: '#ffffff'
    }
}));

const ParentActivities = ({ household, householdSelections, child, setKibbutzUser, addProduct, products, setOpenSummerNote }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isOverlapped = (act) => {
        let bOverlap = false;
        if (act.ItemPurposeType === "2" || act.ItemPurposeType === "4" || act.ItemPurposeType === "6") { //annual fee or Geshem activity cannot be overlapped by other activities
            return false;
        }
        try {
            // make sure current selected activity blocks overlapping activities
            const selected = householdSelections.filter(f => { return f.StudentCardCode === act.StudentCardCode && f.ActivCode !== act.ActivCode && f.ItemPurposeType !== "2" }) //same activity, don't compare
            for (let sel of selected) {
                try {
                    bOverlap = areIntervalsOverlapping(
                        {
                            start: new Date(act.StartDateD),
                            end: new Date(act.EndDateD),
                        },
                        {
                            start: new Date(sel.StartDateD),
                            end: new Date(sel.EndDateD),
                        }
                    )
                } catch (error) {
                    bOverlap = false;
                }

                if (bOverlap) {
                    return bOverlap;
                }
            }
            //make sure registered activity blocks overlapping activities
            const registered = child.ChildFactoriesPortal.results.filter(f => { return f.StudentCardCode === act.StudentCardCode && f.ActivCode !== act.ActivCode && !!f.Status_request && f.Status_request !== "0" && f.ItemPurposeType !== "2" }) //same activity, don't compare

            for (let reg of registered) {
                if (act.StudentCardCode === reg.StudentCardCode && act.ActivCode === reg.ActivCode) { //same activity, don't compare
                    continue;
                }
                if (act.ItemPurposeType === "3" && (reg.ItemPurposeType === "4" && reg.IdentActivity === "Y")) { //same activity, don't compare
                    return false;
                }
                try {
                    bOverlap = areIntervalsOverlapping(
                        {
                            start: new Date(act.StartDateD),
                            end: new Date(act.EndDateD),
                        },
                        {
                            start: new Date(reg.StartDateD),
                            end: new Date(reg.EndDateD),
                        }
                    )
                } catch (error) {
                    bOverlap = false;
                }

                if (bOverlap) {
                    return bOverlap;
                }
            }

        } catch (error) { }
        if (bOverlap === undefined) {
            bOverlap = false;
        }
        return bOverlap;
    }
    const isActDisabled = (act) => {
        const temp = household.ChildrenList?.results?.map(child => {
            return child.ChildFactoriesPortal.results;
        }).flat();
        const splPaymentReqs = temp.filter((active) => { return active?.Status_request === "3" });
        if (splPaymentReqs.length) {
            for (let i = 0; i < splPaymentReqs.length; i++) {
                if (act.TribeCode === splPaymentReqs[i].TribeCode) {
                    return true
                }
            }
        }
        let bool;
        if (products.length) {
            if (products[0].TribeCode !== act.TribeCode) {
                return true
            }
        }
        if (act.ItemPurposeType === "2" || act.ItemPurposeType === "6")
            bool = false;
        else {
            bool = disabledActivities;
        }
        return bool;
    }

    const formatActivities = (activities) => {
        return activities.map(act => {
            act.disabled = isActDisabled(act);
            act.isOverlapped = act.ItemPurposeType === "2" || act.IdentActivity === "Y" ? false : isOverlapped(act);
            act.class = permanentInfo.childClasses[act.Class];
            return act;
        })
    }
    const disabledActivities = !!child.ChildFactoriesPortal.results.find(f => { return (f.ItemPurposeType === "2" && !f.Status_request) || (f.ItemPurposeType === "2" && ["2", "3"].includes(f.Status_request)) });

    const activities = child && formatActivities(child.ChildFactoriesPortal.results.filter(f => { return !f.Status_request || f.Status_request === "1" || (f.Status_request && f.ItemPurposeType === "4" && !f.Father) }));

    const onlyGeshemRoot = child && child.ChildFactoriesPortal.results.filter(activ => activ.ItemPurposeType === '4')?.every(act => !act.Father) && !child.ChildFactoriesPortal.results.filter(activ => activ.ItemPurposeType === '2' || activ.ItemPurposeType === '3');

    const annualFee = child && child.ChildFactoriesPortal.results.find(f => { return (f.ItemPurposeType === "2" && !f.Status_request) || (f.ItemPurposeType === "2" && f.Status_request !== "4") });

    return (
        child &&
        <Box pb={10} sx={{ marginLeft: { xs: '.5rem', lg: '0' }, marginTop: "1rem" }} className={classes.limitedWidth}>
            <Box display="flex" alignItems="flex-start" flexDirection="column" >
                <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" justifyContent="center">
                    <FollowTheSignsIcon sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }} />
                    <Typography m="0 .5rem" variant="mediumDarkGreyBoldRes" >{`${t("openActivitiesForRegistration")}- ${child.Pupil_FirstName}- ${t("tribe")} ${child?.BPLName?.slice(5)}`}</Typography>
                    {!!activities.length && annualFee && <Typography m="0 .5rem" variant="smallRedText" >{t("ifActBlocked")}</Typography>}
                    {!!activities.length && !!products.length && products[0].TribeCode !== child.DflWhs && <Typography m="0 .5rem" variant="smallRedText" >{t("diffTribes")}</Typography>}
                </Box>
            </Box>
            <Box p={1} className={classes.parentLightStyle} >
                {activities.length && !onlyGeshemRoot ?
                    <OpenActivities
                        activities={activities}
                        isParent={true}
                        setKibbutzUser={setKibbutzUser}
                        addProduct={addProduct}
                        household={household}
                        setOpenSummerNote={setOpenSummerNote}
                    />
                    :
                    <Box display="flex" flexDirection="column" alignItems="center" p={1} >
                        <img
                            style={{ marginTop: "1rem" }}
                            width="200px"
                            height="120px"
                            alt={"..."}
                            src={noActivity} />
                        <Typography m="0 .5rem" variant="mediumDarkGreyBoldRes" >{t("noActivites")}</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}
export default ParentActivities;