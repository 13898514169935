import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Typography, Dialog, DialogActions, DialogTitle, Container, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, IconButton, useMediaQuery } from '@mui/material';
import { HBox, VBox } from '../../assets/SharedComponents';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import verticalRope from '../../assets/verticalRope.svg'
import ContactDialog from 'Components/Common/ContactDialog';

const useStyles = makeStyles(() => ({
    dialogText: {
        fontSize: "1.5rem",

    },
    tableHeadRow: {
        fontWeight: "bold !important"
    },
    hiddenXs: {
        '@media (max-width: 576px)': {
            display: "none"
        }
    },
    hiddenMd: {
        height: "5rem",
        '@media (min-width: 577px)': {
            display: "none"
        }
    },
    tableRow: {
        "& td": {
            padding: '.25rem 0'
        }
    },
    LinkStyle: {
        paddingInlineStart: '0.3em',
        textDecoration: 'underline !important',
        color: '#007f00',
        fontSize: '0.7rem !important',
        cursor: 'pointer',
        fontFamily: "Alef,sans-serif !important"
    }
}));
const SystemRequirementsDialog = ({ open, setOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery('(max-width:400px)');
    const [openContact, setOpenContact] = useState(false);
    // Get User Agent String
    const userAgent = navigator.userAgent;

    // Get Operating System
    let operatingSystem = "Unknown OS";
    if (userAgent.indexOf("Windows NT 10.0") !== -1) operatingSystem = "Windows 10";
    if (userAgent.indexOf("Windows NT 6.3") !== -1) operatingSystem = "Windows 8.1";
    if (userAgent.indexOf("Windows NT 6.2") !== -1) operatingSystem = "Windows 8";
    if (userAgent.indexOf("Windows NT 6.1") !== -1) operatingSystem = "Windows 7";
    if (userAgent.indexOf("Windows NT 6.0") !== -1) operatingSystem = "Windows Vista";
    if (userAgent.indexOf("Windows NT 5.1") !== -1) operatingSystem = "Windows XP";
    if (userAgent.indexOf("Windows NT 5.0") !== -1) operatingSystem = "Windows 2000";
    if (userAgent.indexOf("Mac") !== -1) operatingSystem = "Macintosh";
    if (userAgent.indexOf("X11") !== -1) operatingSystem = "UNIX";
    if (userAgent.indexOf("Linux") !== -1) operatingSystem = "Linux";

    // Get Browser Language
    const browserLanguage = navigator.language;

    // Get Screen Resolution
    const screenResolution = `${window.screen.width} * ${window.screen.height}`;
    const screenResoObj = { width: window.screen.width, height: window.screen.height };
    // Check if Cookies Enabled
    const cookiesEnabled = navigator.cookieEnabled;


    const browserName = (function () {
        const agent = navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'Microsoft Edge';
            case agent.indexOf('opr') > -1 && !!window.opr:
                return 'Opera';
            case agent.indexOf('chrome') > -1 && !!window.chrome:
                return 'Google Chrome';
            case agent.indexOf('safari') > -1 && !!window.safari:
                return 'Safari';
            case agent.indexOf('firefox') > -1:
                return 'Mozilla Firefox';
            case agent.indexOf('trident') > -1:
                return 'Microsoft Internet Explorer';
            default:
                return 'Unknown';
        }
    })();


    const rows = [{
        rowName: t('osType'),
        portalReq: t('osReq'),
        userDevice: operatingSystem,
        isMatch: () => {
            let words = operatingSystem.split(" ");
            let version = words[words.length - 1];
            return operatingSystem.includes("Windows") && parseInt(version) >= 7 || operatingSystem.includes("Mac") ? true : false
        }
    },
    {
        rowName: t('osLang'),
        portalReq: t('osLangReq'),
        userDevice: browserLanguage,
        isMatch: () => {
            return browserLanguage.includes("HE") || browserLanguage.includes("EN") ? true : false
        }
    },
    {
        rowName: t('browserType'),
        portalReq: t('browserTypeReq'),
        userDevice: browserName,
        isMatch: () => {
            return browserName.includes("Chrome") || browserName.includes("Safari") || browserName.includes("Edge") ? true : false
        }
    },
    {
        rowName: t('cookie'),
        portalReq: t('activeCookie'),
        userDevice: cookiesEnabled ? t('activeCookie') : t('notActiveCookie'),
        isMatch: cookiesEnabled
    },
    {
        rowName: t('reso'),
        portalReq: t('resoReq'),
        userDevice: screenResolution,
        isMatch: () => {
            return screenResoObj.width < 1280 && screenResoObj.height < 720
        }
    },
    ]
    const openSupport = () => {
        setOpenContact(true);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={e => { setOpen(false) }}
                PaperProps={{
                    sx: { borderRadius: "40px !important", backgroundColor: "#f0f3f0" }
                }}
                maxWidth={"100%"}
            >
                <Container sx={{ alignItems: "center", width: { xs: "18rem", md: "100%" }, height: { xs: "46rem", md: "100%" } }} >
                    <DialogActions>
                        <IconButton onClick={e => { setOpen(false) }} disableRipple={true}>
                            <CloseOutlinedIcon sx={{ fontSize: "2rem", color: "#3a7735" }} />
                        </IconButton>
                    </DialogActions>
                    <HBox justifyContent={"center"}>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            style={{ transform: "rotate(270deg)" }}
                            className={classes.hiddenMd}
                        />
                    </HBox>
                    <HBox justifyContent={"space-between"}>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            height="142px"
                            className={classes.hiddenXs}
                        />
                        <VBox alignItems={"center"}>
                            <DialogTitle>
                                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.9rem", md: "2rem" } }}>{t('SystemRequirements')}</Typography>
                            </DialogTitle>
                            <VBox alignItems={"right !important"}>
                                <Typography sx={{ fontSize: { xs: "0.6rem", md: "0.8rem" } }}>{t('SystemRequirements1')}</Typography>
                                <Typography sx={{ fontSize: "0.8rem", color: "#7f7f7f", display: { xs: "none", md: "block" } }}>{t('SystemRequirements2')}</Typography>
                            </VBox>
                        </VBox>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            height="142px"
                            className={classes.hiddenXs}
                        />
                    </HBox>

                    <VBox alignItems={"center"} sx={{ padding: "1rem 0" }}>

                        <TableContainer component={Paper} sx={{ backgroundColor: "#f0f3f0", boxShadow: "none" }} >
                            <Table aria-label="simple table" size={matches ? "small" : "medium"}>
                                <TableHead>
                                    <TableRow className={matches ? classes.tableRow : ""}>
                                        <TableCell className={classes.tableHeadRow} sx={{ display: { xs: "none", md: "table-cell" } }}>{t('component')}</TableCell>
                                        <TableCell align="right" className={classes.tableHeadRow}>{t('portalReq')}</TableCell>
                                        <TableCell align="right" className={classes.tableHeadRow}>{t('yourDevice')}</TableCell>
                                        <TableCell align="right" className={classes.tableHeadRow}>{t('isMatch')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow className={matches ? classes.tableRow : ""}
                                            key={row.rowName}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ display: { xs: "none", md: "table-cell" } }}> {row.rowName} </TableCell>
                                            <TableCell align="right">{row.portalReq}</TableCell>
                                            <TableCell align="right">{row.userDevice}</TableCell>
                                            <TableCell align="right">{row.isMatch ? <TaskAltRoundedIcon sx={{ color: "#3a7735" }} /> : <HighlightOffIcon sx={{ color: "#d9001b" }} />}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </VBox>
                    <HBox justifyContent={"space-between"}>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            height="142px"
                            className={classes.hiddenXs}

                        />
                        <VBox alignItems={"right !important"}>
                            <HBox>
                                <TaskAltRoundedIcon sx={{ color: "#3a7735" }} />
                                <Typography sx={{ fontSize: "0.8rem", color: "#7f7f7f" }}>{t('isOkReq')}</Typography>
                            </HBox>
                            <HBox>
                                <HighlightOffIcon sx={{ color: "#d9001b" }} />
                                <Typography sx={{ fontSize: "0.8rem", color: "#7f7f7f" }}>{t('notOkReq')}</Typography>
                            </HBox>
                        </VBox>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            height="142px"
                            className={classes.hiddenXs}

                        />
                    </HBox>
                    <HBox justifyContent={"center"}>
                        <img
                            alt={"..."}
                            src={verticalRope}
                            width="23px"
                            style={{ transform: "rotate(270deg)" }}
                            className={classes.hiddenMd}
                        />
                    </HBox>
                    <HBox justifyContent="flex-end" marginBottom="1rem">
                        <Typography className={`${classes.LinkStyle}`} onClick={e => openSupport()} style={{ fontSize: "1.3rem" }}>
                            {t("needHelp")}
                        </Typography>
                    </HBox>

                </Container>

            </Dialog >
            {openContact &&
                <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}
        </>
    )
}
export default SystemRequirementsDialog;