import { useEffect } from 'react'
import { useStyles } from '../../assets/LoginStyle'
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCountDown from 'react-countdown-hook';

const ReLinkCounter = ({ setVisible }) => {
    const classes = useStyles();
    const initialTime = 30 * 1000;
    const interval = 1000;
    const [timeLeft, { start }] = useCountDown(initialTime, interval);
    const { t } = useTranslation();

    useEffect(() => {
        start();
    }, [])
    setTimeout(function () {
        setVisible(true)
    }, 30001)
    return (
        <>
            <Typography sx={{ fontSize: "0.875rem !important" }} className={`${classes.CenterItems} ${classes.LinkStyle} ${classes.paddingBottom1rem}`}>{t("resendAuthCode")} {(timeLeft / 1000)} {t("seconds")}</Typography>
        </>
    )
}
export default ReLinkCounter;
