import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useGlobal from "../../../store/store";

import { Button, Typography, IconButton, Input, Box } from '@mui/material';
import { VBox, HBox } from '../../../assets/SharedComponents';

import regReq from '../../../assets/regReq.png'
import ParentCartHeader from './ParentCartHeader';
import { addSplitPaymentLinkAPI } from 'api/api';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const useStyles = makeStyles(() => ({
    marginTop: {
        marginTop: '0.5rem !important'
    },
    boldText: {
        color: 'black !important',
        textAlign: 'center',
        fontWeight: 'bold !important',
        fontSize: '1.1rem !important',
        margin: '1rem !important'
    },
    backgroundLink: {
        backgroundColor: '#e6efe6 !important',
        border: '1px solid #3a7735 !important',
        height: '2.75rem !important',
        width: '80% !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        borderRadius: '10px !important'
    },
    link: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: 'black !important',
            '&.MuiInput-underline.Mui-disabled:before': {
                borderBottomStyle: "none !important"
            }
        },
        fontWeight: 'bold !important'
    },
    buttonsGroup: {
        alignItems: 'flex-start !important',
        justifyContent: 'space-evenly !important',
        alignContent: 'space-between !important',
        marginTop: '0.5rem !important',
    },
    buttons: {
        "&:hover": {
            background: '#3a7735 !important',
        },
        margin: '1.5rem !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '50px !important',
        boxShadow: '6px 6px 10px 0px rgba(0, 0, 0, 0.16) !important'
    },
    buttonAndText: {
        textAlign: 'center !important'
    },
    textUnderButtons: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        color: '#3a7735 !important',
        fontSize: '1.3 !important'
    },
    yellowButton: {
        "&:hover": {
            background: '#ffbb41 !important',
        },
        backgroundColor: '#ffbb41 !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#064601 !important',
        fontSize: '1.2rem !important',
        fontWeight: "bold !important",
        borderRadius: '50px !important',
        marginTop: "2rem !important",
    }
}));

const SplitPaymentApproval = ({ products, onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [link, setLink] = useState('');
    const [finalPrice] = useGlobal(
        (state) => { return [state.finalPrice] });

    const [user] = useGlobal((state) => state.user)

    useEffect(() => {
        const idsArray = products.map((pr) => {
            return pr._id
        })
        setLink(`${window.location.origin}/spl/${idsArray}`);
        setIsPaid(true);
        addToMongoLink();
    }, []);

    const addToMongoLink = async () => {
        const activitiesCodes = [];
        const productsIds = [];
        products.forEach((pr) => {
            activitiesCodes.push(pr.ActivCode);
            productsIds.push(pr._id);
        });
        const linkDetails = {
            paymentLink: `${window.location.origin}/spl/${productsIds}`,
            parentCardCode: products[0].ParentCardCode,
            activitiesCodes,
            productsIds,
            originalPrice: finalPrice.toString(),
            leftToPay: (finalPrice / 2).toFixed(2).toString(),
            userEmail: user.email,
            userPhoneNumber: user.phone_number,
            userFullName: `${user.firstName} ${user.lastName}`,
            tribeCode: products[0].TribeCode,
            date: new Date().toString()
        }
        await addSplitPaymentLinkAPI(linkDetails);
    }

    return (
        <>
            <ParentCartHeader title={t('paymentApproval')} onCloseCart={onCloseCart} />
            <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center'>
                <img
                    alt={"..."}
                    src={regReq}
                    width="200px"
                    height="200px"
                />

                <Typography sx={{ color: "#3a7735", textAlign: 'center' }}>{`${t('splitApprovalText5')} ${t('splitApprovalText2')}`} </Typography>
                <Typography sx={{ color: "#b34730", fontSize: "1.1rem", fontWeight: "bold", textAlign: 'center' }}>{t('approveAfterPayment')}</Typography>

                <Typography className={classes.boldText}>{t('linkTo')}: {(finalPrice / 2).toFixed(2)} {t("ILS")}</Typography>
                <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center' width='100%'>
                    <Box flexDirection='row' display='flex' justifyContent='center' alignItems='center' className={classes.backgroundLink}>
                        <Input
                            sx={{ textAlign: 'center !important' }}
                            disabled
                            className={classes.link}
                            fullWidth={true}
                            value={link} />
                    </Box>
                </Box>
                <ShareButtons link={link} />
                <Button className={classes.yellowButton} onClick={e => onCloseCart(e)}>{t('backToHomePage')}</Button>
            </Box>
        </>
    );
}
export const ShareButtons = ({ link, unlockText = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const whatsappText = unlockText ? "שלום ברצוני לשתף איתך קישור להרשמה מאוחרת לפעילות בפורטל ההורים" : "שלום ברצוני לשתף איתך קישור להשלמת רכישת פעילויות בפורטל הצופים";
    const whatsappLink = `https://api.whatsapp.com/send?text=${whatsappText}- ${link} `;

    const copyLink = () => {
        navigator.clipboard.writeText(link)
        const key = enqueueSnackbar(t('copiedLink'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500)
    }
    return (
        <HBox className={classes.buttonsGroup}>
            <VBox className={classes.buttonAndText} >
                <IconButton className={classes.buttons} onClick={e => copyLink()}>
                    <ContentCopyOutlinedIcon sx={{ color: '#ffffff' }} />
                </IconButton>
                <Typography className={classes.textUnderButtons}>{t('copy')}</Typography>
            </VBox>
            <VBox className={classes.buttonAndText}>
                <IconButton className={classes.buttons} onClick={e => window.open(`mailto:?subject=${unlockText ? whatsappText : t("paymentLinkFromScoutsSystem")}&body=${link}`, "_blank")} >
                    <MailOutlinedIcon sx={{ color: '#ffffff' }} />
                </IconButton>
                <Typography className={classes.textUnderButtons}>{t('sendBy')} <br></br>{t('email')}</Typography>
            </VBox>
            <VBox className={classes.buttonAndText}>
                <IconButton className={classes.buttons} onClick={e => window.open(whatsappLink)}>
                    <WhatsAppIcon sx={{ color: '#ffffff' }} />
                </IconButton>
                <Typography className={classes.textUnderButtons}>{t('share')}-<br></br>Whatsapp</Typography>
            </VBox>
        </HBox >
    )
}
export default SplitPaymentApproval;