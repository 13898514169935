import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'assets/LoginStyle';
import { Box, Typography } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import { LanguagePicker } from 'Components/Common/LanguagePicker.jsx';
import Logo from 'assets/Logo.png'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import SystemRequirementsDialog from './SystemRequirementsDialog';

import ContactDialog from 'Components/Common/ContactDialog';

const LoginPageHeadr = ({ tribeTab, backToSMS, tribeUserTab, guest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [openContact, setOpenContact] = useState(false);
    const [openSettings, setOpentSettings] = useState(false);
    return (
        <Box sx={{ height: '30%' }} display="flex" flexDirection="column" >
            <Box className={`${classes.padding0_7} ${classes.spaceBetween}`} display="flex" flexDirection="row"  >
                {!guest && <>
                    {tribeUserTab ?
                        <LoginIconAndText func={backToSMS} icon={<Groups2OutlinedIcon sx={{ cursor: 'pointer', color: 'white', fontSize: "28px" }} />} text={t("parentLogin")} />
                        :
                        <LoginIconAndText func={tribeTab} icon={<PersonOutline sx={{ cursor: 'pointer', stroke: "#ffffff", strokeWidth: 0.2, color: 'white', fontSize: "28px", border: '2px solid' }} />} text={t("tribalLogin")} />
                    }</>}
                <Box className={`${classes.padding0_7}`} display="flex" flexDirection="row" >
                    <VIconAndText func={e => setOpentSettings(true)} icon={<SettingsSuggestIcon sx={{ color: "#ffffff", cursor: 'pointer', }} />} text={t("settingsSuggest")} />
                    <VIconAndText func={e => setOpenContact(true)} icon={<SupportAgentIcon sx={{ color: "#ffffff", cursor: 'pointer' }} />} text={t("anyProblem")} />
                    <LanguagePicker />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
                <img
                    alt={"..."}
                    className={classes.LogoSize}
                    src={Logo}
                    loading="lazy"
                />
                <Typography sx={{ fontWeight: "bold", color: "#ffffff", fontSize: "1.5rem" }}>{guest ? t("guestTitle") : t("theParentsPortal")}</Typography>
            </Box>
            {openContact &&
                <ContactDialog openContact={openContact} setOpenContact={setOpenContact} />}
            {openSettings &&
                <SystemRequirementsDialog open={openSettings} setOpen={setOpentSettings} />}


        </Box>)
}

const LoginIconAndText = ({ func, icon, text }) => {
    return (
        <Box display="flex" flexDirection="row" onClick={e => func(e)} height="fit-content" alignItems="flex-end">
            {icon}
            <Typography sx={{ cursor: 'pointer', color: "#ffffff", fontSize: "1rem", textDecoration: "underline", marginLeft: "1rem", visibility: { xs: "hidden", sm: "visible" } }}> {text}</Typography>
        </Box>
    )
}
export const VIconAndText = ({ func, icon, text }) => {
    return (
        <Box onClick={e => func(e)} display="flex" alignItems="center" flexDirection="column" height="fit-content" marginRight={{ xs: "0.8rem", sm: "2rem" }} width="5rem">
            {icon}
            <Typography sx={{ color: "#ffffff", fontSize: "0.7rem", textAlign: "center" }}> {text}</Typography>
        </Box>
    )
}

export default LoginPageHeadr;