export default () => {

    const initialize = (sGoogleId) => {

        const script = document.createElement("script");
        var configParams = {};

        script.type = "text/javascript";
        script.setAttribute("async", "true");
        script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + sGoogleId);
        script.setAttribute("crossOrigin", "anonymous");

        document.documentElement.firstChild.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        // configParams = {
        //     user_id: "user id may sent here",
        //     dimension1: "customDimensionValue such as user id may sent here"
        // };

        gtag('config', sGoogleId
            // , configParams
        );

        // Report page view each hash change
        // window.addEventListener('hashchange', function () {

        //     window.dataLayer = window.dataLayer || [];
        //     function gtag() { window.dataLayer.push(arguments); }
        //     var path = window.location.pathname + window.location.search;

        //     gtag('set', 'page_path', path);
        //     // gtag('event', 'page_view', {
        //     //     // dimension1: "customDimensionValue such as user id may sent here"
        //     // });

        // }, false);

        return gtag;

    }

    return {
        initialize
    };
};


