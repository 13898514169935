import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/locales/config';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { system } from "./api/client"

const recaptchaRef = React.createRef();

ReactDOM.render(
  <React.StrictMode>
    <ReCAPTCHA
      ref={recaptchaRef}
      badge="bottomleft"
      size="invisible"
      sitekey={process.env[`REACT_APP_RECAPTCHA_SITE_KEY_${system.toUpperCase()}`]}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

