import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { Button, Container, Typography, Box } from '@mui/material';

import { UpdateRegAPI } from '../../../api/api';
import useApi from '../../../hooks/useApi';
import useGlobal from "../../../store/store"
import regReq from '../../../assets/regReq.png'

const useStyles = makeStyles(() => ({
    text: {
        color: '#064601 !important',
        textAlign: 'center !important',
        fontSize: '1.3rem !important'
    },
    boldText: {
        fontWeight: 'bold !important',
        textAlign: 'center !important',
        color: '#064601 !important',
        fontSize: '1.3rem !important'
    },
    yellowButton: {
        "&:hover": {
            background: '#ffbb41 !important',
        },
        backgroundColor: '#ffbb41 !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#064601 !important',
        fontSize: '1.1rem !important',
        borderRadius: '50px !important',
        fontWeight: "bold !important",
    },
    underLineText: {
        "&:hover": {
            fontWeight: 'bold !important',
            textDecoration: 'underline !important',
            cursor: 'pointer !important'
        },
        textDecoration: 'underline !important',
        fontSize: '1.1rem !important',
        color: '#007f00 !important'
    }
}));

const CashAndCheckApproval = ({ setLevel, onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const updateStatus = useApi(UpdateRegAPI)

    const [products] = useGlobal(
        (state) => state.products);
    //update the requests status to "not register"=1
    const updateReqStatus = () => {
        products.map(async (product) => {
            product.Status_request = "1";
            await updateStatus.requestPromise({ _id: product._id, data: { Status_request: '1' } })
        })
        setLevel(3)
    }
    useEffect(() => {
        setIsPaid(true)
    }, [])
    return (
        <Container>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Box display='flex' flexDirection='column' marginTop="2rem">
                    <img
                        alt={"..."}
                        src={regReq}
                        width="200px"
                        height="200px"
                    />

                    <Typography className={classes.boldText}>{t('regReqCreated')}</Typography>
                </Box>
                <Box display='flex' flexDirection='column' marginTop="2rem" alignItems='center'>
                    <Typography className={classes.boldText}>{t('payAttention')}</Typography>
                    <Typography className={classes.text}>{t('regReqCreated1')}</Typography>
                    <Typography className={classes.text}>{t('extraPayment')}</Typography>
                    <Button className={classes.yellowButton} onClick={e => onCloseCart(e)} sx={{ marginTop: "1.5rem" }} >{t('understandGoHomePage')}</Button>
                    <Typography className={classes.underLineText} onClick={e => updateReqStatus()} sx={{ marginTop: "1rem" }}>{t('otherPaymentMethod')}</Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default CashAndCheckApproval;