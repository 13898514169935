import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns'

import { Button, Typography, Card, Container } from '@mui/material';
import { VBox, HBox } from '../../../assets/SharedComponents';
import { addPaymentHana } from '../../../api/api'

import splitPay from '../../../assets/splitPay.svg'
import approvalPay from '../../../assets/approvalPay.png'
import failedTransc from '../../../assets/failedTransc.svg'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MakeTransaction from '../../Payments/MakeTransaction';
import useGlobal from "../../../store/store"
import { system } from '../../../api/client'
import GenericDialog from "Components/Common/GenericDIalog";

const useStyles = makeStyles(() => ({
    cardBox: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginTop: '2rem !important'
    },
    splitCard: {
        justifyContent: 'center !important',
        marginBottom: '1rem !important',
        borderRadius: '50px !important'
    },
    cardTitleAndIcons: {
        justifyContent: 'space-between !important'
    },
    cardTitleAndIcon: {
        alignItems: 'center !important'
    },
    cardTitle: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        marginRight: '1rem !important'
    },
    text: {
        color: '#064601 !important',
        textAlign: 'center !important',
        fontSize: '1rem !important'
    },
    boldText: {
        fontWeight: 'bold !important',
        textAlign: 'center !important',
        color: '#064601 !important',
        fontSize: '1.2rem !important'
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#3a7735 !important',
        marginTop: '2rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#ffffff !important',
        fontSize: '1rem !important'
    },
    whiteButton: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#ffffff !important',
        marginTop: '1rem !important',
        border: '1px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '14.75rem !important'
    },
    underLineText: {
        "&:hover": {
            fontWeight: 'bold !important',
            textDecoration: 'underline !important',
            cursor: 'pointer !important'
        },
        textDecoration: 'underline !important',
        fontSize: '1rem !important',
        color: '#007f00 !important'
    },
    smallText: {
        textAlign: 'center !important',
        fontSize: '0.875rem !important',
        color: '#007f00 !important'
    }
}));

const SplitPayment = ({ setLevel, onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [paymentScreen, setPaymentScreen] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [sapErrorPayment, setSapErrorPayment] = useState(false);

    const [household] = useGlobal(
        (state) => state.household);
    const [products] = useGlobal(
        (state) => state.products);
    const [payByExtra] = useGlobal(
        state => state.payByExtra);
    const [payWithAllExtra] = useGlobal(
        (state) => state.payWithAllExtra);
    const [finalPrice] = useGlobal(
        (state) => state.finalPrice);
    const splitPaymentApprovalLevel = 7;
    const today = format(new Date(), 'yyyy-MM-dd');

    //listener for credit guard ifram status ,after user paid collecting data from transaction and post it to SAP 
    useEffect(() => {
        const handler = async (...args) => {
            if (args?.length) {
                try {
                    if (payByExtra && !payWithAllExtra && args[0].data.response?.Status) {
                        const extraPaymentDetails =
                        {
                            Guid: args[0].data.response?.Guid,
                            ParentCardCode: household.CardCode,
                            UnitCode: products[0].TribeCode,
                            PaymentDate: today,
                            PaymentExecutionType: "10"
                        }
                        if (system !== "prod") {
                            extraPaymentDetails.PaymentPercent = 50;
                            extraPaymentDetails.InvoiceNum = "0";
                        }
                        const res1 = await addPaymentHana(extraPaymentDetails);
                    }
                    if (args[0].data.error?.CGError) {
                        setOpenErrorDialog(true);
                        return;
                    }
                    if (args[0].data.error?.sapInvoiceError || args[0].data.error?.sapRegError || args[0].data.error?.mongoDBError || args[0].data.error?.transactionMongoDBError) {
                        console.log(args[0].data?.error);
                        setSapErrorPayment(true);
                        setOpenErrorDialog(true);
                        return;
                    }
                    if (args[0].data.response?.Status) {
                        setLevel(splitPaymentApprovalLevel);
                    }

                } catch (error) {
                    console.log(error);
                }
            }
        }
        window.addEventListener("message", handler);
        return () => {

            window.removeEventListener("message", handler);
        }
    }, []);

    const handleCloseButton = (e) => {
        setIsPaid(true);
        setSapErrorPayment(false);
        setOpenErrorDialog(false);
        onCloseCart(e);
    }
    const handleGoToButton = () => {
        setOpenErrorDialog(false);
        setLevel(3);
    }
    return (
        <Container>
            {!paymentScreen ?
                <VBox className={classes.cardBox}>
                    <Card className={classes.splitCard} sx={{ height: { xs: "40rem", md: "42rem" }, width: { xs: "16rem", md: "24rem" } }} >
                        <Container style={{ marginTop: '1rem', textAlign: 'center' }}>
                            <HBox className={classes.cardTitleAndIcons}>
                                <HBox className={classes.cardTitleAndIcon}>
                                    <Typography className={classes.cardTitle}>{t('splitPaymentTitle')} </Typography>
                                </HBox>
                                <CloseOutlinedIcon onClick={e => setLevel(3)} style={{ cursor: "pointer" }} />
                            </HBox>

                            <VBox alignItems={"center"}>
                                <img
                                    alt={"..."}
                                    src={splitPay}
                                    width="200px"
                                    height="200px"
                                />
                                <Typography className={classes.boldText}>{t('splitPaymentText1')} </Typography>
                                <Typography className={classes.text}>{t('splitPaymentText2')} </Typography>
                                <Typography className={classes.text}>{t('splitPaymentText3')} </Typography>
                                <Typography className={classes.text} sx={{ marginTop: "1rem" }}>{t('splitPaymentText4')} </Typography>
                                <Button className={classes.greenButton} onClick={e => setPaymentScreen(true)}>{t('okSplitPayment')}</Button>
                                <Button className={classes.whiteButton} onClick={e => setLevel(3)}>{t('otherPay')}</Button>
                            </VBox>
                        </Container>
                    </Card>
                </VBox> :
                <VBox>
                    <Typography className={classes.underLineText} sx={{ alignSelf: "center !important" }} onClick={e => setLevel(3)}>{t('otherPaymentMethod')}</Typography>
                    <MakeTransaction divorcedParent={true} />
                    <GenericDialog open={openErrorDialog} setOpen={setOpenErrorDialog} showRopes={false} height={"32rem"} showExit={false} >
                        {sapErrorPayment ?
                            <>
                                <img
                                    alt={"..."}
                                    src={approvalPay}
                                    width="150px"
                                    height="150px"
                                />
                                <Typography color="#064601" mt="1rem" variant="mediumBoldText">{t('paymetSuccess')}</Typography>
                                <Typography color="#064601" mt="1rem" sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>{t('regInNextHours')} </Typography>
                                <Button className={classes.greenButton} onClick={e => handleCloseButton(e)}>{t('whatAfun')}</Button>
                                <Typography mt="3rem" className={classes.smallText}>{t('emailToPayment')} </Typography>
                            </>
                            : <>
                                <img
                                    alt={"..."}
                                    src={failedTransc}
                                    width="150px"
                                    height="150px"
                                />
                                <Typography color="#bb0000" mt="1rem" variant="mediumBoldText">{t('cardFaild')}</Typography>
                                <Typography color="#bb0000" mt="1rem" sx={{ textAlign: "center", fontWeight: "400" }}>{t('notCharged')}</Typography>
                                <Typography mt="3rem" className={classes.smallText}>{t('tryAgainInCard')} </Typography>
                                <Button className={classes.greenButton} onClick={e => handleGoToButton()}>{t('goToOtherPayment')}</Button>
                            </>}
                    </GenericDialog>
                </VBox>}
        </Container >
    );
}

export default SplitPayment;