import React, { useState, useEffect } from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    messageboxStyle: {
        "& .MuiDialog-paper": {
            borderRadius: "20px !important;"
        },
        "& .MuiDialogActions-root ": {
            justifyContent: "center"
        }
    }
}));

export const MessageBox = function ({ messageBoxState, setMessageBox }) {
    const classes = useStyles();
    const handleClose = () => {
        setMessageBox({ open: false });
    };
    // 
    return (
        <Dialog
            onClose={messageBoxState.customOnClose ? messageBoxState.customOnClose : handleClose}
            open={messageBoxState.open}
            className={classes.messageboxStyle}
            PaperProps={{
                sx: { width: { xs: "20rem", md: "45rem" }, borderRadius: "50px", maxWidth: "max-content", backgroundColor: "#f0f3f0" }
            }}
        >
            {
                messageBoxState.title &&
                <DialogTitle>
                    {messageBoxState.title}
                </DialogTitle>
            }
            {
                messageBoxState.content &&
                <DialogContent>
                    {messageBoxState.content}
                </DialogContent>
            }
            {
                messageBoxState.actions &&
                <DialogActions >
                    {messageBoxState.actions}
                </DialogActions>
            }
        </Dialog >
    );
}


