import { useState } from 'react'
import { useTranslation } from 'react-i18next';
//mui
import { Typography, Box, Tab } from '@mui/material';
import { MuiTabs } from 'pages/parentHistoryPages/sharedHistoryComponents/HistoryComponents'
//assets
import notSameTribe from '../../assets/notSameTribe.svg'
//components
import * as Styled from "../Common/StyledComponents";
import GenericDialog from 'Components/Common/GenericDIalog';

const ChildrenTabBar = ({ household, currentTab, setSelectedChild, products, setProducts, removeHouseholdSelection }) => {
    const { t } = useTranslation();
    const [openDialogPay, setOpenDialogPay] = useState(false);
    const [moveToName, setMoveToName] = useState("");
    const [moveToTab, setMoveToTab] = useState(0);

    const childList = household?.ChildrenList?.results?.filter(ch => ch.PupilStatus === "Y");

    const productsInCartConfirm = () => {
        try {
            products && products.forEach(p => {
                removeHouseholdSelection(p);
            });
            products && setProducts([]);
            setSelectedChild(moveToTab);
        } catch (error) { }
        setOpenDialogPay(false);
    };

    const productsInCartDecline = () => {
        setOpenDialogPay(false);
    };

    const handleTabChange = (event, newValue) => {
        if (!newValue) {
            setSelectedChild(newValue);
            return;
        }
        // child selected
        if (products?.length > 0 && products[0].TribeCode !== childList[newValue - 1]?.DflWhs) { //products in cart are from different tribe
            setMoveToName(childList[newValue - 1]?.Pupil_FirstName);
            setMoveToTab(newValue);
            setOpenDialogPay(true);
            return;
        }
        setSelectedChild(newValue);
    };

    return (
        <Box display="flex" flexDirection="row" alignItems="center" paddingTop="1rem">
            <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, fontWeight: "bold", paddingLeft: { xs: "1rem" } }}>{`${t("familyOf")} ${household.Parent1_LastName}`}</Typography>
            <MuiTabs
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto">
                {childList.length !== 1 && <Tab label={t('everyone')} key={`ChildTabEveryone}`} />}
                {childList.map((child, i) => (
                    <Tab label={child.Pupil_FirstName} key={`ChildTab-${i}`} disabled={child.PupilStatus === "N"} />
                ))}
            </MuiTabs>
            <GenericDialog open={openDialogPay} setOpen={setOpenDialogPay} iconSVG={notSameTribe} showRopes={true} height="23rem">
                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem", color: "#3a7735", textAlign: "center" }}> {`${t("diffTribePop")}`}</Typography>
                <Typography sx={{ color: "#3a7735", textAlign: "center" }}>{t("diffTribePop1")}</Typography>
                <Styled.RoundedButton variant="contained" onClick={productsInCartDecline} sx={{ width: "15rem" }}>
                    {`${t("understandGoBack")} ${t("to")}${products[0]?.Pupil_FirstName}`}
                </Styled.RoundedButton>
                <Styled.RoundedButton variant="outlined" onClick={() => { productsInCartConfirm() }} sx={{ width: "15rem" }}>
                    {`${t("understandEmptyCart")} ${t("to")}${moveToName}`}
                </Styled.RoundedButton>
            </GenericDialog>
        </Box>
    )
}
export default ChildrenTabBar;