import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import useGlobal from 'store/store';
import { Button, Typography, Box } from '@mui/material';
import ParentCartHeader from './Parent/ParentCartHeader';
import approvalPay from '../../assets/approvalPay.png'
import donate from '../../assets/donate.svg';
import { updatePreferences } from 'api/api';
import useApi from 'hooks/useApi';
import { getAuth } from "firebase/auth";

const useStyles = makeStyles(() => ({
    title: {
        color: '#3a7735 !important',
        textAlign: 'right !important',
        margin: '1rem !important',
        fontSize: '1.5rem !important'
    },
    content: {
        textAlign: "center !important",
        fontWeight: "bold !important",
        marginTop: "2rem !important",
        color: "#064601 !important",
    },
    content1: {
        textAlign: "center !important",
        color: "#064601 !important",
        marginTop: "1rem !important",
    },
    yellowButton: {
        "&:hover": {
            background: '#ffbb41 !important',
        },
        backgroundColor: '#ffbb41 !important',
        width: '14.75rem !important',
        color: '#064601 !important',
        fontSize: '1.2rem !important',
        fontWeight: "bold !important",
        borderRadius: '50px !important',
        marginTop: "2rem !important",
    },
    buttonWhite: {
        margin: "0.5rem !important",
        color: '#3a7735 !important',
        backgroundColor: 'white !important',
        border: '2px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '12rem !important',
        textTransform: "capitalize !important",
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)'
    },
    buttonGreen: {
        margin: "0.5rem !important",
        width: '12rem !important',
        color: 'white !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '50px !important',
        textTransform: "capitalize !important",
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)'
    },
    linkStyle: {
        color: '#064601 !important',
        fontSize: "0.875rem !important",
        paddingInlineStart: '0.3em !important',
        textDecoration: 'underline !important',
        fontWeight: 'bold !important',
        cursor: 'pointer !important'
    }
}));

const ApprovalPayment = ({ onCloseCart, setIsPaid }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [user] = useGlobal(state => state.user);

    useEffect(() => {
        setIsPaid(true);
    }, []);

    //this component handle 2 situations one regular approval for tribe and parent who dont want to donate and another one with donation iframe
    return (
        <>
            <ParentCartHeader title={t('paymentApproval')} onCloseCart={onCloseCart} />
            <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: "#f0f3f0" }}>
                {user.role === "parent" && user.userPrefs?.showDonation ? <ApprovelWithDonation onCloseCart={onCloseCart} />
                    :
                    <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center'>
                        <img
                            alt={"..."}
                            src={approvalPay}
                            width="200px"
                            height="200px"
                        />
                        <Typography className={classes.content} sx={{ fontSize: "1.5rem" }} >{t('approvalText')}<br></br>{t('approvalText1')}</Typography>
                        <Typography className={classes.content1} sx={{ fontSize: "1rem" }}>{t('approvalText2')}<br></br>{t('approvalText3')}</Typography>
                        <Button className={classes.yellowButton} onClick={e => onCloseCart(e)}>{t('backToHomePage')}</Button>
                    </Box>}
            </Box >
        </>
    )
}

const ApprovelWithDonation = ({ onCloseCart }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [user] = useGlobal((state) => state.user);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [showDonationIframe, setShowDonationIframe] = useState(false);
    const updateUserPref = useApi(updatePreferences);
    const auth = getAuth();

    const handleDontShowAgain = async () => {
        try {
            const data = { showDonation: dontShowAgain }
            await updateUserPref.requestPromise(user.userPrefs?._id, data);
            auth.currentUser.reload()
            setDontShowAgain(!dontShowAgain);
        } catch (error) {
            console.log(error);

        }
    }

    return (
        <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center'>
            <img
                alt={"..."}
                src={approvalPay}
                width="100px"
                height="100px"
            />
            <Typography className={classes.content} sx={{ fontSize: "0.875rem" }} >{t('approvalText')}<br></br>{t('approvalText1')}</Typography>
            <Typography className={classes.content1} sx={{ fontSize: "0.875rem" }}>{t('approvalText2')}<br></br>{t('approvalText3')}</Typography>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{
                borderRadius: "20px",
                margin: "1rem",
                padding: "1rem",
                overflow: "auto",
                border: "2px solid #064601",
                height: { xs: "36rem", md: "33rem" },
                background: "#ffbb41"
            }}>
                {showDonationIframe ? <>
                    <iframe src="https://v.cardcom.solutions/EA5/G9QhLH0gEa2BHIy0ywsMg/Order"
                        id={"donateiFrame"}
                        style={{ display: "block", border: "none", height: "100%", width: "100%" }}
                        title={"donate"}
                    />
                </>
                    : <><img
                        width='100px'
                        height='100px'
                        alt="..."
                        src={donate}
                        loading="lazy"
                    />
                        <Typography sx={{ color: "#064601", fontWeight: "bold", fontSize: "1.2rem" }}>{t("donateTitle")}</Typography>
                        <Typography sx={{ textAlign: "center" }}>{t("donateText1")}</Typography>
                        <Typography sx={{ textAlign: "center" }} component='span'><Typography sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={e => window.open("https://drive.google.com/file/d/11FBj0touLrl48FMEYbq4GiFdUm7vaP4O/view?usp=sharing")}>{t("kerenZofim")}</Typography>{t("donateText2")}</Typography>
                        <Typography sx={{ textAlign: "center" }}>{t("donateText3")}</Typography>
                        <Typography sx={{ textAlign: "center", fontSize: "0.875rem" }}>{t("donateText4")}</Typography>
                        <Box display='flex' flexDirection={{ xs: 'column', xl: 'row' }} marginTop={{ xs: "1rem", xl: '3rem' }}>
                            <Button className={classes.buttonGreen} onClick={e => setShowDonationIframe(true)}>{t('sureLetsGo')}</Button>
                            <Button className={classes.buttonWhite} onClick={e => onCloseCart(e)}>{t('maybeNextTime')}</Button>
                        </Box>
                        {/* <Box display='flex' flexDirection='row' alignItems='center' marginTop={{ xs: "1rem", xl: '3rem' }}>
                            {updateUserPref.loading ? <CircularProgress size='small' /> :
                                <Checkbox checked={dontShowAgain} onChange={e => handleDontShowAgain()}
                                    icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                                    checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                                />}
                            <Typography sx={{ fontSize: { xs: "0.75rem", xl: "0.875rem" } }}>{t('dontShowAgain')}</Typography>
                        </Box> */}
                    </>}
            </Box>
            <Typography className={classes.linkStyle} onClick={e => onCloseCart(e)}>{t('backToHomePage')}</Typography>
        </Box>
    )
}
export default ApprovalPayment;