import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'hooks/useApi';
import useGlobal from '../../store/store';

import { makeStyles } from '@mui/styles';
import { Slide, Box, Typography, SwipeableDrawer, IconButton, Autocomplete, TextField, CircularProgress } from "@mui/material";

import { getSchoolsList, getTroopGroup, updatePupilsList } from 'api/api';

import CloseIcon from '@mui/icons-material/Close';
import { ChildDetailsEditor } from 'Components/ParentComponents/MyFamilyComponents/ChildDetailsEditor';
import { CustomBusy } from 'Components/Common/CustomBusy';
import regReq from '../../assets/regReq.png'
import { GreenButton } from 'Components/Login/EmailEmptyModal';

const useStyles = makeStyles(() => ({
    marginBottom1: {
        marginBottom: "0.85rem !important"
    },
    sliderBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: "#f0f3f0"
    },
    slider: {
        width: '100% !important'
    },
    title: {
        fontWeight: 'bold !important',
        alignItems: 'center !important',
        fontSize: '1.3rem !important',
        color: '#3a7735  !important'
    }
}));

const PupilUpdateDrawer = ({ tribe, familyUpdated, setFamilyUpdated }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [schoolValue, setSchoolValue] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedTroop, setSelectedTroop] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [schoolsList, setSchoolList] = useState([]);
    const [troopList, setTroopList] = useState([]);

    const [updatePupilDrawer, setUpdatePupilDrawer] = useGlobal((state) => state.updatePupilDrawer, (actions) => actions.setUpdatePupilDrawer);
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
    const [child, setChild] = useGlobal((state) => state.child, (actions) => actions.setChild);
    const [troopClass, setTroopClass] = useGlobal((state) => state.troopClass, (actions) => actions.setTroopClass);
    const [pupilsToUpdate, setPupilsToUpdate] = useGlobal((state) => state.pupilsToUpdate, (actions) => actions.setPupilsToUpdate);

    const schools = useApi(getSchoolsList);
    const troopGroup = useApi(getTroopGroup);
    const updatePupils = useApi(updatePupilsList);

    useEffect(() => {
        if (familyUpdated) {
            setUpdatePupilDrawer({ isOpen: false, level: 1 });
            setChild(null);
            setFamily(null);
        }
    }, [familyUpdated])

    useEffect(() => {
        let isMounted = true;
        if (isMounted && updatePupilDrawer.level === 2) {
            getSchoolsAndTroops();
        }
        return () => { isMounted = false; }
    }, [updatePupilDrawer.isOpen]);

    const getSchoolsAndTroops = async () => {
        const school = await schools.requestPromise();
        setSchoolList(school.d.results);
        const query = { childClass: troopClass, childTribe: tribe.UnitCode };
        const troopResult = await troopGroup.requestPromise(query);
        setTroopList(troopResult);
    }

    const handleClose = () => {
        setChild(null);
        setFamily(null);
        setUpdatePupilDrawer({ isOpen: false, level: 1 });
    }

    const handleTroopGroup = (selected) => {
        setSelectedTroop(selected.split(',')[0]);
        setSelectedGroup(selected.split(',')[1]);
    }

    const handleSchool = (selected) => {
        setSelectedSchool(selected.SchoolCode);
    }

    const saveManyPupils = async () => {
        try {
            //need to add school when they develop it
            // "School": selectedSchool
            const data = pupilsToUpdate.map((child) => {
                return {
                    "CardCode": child.StudentCardCode,
                    "Group": selectedGroup || child.Group,
                    "Troop": selectedTroop || child.Troop,
                    "School": selectedSchool || child.School
                }
            })
            const result = await updatePupils.requestPromise(data);
            if (result) {
                setFamilyUpdated(familyUpdated + 1);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {
                    left: 'auto',
                    right: 0,
                    height: '100%',
                    width: {
                        xs: '90%',
                        sm: '75%',
                        md: '45%',
                        lg: '40%',
                        xl: '35%'
                    }
                }
            }}
            ModalProps={{
                keepMounted: false
            }}
            disableBackdropTransition={true}
            disableSwipeToOpen={true}
            open={updatePupilDrawer.isOpen}
            onOpen={e => setUpdatePupilDrawer({ isOpen: true })}
            onClose={e => handleClose()}
            dir='rtl'
            anchor="right"
        >
            <Box flex={'1'} sx={{ backgroundColor: "#f0f3f0" }}>
                <Box justifyContent={'space-between'} display='flex' flexDirection='row' margin="1rem">
                    <Typography className={classes.title}>{updatePupilDrawer.level === 1 ? t("editParentSetting") : t("updateMany")}</Typography>
                    <IconButton onClick={e => handleClose()}>
                        <CloseIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </Box>
                <MySlide className={classes.slider} currentLevel={1} slideLevel={updatePupilDrawer.level}>
                    <Box className={classes.sliderBox}>
                        {!child ? <CustomBusy text={t("loading")} /> :
                            <ChildDetailsEditor fromTribe={true} fromDrawer={true} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />}
                    </Box>
                </MySlide>
                <MySlide className={classes.slider} currentLevel={2} slideLevel={updatePupilDrawer.level}>
                    <Box className={classes.sliderBox}>
                        <Box display='flex' flexDirection='column' marginTop='1rem' alignItems='center'>
                            <img
                                alt={"..."}
                                src={regReq}
                                width="170px"
                                height="170px"
                            />
                        </Box>
                        <Typography variant='greenText'>{t('chooseEditFields')}</Typography>
                        <Box display='flex' flexDirection='column' marginTop='1.5rem' alignItems='center' width='24rem'>
                            {!!troopList && <Autocomplete
                                id="troop"
                                fullWidth={true}
                                disableClearable={true}
                                className={classes.marginBottom1}
                                options={troopList}
                                isOptionEqualToValue={(option, value) => option.TroopGroupCode === value.TroopGroupCode}
                                noOptionsText={t("troopNoOption")}
                                getOptionLabel={(option) => { return (`${option.TroopName},${option.TroopGroupName || ""}`) }}
                                onChange={(e, value) => handleTroopGroup(`${value?.TroopName},${value?.TroopGroupName || ""}`)}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.TroopGroupCode}>
                                        <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{option.TroopName}</div>,<div style={{ color: "#3a7735", marginRight: "3px" }}>{option.TroopGroupName || ""}</div>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params} label={t("troopGroup")}
                                        placeholder={t("troopSearch")}
                                        id="troop"
                                    />}
                            />}
                            {!!schoolsList && <Autocomplete
                                id="school"
                                fullWidth={true}
                                disableClearable={true}
                                className={classes.marginBottom1}
                                options={schoolsList.sort((a, b) => b.CityName.toString().localeCompare(a.CityName.toString()))}
                                isOptionEqualToValue={(option, value) => option.SchoolCode === value.SchoolCode}
                                noOptionsText={t("schoolNoOption")}
                                getOptionLabel={(option) => `${option.SchoolName} , ${option.CityName}`}
                                onChange={(e, value) => handleSchool(value)}
                                inputValue={schoolValue}
                                renderOption={(props, option, state, i) => (
                                    <Box component="li" {...props} key={`${option.SchoolCode}-${option.CityCode}`}>
                                        <div style={{ color: "#3a7735" }}>{option.SchoolName}</div>,<div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{option.CityName}</div>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        placeholder={t("searchPlaceHolder1")}
                                        label={t("schoolSearch")}
                                        InputProps={{
                                            inputMode: "search",
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {schools.loading && <CircularProgress color="inherit" size={20} />}
                                                    {params.InputProps.endAdornment}
                                                </>)
                                        }} />}
                                onInputChange={(event, newInputValue) => { setSchoolValue(newInputValue); }}
                            />}
                        </Box>
                        <Box display='flex' flexDirection='row' marginTop={{ xs: '1rem', xl: "10rem" }} alignItems='center'>
                            <GreenButton negative='true' onClick={e => handleClose()}>{t("cancel")}</GreenButton>
                            <GreenButton onClick={e => saveManyPupils()} >{t("save")}</GreenButton>
                        </Box>
                    </Box>
                </MySlide>
            </Box>
        </SwipeableDrawer >
    )
}
export default PupilUpdateDrawer;

const MySlide = ({ currentLevel, slideLevel, children }) => {
    return (
        <>
            {currentLevel === slideLevel &&
                <Slide
                    timeout={{ enter: 1000, exit: 1000 }}
                    direction='up'
                    in={currentLevel === slideLevel}
                    style={{ transformOrigin: '0 0 0', backgroundColor: "#f0f3f0" }}>
                    {children}
                </Slide>}
        </>)
}
