import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PaymentByCheck from "./PaymentByCheck"
import { HBox } from '../../../assets/SharedComponents';
import { Typography, Dialog, DialogTitle, Box, Container, IconButton, TextField, Button } from '@mui/material';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MoneyIcon from '@mui/icons-material/Money';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    addAndRemove: {
        alignItems: 'center !important',
        justifyContent: 'space-around !important',
        marginBottom: "1rem"
    },
    buttonWhite: {
        '& .MuiButton-label': {
            color: '#3a7735 !important',
            fontSize: '1rem !important',
            fontWeight: 'bold !important'
        },
        '& .MuiButton-startIcon': {
            marginLeft: '2px !important',
            marginRight: '-3px !important'
        },
        backgroundColor: 'white !important',
        border: '2px solid #3a7735 !important',
        borderRadius: '2rem !important',
        width: '12rem !important',
        marginBottom: '1.1rem !important',
        textTransform: "inherit",
        margin: '0.875rem !important'
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    greenButton: {
        margin: '0.875rem !important',
        backgroundColor: '#3a7735 !important',
        color: "#ffffff !important",
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '12rem !important'
    },
    notchedOutline: {
        borderColor: "transparent !important"
    }
}));
const CheckPaymentHandler = ({ paymentDetails, setPaymentDetails, doneDisable, setDoneDisable, setChecks, checkDialog, setCheckDialog, finalPrice, setTotalCheckAmount, overPay, setOverPay, addNewCheck, chosenMethod }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (chosenMethod.check && !chosenMethod.creditCard && !chosenMethod.cash) {
            setCheckDialog(true);
        }
    }, []);
    const confirmChack = () => {
        setChecks({ PaymentChecks: paymentDetails.checks });
        setCheckDialog(false);
        enqueueSnackbar(t("checkNote1"), { className: classes.orangeBG });
    }

    const removeCheck = (index) => {
        const checks = [...paymentDetails.checks];
        checks.splice(index - 1, 1);

        // Update checkIndex for the remaining checks
        const updatedChecks = checks.map((check, i) => {
            return { ...check, checkIndex: i + 1 };
        });
        if (updatedChecks.length === 1) { setDoneDisable(false); }
        setPaymentDetails({ ...paymentDetails, checks: updatedChecks });
    };
    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" >
                <Box display="flex" flexDirection="row" alignSelf="flex-start" alignItems="center">
                    <MoneyIcon sx={{ color: "#3a7735", fontSize: { xs: "1.1rem", md: "1.5rem" } }} />
                    <Typography sx={{ marginLeft: "1rem", color: "#3a7735", fontSize: { xs: '1rem', md: '1.5rem' } }}>{t("check")}</Typography>
                </Box>
                <TextField
                    name='CheckSum'
                    size="small"
                    type="number"
                    sx={{ width: "14rem", marginTop: "1rem", background: "#ffffff", borderRadius: "10px" }}
                    value={Number(paymentDetails.checks?.reduce((accum, check) => accum + (parseFloat(check.CheckSum) || 0), 0)).toFixed(2)}
                    style={{ textAlign: "center !important" }}
                    disabled={true}
                    InputProps={{
                        sx: { borderRadius: "10px" },
                        classes: {
                            notchedOutline: !paymentDetails.checks?.reduce((accum, check) => accum + (parseFloat(check.CheckSum) || 0), 0) && classes.notchedOutline
                        }
                    }}
                />
            </Box>
            <PaymentDialog text={t('addCheckPayment')} open={checkDialog} setOpen={setCheckDialog}>
                <Container>
                    {paymentDetails.checks.map((item, i) => (
                        <PaymentByCheck
                            check={item}
                            setTotalCheckAmount={setTotalCheckAmount}
                            finalPrice={finalPrice}
                            setPaymentDetails={setPaymentDetails}
                            paymentDetails={paymentDetails}
                            overPay={overPay}
                            setOverPay={setOverPay}
                            removeCheck={removeCheck}
                            setDoneDisable={setDoneDisable}
                            key={`numberOfCheck${i}`}
                        />
                    ))}
                    <HBox className={classes.addAndRemove}>
                        {!!paymentDetails.checks[0]?.CheckSum &&
                            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                                <Button onClick={e => addNewCheck(true)} className={classes.buttonWhite}>{t("iHaveMoreChecks")}</Button>
                                <Button onClick={e => confirmChack()} className={classes.greenButton} disabled={doneDisable}>{t("done")}</Button>
                            </Box>}
                    </HBox>
                </Container>
            </PaymentDialog >
        </>
    )
}

const PaymentDialog = ({ text, children, open, setOpen }) => {
    return (
        <Dialog
            open={open}
            onClose={e => setOpen(false)}
            PaperProps={{
                sx: { width: { xs: "20rem", md: "45rem" }, borderRadius: "50px !important", maxWidth: "max-content" }
            }}>
            <Container>
                <DialogTitle>
                    <Box display="flex" flexDirection='row' paddingRight={'0.5rem'} alignItems={'end'} justifyContent={"space-between"}>
                        <CalculateOutlinedIcon />
                        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
                        <IconButton onClick={e => setOpen(false)} >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {children}
            </Container>
        </Dialog>
    )
}
export default CheckPaymentHandler;