import { useTranslation } from 'react-i18next';
import { useStyles } from '../assets/LoginStyle.jsx'
import { Box, Card, Typography } from '@mui/material';
import Logo from '../assets/Logo.png'
import NoRegRequests from '../assets/NoRegRequests.svg';

export const MaintenancePage = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={`${classes.height100} ${classes.AppBackground}`} style={{ minHeight: '50rem' }}>
            <Box alignItems="center" display="flex" flexDirection="column"  >
                <Box display="flex" flexDirection="column" alignItems="center" mt="5rem">
                    <img
                        alt={"..."}
                        className={classes.LogoSize}
                        src={Logo}
                        loading="lazy"
                    />
                    <Typography sx={{ fontWeight: "bold", color: "#ffffff", fontSize: "1.5rem" }}>{t("theParentsPortal")}</Typography>
                </Box>
                <Card className={classes.loginCard} sx={{ marginTop: '2rem !important' }}>
                    <Box sx={{ height: { xs: '55%', lg: '50%', xl: '50%' } }} className={`${classes.margin0_1} ${classes.SmsMinHeight}`} >
                        <Box className={classes.FatherTabsStyle} alignItems='center' display='flex' flexDirection='column' sx={{ marginTop: '2rem !important' }}>
                            <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.2rem" }}>{t("maintenenceTitle")}</Typography>
                            <img
                                alt={"..."}
                                src={NoRegRequests}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ color: "#3a7735", fontSize: "1rem" }}>{t("maintenenceText")}</Typography>
                            <Typography sx={{ color: "#3a7735", fontSize: "1rem" }}>{t("maintenenceText1")}</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem", fontStyle: "italic" }}>{t("TheScouts")}</Typography>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box >
    );
}