import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'hooks/useApi';
import useGlobal from '../../store/store';
import { useSnackbar } from 'notistack';
import { TribeIsUpdatedContext } from "context/tribeIsUpdateContext";
import { makeStyles } from '@mui/styles';
import { Slide, Box, Typography, SwipeableDrawer, IconButton, Button, Divider, Input, Tooltip } from "@mui/material";
import calender from '../../assets/calender.svg'
import approvalPay from '../../assets/approvalPay.png'
import { getTribePupils, addDeepLinkAPI, updateDeepLink, removingDeepLink } from 'api/api';
import { dateFormat } from './ActivityItem';
import { MyInputStyle } from './OpenSignReqs';
import { CustomBusy } from 'Components/Common/CustomBusy';
import { ShareButtons } from 'Components/Cart/Parent/SplitPaymentApproval';

import CloseIcon from '@mui/icons-material/Close';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const useStyles = makeStyles(() => ({
    sliderBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center !important',
        width: '100% !important',
        backgroundColor: "#f0f3f0"
    },
    slider: {
        width: '100% !important'
    },
    title: {
        fontWeight: "bold !important",
        alignItems: 'center !important',
        fontSize: '1.3rem !important',
        color: '#3a7735  !important'
    },
    yellowButton: {
        "&:hover": {
            background: '#ffbb41 !important',
        },
        "&.Mui-disabled": {
            backgroundColor: '#dbdbdb !important',
            color: '#a2a2a2 !important'
        },
        textTransform: "capitalize !important",
        backgroundColor: '#ffbb41 !important',
        width: '14.75rem !important',
        color: '#064601 !important',
        fontSize: '1.2rem !important',
        fontWeight: "bold !important",
        borderRadius: '50px !important',
        margin: "1.5rem !important"
    },
    backgroundLink: {
        backgroundColor: '#e6efe6 !important',
        border: '1px solid #3a7735 !important',
        height: '2.75rem !important',
        width: '80% !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        borderRadius: '10px !important'
    },
    link: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: 'black !important',
            '&.MuiInput-underline.Mui-disabled:before': {
                borderBottomStyle: "none !important"
            }
        },
        fontWeight: 'bold !important'
    },
    linkStyle: {
        color: '#064601 !important',
        fontSize: "0.875rem !important",
        paddingInlineStart: '0.3em !important',
        textDecoration: 'underline !important',
        fontWeight: 'bold !important',
        cursor: 'pointer !important'
    },
    buttons: {
        margin: '1.5rem !important',
        backgroundColor: '#f06752 !important',
        borderRadius: '50px !important',
        boxShadow: '6px 6px 10px 0px rgba(0, 0, 0, 0.16) !important'
    },
    buttonAndText: {
        textAlign: 'center !important',
        padding: '0rem 3rem 1rem'
    },
    textUnderButtons: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        color: '#3a7735 !important',
        fontSize: '1.3 !important'
    }
}));

const UnlockActivityTribe = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const getPupilsByTribe = useApi(getTribePupils);
    const [pupilList, setPupilList] = useState([]);
    const [filterdPupilList, setFilterdPupilList] = useState([]);
    const [selectedPupils, setSelectedPupils] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [link, setLink] = useState("");
    const { linkUpdate, setLinkUpdate } = useContext(TribeIsUpdatedContext)
    const [unlockActivityDrawer, setUnlockActivityDrawer] = useGlobal(
        state => state.unlockActivityDrawer,
        actions => actions.setUnlockActivityDrawer);

    const [activityToUnlock, setActivityToUnlock] = useGlobal(
        state => state.activityToUnlock,
        actions => actions.setActivityToUnlock);

    const [user] = useGlobal(
        state => state.user);
    //need to know what is the right endreg date
    const endReg = dateFormat(activityToUnlock?.EndPort);
    //start activity date for step2 & step3
    const startDate = dateFormat(activityToUnlock?.StartDate);

    const filteredPupils = !searchValue ? [] :
        pupilList.filter(child =>
            (child.Pupil_FirstName.concat(' ', child.Pupil_LastName)).includes(searchValue) ||
            child.Pupil_FirstName.includes(searchValue) ||
            child.Pupil_LastName.includes(searchValue) ||
            child.PupilID.includes(searchValue));

    useEffect(() => {
        let isMounted = true;
        isMounted && getPupils();
        if (isMounted && unlockActivityDrawer.level === 3) {
            setSelectedPupils(activityToUnlock.deepLinkData[0].allowedPupilsList);
            setLink(`${window.location.origin}/dl/${activityToUnlock.Code}-${activityToUnlock.ActivCode}-${activityToUnlock.Class}-${activityToUnlock.ItemCode}`);
            setFilterdPupilList([]);
        }

        return () => { isMounted = false; }
    }, [unlockActivityDrawer.isOpen]);

    useEffect(() => {
        setFilterdPupilList(filteredPupils);
    }, [searchValue, pupilList]);

    const getPupils = async () => {
        if (unlockActivityDrawer.isOpen) {
            const query = { tribeCode: activityToUnlock?.Code, classNo: activityToUnlock?.Class };
            const pupilsResult = await getPupilsByTribe.requestPromise(query);
            setPupilList(pupilsResult.filter((child) => child.PupilID));
        }
    }
    const extractDate = (stringDate) => {
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
        const extractFromJson = reMsAjax.exec(stringDate);
        const dateFormat = extractFromJson && new Date(+extractFromJson[1])
        return dateFormat || '';
    }
    const makeDeepLink = async () => {
        try {
            const allowedPupilsList = selectedPupils.map((child) => { return { PupilID: child.PupilID, Pupil_FirstName: child.Pupil_FirstName, Pupil_LastName: child.Pupil_LastName } });
            const expirationDateType = extractDate(activityToUnlock?.EndPort);
            const data = {
                allowedPupilsList,
                deepLink: `${window.location.origin}/dl/${activityToUnlock.Code}-${activityToUnlock.ActivCode}-${activityToUnlock.Class}-${activityToUnlock.ItemCode}`,
                tribeCode: activityToUnlock?.Code,
                class: activityToUnlock?.Class,
                activeName: activityToUnlock?.ActivName,
                activeCode: activityToUnlock?.ActivCode,
                expirationDate: activityToUnlock?.EndPort,
                expirationDateFormated: endReg,
                expirationDateType,
                deepLinkMaker: user.email,
                creationDate: new Date().toString()
            };
            await addDeepLinkAPI(data);
            setLinkUpdate(linkUpdate + 1);
            setLink(`${window.location.origin}/dl/${activityToUnlock.Code}-${activityToUnlock.ActivCode}-${activityToUnlock.Class}-${activityToUnlock.ItemCode}`);
            setUnlockActivityDrawer({ isOpen: true, level: 2 });
        } catch (error) {
            console.log(error);
            const key = enqueueSnackbar(t('linkAlreadyCreated'), { variant: "error" });
            setTimeout(() => { closeSnackbar(key) }, 1500)
        }
    }

    const updateDeepLinkFunc = async () => {
        try {
            const allowedPupilsList = selectedPupils.map((child) => { return { PupilID: child.PupilID, Pupil_FirstName: child.Pupil_FirstName, Pupil_LastName: child.Pupil_LastName } });
            const data = { allowedPupilsList }
            await updateDeepLink(activityToUnlock.deepLinkData[0]._id, data);
            setLinkUpdate(linkUpdate + 1);
            // setUnlockActivityDrawer({ isOpen: false, level: 1 });
            const key = enqueueSnackbar(t('linkWasUpdated'), { variant: "success" });
            setTimeout(() => { closeSnackbar(key) }, 1500);
        } catch (error) {
            console.log(error);
        }
    }

    const removeDeepLink = async () => {
        try {
            await removingDeepLink({ _id: activityToUnlock.deepLinkData[0]._id });
            setLinkUpdate(linkUpdate + 1);
            setUnlockActivityDrawer({ isOpen: false, level: 1 });
            const key = enqueueSnackbar(t('linkDeleted'), { variant: "success" });
            setTimeout(() => { closeSnackbar(key) }, 1500);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {
                    left: 'auto',
                    right: 0,
                    height: '100%',
                    width: {
                        xs: '90%',
                        sm: '75%',
                        md: '45%',
                        lg: '40%',
                        xl: '35%'
                    }
                }
            }}
            ModalProps={{
                keepMounted: false
            }}
            disableBackdropTransition={true}
            disableSwipeToOpen={true}
            open={unlockActivityDrawer.isOpen}
            onOpen={e => setUnlockActivityDrawer({ isOpen: true })}
            onClose={e => setUnlockActivityDrawer({ isOpen: false, level: 1 })}
            dir='rtl'
            anchor="right"
        >
            <Box flex={'1'} sx={{ backgroundColor: "#f0f3f0" }}>
                <Box justifyContent={'space-between'} display='flex' flexDirection='row' margin="1rem">
                    <Typography className={classes.title}>{t("openLateRegTittle")}</Typography>
                    <IconButton onClick={e => setUnlockActivityDrawer({ isOpen: false, level: 1 })}>
                        <CloseIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </Box>
                <Box display='flex' flexDirection='column' marginTop='0.5rem' alignItems='center'>
                    <Typography sx={{ color: "#3a7735", marginTop: "0.5rem", fontWeight: "bold", fontSize: "1.8rem", textAlign: "center" }}>{activityToUnlock?.ActivName}</Typography>
                    <Typography sx={{ color: "#3a7735", fontSize: "1.4rem", textAlign: "center" }}>{`${t('registerCloseAt')} ${endReg}`}</Typography>
                </Box>
                <MySlide className={classes.slider} currentLevel={1} slideLevel={unlockActivityDrawer.level}>
                    <Box className={classes.sliderBox}>
                        <Box display='flex' flexDirection='column' marginTop='1rem' alignItems='center'>
                            <img
                                alt={"..."}
                                src={calender}
                                width="170px"
                                height="170px"
                            />
                        </Box>
                        <Typography variant='greenText'>{t('choosePupilsForLink')}</Typography>
                        <MyInputStyle
                            variant="outlined"
                            sx={{ marginTop: "0.5rem" }}
                            placeholder={t('searchPupil')}
                            onChange={e => setSearchValue(e.target.value)}
                            InputProps={{
                                endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
                            }}
                        />
                        {getPupilsByTribe.loading ? <CustomBusy text={t("loading")} /> :
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: '1rem' }}>
                                {filterdPupilList.map((child) => {
                                    return <Pupil child={child} selectedPupils={selectedPupils} setSelectedPupils={setSelectedPupils} setFilterdPupilList={setFilterdPupilList} key={child.PupilID} removeDeepLink={removeDeepLink} />
                                }
                                )}
                            </Box>}
                        <Divider sx={{ color: "#797979", width: "90%" }} />
                        <Typography variant='greenText' sx={{ alignSelf: "flex-start", ml: "1rem" }}>{`${t('allowedPupils')} (${selectedPupils.length})`}</Typography>
                        {!!selectedPupils.length &&
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: '1rem' }}>
                                {selectedPupils.map((child) => {
                                    return <Pupil child={child} selectedPupils={selectedPupils} setSelectedPupils={setSelectedPupils} setFilterdPupilList={setFilterdPupilList} key={child.PupilID} removeIcon={true} removeDeepLink={removeDeepLink} />
                                }
                                )}
                            </Box>}
                        <Button className={classes.yellowButton} disabled={!selectedPupils.length} onClick={e => makeDeepLink()} endIcon={<Tooltip title={t("lateRegExplain")}><HelpOutlineOutlinedIcon /></Tooltip>}>
                            {t("createLink")}
                        </Button>
                    </Box>
                </MySlide>
                <MySlide className={classes.slider} currentLevel={2} slideLevel={unlockActivityDrawer.level}>
                    <Box className={classes.sliderBox} mb="2rem">
                        <Box display='flex' flexDirection='column' marginTop='2rem' alignItems='center'>
                            <img
                                alt={"..."}
                                src={approvalPay}
                                width="170px"
                                height="170px"
                            />
                        </Box>
                        <Box display='flex' flexDirection='column' marginTop='2rem' alignItems='center'>
                            <Typography className={classes.title}>{t("linkCreated")}</Typography>
                        </Box>
                        <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center' width='100%' >
                            <Box flexDirection='row' display='flex' justifyContent='center' alignItems='center' className={classes.backgroundLink}>
                                <Input
                                    sx={{ textAlign: 'center !important' }}
                                    disabled
                                    className={classes.link}
                                    fullWidth={true}
                                    value={link} />
                            </Box>
                        </Box>
                        <ShareButtons link={link} unlockText={true} />
                    </Box>
                </MySlide>
                <MySlide className={classes.slider} currentLevel={3} slideLevel={unlockActivityDrawer.level}>
                    <Box className={classes.sliderBox}>
                        <Box flexDirection='column' display='flex' justifyContent='center' alignItems='center' width='100%' mb="2rem">
                            <Box flexDirection='row' display='flex' justifyContent='center' alignItems='center' className={classes.backgroundLink} mt="2rem">
                                <Input
                                    sx={{ textAlign: 'center !important' }}
                                    disabled
                                    className={classes.link}
                                    fullWidth={true}
                                    value={link} />
                            </Box>
                            <Box flexDirection={{ xs: 'column', sm: 'row' }} display='flex' justifyContent='center' alignItems='center'>
                                <ShareButtons link={link} unlockText={true} />
                                <Box className={classes.buttonAndText} >
                                    <IconButton className={classes.buttons} onClick={e => removeDeepLink()}>
                                        <EventBusyIcon sx={{ color: '#ffffff' }} />
                                    </IconButton>
                                    <Typography className={classes.textUnderButtons}>{t('deleteDeepLink')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant='greenText'>{t('choosePupilsForLink')}</Typography>
                        <MyInputStyle
                            variant="outlined"
                            sx={{ marginTop: "0.5rem" }}
                            placeholder={t('searchPupil')}
                            onChange={e => setSearchValue(e.target.value)}
                            InputProps={{
                                endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
                            }}
                        />
                        {getPupilsByTribe.loading ? <CustomBusy text={t("loading")} /> :
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: '1rem' }}>
                                {filterdPupilList.map((child) => {
                                    return <Pupil child={child} selectedPupils={selectedPupils} setSelectedPupils={setSelectedPupils} setFilterdPupilList={setFilterdPupilList} key={child.PupilID} removeDeepLink={removeDeepLink} />
                                }
                                )}
                            </Box>}
                        <Divider sx={{ color: "#797979", width: "90%" }} />
                        <Typography variant='greenText' sx={{ alignSelf: "flex-start", ml: "1rem" }}>{`${t('allowedPupils')} (${selectedPupils.length})`}</Typography>
                        {!!selectedPupils.length &&
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: '1rem' }}>
                                {selectedPupils.map((child) => {
                                    return <Pupil child={child} selectedPupils={selectedPupils} setSelectedPupils={setSelectedPupils} setFilterdPupilList={setFilterdPupilList} key={child.PupilID} removeIcon={true} removeDeepLink={removeDeepLink} />
                                })}
                            </Box>}
                        <Button className={classes.yellowButton} disabled={!selectedPupils.length} onClick={e => updateDeepLinkFunc()}>
                            {t("updtaeLink")}
                        </Button>
                    </Box>
                </MySlide>
            </Box >
        </SwipeableDrawer >
    )
}
export default UnlockActivityTribe;

const MySlide = ({ currentLevel, slideLevel, children }) => {
    return (
        <>
            {currentLevel === slideLevel &&
                <Slide
                    timeout={{ enter: 1000, exit: 1000 }}
                    direction='up'
                    in={currentLevel === slideLevel}
                    style={{ transformOrigin: '0 0 0', backgroundColor: "#f0f3f0" }}>
                    {children}
                </Slide>}
        </>)
}
const Pupil = ({ child, selectedPupils, setSelectedPupils, setFilterdPupilList, removeIcon = false, removeDeepLink }) => {

    const addPupil = () => {
        if (!selectedPupils.some(pupil => pupil.PupilID === child.PupilID)) {
            setSelectedPupils(prevPupils => [...prevPupils, child]);
            setFilterdPupilList(prevList => prevList.filter(pupil => pupil.PupilID !== child.PupilID));
        }
    }

    const removePupil = () => {
        console.log(selectedPupils.length)
        setSelectedPupils(prevPupils => prevPupils.filter(pupil => pupil.PupilID !== child.PupilID));
        setFilterdPupilList([]);
        if (selectedPupils.length === 1) {
            removeDeepLink();
        }
    }

    return (
        <Box display='flex' flexDirection='row' justifyContent='space-between' onClick={e => addPupil()} sx={{ width: { xs: '90%', md: '25vw' }, cursor: !removeIcon && 'pointer', minWidth: { xs: '15rem', md: '27rem' }, ":hover": { backgroundColor: !removeIcon && "#cee2cd" } }}>
            <Box alignItems='center' display='flex' flexDirection='row' >
                {selectedPupils.some(pupil => pupil.PupilID === child.PupilID) ? <CheckIcon sx={{ color: '#3a7735' }} /> : <PersonOutlineOutlinedIcon sx={{ color: '#3a7735' }} />}
                <Typography marginLeft='0.5rem'>{`${child.Pupil_FirstName} ${child.Pupil_LastName}`}</Typography>
            </Box>
            <Box alignItems='center' display='flex' flexDirection='row' >
                <Typography marginRight='0.5rem'>{child.PupilID}</Typography>
                {removeIcon && <IconButton onClick={e => removePupil()}><DeleteOutlineIcon sx={{ color: "#99292e" }} /></IconButton>}
            </Box>
        </Box>
    )
}