import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography ,Box} from '@mui/material';
import { CGinquireTransaction, loggerService } from '../../api/api'
import { xmlInquireTransactionRequest } from "../../data/CGxmlRequests";
import useApi from '../../hooks/useApi';

import TransactionLayout from 'layouts/TransactionLayout';
import useGlobal from 'store/store';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const useStyles = makeStyles(() => ({
    text: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important'
    }
}));

const SuccessPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const inquireTransactionReq = useApi(CGinquireTransaction);
    const logger = useApi(loggerService);
    const [user] = useGlobal((state) => state.user);

    useEffect(() => {
        const data = {
            txId: window.parent.txid,
            terminalNumber: window.parent.terminal,
            mid: window.parent.mid
        }
        logger.requestPromise(data);
        inquireTransaction();
    }, []);

    //Receiving details from Credit Guard about the successfully executed transaction
    const inquireTransaction = async () => {
        let int_in = xmlInquireTransactionRequest({
            txId: window.parent.txid,
            terminalNumber: window.parent.terminal,
            mid: window.parent.mid
        });
        try {
            let data = { int_in, products: window.parent.products, isDivorced: window.parent.isDivorced, invoices: window.parent.invoices, isTribeUser: user.role === 'tribe' ? true : false, isError: false };
            const response = await inquireTransactionReq.requestPromise(data);

            window.parent.postMessage({
                success: true,
                paymentIframe: true,
                response: response
            }, "*");
        } catch (error) {
            console.log(error);
        }

    }
    return (
        <TransactionLayout >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                {user.role === 'tribe' && <Typography className={classes.text}>{t("pleaseNote")}</Typography>}
                <Typography className={classes.text} sx={{ color: "#bb0000" }}>{t("dontClose")}</Typography>
                <HourglassTopIcon />
                <Typography className={classes.text}>{t("pleaseWaitPayment")}</Typography>
                <CircularProgress sx={{ color: "#3a7735b3" }} />
            </Box>
        </TransactionLayout>
    );
}
export default SuccessPage;