import { useState, useEffect } from "react";
import { useNavigate, usePath } from 'raviger'
import { useTranslation } from 'react-i18next';
import useGlobal from "../../store/store";
import useFeathers from "../../hooks/useFeathers";
//mui
import { AppBar, IconButton, Toolbar, Box, Hidden, ListItem, SwipeableDrawer, Typography, Link, Button, Badge, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
//components
import { LanguagePicker } from '../Common/LanguagePicker'
import { HBox, VBox } from '../../assets/SharedComponents';
import GenericDialog from "Components/Common/GenericDIalog";
//assets
import Logo from '../../assets/Logo.png'
import appBG from '../../assets/appBG.jpg';

const useStyles = makeStyles(() => ({
    TZToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '30px !important'
    },
    TZAppbar: {
        top: 0,
    },
    paddingBottom2: {
        paddingBottom: '2rem'
    },
    mobileListItem: {
        justifyContent: 'center !important',
        padding: '0 !important'
    },
    linkMobileDiff: {
        color: '#3a7735 !important',
        background: 'white !important',
        borderRadius: '2rem !important',
        height: '2rem ',
        marginBottom: '1rem !important',
        textTransform: "capitalize !important",
        fontSize: "1rem !important",
        width: "85%",
        '&:hover': {
            backgroundColor: '#b7c7b6 !important'
        }
    },
    linkMobile: {
        fontSize: "1rem !important",
        color: '#ffffff !important',
        width: '100% !important',
        padding: '0.7rem !important',
        paddingInlineEnd: '4rem !important',
        justifyContent: 'start !important',
        textTransform: "capitalize !important",
        '&:hover': {
            backgroundColor: '#7d9f7b82 !important',
            borderRadius: "100px !important"
        }
    },
    linkDesktop: {
        padding: '0.7rem 0',
        margin: '0 1rem',
        color: 'white !important',
        marginBottom: '3px !important',
        textDecorationLine: 'none !important',
        '&:hover': {
            cursor: 'pointer',
            marginBottom: '0 !important',
            borderBottom: '3px solid white'
        }
    },
    activeLink: {
        marginBottom: '0 !important',
        borderBottom: '3px solid white'
    },
    smallMarginEnd: {
        marginInlineEnd: '1rem !important'
    },
    greenBackground: {
        '& .MuiDrawer-paper.MuiDrawer-paper': {
            backgroundImage: `url(${appBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom'
        }
    },
    cartBadge: {
        "&:hover": {
            cursor: 'pointer',
        },
        "& .MuiSvgIcon-root": {
            margin: 'auto'
        },
        '& .MuiBadge-badge': {
            top: "12px",
            color: '#ffffff !important',
            fontWeight: '600 !important'
        },

    },
    cartButton: {
        "&:hover": {
            backgroundColor: '#f0f3f0 !important',
        },
    },
    whiteButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#ffffff !important',
        margin: '1rem !important',
        border: '1px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '10.75rem !important',
        textTransform: "capitalize !important"
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        backgroundColor: '#3a7735 !important',
        color: "#ffffff !important",
        alignItems: 'center !important',
        justifyContent: 'center !important',
        margin: '1rem !important',
        borderRadius: '50px !important',
        width: '10.75rem !important',
        textTransform: "capitalize !important"
    }
}));

export const TribeAppBar = ({ children, leader, tribe, setFamily, family }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate()
    const feathers = useFeathers();
    const [open, setOpen] = useState(false);
    const [openGoBack, setOpenGoBack] = useState(false);
    const location = usePath();

    const [user, setUser] = useGlobal((state) => state.user, (actions) => actions.setUser);
    const [products, setCartState] = useGlobal((state) => state.products, (actions) => actions.setCartState);

    const BILink = leader ? "https://bi.zofim.org.il/" : "https://portalbi.zofim.org.il:4994/#/reports";
    const isActive = (path) => location === path;

    useEffect(() => {
        let isMounted = true;
        isMounted && checkUserRole();
        return () => { isMounted = false }
    }, []);

    const checkUserRole = async () => {
        try {
            if (user.role === undefined)
                await feathers.reAuthenticate();
        } catch (error) {
            console.log(error);
        }
    }

    const navigationLinks = [{ name: t("addStudentOrMember"), href: '/tribe/tribeSearch', title: t("add"), icon: <PersonAddAltIcon />, visible: true },
    { name: t("activities"), href: '/tribe', title: t("activities"), icon: <DateRangeOutlinedIcon />, visible: true },
    { name: t("activityReport"), href: '/tribe/activitiesReport', title: t("activityReport"), icon: <EventAvailableIcon />, visible: true },
    { name: t("systemBI"), href: BILink, icon: <AnalyticsOutlinedIcon />, type: "newTab", visible: true },
    { name: t("pupilsTable"), href: '/tribe/pupilsUpdatePage', title: t("pupilsTable"), icon: <BackupTableOutlinedIcon />, visible: true },
    { name: t("refundsForTribe"), href: '/tribe/tribeRefundsPage', title: t("refundsForTribe"), icon: <RequestQuoteOutlinedIcon />, visible: true },
    { name: t("activityRegister"), href: '/tribe/activityRegister', title: t("activityRegister"), icon: <AppRegistrationOutlinedIcon />, visible: tribe?.Activity === "3" },
    { name: t("disconnect"), href: '/', icon: <PowerSettingsNewIcon sx={{ color: "#f9bc45" }} />, func: true, visible: true }]

    const handleLinksClick = (sHref, type = "") => {
        if (type === "newTab") {
            setOpen(false);
            window.open(sHref, "_blank");
            return;
        }
        setOpen(false);
        navigate(sHref);
    }
    const linksClick = (item) => {
        if (item.type) { handleLinksClick(item.href, item.type) }
        else if (item.func) { feathers.logOut(); }
        else {
            handleLinksClick(item.href, item.title)
        }
    }
    const handleCloseFamily = () => {
        setOpenGoBack(true);
    }
    const closeDialog = () => {
        setOpenGoBack(false);
        if (open) {
            setOpen(false);
        }
        setFamily(null);
        navigate('/tribe');
    }

    return (
        <AppBar className={`${classes.TZAppbar}`} sx={{
            backgroundImage: `url(${appBG})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}>
            <Toolbar className={classes.TZToolbar} >
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Box alignItems="center" display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                        {/* right content */}
                        <Box alignItems="center" display="flex" flexDirection="row" sx={{ visibility: { xl: 'hidden' } }}>
                            <IconButton onClick={e => setOpen(true)} color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        {/* mid content */}
                        <Box display="flex" flexDirection="column" alignItems="center" marginTop="1.5rem">
                            <img
                                width='auto'
                                height='44px'
                                alt="..."
                                src={Logo}
                                loading="lazy"
                            />
                            {leader ?
                                <Typography sx={{ fontSize: { sm: "1.25rem" } }} >{t('leader')}</Typography> :
                                <Typography sx={{ fontSize: { sm: "1.25rem" } }} >{tribe?.UnitName && `${t('tribe')} ${tribe.UnitName}`}</Typography>}
                        </Box>
                        {/* left content */}
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Hidden mdDown>
                                {children}
                                <Tooltip title={t("disconnect")}>
                                    <IconButton size="small" onClick={e => feathers.logOut()}>
                                        <PowerSettingsNewIcon sx={{ color: "#f9bc45" }} />
                                    </IconButton>
                                </Tooltip>
                                <LanguagePicker />
                            </Hidden>
                        </Box>
                    </Box>
                    <Box justifyContent={'start'} width={'100%'} alignItems="center" display="flex" flexDirection="row">
                        <Hidden xlDown>
                            <Box justifyContent={'start'} width={'100%'} alignItems="center" display="flex" flexDirection="row">
                                <Link onClick={e => handleLinksClick('/tribe')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/homepage') ? classes.activeLink : ''}`}>
                                    {t("Activities")}
                                </Link>
                                <Link onClick={e => handleLinksClick('/tribe/tribeSearch')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/tribeSearch') ? classes.activeLink : ''}`}>
                                    {t("addStudent")}
                                </Link>
                                <Link onClick={e => handleLinksClick('/tribe/activitiesReport')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/activitiesReport') ? classes.activeLink : ''}`}>
                                    {t("activityReport")}
                                </Link>
                                <Link onClick={e => handleLinksClick(BILink, "newTab")} className={`${classes.linkDesktop} ${classes.smallMarginEnd}`}>
                                    {t("systemBI")}
                                </Link>
                                <Link onClick={e => handleLinksClick('/tribe/pupilsUpdatePage')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/pupilsUpdatePage') ? classes.activeLink : ''}`}>
                                    {t("pupilsTable")}
                                </Link>
                                <Link onClick={e => handleLinksClick('/tribe/tribeRefundsPage')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/tribeRefundsPage') ? classes.activeLink : ''}`}>
                                    {t("refundsForTribe")}
                                </Link>
                                {tribe?.Activity === "3" && <Link onClick={e => handleLinksClick('/tribe/activityRegister')} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/tribe/activityRegister') ? classes.activeLink : ''}`}>
                                    {t("activityRegister")}
                                </Link>}
                                {family && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                    <Link onClick={e => handleLinksClick(`/tribe/tribeMyFamily/${family.CardCode}`)} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive(`/tribe/tribeMyFamily/${family.CardCode}`) ? classes.activeLink : ''}`}>
                                        {`${t("familyOf")} ${family.Parent1_LastName}`}
                                    </Link>
                                    <CloseIcon sx={{ color: "#ffffff", cursor: "pointer" }} onClick={e => handleCloseFamily()} />
                                </Box>}
                            </Box>
                        </Hidden>
                    </Box>
                    <Box justifyContent={'end'} width={'100%'} alignItems="center" display="flex" flexDirection="row">
                        <Button sx={{ backgroundColor: "#f0f3f0", height: "5rem", position: "fixed", top: { xs: "3rem", sm: "10.5rem", xl: "14rem" }, right: "0", borderTopLeftRadius: "16px", borderBottomLeftRadius: "16px", boxShadow: '0 8px 9px rgb(125 125 125 / 40%)' }} onClick={e => setCartState(true)} className={classes.cartButton}>
                            <Badge
                                className={classes.cartBadge}
                                color="primary"
                                showZero
                                badgeContent={products.length}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                <ShoppingCartOutlinedIcon sx={{ fontSize: "2.5rem", color: "#3a7735" }} />
                            </Badge>
                        </Button>
                    </Box>
                </Box>
            </Toolbar >
            {/* side bar content */}
            < SwipeableDrawer
                onClose={e => setOpen(false)}
                onOpen={e => setOpen(true)}
                dir="rtl"
                className={classes.greenBackground}
                open={open}
                sx={{ display: { xl: 'none' } }}>
                <HBox sx={{ justifyContent: "space-between !important", alignItems: "center" }} >
                    <IconButton onClick={e => setOpen(false)} >
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                    <LanguagePicker />
                </HBox>
                <VBox alignItems={'center'} className={classes.paddingBottom2}>
                    <img width={'41px'}
                        height='60px'
                        alt="..."
                        src={Logo}
                        loading="lazy"
                    />
                    <Typography color='secondary' variant="h5" fontWeight={'600'}>{t("parentsPortal")}</Typography>
                </VBox>
                {
                    family && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"><Button onClick={e => handleLinksClick(`/tribe/tribeMyFamily/${family.CardCode}`)} className={`${classes.linkMobile}`}>
                        {`${t("familyOf")} ${family.Parent1_LastName}`}</Button>
                        <CloseIcon sx={{ color: "#ffffff", cursor: "pointer" }} onClick={e => handleCloseFamily()} /></Box>
                }
                {
                    navigationLinks.map((item, i) => (

                        item.visible &&
                        <ListItem key={`navigationDrawerLinks-${i}`} className={classes.mobileListItem}>
                            {i === 0 ?
                                <Button onClick={e => linksClick(item)} className={classes.linkMobileDiff} endIcon={item.icon}>
                                    {item.name}
                                </Button>
                                :
                                <Button onClick={e => linksClick(item)} className={classes.linkMobile} startIcon={item.icon}>
                                    {item.name}
                                </Button>}
                        </ListItem>

                    ))
                }
            </ SwipeableDrawer>
            {openGoBack &&
                <GenericDialog open={openGoBack} setOpen={setOpenGoBack} showRopes={true} height="16rem">
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold", color: "#3a7735", textAlign: "center" }}>{t('ifUpdated')}</Typography>
                    <Typography sx={{ fontSize: "0.75rem", color: "#3a7735", textAlign: "center" }}>{`${t("areUsure")} ${family?.Parent1_LastName}`}</Typography>
                    <Box alignItems={"center"} display="flex" flexDirection={{ xs: "column", md: "row" }} marginTop="3rem">
                        <Button className={classes.greenButton} onClick={e => setOpenGoBack(false)}>{t('stayInFamily')}</Button>
                        <Button className={classes.whiteButton} onClick={e => closeDialog()}>{t('closeFamily')}</Button>
                    </Box>
                </GenericDialog>}
        </AppBar >
    );
}