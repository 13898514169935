import { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from 'react-i18next';
import useGlobal from "../../store/store";
// mui
import { Slide, Box, SwipeableDrawer, Stepper, StepLabel, Badge } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
// components
import { CartStepStyle } from "Components/Common/StyledComponents";
import ParentCartHeader from "./Parent/ParentCartHeader";
import FirstStep from "./FirstStep";
import Balance from "./Balance";
import ApprovalPayment from './ApprovalPayment';
import ParentStatment from './Parent/ParentStatment/ParentStatement'
import ParentPayment from './Parent/ParentPayment';
import CashAndCheckApproval from './Parent/CashAndCheckApproval';
import KibbutzApproval from './Parent/KibbutzApproval';
import SplitPaymentApproval from './Parent/SplitPaymentApproval';
import SplitPayment from './Parent/SplitPayment';
import CreditCardLevel from './Parent/CreditCardLevel'
import PaymentOptions from "./TribeUser/PaymentOptions";
// api
import { getRegAPI } from "api/api";
//context
import { BalanceContext } from "context/balanceContext";
import { DiscountContext } from "context/discountContext";
import { TribeIsUpdatedContext } from "context/tribeIsUpdateContext";


const useStyles = makeStyles(() => ({
    stteper: {
        '& .MuiStepConnector-root': {
            margin: "0px !important",
            padding: "0.5rem"
        },
        '& .MuiStepConnector-line': {
            borderColor: "#797979 !important",
            borderRightWidth: "2px !important",
            minHeight: "2rem !important"

        }
    },
    cartBadge: {
        "& .MuiSvgIcon-root": {
            margin: 'auto'
        },
        '& .MuiBadge-badge': {
            top: "12px",
            color: '#ffffff !important',
            fontWeight: '600 !important'
        }
    }
}))

export const Cart = ({ removeProduct, userType, isPaid, setIsPaid, isUpdated, setisUpdated, CardCode = "", childrenList }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [level, setLevel] = useState(1); // the current level/slide of the cart
    const [disabledButton, setDisabledButton] = useState(false);
    const [statemantUpdater, setStatemantUpdater] = useState(false);
    const [tribePayment, setTribePayment] = useState(false);
    const [products] = useGlobal((state) => state.products);
    const [cartState, setCartState] = useGlobal((state) => state.cartState, (actions) => actions.setCartState);
    const [prevProductsIds, setPrevProducts] = useState(getIds(products));
    const [balance, setBalance] = useState({ familyId: '', amount: 0 });
    const [discount, setDiscount] = useState({ sum: 0 });
    const balanceContextObj = { balance, setBalance };
    const discountContextObj = { discount, setDiscount };
    const { tribeUpdate, setUpdated } = useContext(TribeIsUpdatedContext);
    const [cartFamily] = useGlobal((state) => state.cartFamily);
    const [UTDRequests, setUTDRequests] = useGlobal((state) => state.UTDRequests, (actions) => actions.setUTDRequests);

    useEffect(() => {
        let isMounted = true;
        if (cartFamily) {
            const getUTDReqs = async (code) => {
                const { data } = await getRegAPI({ ParentCardCode: code, Status_request: '0' });
                isMounted && setUTDRequests(data);
            }
            if (cartFamily) {
                getUTDReqs(cartFamily);
            }
            else if (CardCode) {
                getUTDReqs(CardCode);
            }
            else setUTDRequests([]);
        }
        return () => { isMounted = false }
    }, [cartFamily, CardCode])

    const nextLevel = () => {
        if ((userType === 'parent' || userType === 'kibbutz') && level === 2 && !disabledButton) {
            setStatemantUpdater(true);
        }
        else { setLevel(prev => prev + 1); }
    }

    const tribeNextLevel = () => {
        console.log(level)
        if (products.some((el) => el.isTribeUserOpen)) {
            if (level === 2 && !disabledButton) {
                setStatemantUpdater(true);
            }
            else if (level === 3 && !disabledButton) {
                setTribePayment(true);
            }
            else { setLevel(prev => prev + 1); }
        }
        else {
            if (level === 3 && !disabledButton) {
                setTribePayment(true);
            }
            else { setLevel(prev => prev + 2); }
        }
    }

    const prevLevel = () => {
        if ((userType === 'parent' || userType === 'kibbutz' || userType === 'tribe') && level === 2 && disabledButton) {
            setDisabledButton(false);
        }
        setLevel(prev => prev - 1);
        if (userType === 'tribe' && level > 2 && disabledButton) {
            setLevel(1);
        }
    }

    const onCloseCart = () => {
        if (isPaid) {
            if (userType === 'parent' || userType === 'kibbutz') { setisUpdated(isUpdated + 1); }
            else { setUpdated(tribeUpdate + 1); }
        }
        setDisabledButton(false);
        setCartState(false);
        setLevel(1);
    }

    // avoid recall to  getDiscount API
    const productsHasUpdated = useMemo(() => {
        const arrayChanged = () => {
            setPrevProducts(getIds(products));
            return { changed: true };
        }
        //check only if cart is open
        if (cartState) {
            // if remove/add some object no need for check => its updated
            if (Object.keys(prevProductsIds).length !== products.length)
                return arrayChanged();
            // same length => check if some object id has changed
            else {
                const notChanged = products.every(el => !!prevProductsIds[el._id])
                if (!notChanged) {
                    return arrayChanged();
                }
            }
        }
        return { changed: false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartState, products])

    const tribeCartLevels = [
        <FirstStep onDeleteFromCart={removeProduct} onCloseCart={onCloseCart} products={products} />,
        <ParentStatment setStatemantUpdater={setStatemantUpdater} statemantUpdater={statemantUpdater} products={products} setDisabledButton={setDisabledButton} prevLevel={prevLevel} nextLevel={nextLevel} setLevel={setLevel} childrenList={childrenList} />,
        <PaymentOptions products={products} onCloseCart={onCloseCart} setDisabledButton={setDisabledButton} setTribePayment={setTribePayment} tribePayment={tribePayment} setLevel={setLevel} prevLevel={prevLevel} setIsPaid={setIsPaid} />,
        <ApprovalPayment onCloseCart={onCloseCart} setIsPaid={setIsPaid} />
    ];
    const parentCartLevels = [
        <FirstStep onDeleteFromCart={removeProduct} onCloseCart={onCloseCart} products={products} />,
        <ParentStatment setStatemantUpdater={setStatemantUpdater} statemantUpdater={statemantUpdater} products={products} setDisabledButton={setDisabledButton} prevLevel={prevLevel} nextLevel={nextLevel} setLevel={setLevel} childrenList={childrenList} />,
        <ParentPayment products={products} setLevel={setLevel} />,
        <CreditCardLevel setLevel={setLevel} onCloseCart={onCloseCart} setIsPaid={setIsPaid} />,
        <CashAndCheckApproval setLevel={setLevel} onCloseCart={onCloseCart} setIsPaid={setIsPaid} />,
        <SplitPayment setLevel={setLevel} onCloseCart={onCloseCart} setIsPaid={setIsPaid} />,
        <SplitPaymentApproval products={products} setLevel={setLevel} onCloseCart={onCloseCart} setIsPaid={setIsPaid} />,
        <ApprovalPayment onCloseCart={onCloseCart} setLevel={setLevel} setIsPaid={setIsPaid} />
    ];
    const kibbutzCartLevels = [
        <FirstStep onDeleteFromCart={removeProduct} onCloseCart={onCloseCart} products={products} />,
        <ParentStatment setStatemantUpdater={setStatemantUpdater} statemantUpdater={statemantUpdater} products={products} setDisabledButton={setDisabledButton} prevLevel={prevLevel} nextLevel={nextLevel} setLevel={setLevel} childrenList={childrenList} />,
        <KibbutzApproval products={products} onCloseCart={onCloseCart} setIsPaid={setIsPaid} />
    ];
    const cartLevels = (levelsArray) =>
        levelsArray.map((Component, index) => {
            return (<MySlide currentLevel={level} slideLevel={index + 1} key={`cartLevelsSlide-${index}`}>
                {Component}
            </MySlide>)
        })

    // build string equals to ont of the levels and use eval to send it to function example:
    // userType = kibbutz -->eval('kibbutzCartLevels')

    const showLevelsByRole = cartLevels(eval(`${userType}CartLevels`))
    const showBalance = userType === 'parent' ? level < 3 : level !== 4;
    const steps = userType === 'parent' ?
        [{ label: t('verticalStepper1'), icon: <ShoppingCartOutlinedIcon sx={{ fontSize: "2rem" }} /> },
        { label: t('verticalStepper2'), icon: <TextSnippetOutlinedIcon sx={{ fontSize: "2rem" }} /> },
        { label: t('verticalStepper3'), icon: <PaymentsOutlinedIcon sx={{ fontSize: "2rem" }} /> }] :
        [{ label: t('verticalStepper1'), icon: <ShoppingCartOutlinedIcon sx={{ fontSize: "2rem" }} /> },
        { label: t('verticalStepper3'), icon: <PaymentsOutlinedIcon sx={{ fontSize: "2rem" }} /> }];

    return (
        <BalanceContext.Provider value={balanceContextObj}>
            <DiscountContext.Provider value={discountContextObj}>
                <SwipeableDrawer
                    anchor="right"
                    onClose={e => onCloseCart(e)}
                    onOpen={e => setCartState(true)}
                    dir="rtl"
                    open={cartState}
                    variant={level === 2 && userType === 'parent' ? "persistent" : "temporary"}
                    hideBackdrop={false}
                    PaperProps={{
                        sx: {
                            left: 'auto',
                            right: 0,
                            height: '100%',
                            width: {
                                xs: '90%',
                                sm: '75%',
                                md: '40%',
                                lg: '35%'
                            }
                        }
                    }}
                    disableBackdropTransition={true}
                    disableSwipeToOpen={true}
                >
                    {level < 4 &&
                        <Box sx={{
                            position: 'inherit',
                            backgroundColor: "#f0f3f0",
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            zIndex: 1201,
                            height: "18rem",
                            alignItems: "center",
                            padding: "0.5rem",
                            top: "4rem",
                            right: {
                                sm: '75%',
                                md: '40%',
                                lg: '35%'
                            },
                            display: { xs: "none", sm: "flex" }
                        }}>
                            <Stepper activeStep={level} orientation="vertical" sx={{ display: "flex", alignItems: "center" }} className={classes.stteper}>
                                {steps.map((step, index) => (
                                    <CartStepStyle completed={level > index + 1} active={level === index + 1} key={step.label} >
                                        {!index ? <Badge
                                            className={classes.cartBadge}
                                            color="primary"
                                            showZero
                                            badgeContent={products.length}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                            {step.icon}
                                        </Badge> :
                                            <>
                                                {step.icon}
                                            </>}
                                        <StepLabel >
                                            {step.label}
                                        </StepLabel>
                                    </CartStepStyle>
                                ))}
                            </Stepper>
                        </Box>}
                    <Box flex={'1'} sx={{ backgroundColor: "#f0f3f0" }}>
                        {userType === 'parent' && level > 2 && level !== 8 && level !== 7 && level !== 4 && <ParentCartHeader setLevel={setLevel} title={t('payment')} />}
                        {showLevelsByRole}
                    </Box>
                    {showBalance && !!products.length && <Balance
                        products={products}
                        nextLevel={userType === 'tribe' ? tribeNextLevel : nextLevel}
                        disabledButton={disabledButton || products.length === 0}
                        productsHasUpdated={productsHasUpdated}
                        statemantUpdater={statemantUpdater}
                        tribePayment={tribePayment} />}
                </SwipeableDrawer>

            </DiscountContext.Provider>
        </BalanceContext.Provider>
    )
}
export const MySlide = ({ currentLevel, slideLevel, children }) => {
    const visbleSlide = currentLevel === slideLevel;
    return (
        <>
            {visbleSlide &&
                <Slide timeout={{ enter: slideLevel === 1 ? 0 : 1000, exit: 1000 }} direction='right' in={visbleSlide} style={{ transformOrigin: '0 0 0' }} >
                    <Box height={'100%'}>
                        {children}
                    </Box>
                </Slide>}
        </>
    )
}

const getIds = (array) => {
    const objectOfIds = {}
    array.map(el => objectOfIds[el._id] = true)
    return objectOfIds;
}