import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { paymentsService, addCreditTransactionAPI } from "../../api/api";
import { Box, Typography } from '@mui/material';
import { PaymentLoader } from "./PaymentLoader";
import useGlobal from "../../store/store";
import noActivity from '../../assets/noActivity.svg';

const MakeTransaction = ({ tribeUser = false, divorcedParent = false, cardAmount }) => {
    const { t } = useTranslation();
    const paymentsServiceAPI = useApi(paymentsService);
    const [products] = useGlobal((state) => state.products);
    const [redirectUrl, setRedirectUrl] = useState();
    const [someError, setSomeError] = useState(false);
    const [user] = useGlobal((state) => state.user);

    useEffect(() => {
        transaction();
    }, [])

    const transaction = async () => {
        try {
            const pymtData = {
                "registrationRequests": products,
                "getPaymentLink": true,
                "origin": window.location.origin
            }

            if (tribeUser) {
                pymtData.manualTotal = cardAmount;
            }

            if (divorcedParent) {
                pymtData.divorced = { isDivorced: true, divorced2: false };
            }

            const result = await paymentsServiceAPI.requestPromise(pymtData);

            window.mid = result.cg.ashrait.response.doDeal.mid._text;
            window.terminal = result.cg.ashrait.response.doDeal.terminalNumber._text;
            window.txid = result.cg.ashrait.response.doDeal.token._text;
            window.products = pymtData.registrationRequests;
            window.isDivorced = divorcedParent;
            window.invoices = [];

            const productsIds = pymtData.registrationRequests.map((pr) => { return pr._id });

            const transction = {
                mid: result.cg.ashrait.response.doDeal.mid._text,
                terminalNumber: result.cg.ashrait.response.doDeal.terminalNumber._text,
                txId: result.cg.ashrait.response.doDeal.token._text,
                isDivorced: divorcedParent,
                invoices: [],
                productsIds,
                userEmail: user.email,
                userPhoneNumber: user.phone_number,
                parent1_Email: pymtData.registrationRequests[0].Parent1_Mail,
                parent1_Id: pymtData.registrationRequests[0].Parent1_ID,
                parent1_Firstname: pymtData.registrationRequests[0].Parent1_FirstName,
                parent1_Lastname: pymtData.registrationRequests[0].Parent1_LastName,
                parentCardCode: pymtData.registrationRequests[0].ParentCardCode,
                tribeCode: pymtData.registrationRequests[0].TribeCode,
                userRole: user.role,
                date: new Date().toString()
            }
            if (result.cg.ashrait.response.doDeal.mpiHostedPageUrl._text) {
                setRedirectUrl(result.cg.ashrait.response.doDeal.mpiHostedPageUrl._text);
            }
            else {
                const error = { cgFaild: true };
                throw error;
            }
            await addCreditTransactionAPI(transction);
        } catch (error) {
            setSomeError(true);
            console.log(error);
        }
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            {paymentsServiceAPI.error || someError &&
                <Box display="flex" flexDirection="column" alignItems="center" sx={{
                    borderRadius: "20px",
                    margin: "1rem",
                    padding: "1rem",
                    overflow: "auto",
                    height: "23rem"
                }}><img
                        width='100px'
                        height='100px'
                        alt="..."
                        src={noActivity}
                        loading="lazy"
                    />
                    <Typography sx={{ fontWeight: 'bold', color: "#064601", textAlign: "center" }}>{t("oops")}</Typography>
                    <Typography sx={{ fontWeight: 'bold', color: "#064601", textAlign: "center" }}>{t("dontfall")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("youCanChoose")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("refrash")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("supports")}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{t("otherPaymentMethod")}</Typography>
                </Box>}
            {paymentsServiceAPI.loading ?
                <PaymentLoader /> :
                <iframe src={redirectUrl}
                    id={"creditGuardiFrame"}
                    style={{ display: "block", border: "none", height: "43rem", width: "100%", marginTop: "1rem" }}
                    title={"payment"} />
            }
        </Box>)
}
export default MakeTransaction;