
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "hooks/useApi";
//mui
import { Typography, Box, FormControl, Select, MenuItem, TextField, Autocomplete, CircularProgress, InputLabel, InputAdornment, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
//api
import { getSchoolsList, getTroopGroup } from "api/api";
//components
import GenericDialog from "Components/Common/GenericDIalog";
import { GreenButton } from "Components/Login/EmailEmptyModal";
//data
import { permanentInfo } from 'data/permanentInfo';

const FiltersDialog = ({ open, setOpen, tribeCode, filterObj, setFilterObj, setApplyFilter, setClearFilter, classNo, troopsList, troopFilter, handleCureentFilter, handleClearCurrentFilter }) => {
    const { t } = useTranslation();
    const schools = useApi(getSchoolsList);
    const getTroopData = useApi(getTroopGroup);
    const [troopList, setTroopList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [schoolsList, setSchoolList] = useState([]);

    const [schoolValue, setSchoolValue] = useState('');
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedTroop, setSelectedTroop] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [schoolOpen, setSchoolOpen] = useState(false);

    useEffect(() => {
        let isMounted = true;
        isMounted && getSchoolsData();
        return () => { isMounted = false }
    }, [])

    useEffect(() => {
        let isMounted = true;
        isMounted && open && getTroopGroupData();
        return () => { isMounted = false }
    }, [classNo, open])

    useEffect(() => {
        let isMounted = true;
        isMounted && setFilterObj({ ...filterObj, selectedTroop, selectedGroup, selectedSchool })
        return () => { isMounted = false }
    }, [selectedTroop, selectedGroup, selectedSchool])

    const getSchoolsData = async () => {
        const res = await schools.requestPromise();
        setSchoolList(res.d.results);
    }
    const getTroopGroupData = async () => {
        if (classNo) {
            const classNumber = Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === classNo)
            const query = { childClass: classNumber, childTribe: tribeCode };
            const troopResult = await getTroopData.requestPromise(query);
            const uniqueTroopNames = new Set();
            const troopList = [];
            const groupList = [];
            troopResult.forEach((tr) => {
                if (!uniqueTroopNames.has(tr.TroopName)) {
                    troopList.push(tr.TroopName);
                    uniqueTroopNames.add(tr.TroopName);
                }
                if (tr.TroopGroupName) { groupList.push(tr.TroopGroupName); }
            });
            setTroopList(troopList);
            setGroupList(groupList);
        }
    }

    const schoolValueChanged = (value) => {
        setSelectedSchool(value.SchoolCode);
        setSchoolOpen(false);
    }
    const handleFilter = () => {
        setApplyFilter(true);
        setOpen(false);
    }
    const clearFilter = () => {
        setSelectedSchool(null);
        setSelectedTroop(null);
        setSelectedGroup(null);
        setFilterObj({});
        setClearFilter(true);
        setApplyFilter(false);
        setOpen(false);
    }

    return (
        <GenericDialog open={open} setOpen={setOpen} showRopes={true} height={"32rem"}>
            <Typography color="#064601" mt="1rem" variant="mediumBoldText">{t('filters')}</Typography>
            <Box display='flex' flexDirection='column' marginTop='1.5rem' alignItems='center' width='24rem'>

                <FormControl fullWidth>
                    <InputLabel>{t('troop')}</InputLabel>
                    <Select
                        fullWidth={true}
                        sx={{ marginBottom: "1rem" }}
                        id="troop"
                        name="troop"
                        label={t("troop")}
                        disabled={!classNo || getTroopData.loading}
                        value={selectedTroop}
                        onChange={(e) => setSelectedTroop(e.target.value)}
                        endAdornment={(
                            <>
                                {getTroopData.loading && <CircularProgress color="inherit" size={20} />}
                            </>)}
                    >
                        {troopList.map((troop, i) => {
                            return <MenuItem value={troop} key={i}>{troop}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>{t('group')}</InputLabel>
                    <Select
                        fullWidth={true}
                        sx={{ marginBottom: "1rem" }}
                        id="group"
                        name="group"
                        label={t("group")}
                        disabled={!classNo || getTroopData.loading || !groupList.length}
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        endAdornment={(
                            <>
                                {getTroopData.loading && <CircularProgress color="inherit" size={20} />}
                            </>)}
                    >
                        {groupList.map((group, i) => {
                            return (
                                <MenuItem value={group} key={i}>{group}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <InputLabel sx={{ alignSelf: "flex-start" }}>{t("multiple")}</InputLabel>
                <FormControl fullWidth>
                    <InputLabel>{t("filterByCurrentTroops")}</InputLabel>
                    <Select
                        id="currentTroops"
                        name="currentTroops"
                        multiple={true}
                        label={t("filterByCurrentTroops")}
                        fullWidth={true}
                        sx={{ marginBottom: "1rem" }}
                        value={troopFilter}
                        disabled={!classNo}
                        onChange={e => handleCureentFilter(e.target.value)}
                        endAdornment={
                            troopFilter && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={e => handleClearCurrentFilter()}
                                        size="small"
                                        aria-label="clear filter"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    >
                        {troopsList.map((troop, i) => {
                            return (
                                <MenuItem value={troop.filterTroop} key={i}>{troop.filterTroop}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <Autocomplete
                    fullWidth={true}
                    sx={{ marginBottom: "1rem" }}
                    id="school"
                    name="school"
                    open={schoolValue.length >= 3 && schoolOpen}
                    inputValue={schoolValue}
                    onInputChange={(event, newInputValue) => {
                        setSchoolValue(newInputValue);
                        setSchoolOpen(true);
                    }}
                    noOptionsText={t('schoolNoOption')}
                    onChange={(e, value) => schoolValueChanged(value)}
                    options={schoolsList.sort((a, b) =>
                        b.CityName.toString().localeCompare(a.CityName.toString())
                    )}
                    isOptionEqualToValue={(option, value) => option.SchoolCode === value.SchoolCode}
                    getOptionLabel={(option) => `${option.SchoolName} , ${option.CityName}`}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={`${option.SchoolCode}`}>
                            <div style={{ color: "#3a7735", fontSize: "0.8rem" }}>{option.SchoolName}</div>,<div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>{option.CityName}</div>
                        </Box>
                    )}
                    renderInput={(params) =>
                        <TextField {...params}
                            label={t('schoolSearch')}
                            placeholder={t("searchPlaceHolder1")}
                            InputProps={{
                                ...params.InputProps,
                                inputMode: "search",
                                endAdornment: (
                                    <>
                                        {schools.loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>)
                            }} />}
                />
                <Box display="flex">
                    <GreenButton negative="true" onClick={e => clearFilter()}>{t('clearFilter')}</GreenButton>
                    <GreenButton onClick={e => handleFilter()} >{t('makeFilter')}</GreenButton>
                </Box>
            </Box>
        </GenericDialog>
    )
}

export default FiltersDialog;