import { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { useNavigate, usePath } from 'raviger'
import { useTranslation } from 'react-i18next';
import useGlobal from "../../store/store";
import useFeathers from "../../hooks/useFeathers";
//mui
import { AppBar, IconButton, Toolbar, Box, Hidden, ListItem, SwipeableDrawer, Typography, Link, Button, Badge, Tooltip } from "@mui/material";
import { HBox, VBox } from '../../assets/SharedComponents';
//componentes
import { LanguagePicker } from '../Common/LanguagePicker'
import AppBarDropdown from "Components/Common/AppBarDropdown";
import GenericDialog from "Components/Common/GenericDIalog";
//mui-icons
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
//assets
import Logo from '../../assets/Logo.png';
import appBG from '../../assets/appBG.jpg';
import donate from '../../assets/donate.svg';

const useStyles = makeStyles(() => ({
    cartBadge: {
        "&:hover": {
            cursor: 'pointer',
        },
        "& .MuiSvgIcon-root": {
            margin: 'auto'
        },
        '& .MuiBadge-badge': {
            top: "12px",
            color: '#ffffff !important',
            fontWeight: '600 !important'
        }
    },
    cartButton: {
        "&:hover": {
            backgroundColor: '#f0f3f0 !important',
        },
    },
    TZToolbar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30px !important'
    },
    paddingBottom2: {
        paddingBottom: '2rem'
    },
    mobileListItem: {
        justifyContent: 'center !important',
        padding: '0 !important'
    },
    linkMobileDiff: {
        color: '#3a7735 !important',
        background: 'white !important',
        borderRadius: '2rem !important',
        height: '2rem ',
        marginBottom: '1rem !important',
        textTransform: "capitalize !important",
        fontSize: "1rem !important",
        width: "85%",
        '&:hover': {
            backgroundColor: '#b7c7b6 !important'
        }
    },
    linkMobile: {
        fontSize: "1rem !important",
        color: 'white !important',
        width: '100% !important',
        padding: '0.7rem !important',
        paddingInlineEnd: '4rem !important',
        justifyContent: 'start !important',
        textTransform: "capitalize !important",
        '&:hover': {
            backgroundColor: '#7d9f7b82 !important',
            borderRadius: "100px !important"
        }
    },
    linkDesktop: {
        padding: '0.7rem 0',
        margin: '0 1rem',
        color: 'white !important',
        marginBottom: '3px !important',
        textDecorationLine: 'none !important',
        '&:hover': {
            cursor: 'pointer',
            marginBottom: '0 !important',
            borderBottom: '3px solid white'
        }
    },
    activeLink: {
        marginBottom: '0 !important',
        borderBottom: '3px solid white'
    },
    smallMarginEnd: {
        marginInlineEnd: '1rem !important'
    },
    greenBackground: {
        '& .MuiDrawer-paper.MuiDrawer-paper': {
            backgroundImage: `url(${appBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom'
        }
    },
    smallYellowButton: {
        width: "7rem !important",
        height: "2rem !important",
        borderRadius: "50px !important",
        backgroundColor: "#ffbb41 !important",
        boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.11) !important",
        fontWeight: "700 !important",
        color: "#064601 !important",
        textAlign: "center !important",
        alignItems: "stretch !important",
        textTransform: "capitalize !important",
        '&:hover': {
            backgroundColor: "#cee2cd !important",
        }
    }
}));

const ParentAppBar = ({ setAppBarTitle, appBarTitle, setKibbutzUser, products, setCartState }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [openDonate, setOpenDonate] = useState(false);
    const discountsLink = "https://www.zofim.org.il/discount/";
    const navigate = useNavigate();
    const auth = useFeathers();
    const location = usePath();

    const [user, setUser] = useGlobal((state) => state.user, (actions) => actions.setUser);
    const isActive = (path) => location === path;

    const oTitles = {
        parentUserSettings: t("settings"),
        homepage: t("activities"),
        contact: t("contactTribe"),
        parent: t("activities"),
        MyFamily: t("myFamily"),
        reghist: t("history"),
        childDetailsEditor: t("childDetailsEditor")
    };
    const myFamLinks = [{ name: t("myFamily"), href: '/parent/MyFamily', title: t("myFamily") }, { name: t("addStudent"), href: '/parent/addChild', title: t("addStudent") }, { name: t("userSettings"), href: '/parent/parentUserSettings', title: t("settings") }]
    const myHistoryLinks = [{ name: t("regActives"), href: '/parent/reghist', title: t("regActives") }, { name: t("myRefunds"), href: '/parent/regHistRefunds', title: t("myRefunds") }]

    const onCartClick = () => {
        const isKibbutz = products.length && products[0].Type === "02";
        setKibbutzUser(isKibbutz);
        setCartState(true);
    }
    useEffect(() => {
        let aHref = window.location.href.split("/")
        const sHref = aHref[aHref.length - 1];
        const sTitle = oTitles[sHref] || ""
        setAppBarTitle(sTitle);
    }, []);

    useEffect(() => {
        let isMounted = true;
        isMounted && checkUserRole();
        return () => { isMounted = false }
    }, []);
    const checkUserRole = async () => {
        try {
            if (user.role === undefined)
                await auth.reAuthenticate();
        } catch (error) {
            console.log(error);
        }
    }

    const navigationLinks = [
        { name: t("activitiesRegistration"), href: '/parent', title: t("Activities"), icon: <ArrowBackIosIcon /> },
        { name: t("historyMenuTitle"), href: '/parent/reghist', title: t("historyMenu"), icon: <PersonPinOutlinedIcon /> },
        { name: t("myRefunds"), href: '/parent/regHistRefunds', title: t("myRefunds"), icon: <RequestQuoteOutlinedIcon /> },
        { name: t("familyAndBalance"), href: '/parent/MyFamily', title: t("myFamily"), icon: <GroupsOutlinedIcon /> },
        { name: t("addFamilyMembers"), href: '/parent/addChild', title: t("myFamily"), icon: <PersonAddAltIcon /> },
        { name: t("settingsAndUserDetails"), href: '/parent/parentUserSettings', title: t("settings"), icon: <ManageAccountsOutlinedIcon /> },
        { name: t("contactTribe"), href: '/parent/contact', title: t("contactTribe"), icon: <MailOutlineOutlinedIcon /> },
        { name: t("membersDiscount"), href: "https://www.zofim.org.il/discount/", icon: <DiscountOutlinedIcon />, type: "newTab" },
        { name: t("disconnect"), href: '/', icon: <PowerSettingsNewIcon sx={{ color: "#f9bc45" }} />, func: true }
    ]

    const logOut = () => {
        auth.logOut();
    }

    const handleLinksClick = (sHref, sTitle, type = "") => {
        if (type === "newTab") {
            setOpen(false);
            window.open(sHref, "_blank")
            return;
        }
        setOpen(false);
        navigate(sHref);
        setAppBarTitle(sTitle);

    }
    const linksClick = (item) => {
        if (item.func) { logOut(); }
        handleLinksClick(item.href, item.title, item.type);
    }

    return (
        <AppBar sx={{
            backgroundImage: `url(${appBG})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
            <Toolbar className={classes.TZToolbar} >
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Box alignItems="center" display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                        {/* right content */}
                        <Box alignItems="center" display="flex" flexDirection="row" sx={{ visibility: { xl: 'hidden' } }}>
                            <IconButton onClick={e => setOpen(true)} color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        {/* mid content */}

                        <Box display="flex" flexDirection="column" alignItems="center" marginTop="1.5rem" marginRight={{ xs: "3rem", sm: "0rem" }}>
                            <img
                                width='auto'
                                height='44px'
                                alt="..."
                                src={Logo}
                                loading="lazy"

                            />
                            <Typography sx={{ fontSize: { sm: "1.25rem" } }} > {appBarTitle}</Typography>
                        </Box>
                        {/* left content */}
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Hidden mdDown>
                                <Button onClick={e => setOpenDonate(true)} className={classes.smallYellowButton} startIcon={<VolunteerActivismIcon sx={{ color: "#064601" }} />}>{t("donate")}</Button>
                                <Tooltip title={t("disconnect")}>
                                    <IconButton size="small" onClick={e => logOut()}>
                                        <PowerSettingsNewIcon sx={{ color: "#f9bc45" }} />
                                    </IconButton>
                                </Tooltip>
                                <LanguagePicker />
                            </Hidden>
                        </Box>
                    </Box>

                    <Box justifyContent={'start'} width={'100%'} alignItems="center" display="flex" flexDirection="row">
                        <Hidden xlDown>
                            <Link onClick={e => handleLinksClick('/parent/homepage', t("Activities"))} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/parent/homepage') ? classes.activeLink : ''}`}>
                                {t("Activities")}
                            </Link>
                            <AppBarDropdown setAppBarTitle={setAppBarTitle} links={myFamLinks} title={t("myFamily")} />
                            <AppBarDropdown setAppBarTitle={setAppBarTitle} links={myHistoryLinks} title={t("myRegistrations")} />
                            <Link onClick={e => handleLinksClick('/parent/contact', t("contactTribe"))} className={`${classes.linkDesktop} ${classes.smallMarginEnd} ${isActive('/parent/contact') ? classes.activeLink : ''}`}>
                                {t("contactTribe")}
                            </Link>
                            <Link onClick={e => handleLinksClick(discountsLink, '', "newTab")} className={`${classes.linkDesktop} ${classes.smallMarginEnd} `}>
                                {t("membersDiscount")}
                            </Link>
                        </Hidden>
                    </Box>

                    <Box justifyContent={'end'} width={'100%'} alignItems="center" display="flex" flexDirection="row">
                        <Button sx={{ backgroundColor: "#f0f3f0", height: "5rem", position: "fixed", top: { xs: "3rem", sm: "5rem", xl: "7rem" }, right: "0", borderTopLeftRadius: "16px", borderBottomLeftRadius: "16px", boxShadow: '0 8px 9px rgb(125 125 125 / 40%)' }} onClick={e => onCartClick()} className={classes.cartButton}>
                            <Badge
                                className={classes.cartBadge}
                                color="primary"
                                showZero
                                badgeContent={products.length}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                <ShoppingCartOutlinedIcon sx={{ fontSize: "2.5rem", color: "#3a7735" }} />
                            </Badge>
                        </Button>
                    </Box>

                </Box>
            </Toolbar>
            {/* side bar content */}
            <SwipeableDrawer onClose={e => setOpen(false)} onOpen={e => setOpen(true)} dir="rtl" className={classes.greenBackground} open={open} sx={{ display: { xl: 'none' } }}>
                <HBox sx={{ justifyContent: "space-between !important", alignItems: "center" }} >
                    <IconButton onClick={e => setOpen(false)} >
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                    <LanguagePicker />
                </HBox>
                <VBox alignItems={'center'} className={classes.paddingBottom2}>
                    <img width={'41px'}
                        height='60px'
                        alt="..."
                        src={Logo}
                        loading="lazy"
                    />
                    <Typography color='secondary' variant="h5" fontWeight={'600'}>{t("parentsPortal")}</Typography>
                    <Button onClick={e => setOpenDonate(true)} className={classes.smallYellowButton} startIcon={<VolunteerActivismIcon sx={{ color: "#064601" }} />}>{t("donate")}</Button>
                </VBox>

                {navigationLinks.map((item, i) => (
                    <ListItem key={`navigationDrawerLinks-${i}`} className={classes.mobileListItem}>
                        {i === 0 ?
                            <Button onClick={e => linksClick(item)} className={classes.linkMobileDiff} endIcon={item.icon}>
                                {item.name}
                            </Button>
                            :
                            <Button onClick={e => linksClick(item)} className={classes.linkMobile} startIcon={item.icon}>
                                {item.name}
                            </Button>}
                    </ListItem>
                ))}
            </SwipeableDrawer>
            {openDonate && <GenericDialog open={openDonate} setOpen={setOpenDonate} iconSVG={donate} showRopes={false} height="48rem">
                <Typography sx={{ color: "#064601", fontWeight: "bold", fontSize: "1.2rem" }}>{t("donateTitle")}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t("donateText1")}</Typography>
                <Typography sx={{ textAlign: "center" }} component='span'><Typography sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={e => window.open("https://drive.google.com/file/d/11FBj0touLrl48FMEYbq4GiFdUm7vaP4O/view?usp=sharing")}>{t("kerenZofim")}</Typography>{t("donateText2")}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t("donateText3")}</Typography>
                <Typography sx={{ textAlign: "center", fontSize: "0.875rem" }}>{t("donateText4")}</Typography>
                <Box display="flex" flexDirection="column" alignItems="center" width="100%" sx={{ overflow: "auto" }}>
                    <iframe src="https://v.cardcom.solutions/EA5/G9QhLH0gEa2BHIy0ywsMg/Order"
                        id={"donateiFrame"}
                        style={{ display: "block", border: "none", height: "43rem", width: "100%" }}
                        title={"donate"}
                    />
                </Box>
            </GenericDialog>}
        </AppBar >
    );
}
export default ParentAppBar;