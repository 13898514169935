import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// mui
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Card, Checkbox, CardContent, Typography, Link, styled } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
// components
import { HBox, VBox } from '../../assets/SharedComponents';
import StatusChip from 'Components/Common/StatusChip';
import { useTranslation } from 'react-i18next';
import { MessageBox } from 'Components/Common/MessageBox';
//data
import { permanentInfo } from 'data/permanentInfo'
import { removeRegAPI } from 'api/api';
import { BottomCancelReg, ContactCancelReg, TopCancelReg } from 'Components/Common/CancelRegPopups';

const RequestCard = styled(Card)(({ theme, ...props }) => ({
    borderRadius: '1rem',
    backgroundColor: "#ffffff",
    width: '100%',
    border: '1px solid #0000004d',
    '& .MuiCardContent-root': {
        paddingBottom: '0',
        padding: '0'
    },
    '& .MuiTypography-root': {
        color: !!props.disable && 'grey',
        margin: !!props.disable && '0'
    },
    '& .disabledBackground': {
        backgroundColor: !!props.disable && 'grey !important',
        color: !!props.disable && 'black'
    },

}));
const RequestCardCont = styled(CardContent)(() => ({
    width: '100%',
    marginEnd: '2rem',
    paddingBottom: '0 !important',


}));
const CheckBoxIcon = styled(Checkbox)(({ theme }) => ({
    padding: 0,
    [theme.breakpoints.up('xs')]: {
        order: 1
    },
    "& .MuiSvgIcon-root": {
        color: theme.palette.primary['main'],
        height: '1em !important',
        [theme.breakpoints.down('sm')]: {
            height: '0.7em !important'
        }
    }
}));

const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#3a7735',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        fontSize: '1rem',
    }
}));

const ActivityReq = ({ request, cartFamily, familyId, products, removeProduct, getOpenRequsts, handleSelection, tribeUserSelection, nodesActivities }) => {
    const { t } = useTranslation();
    let paymentMethod = permanentInfo.paymentMethods[request.Payment_Method];

    const [visibleCheckBox, setVisibleCheckBox] = useState(false);
    const [messageBoxState, setMessageBox] = useState({ open: false });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (products?.length) {
            setVisibleCheckBox(request?.ParentCardCode === products[0].ParentCardCode);
        }
        else {
            setVisibleCheckBox(true);
        }
    }, [tribeUserSelection, products]);

    const handleCart = (checked) => {
        handleSelection(request, checked ? "add" : "sub");
        if (request.ItemPurposeType === "4" && !request.Father) {
            nodesActivities.forEach(el => {
                handleSelection(el, checked ? "add" : "sub");
            });
        }
    };
    const onCancelRegistration = (row) => {
        if (request.ItemPurposeType === "4" && !request.Father) {
            try {
                removeProduct(request);
                removeRegAPI(row);
                nodesActivities.forEach(el => {
                    removeProduct(el);
                    removeRegAPI(el);
                });
            } catch (error) {
                console.log(error);
            }

        }
        else {
            try {
                removeProduct(request);
                removeRegAPI(row);

            } catch (error) {
                console.log(error);
            }
        }

        const closeMsgBox = () => {
            setMessageBox({ open: false });
            getOpenRequsts();
        };
        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <TopCancelReg closeMsgBox={closeMsgBox} />,
            content:
                <ContactCancelReg tribe={true} />,
            actions:
                <BottomCancelReg closeMsgBox={closeMsgBox} />
        });
    };
    const visibleAct = cartFamily && (familyId !== cartFamily) ? 'true' : '';
    const leftToPay = request.FinalPrice - request.paid > 0 ? +(request.FinalPrice - request.paid).toFixed(2) : 0;
    const isInSelect = !!tribeUserSelection.find(el => el._id === request._id);
    const disabled = isInSelect && request.selStatus === "2"

    const copyPayLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/spl/${request._id}`);
        const key = enqueueSnackbar(t('copiedLink'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500)
    }
    const names = nodesActivities.map(el => el.ActivName)

    return (
        <>
            <RequestCard disable={visibleAct}>
                <RequestCardCont>
                    <HBox alignContent={'center'} alignItems={'center'} padding="1rem" justifyContent="space-between" flexWrap="wrap">
                        <HBox alignContent={'center'} alignItems={'center'} >
                            <HBox alignItems='center' width='3rem' height='2rem'>
                                {!visibleAct &&
                                    <CheckBoxIcon value={isInSelect} disabled={disabled} checked={isInSelect || false}
                                        icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: disabled ? 'gray' : '#3a7735' }} />}
                                        checkedIcon={<CheckBoxOutlinedIcon sx={{ color: disabled ? 'gray' : '#3a7735' }} />}
                                        onChange={e => handleCart(e.target.checked)}

                                    />}
                            </HBox>
                        </HBox>
                        <HBox alignContent={'center'} alignItems={'center'} >
                            <Typography textAlign={'center'} variant="regReqNameAct"> {request.ActivName} </Typography>
                        </HBox>
                        {!!nodesActivities.length && <MyTooltip arrow title={
                            <VBox >
                                {names.map(name => <Typography>{name}</Typography>)}
                            </VBox>}>
                            <HBox alignContent={'center'} alignItems={'center'} sx={{ color: "#3a7735", fontWeight: "bold" }} >
                                <DynamicFeedOutlinedIcon />{`(${nodesActivities.length})`}
                            </HBox>
                        </MyTooltip>}
                        <HBox alignContent={'center'} alignItems={'center'} >
                            <Typography textAlign={'center'} variant="regReqNameAct">  {request.Pupil_FirstName} {request.Pupil_lastName}</Typography>
                        </HBox>
                        <HBox alignContent={'center'} alignItems={'center'} >
                            <StatusChip status={request.Status_request} size="small" />
                        </HBox>
                        <HBox alignContent={'center'} alignItems={'center'} >
                            <Typography textAlign={'center'} variant="regReqTxt"> {`${t('payed')}  `}<b>{` ${request.paid} \u20AA`}</b></Typography>
                        </HBox>
                        <HBox alignContent={'center'} alignItems={'center'} justifyContent='space-between' >
                            <Typography textAlign={'center'} variant="regReqTxt"> {`${t('shortPayType')}  `}<b>{` ${paymentMethod || ' -'} `}</b></Typography>
                        </HBox>
                        <HBox alignItems={{ xs: 'end', sm: 'center' }} justifyContent={{ xs: 'space-between', sm: 'center' }}>
                            <Typography textAlign={'center'} variant="regReqTxt"> {`${t('rightBalance')}  `}<b>{` ${leftToPay} \u20AA`}</b></Typography>
                        </HBox>
                        {request.Status_request === "2" &&
                            <Link component='button' onClick={() => { onCancelRegistration(request) }} style={{ marginLeft: "1rem" }} >
                                <Typography lineHeight='1.5rem' sx={{ color: '#3a7735', fontWeight: "bold" }} variant="responsiveTextSize">{t("cancelReq")}</Typography>
                            </Link>}
                        {request.Status_request === "3" &&
                            <Tooltip title={t("getSplitLink")}>
                                <Link component='button' onClick={() => { copyPayLink() }} style={{ marginLeft: "1rem" }} >
                                    <Typography lineHeight='1.5rem' sx={{ color: '#3a7735', fontWeight: "bold" }} variant="responsiveTextSize">{t("copyLink")}</Typography>
                                </Link>
                            </Tooltip>}
                    </HBox>
                </RequestCardCont>
            </RequestCard >
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
        </>
    );
}
export default ActivityReq; 