import { useTranslation } from 'react-i18next';
import { useNavigate } from 'raviger';
import useGlobal from 'store/store';
//libs
import { format } from 'date-fns';
//mui
import { makeStyles } from '@mui/styles';
import { Typography, Button, Container, Chip, Box, Tooltip, Link } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import EditIcon from '@mui/icons-material/Edit';
//components
import { VBox, HBox } from 'assets/SharedComponents';
//data
import { permanentInfo } from 'data/permanentInfo';
import { useState } from 'react';
import OpenActivitiesToReg from 'Components/TribeComponents/OpenActivitiesToReg';
import { getChildAvailableActivities } from 'api/api';

const useStyles = makeStyles((theme) => ({
    cardBox: {
        alignItems: 'center !important'
    },
    marginBottom: {
        marginBottom: "0.3rem"
    },
    cardColor: {
        background: "#ffffff",
        height: "auto"
    },
    cardColor1: {
        background: "#f2f7f2",
        height: "auto"
    },
    buttons: {
        height: '1.125rem !important',
        borderRadius: '2rem !important',
        marginRight: '2.5rem !important',
        textTransform: "capitalize !important"
    },
    activeButton: {
        border: '1px solid #007f00 !important',
        backgroundColor: '#b6f3b5 !important',
        color: '#007f00 !important'
    },
    schoolName: {
        width: "max-content !important"
    },
    nonActiveButton: {
        border: '1px solid #cc5237 !important',
        backgroundColor: '#efe3d1 !important',
        color: '#cc5237 !important'
    },
    openRegistrationBtn: {
        backgroundColor: 'rgb(58, 119, 53) !important',
        color: '#fff !important'
    },
    topCard: {
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    ActivChip: {
        "& .MuiChip-label": {
            display: 'flex',
            justifyContent: 'center',
        },
        height: '1rem !important',
        color: `${theme.palette.green.darker} !important`,
        backgroundColor: 'transparent !important',
        border: `1px solid ${theme.palette.green.darker} !important`
    }
}));

const ChildDetails = ({ children, index, school, isFromTribe, setAppBarTitle }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openActivities, setActivities] = useState([]);
    const [openDrawer, setDrawer] = useState(false);

    const [child, setChild] = useGlobal(state => state.child, actions => actions.setChild);

    const noSchool = { SchoolName: t("cantFindSchool"), CityName: "" };

    const handleMoveToEdit = () => {
        setChild(children);
        if (isFromTribe) {
            navigate(`/childDetailsEditor/${children.FatherCard}`);
        }
        else {
            navigate(`/childDetailsEditor`);
            setAppBarTitle(t("childDetailsEditor"));
        }
    }
    const isPeriphery = isFromTribe && checkIfPeriphery(children.ChildFactoriesPortal.results);

    const onOpenActivities = async () => {
        const periodCode = children.ChildFactoriesPortal.results[0].PeriodCode,
            pupilId = children.LicTradNum,
            activeCodes = children.ChildFactoriesPortal.results.map(({ ActivCode }) => ActivCode),
            results = await getChildAvailableActivities({ periodCode, pupilId, activeCodes });
        let childFactories = children.ChildFactoriesPortal.results;
        childFactories = children.ChildFactoriesPortal.results.filter(a => { if (a.ActivCode && !futureRegistration(a.StartReg) && !pastRegistration(a.EndReg) && checkCorrectDates(a)) return a });
        const activityList = childFactories.filter((act) => results.includes(act.ActivCode));
        setActivities(activityList);
        setDrawer(true);
    }
    return (
        <>
            <VBox className={index % 2 === 0 ? `${classes.cardBox} ${classes.cardColor}` : `${classes.cardBox} ${classes.cardColor1}`}>
                <HBox mt={2} className={`${classes.marginBottom} ${classes.topCard}`}>
                    <HBox>
                        <PersonOutlineOutlinedIcon sx={{ color: "#3a7735" }} />
                        <Typography fontWeight={'bold'}>{children.CardName} </Typography>
                    </HBox>
                    {children.PupilStatus === "Y" || children.PupilStatus === null ?
                        <Button className={`${classes.buttons} ${classes.activeButton}`} disabled={true}>{t("active")}</Button>
                        : <Button className={`${classes.buttons} ${classes.nonActiveButton}`} disabled={true}>{t("notactive")}</Button>}
                    <Tooltip title={t("pressToEdit")}>
                        <EditIcon sx={{ color: "#3a7735", cursor: "pointer", marginRight: "1.2rem" }} fontSize="medium" onClick={e => handleMoveToEdit()} />
                    </Tooltip>
                </HBox>

                <Container>
                    <VBox>
                        <HBox className={`${classes.marginBottom} ${classes.topCard}`} sx={{ marginTop: "0.9rem" }} >
                            {children.Class !== '' && <Box display='flex' alignItems="center" minHeight="17%"><Chip label={`${t("classOf")} ${permanentInfo.childClasses[children.Class]}`} className={classes.ActivChip} /></Box>}
                            {isPeriphery && <Button className={`${classes.buttons} ${classes.openRegistrationBtn}`} onClick={e => onOpenActivities()} >{t('activsPerPupil')}</Button>}
                        </HBox>
                        {children.Troops && <HBox className={classes.marginBottom}>
                            <Typography sx={{ color: "#1a7d80" }}>{`${children.Troops}, ${children.Group || ""}`}</Typography>
                        </HBox>}
                        <HBox className={classes.marginBottom}>
                            <Typography >{children.LicTradNum}</Typography>
                        </HBox>
                        <HBox className={classes.marginBottom}>
                            <Typography> {children.BirthdayDate ? format(new Date(children.BirthdayDate), "dd/MM/yyyy") : t("wrongBirthday")}</Typography>
                        </HBox>
                        {children.Phone1 && <HBox className={classes.marginBottom}>
                            <PhoneIphoneOutlinedIcon />
                            <Link href={children.Phone1[0] === "+" ? `tel:${children.Phone1}` : `tel:+972${children.Phone1}`} sx={{ direction: "rtl", textDecoration: "none" }}>{children.Phone1[0] === "+" || children.Phone1[0] === "0" ? children.Phone1 : `0${children.Phone1}`}</Link>
                        </HBox>}
                        {school ? <VBox className={classes.marginBottom}>
                            <Typography className={classes.schoolName}>{school.SchoolName}</Typography>
                            <Typography>{school.CityName}</Typography>
                        </VBox> :
                            <VBox className={classes.marginBottom}>
                                <Typography className={classes.schoolName}>{`${noSchool.SchoolName}`}</Typography>
                                <Typography>{`${noSchool.CityName}`}</Typography>
                            </VBox>}
                        <HBox className={classes.marginBottom} justifyContent="space-between" alignItems="center">
                            <Typography> {permanentInfo.childGender[children.Gender]}</Typography>
                        </HBox>
                    </VBox>
                </Container>
            </VBox>
            {openDrawer && <OpenActivitiesToReg setDrawer={setDrawer} openDrawer={openDrawer} child={children} activities={openActivities} />}
        </>
    );
}
export default ChildDetails;

const checkIfPeriphery = (activities) => {
    return activities.some(act => act.Activity === "3")

}
const futureRegistration = (regDate) => {
    try {
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
        const today = new Date();
        const regDate1 = new Date(Number(reMsAjax.exec(regDate)[1]));
        return today < regDate1;
    } catch (error) {
        return false;
    }
}

const pastRegistration = (someDate) => {
    try {
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
        const today = new Date();
        const endDate = new Date(Number(reMsAjax.exec(someDate)[1]));
        return today > endDate;
    } catch (error) {
        return false;
    }
}

const checkCorrectDates = (activity) => {
    if (!activity.EndDate || !activity.EndDateD || !activity.EndReg || !activity.EndRegDate || !activity.StartDate || !activity.StartDateD || !activity.StartReg || !activity.StartRegDate) {
        return false;
    }
    else {
        return activity;
    }
}