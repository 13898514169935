export const xmlInquireTransactionRequest = (params) => {
    return (
        "<ashrait>" +
        "<request>" +
        "<requestId></requestId>" +
        "<version>2000</version>" +
        "<language>HEB</language>" +
        "<dateTime/>" +
        " <command>inquireTransactions</command>" +
        "<inquireTransactions>" +
        "<terminalNumber>" + params.terminalNumber + "</terminalNumber>" +
        "<mainTerminalNumber/>" +
        "<queryName>mpiTransaction</queryName>" +
        "<mid>" + params.mid + "</mid>" +
        "<mpiTransactionId>" + params.txId + "</mpiTransactionId>" +
        "</inquireTransactions>" +
        "</request>" +
        "</ashrait>"
    )
}
