import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TransactionLayout from 'layouts/TransactionLayout';
import useApi from 'hooks/useApi';
import { loggerService } from 'api/api';

const useStyles = makeStyles(() => ({
    cardBox: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem'
    },
    splitCard: {
        height: '7rem',
        width: '20rem',
        justifyContent: 'center !important',
        marginBottom: '1rem',
        borderRadius: '50px',
        alignItems: 'center',
    },
    cardTitleAndIcon: {
        alignItems: 'center'
    },
    cardTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginRight: '1.3rem'
    },
    text: {
        color: 'black',
        fontSize: '1rem',
    }
}));

const CancelPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const logger = useApi(loggerService);

    useEffect(() => {
        try {
            const data = {
                cancel: true,
                paymentIframe: true,
                txId: window.parent.txid,
                terminalNumber: window.parent.terminal,
                mid: window.parent.mid
            }
            logger.requestPromise(data);
            window.parent.postMessage(data, "*");
        } catch (error) { }
    }, [])
    return (
        <TransactionLayout bodyText={t("backToPaymentMethodSelection")}>
            <CancelOutlinedIcon />
            <Typography className={classes.cardTitle}>{t("paymentCancelledByUser")}</Typography>
        </TransactionLayout>
    );
}

export default CancelPage;