import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { usePathParams } from 'raviger'
import { useSnackbar } from 'notistack';
import useGlobal from 'store/store';
import useApi from '../hooks/useApi';
import useFeathers from '../hooks/useFeathers';
//mui
import { Button, Typography, Card, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
//libs
import { format } from 'date-fns'
//api
import { UpdateRegAPI, getRegAPI, paymentsService, updateInvoiceNumber } from '../api/api'
//components
import { Login } from './Login';
import { RegisterStepper } from 'Components/Register/RegisterStepper';
import { CustomBusy } from 'Components/Common/CustomBusy';
import { VBox, HBox } from '../assets/SharedComponents';
import SplitPaymentHandler from '../Components/Payments/SplitPaymentHandler';
import LoginPageHeadr from 'Components/Login/LoginPageHeadr';
import GenericDialog from 'Components/Common/GenericDIalog';
//assets
import appBG from '../assets/appBG.jpg';
import approvalPay from '../assets/approvalPay.png'
import failedTransc from '../assets/failedTransc.svg'


const useStyles = makeStyles(() => ({
    subTitle: {
        color: '#3a7735 !important',
        textAlign: 'center !important',
        fontSize: '1.5rem !important',
        margin: '2.2rem !important'
    },
    card: {
        alignItems: 'center !important',
        minHeight: "24rem",
        width: '22.5rem !important',
        direction: 'rtl !important',
        borderRadius: '20px !important',
    },
    bottomContent: {
        alignItems: 'center !important',
        margin: '2.5rem !important'
    },

    childActivities: {
        margin: '1rem !important'
    },
    boldName: {
        fontWeight: 'bold !important'
    },
    nameAndDate: {
        justifyContent: 'space-between !important'
    },
    payButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        color: '#ffffff !important',
        fontSize: '1rem !important'
    },
    grayButton: {
        backgroundColor: '#0000001c !important',
    },
    greenButton: {
        backgroundColor: '#3a7735 !important',
    },
    totalContent: {
        justifyContent: 'space-between !important',
        paddingBottom: '1rem !important',
        backgroundColor: '#0000001c !important',
        marginTop: '0.5rem !important'
    },
    height100: {
        height: '100%'
    },
    AppBackground: {
        backgroundImage: `url(${appBG})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        '@media (max-width:500px)': {
            backgroundSize: 'initial',
            backgroundPosition: '-64rem -10rem'
        }

    },
    loginCard: {
        '@media (min-width:320px)': {
            width: "21.75rem",
            height: "28.75rem",
            borderRadius: "50px !important",
        },
        '@media (min-width:600px)': {
            width: "24.75rem",
            height: "28.75rem",
            borderRadius: "100px !important",
        },
        '@media (min-width:1025px)': {
            width: " 25.75rem",
            height: "28.75rem",
            borderRadius: "100px !important",
        },
        '@media (min-width:1281px)': {
            width: "26.75rem",
            height: "31.75rem",
            borderRadius: "100px !important",
        },
        padding: "2px 2px 2px 2px",

        backgroundColor: "#ffffff !important",
        boxSizing: "border - box !important",
        boxShadow: "15px 15px 30px 0px rgba(0, 0, 0, 0.26) !important",
        display: "flex",
        justifyContent: "center"
    }
}));

const SplitPaymentPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const feathers = useFeathers();
    const props = usePathParams('/spl/:regReqId');

    const [registrations, setRegistrations] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [activeStep, setActiveStep] = useState(2);
    const [pay, setPay] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [sapErrorPayment, setSapErrorPayment] = useState(false);

    const getRegReq = useApi(getRegAPI);
    const updateStatus = useApi(UpdateRegAPI);
    const [guestPayment, setGuestPayment] = useGlobal((state) => state.guestPayment, (actions) => actions.setGuestPayment);


    useEffect(() => {
        setGuestPayment(window.location.pathname);
    }, [])

    useEffect(() => {
        initReq();
    }, [feathers.isLoggedIn, pay]);

    const initReq = async () => {
        if (feathers.isLoggedIn) {
            const reqIds = props.regReqId.split(/,/);
            const promises = [];
            const aReqIds = reqIds.map(req => {
                return { _id: req };
            });
            promises.push(getRegReq.requestPromise({ _id: { $in: reqIds } }));
            promises.push(paymentsService({
                registrationRequests: aReqIds,
                getPaymentLink: false,
                divorced: { isDivorced: true, divorced2: true }
            }));
            const result = await Promise.all(promises);
            const updatedReqs = await updateInvoiceNumber({ CardCode: result[1].registrationRequests?.data[0]?.ParentCardCode, products: result[0] });
            if (result[1].wrongBalanceAmount) {
                setTotalToPay(0);
                enqueueSnackbar(t("wrongAmountError"));
            }
            setRegistrations(result[0]);
            const productsInvoice = updatedReqs.products.map((reg) => reg.InvoiceNum);
            setInvoices(productsInvoice);
            if (updatedReqs.products[0].Status_request === "4") {
                setTotal(0);
                setTotalToPay(0);
                setPaymentSuccess(true);
                setPay(false);
                setActiveStep(4);
            } else {
                setTotal(Number(result[1].total).toFixed(2));
                setTotalToPay(Number(result[1].total).toFixed(2));
            }
        }
    }
    const payment = async () => {
        if (registrations.length > 0) {
            window.addEventListener("message", (async (...args) => {
                if (args?.length) {
                    try {
                        if (args[0].data.response?.Status) {
                            registrations.map(async (product) => {
                                product.Status_request = "4";
                                product.paid = product.FinalPrice;
                                await updateStatus.requestPromise({ _id: product._id, data: { Status_request: "4", paid: product.FinalPrice } });
                            })
                            setPaymentSuccess(true);
                            setTotalToPay(0);
                            setPay(false);
                            setActiveStep(4);
                        }
                        if (args[0].data.error?.CGError) {
                            setOpenErrorDialog(true);
                            return;
                        }
                        if (args[0].data.error?.sapInvoiceError || args[0].data.error?.sapRegError || args[0].data.error?.mongoDBError || args[0].data.error?.transactionMongoDBError) {
                            console.log(args[0].data?.error);
                            setSapErrorPayment(true);
                            setOpenErrorDialog(true);
                            return;
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            }))
        }
    }

    const handlePay = () => {
        setPay(true);
        setActiveStep(3);
        payment();
    }
    const guestSteps = [t("phoneVerification"), t("watchActivity"), t("payment")];


    const handleCloseButton = () => {
        setPaymentSuccess(true);
        setTotalToPay(0);
        setPay(false);
        setActiveStep(4);
        setSapErrorPayment(false);
        setOpenErrorDialog(false);
    }
    const handleGoToButton = () => {
        setOpenErrorDialog(false);
        setActiveStep(2);
    }

    return (
        <>
            {!feathers.isLoggedIn ?
                <Login guest={true} />
                :
                <>
                    <Box className={`${classes.height100} ${classes.AppBackground}`} style={{ minHeight: '50rem' }}>
                        <LoginPageHeadr guest={true} />
                        <Box alignItems="center" display="flex" flexDirection="column" justifyContent='center'>
                            <RegisterStepper aSteps={guestSteps} activeStep={activeStep} guest={true} />
                            <Card className={classes.loginCard}>
                                {!paymentSuccess ?
                                    <Box sx={{ height: { xs: '55%', lg: '60%', xl: '60%' }, marginTop: "3rem" }} display='flex' flexDirection='column' alignItems='center' >
                                        <Typography sx={{ fontWeight: "bold" }}>{t("activityWait")}</Typography>
                                        <Typography sx={{ fontSize: "0.875rem" }}>{t("afterPay")}</Typography>
                                        {getRegReq.loading ?
                                            <CustomBusy text={t("pleaseWait")} />
                                            :
                                            <>
                                                <Card sx={{ overflow: pay ? 'scroll !important' : 'hidden' }} className={classes.card}>
                                                    {!pay ?
                                                        <VBox>
                                                            {!!registrations.length ?
                                                                registrations.map((registrationReq, i) => (
                                                                    <ChildRequests registrationReq={registrationReq} key={i} />
                                                                ))
                                                                : <CustomBusy text={t("pleaseWait")} />}
                                                            <HBox className={classes.totalContent}>
                                                                <VBox paddingLeft={'0.5rem'}>
                                                                    <Typography style={{ fontSize: '0.7rem' }} > {t("sum")} ({registrations.length} {t("activities")})</Typography>
                                                                    <Typography style={{ fontSize: '1.2rem' }} > {t("totalPay")}</Typography>
                                                                </VBox>
                                                                <VBox paddingRight={'0.5rem'} alignItems={'end'}>
                                                                    <Typography style={{ fontSize: '0.7rem' }}>{total} {"\u20AA"}</Typography>
                                                                    <Typography style={{ fontSize: '1.2rem' }} >{totalToPay} {"\u20AA"}</Typography>

                                                                </VBox>
                                                            </HBox>
                                                            <VBox className={classes.bottomContent}>
                                                                <Button className={totalToPay <= 0 ? `${classes.payButton} ${classes.grayButton}` : `${classes.payButton} ${classes.greenButton}`} onClick={e => handlePay()} disabled={totalToPay <= 0}>{t('cardPayment')}</Button>
                                                            </VBox>
                                                        </VBox>
                                                        : <VBox alignItems={"center"}>
                                                            <SplitPaymentHandler products={registrations} invoices={invoices} />
                                                        </VBox>}

                                                </Card>
                                            </>}
                                        <GenericDialog open={openErrorDialog} setOpen={setOpenErrorDialog} showRopes={false} height={"32rem"} showExit={false} >
                                            {sapErrorPayment ?
                                                <>
                                                    <img
                                                        alt={"..."}
                                                        src={approvalPay}
                                                        width="150px"
                                                        height="150px"
                                                    />
                                                    <Typography color="#064601" mt="1rem" variant="mediumBoldText">{t('paymetSuccess')}</Typography>
                                                    <Typography color="#064601" mt="1rem" sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>{t('regInNextHours')} </Typography>
                                                    <Button className={classes.greenButton} onClick={e => handleCloseButton(e)}>{t('whatAfun')}</Button>
                                                    <Typography mt="3rem" className={classes.smallText}>{t('emailToPayment')} </Typography>

                                                </>
                                                : <>
                                                    <img
                                                        alt={"..."}
                                                        src={failedTransc}
                                                        width="150px"
                                                        height="150px"
                                                    />
                                                    <Typography color="#bb0000" mt="1rem" variant="mediumBoldText">{t('cardFaild')}</Typography>
                                                    <Typography color="#bb0000" mt="1rem" sx={{ textAlign: "center", fontWeight: "400" }}>{t('notCharged')}</Typography>
                                                    <Typography mt="3rem" className={classes.smallText}>{t('tryAgainInCard')} </Typography>
                                                    <Button className={classes.greenButton} onClick={e => handleGoToButton()}>{t('goToOtherPayment')}</Button>

                                                </>}
                                        </GenericDialog>
                                    </Box>
                                    :
                                    <VBox className={classes.bottomContent}>
                                        <img
                                            alt={"..."}
                                            src={approvalPay}
                                            width="150px"
                                            height="150px"
                                        />
                                        <Typography className={classes.subTitle}>{t("splitPaySuccess")}</Typography>
                                    </VBox>}
                            </Card>
                        </Box>
                    </Box >
                </>}
        </>
    );
}
export default SplitPaymentPage;


const ChildRequests = ({ registrationReq }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const leftToPay = Number(registrationReq.FinalPrice) - Number(registrationReq.paid);
    return (
        <VBox className={classes.childActivities}>
            <Typography className={classes.boldName}>{registrationReq.Pupil_FirstName} {registrationReq.Pupil_lastName}</Typography>
            <HBox className={classes.nameAndDate}>
                <Typography>{registrationReq.ActivName}</Typography>
                <Typography> {format(new Date(registrationReq.EndRegDate), 'dd/MM/yyyy')}</Typography>
            </HBox>
            <HBox>
                <Typography>{t('leftToPay')} ₪{leftToPay.toFixed(2)}</Typography>
            </HBox>
        </VBox>

    )
}