import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useApi from 'hooks/useApi';
import { HBox, VBox } from '../../../assets/SharedComponents';
import addChildPng from '../../../assets/addChildPng.png';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Button } from '@mui/material';
import { PersonOutlineOutlined } from '@mui/icons-material';
import ChildrenCard from './ChildrenCard';
import { getBalanceCalc, getSchoolsList1 } from 'api/api'
import { useNavigate } from 'raviger';
import { CustomBusy } from 'Components/Common/CustomBusy';


const useStyles = makeStyles((_theme) => ({
    displayInherit: {
        display: 'inherit !important;'
    },
    familyBG: {
        background: '#eef3ee'
    },
    margin1Start: {
        marginInlineStart: "1rem !important"
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    avatarStyle: {
        display: 'inline-flex',
        width: '15rem',
        justifyContent: 'space-between',
        paddingTop: '1rem'
    },
    paddingRight: {
        paddingRight: "2rem !important",
        textAlign: "right"
    }
}));

export const ApprenticesAndTribes = ({ household, familyTribes, isFromTribe = false, setAppBarTitle }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const schools = useApi(getSchoolsList1);
    const [balanceFinal, setBalanceFinal] = useState({});
    const [schoolList, setSchoolList] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state

    useEffect(() => {
        // Fetch family balance and school details
        const fetchData = async () => {
            await getFamilyBalance(household.CardCode);
            await getSchoolsDetails();
            setLoading(false); // Set loading to false when data is fetched
        };

        fetchData();
    }, [household.CardCode, familyTribes]); // Added dependencies


    const getSchoolsDetails = async () => {
        let schoolsCodes = [];
        household.ChildrenList.results.forEach((child) => {
            child.School && schoolsCodes.push(encodeURI(child.School));
        })
        try {
            if (schoolsCodes.length) {
                const res = await schools.requestPromise(schoolsCodes);
                setSchoolList(res);
            }
            else {
                setSchoolList([]);
            }
        } catch (error) {
            setSchoolList([]);
            console.log(error);
        }
    }
    const getFamilyBalance = async (CardCode) => {
        try {
            const balanceTemp = {};
            const data = await getBalanceCalc(CardCode);
            const balances = data?.d?.results;

            familyTribes.forEach((tribeName) => {
                const balanceAmount = balances.find(({ TribeName }) => TribeName === tribeName);
                balanceTemp[tribeName] = Number(balanceAmount?.ParentBalance) || 0;
            });

            setBalanceFinal(balanceTemp);
        } catch (error) {
            console.error('Failed to fetch family balance:', error);
            setBalanceFinal({});
        }
    };

    const navTo = () => {
        if (isFromTribe) {
            navigate(`/addChild/${household.CardCode}`);
        }
        else {
            navigate(`/addChild`);
        }
    }
    return (
        <>
            {familyTribes &&
                <VBox className={classes.displayInherit}>
                    <HBox className={`${classes.familyBG} ${classes.alignItemsCenter}`} height="3rem">
                        <PersonOutlineOutlined className={classes.margin1Start} style={{ color: 'black', fontSize: 30 }} />
                        <Typography className={classes.margin1Start} variant='h5'>
                            {t("studentsAndTribes")}
                        </Typography>
                    </HBox>
                    {loading ? <CustomBusy text={t("loading")} /> :
                        <Box width="100%" display="flex" flexWrap="wrap" className={classes.paddingRight}>
                            {familyTribes?.map((tribe, i) => (
                                <ChildrenCard household={household} tribe={tribe} key={i} balancePerTribe={balanceFinal[tribe]} schoolList={schoolList} isFromTribe={isFromTribe} setAppBarTitle={setAppBarTitle} />
                            ))}
                            <HBox justifyContent="flex-end" margin="1rem">
                                <Button onClick={e => navTo()} sx={{ flexDirection: "column", border: "2px dashed #3a7735", width: "298px", height: "165px", mt: "3rem" }}>
                                    <img
                                        alt={"..."}
                                        src={addChildPng}
                                        width="80px"
                                        height="80px"
                                    />
                                    <Typography sx={{ color: "#3a7735", fontSize: "1.5rem", fontWeight: "bold" }}>{t("addStudent")}</Typography>
                                </Button>
                            </HBox>
                        </Box>
                    }
                </VBox>
            }
        </>
    );
}