import { useEffect, useState, useRef } from "react";
import { makeStyles } from '@mui/styles';
import { HBox, VBox } from '../../assets/SharedComponents';
import { TextField, ButtonGroup, Button, Autocomplete, CircularProgress, Box, Typography, Checkbox, Container } from '@mui/material';
import TermsDialog from "Components/Common/TermsDialog";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { checkUserName, getAddresses, updateUser } from '../../api/api';
import useApi from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AutocompleteGooglePlaces from "Components/Common/AutocompleteGooglePlaces";

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    padding1: {
        padding: "1rem"
    },
    marginEnd5p: {
        marginEnd: "5% !important"
    },
    marginBottom1: {
        marginBottom: "1rem !important"
    },
    width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    },
    width70: {
        width: "70%",
    },
    width25: {
        width: "25%",
    },
    TextfieldILabelStyle: {
        '& .MuiInputLabel-root': {
            color: 'black !important',
            fontSize: '1rem !important',
            alignItems: "center"
        },
        '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 'bold !important',
            color: 'black !important',
            fontSize: '1rem !important',
        },
        '& .Muirtl-1in441m': {
            fontSize: '1.2rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        }
    },
    TextfieldILabelStyle1: {
        '& .MuiInputLabel-root': {
            color: 'black !important',
            fontSize: '0.8rem !important',
            alignItems: "center"
        },
        '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 'bold !important',
            color: 'black !important',
            fontSize: '1rem !important',
        },
        '& .Muirtl-1in441m': {
            fontSize: '0.8rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        }
    },
    buttonGreen: {
        '& .MuiButton-label': {
            color: 'white !important',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        borderRadius: '2rem !important',
        marginBottom: '2rem !important',
        color: 'white !important',
        width: "14rem !important",
        textTransform: "capitalize !important"
    },
    greenBG: { backgroundColor: '#3a7735 !important' },
    grayBG: { backgroundColor: '#0000001c !important' },
    selectedBtn: {
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important",
        textTransform: "capitalize !important"
    },
    IdnBtn: {
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important"
    },
    pageStyle: {
        paddingBottom: '4rem !important',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px !important'
        },
    },
    IdentificationBtn: {
        textTransform: "capitalize !important",
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        width: "17rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    redBG: {
        backgroundColor: " #ff6645 !important"
    },
    termsLink: {
        color: "#3a7735",
        fontWeight: 'bold !important',
        fontSize: "0.8rem !important",
        textDecoration: "underline",
        cursor: "pointer"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    }
}));

export const AddNewFamily = ({ addNewPupil, setDisabledLevel2, setDisabledLevel3 }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const users = useApi(checkUserName);
    const updateUserData = useApi(updateUser);
    const maritalStatuss = [
        { text: t("divorced"), key: 'di' },
        { text: t("married"), key: 'ma' },
        { text: t("sharedParentship"), key: 'sh' },
        { text: t("single"), key: 'sg' },
        { text: t("widdowed"), key: 'wi' },
        { text: t("guardian"), key: 'gu' },
        { text: t("Knowninpublic"), key: 'na' },
        { text: t("singleParent"), key: 'si' },
        { text: t("prod"), key: 'se' }
    ]
    const [openDialog, setOpenDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [streetValue, setStreetValue] = useState('');
    const [streetOpen, setStreetOpen] = useState(false);
    const address = useApi(getAddresses);
    const IdTextByType = { "1": t("israelId"), "2": t("passport"), "3": t("journeyId") };
    const inputRef = useRef();
    const inputRef1 = useRef();
    const inputRefP2 = useRef();

    const idChange = (e, parent) => {
        if (parent === "p1") { formik.setValues({ ...formik.values, id: e.target.value }) }
        if (parent === "p2") { formik.setValues({ ...formik.values, parent2Id: e.target.value }) }
    }
    const valueChanged = (value) => {
        formik.setValues({ ...formik.values, maritalStatus: value.key })
    }
    const addressChanged = (oAddress) => {
        formik.setValues({ ...formik.values, street: oAddress.StreetName, streetNo: oAddress.StreetNum, city: oAddress.CityName })
        setStreetOpen(false)
    }
    const termsConfirms = (bool) => {
        setOpenDialog(false);
        formik.setValues({ ...formik.values, termsUse: bool })
    }

    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectName')),
        lastName: yup
            .string(t("lastName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectLName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectLName')),
        id: yup
            .string(t('id'))
            .required(t("mandatoryField"))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .matches(/^[0-9]*$/, t('enterValidValue')),
        maritalStatus: yup
            .string(t("maritalStatus"))
            .required(t("mandatoryField"))
            .min(2, t('enterValidValue')),
        email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .required(t("mandatoryField")),
        phone: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .matches(/^[0-9]*$/, t('insertValidPhone'))
            .required(t("mandatoryField")),
        streetNo: yup
            .string(t("streetNumber"))
            .required(t("mandatoryHouseNum"))
            .matches(/^\d+(\/\d+)*$/, t("streetNumber")),
        parent2Email: yup
            .string(t("email"))
            .email(t("insertValidMail"))
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("insertValidMail"))
            .nullable(),
        parent2Id: yup
            .string(t('id'))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue'))
            .nullable()
    });
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            id: '',
            idType: '1',
            maritalStatus: '',
            phone: '',
            email: '',
            street: '',
            streetNo: '',
            city: '',
            termsUse: false,
            parent2Id: '',
            parent2IdType: '1',
            parent2Email: ''
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.email.includes('zofim.org.il')) {
                enqueueSnackbar(t("emailDomain"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            if (values.parent2Id) {
                if (!is_israeli_id_number(values.parent2Id) && values.parent2IdType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
                if (values.parent2Id.toString().length < 9) {
                    if (values.parent2IdType === "3") {
                        enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                        return
                    }
                    if (values.parent2IdType === "1") {
                        enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                        return
                    }
                }
            }
            if (values.parent2Email) {
                if (values.parent2Email.includes('zofim.org.il')) {
                    enqueueSnackbar(t("emailDomain"), { className: classes.redBG })
                    return
                }
            }

            try {
                const emailExsits = await users.requestPromise({ email: values.email });
                if (emailExsits.userExists) {
                    enqueueSnackbar(t("mailExsit"), { className: classes.redBG });
                    return
                }
                const idExsits = await users.requestPromise({ username: values.id });
                if (idExsits.userExists) {
                    enqueueSnackbar(t("idExsit"), { className: classes.redBG });
                    return
                }
                let phoneNumber = values.phone;
                if (phoneNumber[0] === '0') {
                    phoneNumber = phoneNumber.slice(1, phoneNumber.length);
                }
                const phonExsits = await users.requestPromise({ phone_number: `+972${phoneNumber}` });
                if (phonExsits.userExists) {
                    enqueueSnackbar(t("phoneExsit"), { className: classes.redBG });
                    return
                }
                const user =
                {
                    CardCode: "",
                    Parent1_City: values.city,
                    Parent1_Street: values.street,
                    Parent1_StreetNo: values.streetNo,
                    Parent1_Tel1: values.phone,
                    Parent1_FirstName: values.firstName,
                    Parent1_LastName: values.lastName,
                    Parent1_ID: values.id,
                    Parent1_ID_Type: values.idType,
                    Parent1_Mail: values.email,
                    Parent1_MaritalStatus: values.maritalStatus,
                    Parent2_ID: values.parent2Id,
                    Parent2_ID_Type: values.parent2IdType,
                    Parent2_Mail: values.parent2Email,
                    Parent1_MedicalQalifications: [],
                    Parent1_ApprInfo: 'N',
                    Parent1_IsVolunteered: "N",
                    Parent1_PastScout: "N",
                    Parent1_PlaceOfBirth: "",
                    Parent1_Profession: "",
                    Parent1_CodeFirstHelp: "",
                    Parent1_Country: "",
                    Parent1_EmigrationYear: "",
                    Parent1_FirstHelpName: "",
                    Parent1_Tel1Type: "1",
                    Parent1_Tel2: "",
                    Parent1_Tel2Type: "1",
                    Parent1_TribeName: "",
                    Parent1_WeaponType: "",
                    Parent1_WorkPlace: "",
                    Parent1_ZipCode: "",
                    Parent2_ApprInfo: "N",
                    Parent2_City: "",
                    Parent2_Street: "",
                    Parent2_StreetNo: "",
                    Parent2_Tel1: "",
                    Parent2_FirstName: "",
                    Parent2_LastName: "",
                    Parent2_MaritalStatus: "",
                    Parent2_MedicalQalifications: [],
                    Parent2_PastScout: "Y",
                    Parent2_PlaceOfBirth: "",
                    Parent2_Profession: "",
                    Parent2_CodeFirstHelp: "",
                    Parent2_Country: "",
                    Parent2_EmigrationYear: "",
                    Parent2_FirstHelpName: "",
                    Parent2_IsVolunteered: "N",
                    Parent2_Tel1Type: "1",
                    Parent2_Tel2: "",
                    Parent2_Tel2Type: "1",
                    Parent2_TribeName: "",
                    Parent2_WeaponType: "",
                    Parent2_WorkPlace: "",
                    Parent2_ZipCode: "",
                    Source: "ASH",
                    Tel: "",
                    TelEmergency: ""
                };
                const data = await updateUserData.requestPromise(user);
                if (data.Status && !data.ErrorMessage) {
                    enqueueSnackbar(t("familyCreated"), { className: classes.orangeBG });
                    setDisabledLevel3(false);
                    setDisabledLevel2(true);
                    addNewPupil(data.CardCode, values.lastName);
                }
                else {
                    let sMsg;
                    try {
                        if (data.CardCode === "") {
                            sMsg = data.ErrorMessage.split("System.Exception: ")[1].split("at XIS")[0];
                        }
                    } catch (error) {
                        sMsg = data.ErrorMessage;
                    }
                    enqueueSnackbar(sMsg, { className: classes.redBG });
                }
            } catch (error) {
                console.log(error)
                enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG })
            }
        }
    });

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }


    return (
        <Box display="flex" flexDirection="column" alignItems="center" flexWrap="wrap" width={{ xs: "19rem", sm: "20rem", xl: "24rem" }} >
            <Container sx={{ overflow: "auto" }}>
                <VBox className={`${classes.padding1} ${classes.pageStyle}`} justifyContent="center" >
                    <form onSubmit={formik.handleSubmit}>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`} marginTop={"1rem"}>
                            <TextField
                                label={t("firstName")}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                id="firstName"
                                name="firstName"
                                style={{ width: "100%" }}
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                                variant="outlined"
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                label={t("lastName")}
                                id="lastName"
                                name="lastName"
                                style={{ width: "100%" }}
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                                variant="outlined"
                            />
                        </HBox>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                            <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "1" })}>{IdTextByType[1]} </Button>
                            <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, idType: "2" })}>{IdTextByType[2]}</Button>
                            <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, idType: "3" })}>{IdTextByType[3]}</Button>
                        </ButtonGroup>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                label={IdTextByType[formik.values.idType]}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                id="id"
                                name="id"
                                style={{ width: "100%" }}
                                value={formik.values.id}
                                onChange={e => idChange(e, "p1")}
                                onBlur={formik.handleBlur}
                                error={formik.touched.id && Boolean(formik.errors.id)}
                                helperText={formik.touched.id && formik.errors.id}
                                InputProps={{
                                    inputRef: inputRef,
                                    inputProps: {
                                        inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    }
                                }}
                                onWheel={(e) => {
                                    if (inputRef && inputRef.current && inputRef.current.blur) {
                                        inputRef.current.blur();
                                    }

                                }}
                                variant="outlined"
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <Autocomplete
                                id="maritalStatus"
                                name="maritalStatus"
                                noOptionsText={t('notOption')}
                                disableClearable={true}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                style={{ width: "100%" }}
                                options={maritalStatuss}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                onChange={(e, value) => valueChanged(value)}
                                getOptionLabel={(option) => `${option.text}`}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.key}>
                                        {option.text}
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label={t("maritalStatus")}
                                        error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
                                        helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                                        InputProps={{
                                            ...params.InputProps
                                        }} />}
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                style={{ width: "100%" }}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                label={t("myPhone")}
                                InputProps={{
                                    inputRef: inputRef1,
                                    inputProps: {
                                        inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    }
                                }}
                                onWheel={(e) => {
                                    if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                        inputRef1.current.blur();
                                    }

                                }}
                                type="number"
                                id="phone"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                variant="outlined"
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                label={t("emaillong")}
                                style={{ width: "100%" }}
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                        inputMode: 'url'
                                    },

                                }}
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <AutocompleteGooglePlaces
                                id="address"
                                name="address"
                                onPlaceSelected={(placeDetails) => addressChanged(placeDetails.addressDetails)}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                noOptionsText={t("addressNoOption")}
                                onBlur={formik.handleBlur}
                                inputLabel={t('cityStreetAndHouseNum')}
                                inputPlaceholder={t("addressSearchPlaceholder")}
                                inputError={formik.touched.address && Boolean(formik.errors.streetNo)}
                                inputHelperText={formik.touched.address && formik.errors.streetNo}
                            />
                            {/* <Autocomplete
                                disableClearable={true}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.marginEnd5p} ${classes.width70}`}
                                id="street"
                                name="street"
                                open={streetValue.length >= 3 && streetOpen}
                                inputValue={streetValue}
                                onInputChange={(event, newInputValue) => {
                                    setStreetValue(newInputValue);
                                    setStreetOpen(true)
                                }}
                                noOptionsText={t("addressNoOption")}
                                onChange={(e, value) => streetValueChanged(value)}
                                options={streetsList}
                                isOptionEqualToValue={(option, value) => option.Code === value.Code}
                                getOptionLabel={(option) => `${option.StreetName} ${option.CityName}`}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.Code}>
                                        {option.StreetName}, <div style={{ fontWeight: "bold", marginRight: "3px" }}>{option.CityName}</div>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label={t('cityAndStreet')}
                                        placeholder={t("searchPlaceHolder1")}
                                        error={formik.touched.street && Boolean(formik.errors.street)}
                                        helperText={formik.touched.street && formik.errors.street}
                                        InputProps={{
                                            ...params.InputProps,
                                            inputMode: "search",
                                            endAdornment: (
                                                <>
                                                    {address.loading && <CircularProgress color="inherit" size={20} />}
                                                    {params.InputProps.endAdornment}
                                                </>)
                                        }} />}
                            />
                            <TextField
                                label={t('number')}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle1} ${classes.width25}`}
                                id="streetNo"
                                name="streetNo"
                                value={formik.values.streetNo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.streetNo && Boolean(formik.errors.streetNo)}
                                helperText={formik.touched.streetNo && formik.errors.streetNo}
                                variant="outlined"
                            /> */}
                        </HBox>
                        <ButtonGroup color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`}>
                            <Button className={`${formik.values.parent2IdType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "1" })}>{IdTextByType[1]}</Button>
                            <Button className={`${formik.values.parent2IdType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "2" })}>{IdTextByType[2]}</Button>
                            <Button className={`${formik.values.parent2IdType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, parent2IdType: "3" })}>{IdTextByType[3]}</Button>
                        </ButtonGroup>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                label={`${t("parent2Id")}`}
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle} ${classes.RemoveTextFieldNumberArrow}`}
                                id="parent2Id"
                                name="parent2Id"
                                style={{ width: "100%" }}
                                value={formik.values.parent2Id}
                                onChange={e => idChange(e, "p2")}
                                onBlur={formik.handleBlur}
                                error={formik.touched.parent2Id && Boolean(formik.errors.parent2Id)}
                                helperText={formik.touched.parent2Id && formik.errors.parent2Id}
                                InputProps={{
                                    inputRef: inputRefP2,
                                    inputProps: {
                                        inputMode: 'tel',
                                        pattern: "[0-9]*"
                                    }
                                }}
                                onWheel={(e) => {
                                    if (inputRefP2 && inputRefP2.current && inputRefP2.current.blur) {
                                        inputRefP2.current.blur();
                                    }

                                }}
                                variant="outlined"
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                            <TextField
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                label={t("parent2Mail")}
                                style={{ width: "100%" }}
                                id="parent2Email"
                                name="parent2Email"
                                value={formik.values.parent2Email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.parent2Email && Boolean(formik.errors.parent2Email)}
                                helperText={formik.touched.parent2Email && formik.errors.parent2Email}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                        inputMode: 'url'
                                    }
                                }}
                            />
                        </HBox>
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`} alignItems="center">
                            <Checkbox
                                onTouchEnd={e => setOpenDialog(true)}
                                onClick={e => setOpenDialog(true)}
                                icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} />}
                                checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} />}
                                checked={formik.values.termsUse} />
                            <Typography className={classes.termsLink} sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }} onClick={e => setOpenDialog(true)}>{t("regTerms")}</Typography>
                        </HBox>
                        <TermsDialog
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}
                            termsConfirms={termsConfirms}
                            title={t("termsUse")}
                            src={`${process.env.REACT_APP_REGISTRATION_TERMS_URL}`}
                            showButtons={true} />
                        <HBox width={'100%'} className={` ${classes.marginBottom1}`} alignItems="center" justifyContent='center'>
                            {users.loading || updateUserData.loading ? <CircularProgress sx={{ color: '#3a7735' }} /> :
                                <Button
                                    className={!formik.values.termsUse ? `${classes.grayBG} ${classes.buttonGreen}` : `${classes.greenBG} ${classes.buttonGreen}`}
                                    disabled={!formik.values.termsUse}
                                    type="submit">
                                    {t('continue')}
                                </Button>}
                        </HBox>
                    </form>
                </VBox>

            </Container >

        </Box >
    );

}