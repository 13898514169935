import { useState } from 'react';
import Masonry from 'react-masonry-css'
import { memo } from "react";
// mui
import { styled } from '@mui/system';
// components
import ActivityItem from "./ActivityItem";
import { Box, Typography } from '@mui/material';
import GeshemActivities from 'Components/ParentComponents/GeshemActivities';
import { useTranslation } from 'react-i18next';


const breakpointColumnsObj = {
    default: 5,
    1350: 4,
    1000: 3,
    720: 2,
};

const StyledMasonry = styled(Masonry)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    '& .my-masonry-grid_column > div': {
        marginBottom: '1rem !important'
    }, '& .my-masonry-grid_column ': {
        margin: '0 .5rem !important',
    },
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
    }
}));

const OpenActivities = ({ activities, isParent, setKibbutzUser, addProduct, household, setOpenSummerNote }) => {
    const { t } = useTranslation();
    const { regularActivitis, geshemActivitis, onlyRootAct, onlyChildrenActivites } = splitGeshemActivities(activities, isParent);


    for (const act of activities) {
        if (act.ItemPurposeType === "4" && !act.Father && act.changeName !== 1) {
            const words = act.ActivName.split(' ');
            words.splice(1, 0, "(גבייה שנתית מרוכזת)");
            act.ActivName = words.join(' ');
            act.changeName = 1;
        }
    }
    return (
        <>
            {isParent ?
                <>
                    <StyledMasonry breakpointCols={breakpointColumnsObj} columnClassName="my-masonry-grid_column" >
                        {!!geshemActivitis.length && !onlyRootAct && !onlyChildrenActivites &&
                            <GeshemActivities
                                activities={geshemActivitis}
                                addProduct={addProduct}
                                household={household} />}
                        {onlyChildrenActivites && <Typography>{t("geshemActivesError")}</Typography>}
                        {regularActivitis.map((factory, i) => (
                            <Box display='flex' flexDirection='row' width='100%' key={`box${i}`}>
                                <ActivityItem
                                    props={factory}
                                    key={`activity${i}`}
                                    isParent={isParent}
                                    selected={!!factory._id}
                                    setKibbutzUser={setKibbutzUser}
                                    addProduct={addProduct}
                                    household={household}
                                    setOpenSummerNote={setOpenSummerNote}
                                />
                            </Box>
                        ))}
                    </StyledMasonry>
                </>
                : <>
                    {activities.map((fact, index) => (
                        <Box key={`factoryGroup${index}`} display='flex' flexDirection='column' width='100%'>
                            <h3 style={{ padding: "0px 1rem" }}>{fact.factoryName}</h3>
                            <Box display='flex' flexDirection='row' width='100%'>
                                <StyledMasonry breakpointCols={breakpointColumnsObj} columnClassName="my-masonry-grid_column" >
                                    {fact.factoriesArray?.map((factory, i) => (
                                        <ActivityItem
                                            props={factory}
                                            key={`activity${i}`}
                                            isParent={isParent}
                                            selected={!!factory._id}
                                            setKibbutzUser={setKibbutzUser}
                                            addProduct={addProduct}
                                            household={household}
                                        />
                                    ))}
                                </StyledMasonry>
                            </Box>
                        </Box>))}

                </>}
        </>);
}

function splitGeshemActivities(activities, isParent) {
    let regularActivitis = structuredClone(activities),
        geshemActivitis = [];

    if (isParent) {
        geshemActivitis = activities.filter(activ => activ.ItemPurposeType === '4')
        regularActivitis = activities.filter(activ => activ.ItemPurposeType !== '4')
    }
    const onlyRootAct = geshemActivitis.every(act => !act.Father);
    const onlyChildrenActivites = !!geshemActivitis.length && geshemActivitis.every(act => act.Father);
    return { regularActivitis, geshemActivitis, onlyRootAct, onlyChildrenActivites }

}
export default memo(OpenActivities)