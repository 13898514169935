export const permanentInfo = {
    childClasses: {
        "1": "ג",
        "2": "ד",
        "3": "ה",
        "4": "ו",
        "5": "ז",
        "6": "ח",
        "7": "ט",
        "8": "י",
        "9": "יא",
        "10": "יב"
    },
    childGender: { "1": "זכר", "2": "נקבה", "3": "לא מוצהר" },
    paymentMethods: { "1": "אשראי", "2": "מזומן", "3": "שיק/ים", "4": "אשראי ומזומן", "5": "אשראי ושיק", "6": "מזומן ושיק", "7": "אשראי מזומן ושיק", "10": "יתרת משפחה" },
    swimPermission: {
        Y: " מאשר/ת השתתפות ובני/בתי יודע לשחות ומאשר/ת רחצת יום ולילה. ",
        D: " מאשר/ת השתתפות ובני/בתי יודע לשחות ומאשר/ת רחצת יום בלבד. ",
        H: " מאשר/ת השתתפות ובני/בתי לא יודע/ת לשחות ומאשר/ת רחצת יום ולילה.",
        Z: " מאשר/ת השתתפות ובני/בתי לא יודע/ת לשחות ומאשר/ת רחצת יום בלבד.",
        N: " לא מאשר/ת השתתפות"
    }
}