import globalHook from "use-global-hook";
import * as actions from "../actions/actions";

const initialState = {
  // login
  LoginIdProcess: 1,
  //Auth
  isLoggedIn: undefined,
  user: {},
  //Tribe
  checkRemove: false,
  searchState: false,
  total: 0, // total price of products
  finalPrice: 0,//final price to pay after calculation
  products: [], //regreq inside cart
  productsToAdd: [],
  cartFamily: '',//indicate which family is in cart
  cartState: false,
  householdSelections: [], //activities selected for current household
  household: {},
  language: 'he',
  parentSettings: {},
  creditCardApproval: false,
  guestPayment: '',
  payByExtra: false,
  payWithAllExtra: false,
  openActivities: [],
  activitiesList: [],
  openRequests: [],
  deepLink: null,
  tribePaid: { cash: 0, checks: 0, card: 0 },
  activCode: null,
  parentRegs: [],
  UTDRequests: [],
  userAccess: [],
  accessibiltyTermsDialog: false,
  family: null,
  child: null,
  totalOpenAct: 0,
  emailExsist: false,
  emailEmpty: false,
  unlockActivityDrawer: { isOpen: false, level: 1 },
  updatePupilDrawer: { isOpen: false, level: 1 },
  troopClass: '',
  pupilsToUpdate: [],
  activityToUnlock: {}
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;