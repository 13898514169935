import { useEffect, useState } from 'react'
import useGlobal from 'store/store';
import useApi from 'hooks/useApi';
import { getAddresses, getParentByCardCode } from "api/api"
import { SecondParentSetting } from './SecondParentSetting';
import { FirstParentSetting } from './FirstParentSetting';
import { LinearProgress, Box, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MuiTabs } from 'pages/parentHistoryPages/sharedHistoryComponents/HistoryComponents';
import { PeopleOutline } from '@mui/icons-material';
import { AddSecondParent } from './AddSecondParent';
import { useNavigate, usePathParams } from 'raviger';

export const ParentsSettings = ({ setIsUpdated, isUpdated, fromTribe = false, parentTab, setParentTab, fam, setFamilyUpdated, familyUpdated }) => {
    const { t } = useTranslation();
    const [currentFamily, setCurrentFamily] = useState(fam !== null && fam);
    const address = useApi(getAddresses);
    const props = usePathParams('/parentUserSettings/:familyCardCode');
    const familyCardCode = props?.familyCardCode;
    const getFamily = useApi(getParentByCardCode);
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
    const navigate = useNavigate();

    useEffect(() => {
        handleFamily();
    }, [])

    useEffect(() => {
        setFamily(currentFamily);
    }, [currentFamily])

    const handleFamily = async () => {
        if (fromTribe) {
            if (fam === null) {
                const res = await getFamily.requestPromise(familyCardCode);
                if (res.d?.results) {
                    setCurrentFamily(res.d?.results[0]);
                }
                else {
                    setCurrentFamily({});
                    navigate('/homepage');
                }
            }
        }
    }

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ background: '#eef3ee', height: "3rem" }} >
                <PeopleOutline sx={{ color: 'black', fontSize: 30, marginInlineStart: "1rem !important" }} />
                <Typography sx={{ marginInlineStart: "1rem !important" }} variant='h5'>
                    {t("parents")}
                </Typography>
            </Box>
            {address.loading ? <LinearProgress sx={{ color: "#3a7735 " }} /> : <>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, fontWeight: "bold", paddingLeft: { xs: "1rem" } }}>{`${t("familyOf")} ${currentFamily.Parent1_LastName}`}</Typography>
                    <MuiTabs
                        value={parentTab}
                        onChange={(e, newValue) => setParentTab(newValue)}
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab label={currentFamily.Parent1_FirstName === "" || currentFamily.Parent1_FirstName === null ? t("parent1") : currentFamily.Parent1_FirstName} key={0} value={1} />
                        {currentFamily.Parent2_ID !== "" && <Tab label={currentFamily.Parent2_FirstName === "" || currentFamily.Parent2_FirstName === null ? t("parent2") : currentFamily.Parent2_FirstName} key={1} value={2} />}
                    </MuiTabs>
                </Box>
                {parentTab === 2 && !currentFamily.Parent2_ID ? <Typography variant="h5" fontWeight={'600'} alignSelf="center">{t("addParent2")}</Typography> :
                    <Typography variant="h5" fontWeight={'600'} alignSelf="center">{t("userSettings")}</Typography>}
                {currentFamily.Parent1_ID && parentTab === 1 &&
                    <FirstParentSetting household={currentFamily} setIsUpdated={setIsUpdated} isUpdated={isUpdated} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />}
                {parentTab === 2 && !currentFamily.Parent2_ID && <AddSecondParent setIsUpdated={setIsUpdated} isUpdated={isUpdated} fromTribe={fromTribe} family={currentFamily} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />}
                {parentTab === 2 && currentFamily.Parent2_ID && < SecondParentSetting household={currentFamily} setIsUpdated={setIsUpdated} isUpdated={isUpdated} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />}
            </>}

        </>
    );
}