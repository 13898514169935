import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import ParentHistory from '../Components/ParentComponents/ParentHistory';
import ParentActivities from '../Components/ParentComponents/ParentActivities';
import ChildrenTabBar from '../Components/ParentComponents/ChildrenTabBar';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useGlobal from '../store/store';
import inactiveChildren from '../assets/inactiveChildren.svg'


const useStyles = makeStyles(() => ({
    limitedWidth: {
        maxWidth: '70rem'
    }
}));

const ParentHomePage = ({ isUpdated, setIsUpdated, setKibbutzUser, navigate, setAppBarTitle, setOpenSummerNote }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [allChildren, setAllChildren] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);


    const [household, [setHousehold, removeHouseholdSelection]] = useGlobal(
        state => state.household,
        actions => [actions.setHousehold, actions.removeHouseholdSelection]
    );

    const [products, [setProducts, addProduct]] = useGlobal(
        state => state.products,
        actions => [actions.setProducts, actions.addProduct]
    );

    const [householdSelections, [setHouseholdSelections]] = useGlobal(
        state => state.householdSelections,
        actions => [actions.setHouseholdSelections]
    );

    const childId = useRef(null);
    const evreyoneTab = 0;

    useEffect(() => {
        setAllChildren(household.ChildrenList?.results.filter(ch => ch.PupilStatus === "Y"));
    }, [household])
    const setSelectedChild = (index) => {
        if (index) {
            setCurrentTab(index);
            childId.current = allChildren[index - 1].CardCode
            // childId.current = household.ChildrenList?.results[index - 1].CardCode;
        }
        else {
            setCurrentTab(0);
            setAllChildren(household.ChildrenList?.results.filter(ch => ch.PupilStatus === "Y"));
        }

    }
    const handleNavigate = () => {
        setAppBarTitle(t("myFamily"));
        navigate('/parent/MyFamily');
    }

    return (
        <>
            {/* Tab bar containing children list */}
            <ChildrenTabBar household={household} currentTab={currentTab} setSelectedChild={setSelectedChild} products={products} setProducts={setProducts}
                removeHouseholdSelection={removeHouseholdSelection} householdSelections={householdSelections} navigate={navigate} />
            <Box>
                {/* List of open activities per child available for registration */}
                {currentTab === evreyoneTab ?
                    !!allChildren.length ?
                        allChildren.map((child, i) => {
                            return (<Box className={classes.limitedWidth} mb={5} key={i}>
                                {!i && <ParentHistory childId={child.CardCode} removeHouseholdSelection={removeHouseholdSelection} currentTab={currentTab} isUpdated={isUpdated} setIsUpdated={setIsUpdated} allChildren={true} />}
                                <ParentActivities household={household} householdSelections={householdSelections} child={child} setKibbutzUser={setKibbutzUser} addProduct={addProduct} products={products} setOpenSummerNote={setOpenSummerNote} />

                            </Box>)
                        }) :
                        <Box className={classes.limitedWidth} mb={5} key={"inactiveChildren"}>
                            <img
                                alt={"..."}
                                src={inactiveChildren}
                                width="300px"
                                height="300px"
                            />
                            <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold" }} > {t("inactiveChildren")}</Typography>
                            <Typography sx={{ color: "#ff0000", fontSize: "1.3rem", fontWeight: "bold" }} >{t("notactive")}</Typography>
                            <Typography sx={{ color: "#3a7735", fontSize: "1rem", fontWeight: "bold" }}> {t("youCanChangeIt")}</Typography>
                            <Button onClick={e => handleNavigate()} sx={{ backgroundColor: "#3a7735 !important", color: "#ffffff", marginTop: "1rem" }}>{t("toMyFamily")}</Button>
                        </Box>
                    :
                    <Box className={classes.limitedWidth} mb={5}>
                        {/* History of activities */}
                        <ParentHistory childId={childId.current} removeHouseholdSelection={removeHouseholdSelection} currentTab={currentTab} isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
                        <ParentActivities
                            household={household}
                            householdSelections={householdSelections}
                            child={allChildren[currentTab - 1]}
                            setKibbutzUser={setKibbutzUser}
                            addProduct={addProduct}
                            products={products}
                            setOpenSummerNote={setOpenSummerNote}
                        />
                    </Box>
                }
            </Box>
        </>

    );
}
export default ParentHomePage;