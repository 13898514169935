import { makeStyles } from '@mui/styles';
import appBG from '../assets/appBG.jpg';

const useStyles = makeStyles((theme) => ({

    paddingBottom2: {

        paddingBottom: "2rem"
    },
    padding1: {

        padding: "1rem"
    },
    paddingBottom1: {
        paddingBottom: "1rem"
    },
    fontBold: {
        fontWeight: 600 // or 'bold'
    },
    marginTop1: {
        marginTop: "1rem"
    },
    marginBottom1: {
        marginBottom: "1rem !important"
    },
    width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    },
    paddingBottom1rem: {
        paddingBottom: '1rem !important'
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    selectedBtn: {
        textTransform: "capitalize !important",
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important"
    },
    IdnBtn: {
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important"
    },
    continueBtn: {
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important"
    },
    pageStyle: {
        maxWidth: '30rem',
        margin: 'auto !important',
        '& .MuiCardContent-root': {
            padding: '0px'
        }
    },
    buttonGreen: {
        '& .MuiButton-label': {
            color: 'white',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important'
    },
    buttonGreenLight: {
        '& .MuiButton-label': {
            color: 'white',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        backgroundColor: '#97b595 !important',
        borderRadius: '2rem !important',
        textTransform: "capitalize !important",
    },
    CenterItems: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center'
    },
    marginAuto: {
        margin: 'auto !important',
    },
    SmsVerStyle2: {
        display: 'inline-grid',
        justifyContent: 'center'
    },
    SmsVerStyle: {
        maxWidth: '30rem',
        background: 'white',
        padding: '0 16px',
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important'
    },
    IdentificationBtn: {
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        width: "17rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    BottomAppBarStyle: {
        '& .Muirtl-1t29gy6-MuiToolbar-root': {
            bottom: '0 !important',
            position: 'fixed !important',
            width: '100% !important'
        }
    },

    marginEnd5p: {
        marginEnd: "5% !important"
    },
    LogoSize: {
        width: '30px',
        height: '45px'
    },
    width70: {
        width: "70%",
    },
    width25: {
        width: "25%",
    },
    TextfieldILabelStyle: {
        '& .MuiInputLabel-root': {
            color: 'black !important',
            fontSize: '1.3rem !important',
            top: '-3px',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 'bold !important',
            color: 'black !important',
            fontSize: '1.3rem !important',
            top: '-3px',
            padding: '0 10px'
        },
        '& .Muirtl-1in441m': {
            fontSize: '1.2rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        }
    },
    buttonFont1_2: {
        '& .MuiButton-label': {
            fontSize: '1.2rem'
        }
    },
    buttonFont1: {
        '& .MuiButton-label': {
            fontSize: '1rem'
        }
    },
    backgruond: {
        backgroundImage: `url(${appBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: "100%"
    },
    buttonRed: {
        '& .MuiButton-label': {
            color: 'red',
            fontWeight: 'bold'
        },
        backgroundColor: 'white !important',
        border: '1px solid red !important',
        borderRadius: '2rem !important'
    }

}));
export { useStyles };