import { alpha } from '@mui/material/styles';
import { yearsToMonths } from 'date-fns';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// object
//   lighter: '#D0F2FF',
//   light: '#74CAFF',
//   main: '#1890FF',
//   dark: '#0C53B7',
//   darker: '#04297A',
//   contrastText: '#fff',
// 
// SETUP COLORS
const DISABLE = {
  main: '#797979',
  lighter: '#dbdbdb'
}
const BLACK = {
  main: '#000'
}
const GREEN = {
  hover: '#3b78367a',
  lighter: '#f6f9f6',
  light: '#e3f7e1',
  main: '#3a7735',
  darker: "#1a7d80"
}
const PRIMARY = {
  // lighter: '#D1E9FC',
  // light: '#76B0F1',
  main: '#3a7735',
  // dark: '#103996',
  // darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  // lighter: '#D6E4FF',
  // light: '#84A9FF',
  main: '#ffbb41',
  // dark: '#1939B7',
  // darker: '#091A7A',
  // contrastText: '#fff',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#41c146',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D'
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#ffa800',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01'

};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#ff6645',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};
const GREY = {
  main: '#e0ddd94a'
};
const DARKGREY = {
  main: '#333333'
};
const ALERT = {
  main: '#BB0000'
};
const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: { ...GREY },
  darkgrey: { ...DARKGREY },
  green: { ...GREEN },
  black: BLACK,
  disable: DISABLE,
  alert: { ...ALERT },
  neutral: { main: '#fff', contrastText: '#000' },
  background: { paper: '#fff', default: "#ffffff", neutral: '#3a7735', },

};

export default palette;
