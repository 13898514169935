import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
//mui
import { Typography, Box } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
//components
import MakeTransaction from '../../Payments/MakeTransaction';
//data
import { system } from "../../../api/client"
//libs
import xmlToJSON from 'xmltojson'

const useStyles = makeStyles(() => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    cashTextAndField: {
        alignItems: 'baseline !important'
    },
    cashText: {
        color: '#007f00 !important',
        fontSize: '1rem !important',
        textAlign: 'right !important'
    }
}));

const PaymentByCreditCard = ({ overPay, totalPayed, finalPrice, setCard, setPaymentDetails, paymentDetails, chosenMethod, setRedayForPayment, redayForPayment, setTribePayment }) => {
    const { t } = useTranslation();
    const [cardAmount, setCardAmount] = useState(parseFloat(finalPrice - totalPayed));

    useEffect(() => {
        const handler = (...args) => {
            if (args[0].data.paymentIframe && args[0].data.success) {
                try {
                    let result = xmlToJSON.parseString(args[0].data.response);
                    //amount
                    let amount = (parseInt(result.ashrait[0].response[0].inquireTransactions[0].row[0].amount[0]._text) / 100);
                    // credit card type first digit <creditCompany code="">
                    // Isracard (1),Visa (2),Diners (3), Amex (4),Jcb (5), Alphacard (6)
                    let creditCardType = (result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].creditCompany[0]._attr.code._value.toString().charAt(0))
                    //payment type (regular - 1 ,credit - 2)
                    let creditType = result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].creditType[0]._attr.code._value;
                    //VoucherNumber=fileNumber+slaveTerminalNumber+slaveTerminalSequence
                    let voucherNumber = (`${result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].fileNumber[0]._text}${result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].slaveTerminalNumber[0]._text}${result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].slaveTerminalSequence[0]._text}`);
                    //TranId for refund 
                    let tranId = result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].tranId[0]._text;
                    //handle card Exp if 3 digits add leading "0" else will be 4 digits (date format MM/YY)
                    let cardExp = result.ashrait[0].response[0].inquireTransactions[0].row[0].cardExpiration[0]._text.toString().length === 3 ? "0" + result.ashrait[0].response[0].inquireTransactions[0].row[0].cardExpiration[0]._text.toString() : result.ashrait[0].response[0].inquireTransactions[0].row[0].cardExpiration[0]._text.toString();
                    //handle personal ID if 8 digits add leading "0" else will be 9 digits as correct Israeli ID
                    let personalId = result.ashrait[0].response[0].inquireTransactions[0].row[0].personalId[0]._text.toString().length === 8 ? "0" + result.ashrait[0].response[0].inquireTransactions[0].row[0].personalId[0]._text.toString() : result.ashrait[0].response[0].inquireTransactions[0].row[0].personalId[0]._text.toString();
                    //handle number of payments if 1 CG return [null] else CG return Number and 
                    let numberOfPayments;
                    if (system === "prod") {
                        numberOfPayments = result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].numberOfPayments[0]._text[0] === null ? 1 : result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].numberOfPayments[0]._text;
                    } else {
                        numberOfPayments = result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].numberOfPayments[0]._text[0] === null ? 0 : result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].numberOfPayments[0]._text;
                    }
                    let cardMask = result.ashrait[0].response[0].inquireTransactions[0].row[0].cgGatewayResponseXML[0].ashrait[0].response[0].doDeal[0].cardMask[0]._text;
                    let lastFourDigits = cardMask.substr(cardMask.length - 4);
                    let authNumber = result.ashrait[0].response[0].inquireTransactions[0].row[0].authNumber[0]._text;
                    setCard({
                        CardValidUntil: cardExp,
                        CreditCardOwnerID: personalId,
                        ConfirmationNumber: authNumber,
                        CreditCardNumber: lastFourDigits,
                        CreditCardNumberOfPayments: numberOfPayments,
                        CreditCardPaymentType: creditType,
                        CreditCardType: creditCardType,
                        CreditSum: amount,
                        VoucherNumber: voucherNumber,
                        TranId: tranId
                    });
                    setPaymentDetails({ ...paymentDetails, creditCard: amount });
                    setTribePayment(true);
                } catch (error) {
                    console.log(error);
                }
            }
        }
        window.addEventListener("message", handler);
        return () => {
            window.removeEventListener("message", handler);
        }
    }, []);

    useEffect(() => {
        if (chosenMethod.creditCard && !chosenMethod.check && !chosenMethod.cash) {
            setRedayForPayment(true);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="row" alignSelf="flex-start" alignItems="center">
                <CreditCardOutlinedIcon sx={{ color: "#3a7735", fontSize: { xs: "1.1rem", md: "1.5rem" } }} />
                <Typography sx={{ marginLeft: "1rem", color: "#3a7735", fontSize: { xs: '1rem', md: '1.5rem' } }}> {t("creditCard")}</Typography>
            </Box>
            {!overPay && cardAmount > 0 && redayForPayment && <MakeTransaction tribeUser={true} cardAmount={cardAmount} />}
        </Box>
    )
}
export default PaymentByCreditCard;