import { useRef } from 'react'
import { useStyles } from '../../assets/LoginStyle.jsx'
import { Box, Button, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useVerification from 'hooks/useVerification.js';
import { useSnackbar } from 'notistack';
import resetPassSvg from '../../assets/resetPassSvg.svg'
export const ForgetPassword = ({ changePhoneValue, PhoneNumber, setLoginIdProcess }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const inputRef1 = useRef()
    const hookVerification = useVerification();
    const { enqueueSnackbar } = useSnackbar();

    const handleSendCodeToResetPass = async (event) => {
        try {
            // prepare phoneNumber
            let sPhoneNoHyphen = PhoneNumber['phone'].replaceAll("-", "")
            const sPhoneNumber = "+972" + (sPhoneNoHyphen[0] === '0' ? sPhoneNoHyphen.slice(1, sPhoneNoHyphen.length) : sPhoneNoHyphen);

            const confirmationResult = await hookVerification.sendOTP(sPhoneNumber);
            if (confirmationResult) {
                setLoginIdProcess(3);
                changePhoneValue(confirmationResult, 'confirmationResult');
            }
        } catch (error) {
            enqueueSnackbar(error?.message || t("Error"), { className: classes.redBG });
        }
    };
    return (
        <Box height={'100%'} className={classes.width100} display='flex' alignItems="center" flexDirection='column' >
            <Typography align="center" className={`${classes.paddingBottom1rem} ${classes.paddingTop2_5rem} ${classes.fontSize1_5} ${classes.forgotTitle}`}>
                {t("forgotPassword")}
            </Typography>
            <img
                alt={"..."}
                width="200px"
                height="120px"
                src={resetPassSvg}
            />
            <Typography align="center" sx={{ fontSize: "1.1rem" }} className={`${classes.paddingBottom1rem} ${classes.margin0_3}`} >
                {t("pleaseEnterPhoneNumber")}
            </Typography>
            <TextField
                label={t("phoneNumber")}
                placeholder={t("insertPhoneNumber")}
                className={`${classes.marginEnd5p} ${classes.RemoveTextFieldNumberArrow} ${classes.TextfieldILabelStyle}`}
                InputProps={{
                    inputRef: inputRef1,
                    inputProps: {
                        inputMode: 'tel',
                        pattern: "[0-9]*"
                    }
                }}
                type="number"
                onChange={e => changePhoneValue(e, 'phone')}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSendCodeToResetPass();
                    }
                }}
                value={PhoneNumber.phone}
                variant="outlined"
                onWheel={(e) => {
                    if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                        inputRef1.current.blur();
                    }
                }}
            />
            <Button
                disabled={!PhoneNumber.phone}
                onClick={handleSendCodeToResetPass}
                className={!PhoneNumber.phone ? classes.buttonGreenLight : classes.buttonGreen}
                sx={{ marginTop: "2.5rem" }}
                variant="contained" >
                {t("sendCodeToMyMobile")}
            </Button>
        </Box>
    );
}