import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Stack, LinearProgress, Typography, Button } from '@mui/material';
import { VBox } from '../../assets/SharedComponents';
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

import useFeathers from '../../hooks/useFeathers';
import { useState } from 'react';
import { CustomBusy } from 'Components/Common/CustomBusy.jsx';
import { Animated } from "react-animated-css";
import microsoftpng from '../../assets/microsoftPng.png'
import employeeSvg from '../../assets/employeeSvg.svg'

const useStyles = makeStyles(() => ({
  loginTitle: {
    fontSize: "1.25rem !important",
    fontWeight: "700 !important"
  },
  officeBox: {
    height: '100% !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    margin: '1rem !important'
  },
  buttonGreen: {
    color: 'white !important',
    fontSize: '1.2rem !important',
    fontWeight: 'bold !important',
    backgroundColor: '#3a7735 !important',
    borderRadius: '2rem !important',
    boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
    textTransform: "capitalize !important"
  },
  underLineText: {
    "&:hover": {
      textDecoration: 'underline !important',
      cursor: 'pointer !important'
    },
    textDecoration: 'underline !important',
    fontSize: '1.2rem !important',
    fontWeight: 'bold !important',
    color: '#007f00 !important',
    marginTop: '20rem !important'
  }
}));

export const Office365Tab = ({ handleChange, setTribeUserTab }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const provider = new OAuthProvider('microsoft.com');
  const auth = getAuth();

  const feathersAuth = useFeathers();

  const backToSMS = (e) => {
    handleChange(e, 1)
    setTribeUserTab(false)
  }
  const MicrosoftLogin = () => {
    setBusy(true)
    signInWithPopup(auth, provider)
      .then(async (result) => {
        feathersAuth.authenticationWithToken('firebase', result?.user?.accessToken);
      })
      .catch((error) => {
        setBusy(false)
        console.log(error)
      });
  }

  return (
    <Animated animationOut="fadeInDown" animationOutDuration={3500} isVisible={true}>
      <VBox className={classes.officeBox}>
        <Typography align='center' className={classes.loginTitle} >{t("empLogin")}</Typography>

        {feathersAuth.loading &&
          <CustomBusy text={t("loginBusy")} />}
        {feathersAuth.error && <Typography align='center'>{feathersAuth.error}</Typography>}
        <img
          alt={"..."}
          width="200px"
          height="200px"
          src={employeeSvg}
        />
        <img
          alt={"..."}
          width="45px"
          height="34px"
          src={microsoftpng}
        />
        <Typography align='center'>{t("microsoftText")}</Typography>
        {busy ? <CustomBusy text={t("loginBusy")} /> :
          <Button className={classes.buttonGreen} onClick={e => MicrosoftLogin()} sx={{ marginTop: "2.5rem" }}> {t("moveToLogin")}</Button>}
      </VBox>
    </Animated>

  );
}