import { useTranslation } from 'react-i18next';
import { HBox, VBox } from '../../../assets/SharedComponents';
import { Typography, Avatar, Tooltip } from '@mui/material';
import { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'raviger'
import { PeopleOutline, Person, Add } from '@mui/icons-material';
import useGlobal from 'store/store';

const useStyles = makeStyles((_theme) => ({
    familyBG: {
        background: '#eef3ee'
    },
    margin1Start: {
        marginInlineStart: "1rem !important"
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    avatarStyle: {
        display: 'inline-flex',
        width: '15rem',
        justifyContent: 'space-between',
        padding: '1rem 0rem'
    }

}));

export const Parents = ({ family = {}, isFromTribe, setParentTab }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentFamily, setCurrentFamily] = useState({});
    const [household] = useGlobal((state) => state.household);


    useEffect(() => {
        if (isFromTribe) {
            setCurrentFamily(family);
        }
        else {
            setCurrentFamily(household);
        }
    }, [])


    const handleLinksClick = (parent) => {
        if (isFromTribe) {
            navigate(`/parentUserSettings/${currentFamily.CardCode}`);
        }
        else {
            navigate('/parentUserSettings');
        }
        setParentTab(parent === "p1" ? 1 : 2);
    }

    return (
        <VBox alignItems="center">
            <HBox className={`${classes.familyBG} ${classes.alignItemsCenter}`} height="3rem" width="100%">
                <PeopleOutline className={classes.margin1Start} style={{ color: 'black', fontSize: 30 }} />
                <Typography className={classes.margin1Start} variant='h5'>
                    {t("parents")}
                </Typography>
            </HBox>
            <HBox className={classes.avatarStyle}>
                <VBox>
                    <Tooltip title={t('editParentSetting')}>
                        <div onClick={e => handleLinksClick("p1")} style={{ cursor: "pointer" }}>
                            <Avatar
                                sx={{ width: 65, height: 65, bgcolor: '#cee2cd', boxShadow: '0 8px 9px rgb(125 125 125 / 40%)' }}>
                                {currentFamily.Parent1_FirstName ?
                                    <Person style={{ color: 'white', fontSize: 53 }} />
                                    : <Add style={{ color: 'white', fontSize: 53 }} />}
                            </Avatar>
                            <Typography variant='h5'>
                                {currentFamily.Parent1_FirstName} {currentFamily.Parent1_LastName}
                            </Typography>
                        </div>
                    </Tooltip>
                </VBox>
                <VBox>
                    <Tooltip title={currentFamily.Parent2_ID ? t('editParentSetting') : t('addParent2')}>
                        <div onClick={e => handleLinksClick("p2")} style={{ cursor: "pointer" }}>
                            <Avatar
                                sx={{ width: 65, height: 65, bgcolor: '#cee2cd', boxShadow: '0 8px 9px rgb(125 125 125 / 40%)' }}>
                                {currentFamily.Parent2_ID ?
                                    <Person style={{ color: 'white', fontSize: 53 }} />
                                    : <Add style={{ color: 'white', fontSize: 53 }} />}
                            </Avatar>
                            {currentFamily.Parent2_ID ?
                                <Typography variant='h5'>
                                    {currentFamily.Parent2_FirstName} {currentFamily.Parent2_LastName}
                                </Typography>
                                :
                                <Typography>
                                    {t("addParent2Details")}
                                </Typography>}
                            {currentFamily.Parent2_ID && !currentFamily.Parent2_FirstName &&
                                <Typography variant='h5'>
                                    {t("editParent2Details")}
                                </Typography>}
                        </div>
                    </Tooltip>
                </VBox>

            </HBox>
        </VBox>

    );
}