import { getService, httpClient, baseUrl, feathersClient } from "./client";

export const searchInParent = async (tribe, name) => {

    return getService('search-service').find({ query: { tribe, name } });
}

export const parentChildrenAPI = async () => {
    try {
        const response = await getService('odata/parent-children').get("father?$expand=ChildrenList,ChildrenList/ChildFactoriesPortal,ChildrenList/DiseasesList,ChildrenList/FoodPreferencesList,ChildrenList/AllergiesList");
        return response;
    } catch (error) {
        throw error;
    }

};

export const getChildrenListAPI = async () => {
    try {
        const response = await getService('odata/parent-children').get("father?$expand=ChildrenList");
        return response;
    } catch (error) {
        throw error;
    }
};

export const getParentCardCode = async (id) => {
    try {
        const response = await getService('odata/parent-children').get(`father?$filter=(Parent1_ID eq '${id}' or Parent2_ID eq '${id}')`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const getParentByCardCode = async (cardCode) => {
    try {
        const response = await getService('odata/parent-children').get(`father?$filter=(CardCode eq '${cardCode}')&$expand=ChildrenList,ChildrenList/ChildFactoriesPortal,ChildrenList/DiseasesList,ChildrenList/FoodPreferencesList,ChildrenList/AllergiesList`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const isMailVerified = async (email) => {
    try {
        const response = await getService('email-verified').get(email);
        return response;
    } catch (error) {
        throw error;
    }
}
export const userAccessAPI = (email) => getService('/odata/user-access').get('access', { query: { $filter: `PortalUser eq '${email}'` } });

export const addRegAPI = async reg => {
    try {
        const response = await getService('registration-request').create(reg);
        return response;
    } catch (error) {
        throw error;
    }
}

export const removeRegAPI = ({ _id }) => {
    try {
        const response = getService('registration-request').remove(_id);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRegAPI = (regReq) => getService('registration-request').find({ query: { ...regReq } });

export const getHistoryRegAPI = (parentCardCode) => getService('get-history-registration').get("getParentHistory", { query: { parentCardCode } });

export const UpdateRegAPI = ({ _id, data }) => {
    return new Promise((resolve, reject) => {
        getService('registration-request').patch(_id, data).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const addRefundAPI = async refund => {
    try {
        const response = await getService('refunds').create(refund);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRefundAPI = (refund) => getService('refunds').find({ query: { ...refund } });

export const addParentStatmentAPI = async statment => {
    try {
        const response = await getService('parent-statment').create(statment);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addCreditTransactionAPI = async transaction => {
    try {
        const response = await getService('credit-transactions').create(transaction);
        return response;
    } catch (error) {
        throw error;
    }
}

export const checkMatchingParentAPI = (data) => getService('check-matching-parent').find({ query: data });

export const patchUserAPI = (id, data) => getService('users').patch(id, data);

export const updatePreferences = (id, data) => getService('user-preferences').patch(id, data);

export const getBalanceCalc = (parentCardCode) => getService('odata/parent-balance-calculation').get(`parentBalanceCalculationParameters(ParentCardCode='${parentCardCode}')`);

export const updatePupilStatment = (data) => getService(`/add-update-pupil`).create(data)

export const getSchoolsList = () => {
    const otherCityFilter = encodeURI('לומד בעיר אחרת')
    return getService('odata/get-schools').get(`schools?$filter=SchoolName ne '${otherCityFilter}'`)
}

export const getSchoolsList1 = (list) => {
    return getService('get-schools-list').get(list);
}

export const getAddresses = () => getService('odata/get-addresses').get(`street`);

export const associationInfo = () => getService('odata/association-info').get(`entities?$expand=MasterDataDiseases,MasterDataAllergies,MasterDataBankList,MasterDataFoodPreferences`);

export const getTribes = () => getService('/odata/tribe').find();

export const addRegistrationHana = (activityDetails) => getService(`/add-registration-hana`).create(activityDetails);

export const getGuid = (products, user) => {
    return new Promise((resolve, reject) => {
        let activityList = []
        products.forEach((product) => {
            const temp = {
                StudentCardCode: product.StudentCardCode,
                ActivityItemCode: product.ActivCode,
                ActivityPrice: product.FinalPrice,
                ActivityYear: product.PeriodCode
            }
            activityList.push(temp)
        })
        const activityDetails = {
            ActivityList: activityList,
            ParentCardCode: products[0].ParentCardCode,
            RegistrationSource: user === "parent" ? "APF" : "ASF",
            UnitCode: products[0].TribeCode
        }
        addRegistrationHana(activityDetails).then((res) => {
            // const indexRegistration = res.IndexRegistration
            resolve(res.Guid);
        }).catch((error) => {
            reject(error);
        })
    });
}

export const addPaymentHana = (paymentDetails) => getService(`/add-payment-hana`).create(paymentDetails);

export const CGinquireTransaction = (data) => getService('inquire-transaction').create(data)

export const updateUserData = (data) => {
    return new Promise((resolve, reject) => {
        getService('odata/parent-children').create(data).then(response => {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    });

};

export const updateParentDetails = ({ id, data }) => getService(`update-parent-details`).patch(id, data);

export const checkUserName = (filter) => getService('/check-user').find({ query: { ...filter } })

export const contactTribe = async (data) => {
    await getService('contact').create({ ...data });
};

export const sendParentMail = (data) => {
    getService('send-parent-mail').create({ ...data });
}
export const updateInvoiceNumber = async (data) => getService('/update-invoice-number').create({ ...data });

export const checkParentChildren = (id) => getService('odata/parent-children').get(`father?$filter=substringof('${id}',Parent2_ID)`).then(res => {
    return res;
});
export const updateUser = (userData) => getService('/add-update-parent').create(userData);

export const getFactories = (tribeCode) => getService('/odata/factories').get("getFactoriesTribeUser", { query: { tribeCode } });

export const sendResetPasswordEmail = (data) => {
    return new Promise((resolve, reject) => {
        getService('send-reset-password').create({ data })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const generateVerificationEmail = (data) => {
    getService('generate-verification-email').create({
        ...data,
        continueUrl: `${window.location.origin}/register/mailver/${data.email}`
    });
}

export const getActivitiesReport = (query) => getService('/get-activities-report').find({ query });

export const getReportFile = (data) => getService('/get-activities-report').create(data);

export const httpGetAPI = (url) => httpClient.get(url);

export const uploadFile = async (file) => {

    const token = await feathersClient.authentication.getAccessToken();

    const formData = new FormData();
    // // we support uploading of multiple files
    if (Array.isArray(file)) {
        file.forEach((f) => {
            formData.append("file", file);
        });
    } else {
        formData.append("file", file);
    }

    const { data } = await httpClient.post(
        `${baseUrl}/files`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token
            },
            params: {
                // accountId,
                // title,
                // description
            },
            // ...config
        }
    );

    if (data && data.length) {
        if (data.length === 1) {
            return data[0];
        } else {
            return data;
        }
    } else {
        return null;
    }

}
export const addSplitPaymentLinkAPI = async splitLink => {
    try {
        const response = await getService('split-payment-links').create(splitLink);
        return response;
    } catch (error) {
        throw error;
    }
}

export const findChildInDeepLink = (query) => getService('unlock-deep-link').get("checkAllowedPupil", { query });

export const addDeepLinkAPI = async deepLink => {
    try {
        const response = await getService('register-deep-links').create(deepLink);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSplitLink = (cardCode) => getService('split-payment-links').find({ query: { parentCardCode: cardCode } });

export const removingDeepLink = ({ _id }) => getService('register-deep-links').remove(_id);

export const updateDeepLink = (id, data) => getService('register-deep-links').patch(id, data);

export const paymentsService = (data) => getService('payments-service').create(data);

export const loggerService = (data) => getService('logger-service').create(data);

// export const getIsTribeInPilot = (tribeCode) => getService('check-tribe-pilot').get(tribeCode);

export const updateParentEmail = (query) => getService('update-parent-email').get({}, { query });

export const parentsExsistnece = (query) => getService('parents-exsist-handler').get({}, { query });

export const makeOneHosuehold = (data) => getService('parents-exsist-handler').create(data);

export const updatePupilsList = (data) => getService('update-troop-group').create(data);

export const sendMailToTribe = (data) => getService('mail-to-tribe').create(data);

export const getTroopGroup = (query) => getService('get-troop-group').get("troops", { query });

export const getPeriods = () => getService('/odata/get-periods').get("period");

export const getTribePupils = (query) => getService('/odata/pupil-recognition').get("tribePupils", { query });

export const resetPassViaEmail = (data) => getService('reset-password-via-email').create(data);

export const getChildBalance = (query) => getService('child-balance-calculation').get("ChildBalance", { query });

export const getRefundsToFamily = (query) => getService('refunds-service').get("getRefundsFromSB1", { query });
export const getChildAvailableActivities = (query) => getService('child-available-activities').get("1", { query });
