import { useState } from "react";
import { useTranslation } from 'react-i18next';
import languageSvg from '../../assets/languageSvg.svg'
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { VBox } from '../../assets/SharedComponents';
import LanguageIcon from '@mui/icons-material/Language';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import useGlobal from "../../store/store";

export const LanguagePicker = () => {
  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useGlobal(
    state => state.language,
    actions => actions.setLanguage);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const languageHandler = (language) => {
    setLanguage(language);
    i18n.changeLanguage(language);
    handleClose();
  }

  return (
    <>
      <Tooltip title={t("switchLang")}>
        <img
          onClick={handleClick}
          style={{ cursor: "pointer" }}
          width="30px"
          height="30px"
          alt={"..."}
          src={languageSvg} />
      </Tooltip>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableScrollLock={true}
        // getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            left: '50%',
            transform: 'translateY(8%)',
          }
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}>
        <VBox style={{ alignItems: "center" }}>
          <MenuItem width="20rem" onClick={e => languageHandler('he')}>{language === 'he' && <CheckTwoToneIcon sx={{ color: "#3a7735" }} />} {`${t("hebrew")}`}</MenuItem>
          <MenuItem width="20rem" onClick={e => languageHandler('en')}>{language === 'en' && <CheckTwoToneIcon sx={{ color: "#3a7735" }} />}English </MenuItem>
        </VBox>
      </Menu>
    </ >
  )
}
