import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//mui
import { makeStyles } from '@mui/styles';
import { Card, TextField, Autocomplete, Typography, Input, LinearProgress, Container, Box, Table, TableRow, TableHead, TableBody, TableCell, FormHelperText, Button, IconButton } from '@mui/material';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
//components
import { VBox, HBox } from '../../../../assets/SharedComponents';

const useStyles = makeStyles(() => ({
    cardTitleAndIcon: {
        marginRight: '0.5rem !important',
        marginTop: '0.3rem !important',
        alignItems: 'center !important',
    },
    cardTitle: {
        margin: '0.5rem !important',
        fontSize: '0.9rem !important',
        alignItems: 'center !important',
        fontWeight: "bold !important"
    },
    statmentcard: {
        maxHeight: '100% !important',
        borderRadius: '1.7rem !important',
        margin: '0.8rem !important'
    },
    statmentCardContent: {
        textAlign: 'right !important',
        justifyContent: 'flex-start !important',
        alignItems: "center !important"
    },
    statmentAutoComplite: {
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: "0 !important",
            minWidth: "0px !important"
        }
    },
    select: {
        '&.MuiInput-underline:after': {
            'borderBottom': '#e0e0e0 !important',
        },
        height: '2rem !important',
        width: '10rem !important',
        borderRadius: '1.7rem !important',
        marginLeft: '0.5rem !important'
    },
    tc: {
        "&.MuiTableCell-body": {
            padding: '0 !important'
        }
    },
    buttons: {
        "&:hover": {
            background: '#e0e0e0 !important',
        },
        backgroundColor: '#e0e0e0 !important',
        height: '1.125rem !important',
        borderRadius: '2rem !important ',
        marginRight: '0.2rem !important',
        marginBottom: '0.2rem !important',
        color: 'black !important',
        textTransform: "capitalize !important"

    },
    buttons1: {
        "&:hover": {
            background: '#b6f3b5 !important',
        },
        backgroundColor: '#b6f3b5 !important',
        border: '1px solid #007f00 !important',
    },
}));

const OTHER_DISEASE = "0021";
const NO_DISEASE = "0000";

const Diseases = ({ household, diseases, diseasesListApi, otherDiseaseName, emerDoctor, diseasesValidator, setDiseasesValidator, cardCode, setNeedEmerContact, clearSignature }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [diseasesList, setDiseasesList] = useState([]);
    const [userDiseaseInput, setUserDiseaseInput] = useState('');
    const [haveDisease, setHaveDisease] = useState(false);
    const [printArray, setPrintArray] = useState([]);

    const diseasesListApiFiltered = diseasesListApi ? diseasesListApi.filter(el => el.DiseaseCode !== NO_DISEASE) : [];

    //initialization of child diseases from Sap
    useEffect(() => {
        const childDiseases = []
        if (diseases.length > 0 && !!diseasesListApi) {
            diseases.forEach((disease) => {
                const diseaseFound = diseasesListApi.find((diseaseName) => disease.DiseaseCode === diseaseName.DiseaseCode && disease.DiseaseCode !== NO_DISEASE);
                if (diseaseFound) {
                    childDiseases.push({ Medicare: disease.Medicare, Treatment: disease.Treatment, DiseaseCode: disease.DiseaseCode });
                }
            })
            if (childDiseases.length) {
                setHaveDisease(true);
                if (childDiseases.some(dis => dis.Medicare === "Y")) {
                    setNeedEmerContact(true);
                }
            }
            setDiseasesList(childDiseases);
        }
        if (otherDiseaseName !== null) {
            setUserDiseaseInput(otherDiseaseName);
            setDiseasesValidator(true);
        }
    }, [diseasesListApi])

    //select chosen option and DR name validation
    useEffect(() => {
        clearSignature();
        const validList = diseasesList.every((disease) => {
            const diseaseExsist = !!disease.DiseaseCode;
            const validOtherDisease = (disease.DiseaseCode !== OTHER_DISEASE || !!userDiseaseInput);
            const validDanger = (disease.Medicare === "N" || !!disease.Treatment);

            return validDanger && validOtherDisease && diseaseExsist;
        });
        const validator = !haveDisease || validList;
        setDiseasesValidator(validator);
        if (diseasesList.some(dis => dis.Medicare === "Y")) {
            setNeedEmerContact(true);
        } else {
            setNeedEmerContact(false);
        }
    }, [userDiseaseInput, diseasesList, haveDisease]);

    useEffect(() => {
        if (!!diseasesListApiFiltered.length)
            insertToHousehold();
    }, [userDiseaseInput, diseasesList, haveDisease]);

    //code list creation to update child diseases
    const insertToHousehold = () => {
        const child = household.ChildrenList?.results.find((child) => child.CardCode === cardCode);
        const arrayToPrint = [];
        if (diseasesList.length > 0) {
            child.DiseasesList.results = diseasesList;
            for (let i = 0; i <= diseasesListApiFiltered.length; i++) {
                for (let j = 0; j <= diseasesList.length; j++) {
                    if (diseasesListApiFiltered[i]?.DiseaseCode === diseasesList[j]?.DiseaseCode) {
                        if (diseasesList[j]?.Medicare === "Y") {
                            arrayToPrint.push(`${diseasesListApiFiltered[i]?.DiseaseName} - ${t("lifeDanger")}, ${t("medTreatment")} :${diseasesList[j]?.Treatment}`);
                        }
                        else {
                            arrayToPrint.push(diseasesListApiFiltered[i]?.DiseaseName)
                        }
                    }
                }
            }
            if (userDiseaseInput.length > 0) {
                child.OtherDiseaseName = userDiseaseInput;
                arrayToPrint.push(userDiseaseInput);
            }
        }
        // no disease
        else {
            child.DiseasesList.results = [{ Medicare: "N", Treatment: "", DiseaseCode: "0000" }];
            arrayToPrint.push(t("noDiseasesCompareString"));
        }
        setPrintArray(arrayToPrint);
    }

    const disabledOptions = (option) => {
        const isChosen = diseasesList.find(({ DiseaseCode }) => DiseaseCode === option.DiseaseCode);
        return !!isChosen;
    }
    const setAnotherDetails = (e, type) => {
        const regex = /^[a-z\s\u0590-\u05fe]+$/i
        const value = e.target.value;
        if (regex.test(value) || value === '') {
            if (type === "otherDiseses")
                setUserDiseaseInput(value);
        }
    }

    const addDisease = () => {
        setDiseasesList(curr => [...curr, { Medicare: "N", Treatment: "", DiseaseCode: "" }]);
    }
    const changeDisease = (value, index, reason) => {
        const updateList = [...diseasesList];
        const clearClick = reason === "clear";

        updateList[index].DiseaseCode = clearClick ? '' : value.DiseaseCode;
        setDiseasesList(updateList);

    }
    const changeDangerous = (index, value) => {
        const updateList = structuredClone(diseasesList);
        updateList[index].Medicare = value;
        if (value === "N") {
            updateList[index].Treatment = '';
        }
        setDiseasesList(updateList);
    }
    const changeTreatment = (e, index) => {
        const value = e.target.value;
        const updateList = [...diseasesList];

        updateList[index].Treatment = value;
        setDiseasesList(updateList);

    }
    const findDiseasName = (diseaseCode) => {
        const result = diseasesListApiFiltered.find(el => el.DiseaseCode === diseaseCode);
        return result ? result.DiseaseName : "";
    }
    const removeDisease = (index) => {
        if (diseasesList.length === 1) {
            setHaveDisease(false);
        }
        setDiseasesList(prev => prev.filter((el, i) => i !== index)); // filter by index
    }
    const handleHaveDisease = (boolean) => {
        setDiseasesList([]);
        setHaveDisease(boolean);

        if (boolean) {
            addDisease();
        }
    }

    return (

        !!diseasesListApiFiltered ?
            <>
                <Card className={classes.statmentcard} expanded={(diseasesList.length > 0).toString()} data-html2canvas-ignore="true">
                    <Container>
                        <HBox className={classes.statmentCardContent} width="100%">
                            <VBox width="100%">
                                <HBox className={classes.cardTitleAndIcon}>
                                    <MedicalServicesOutlinedIcon sx={{ color: "#3a7735" }} />
                                    <Typography className={classes.cardTitle}>{t('diseases')} </Typography>
                                </HBox>
                                <Typography variant='smallText'>{t("haveDiseases")} </Typography>
                                <HBox mb={"1rem"} alignItems="center">
                                    <Button sx={{ minWidth: '3rem', mr: '0.5rem' }} className={`${!haveDisease && classes.buttons1} ${classes.buttons}`} onClick={e => handleHaveDisease(false)}>{t('no')}</Button>
                                    <Button sx={{ minWidth: '3rem' }} className={`${haveDisease && classes.buttons1} ${classes.buttons}`} onClick={e => handleHaveDisease(true)}>{t('yes')}</Button>
                                </HBox>
                                {haveDisease &&
                                    diseasesList.map((disease, index) =>
                                        <VBox mb="2rem" key={disease.DiseaseCode + 'index'} flexWrap="wrap">
                                            <Box display='flex' flexDirection={{ xs: "column", lg: "row" }} flexWrap="wrap">
                                                {/* disease options */}
                                                <Box flex={1}>
                                                    <Typography variant='smallText' >{t("theDisease")} </Typography>
                                                    <Autocomplete
                                                        getOptionDisabled={disabledOptions}
                                                        options={diseasesListApiFiltered.sort((a, b) => {
                                                            if (a.DiseaseName === t("otherCompareString")) {
                                                                return 1;
                                                            }
                                                            if (b.DiseaseName === t("otherCompareString")) {
                                                                return -1;
                                                            }
                                                            if (a.DiseaseName === t("noDiseasesCompareString")) {
                                                                return -1;
                                                            }
                                                            if (b.DiseaseName === t("noDiseasesCompareString")) {
                                                                return 1;
                                                            }
                                                            return a.DiseaseName.toString().localeCompare(b.DiseaseName.toString());
                                                        }
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" {...props} key={option.key}>
                                                                {option.DiseaseName}
                                                            </Box>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => ((option.DiseaseCode === value))}

                                                        defaultValue={disease.DiseaseCode}
                                                        className={classes.statmentAutoComplite}
                                                        noOptionsText={t('notOption')}
                                                        getOptionLabel={findDiseasName}
                                                        onChange={(event, value, reason) => changeDisease(value, index, reason)}
                                                        sx={{ mr: '0.9rem' }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant='standard' />
                                                        )}
                                                    />
                                                </Box >
                                                {disease.DiseaseCode === OTHER_DISEASE &&
                                                    <Box flex={1}>
                                                        <Typography variant='smallText' >{t("insertDiseas")} </Typography>
                                                        <Input
                                                            className={classes.select}
                                                            value={userDiseaseInput}
                                                            inputProps={{ maxLength: 50 }}
                                                            onChange={e => setAnotherDetails(e, "otherDiseses")} />
                                                    </Box>
                                                }
                                                {/* is dangerous ? */}
                                                <VBox flex={1} alignItems='flex-start'>
                                                    <Typography variant='smallText' >{t("isDangerous")} </Typography>
                                                    <HBox marginTop={"1rem"} alignItems="center">
                                                        <Button sx={{ minWidth: '3rem', mr: '0.5rem' }} className={`${disease.Medicare === "N" && classes.buttons1} ${classes.buttons}`} onClick={e => changeDangerous(index, "N")}>{t('no')}</Button>
                                                        <Button sx={{ minWidth: '3rem' }} className={`${disease.Medicare === "Y" && classes.buttons1} ${classes.buttons}`} onClick={e => changeDangerous(index, "Y")}>{t('yes')}</Button>
                                                    </HBox>

                                                </VBox>
                                                {/* treatment */}
                                                {disease.Medicare === "Y" &&
                                                    <VBox flex={1} justifyContent='space-between'>
                                                        <Typography variant='smallText' >{t("whatIsTheTreatment")} </Typography>
                                                        <HBox alignItems="center">
                                                            <Input
                                                                defaultValue={disease.Treatment}
                                                                inputProps={{ maxLength: 30 }}
                                                                onBlur={e => { changeTreatment(e, index) }}
                                                            />
                                                        </HBox>
                                                    </VBox>}
                                            </Box>
                                            <Box display='flex' justifyContent='flex-end' alignItems='center'>
                                                <IconButton onClick={e => removeDisease(index)}><DeleteIcon sx={{ color: "#99292e" }} /></IconButton>
                                            </Box>
                                            {/* error msg */}
                                            {!diseasesValidator &&
                                                <FormHelperText error={true} style={{ fontSize: "0.8rem" }} >
                                                    {t('diseasesError')}
                                                </FormHelperText>}
                                        </VBox>)
                                }
                                {haveDisease &&
                                    <HBox justifyContent='end' m=".5rem 0">
                                        <Button sx={{ display: diseasesListApi?.length === diseasesList.length && 'none' }} onClick={addDisease} size="small" endIcon={<AddCircleIcon />}>
                                            {t('anotherDisease')}
                                        </Button>
                                    </HBox>}
                            </VBox>
                        </HBox>
                    </Container>
                </Card>



                {/* for print only */}
                <Box justifyContent='center' className={`${"showOnPrint"}_${cardCode}`} sx={{ display: "none" }} >
                    <VBox className={classes.cardTitleAndIcon}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }}>{`${t('diseases')}`} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tc} >
                                        {printArray.join(", ")}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {diseasesList.find(({ DiseaseCode }) => DiseaseCode === OTHER_DISEASE) && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }}>{t('otherDisease')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tc}> {userDiseaseInput}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>}
                    </VBox>
                </Box>
            </>
            : <LinearProgress color={"primary"} />
    )
}
export default Diseases;
