import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SecondStep from './SecondStep';
import { system } from '../../../api/client';
import useGlobal from '../../../store/store';
import useApi from 'hooks/useApi';
import { format } from 'date-fns'
import { addPaymentHana, UpdateRegAPI, getGuid } from '../../../api/api'
import { PaymentMessage } from 'Components/Payments/PaymentMessage';
import { BalanceContext } from 'context/balanceContext';

import { styled } from '@mui/system';
import { Typography, IconButton, Box, Checkbox, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import MoneyIcon from '@mui/icons-material/Money';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const Header = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#f0f3f0 !important'
}));
export const StartButton = styled(Button)(({ theme, ...props }) => ({
    alignSelf: 'center',
    height: "2rem",
    width: "11rem",
    fontSize: "1.2rem",
    fontWeight: "bold",
    boxShadow: 'none !important',
    backgroundColor: props?.disabled ? '#dbdbdb !important' : '#ffbb41',
    borderRadius: "100px",
    margin: "2rem",
    color: props?.disabled ? '#a2a2a2 !important' : '#064601',
    ":hover": {
        backgroundColor: '#ffcc68'
    }
}));

const PaymentOptions = ({ products, setDisabledButton, onCloseCart, setLevel, setTribePayment, tribePayment, prevLevel, setIsPaid }) => {
    const { t } = useTranslation();
    const [selectedObj, setSelectedObj] = useState({ cash: false, check: false, creditCard: false });
    const [payScreen, setPayScreen] = useState(false);
    const [payByExtraMoneyMassage, setPayByExtraMoneyMassage] = useState(false);
    const { balance } = useContext(BalanceContext);

    const getSapGuid = useApi(getGuid);

    const today = format(new Date(), 'yyyy-MM-dd')

    const [tribePaid, setTribePaid] = useGlobal(
        state => state.tribePaid,
        actions => actions.setTribePaid);
    const [finalPrice, setFinalPrice] = useGlobal(
        (state) => state.finalPrice,
        (action) => action.setFinalPrice);
    const [payByExtra, [setPayByExtra]] = useGlobal(
        state => state.payByExtra,
        actions => [actions.setPayByExtra]);
    const [payWithAllExtra, [setPayWithAllExtra]] = useGlobal(
        state => state.payWithAllExtra,
        actions => [actions.setPayWithAllExtra]);


    const optionArray = [{ text: t("cash"), icon: <PaymentsOutlinedIcon sx={{ color: "#3a7735", height: "1.5em", width: "2em" }} />, optionCode: 1 },
    { text: t("check"), icon: <MoneyIcon sx={{ color: "#3a7735", height: "1.5em", width: "2em" }} />, optionCode: 2 },
    { text: t("creditCard"), icon: <CreditCardOutlinedIcon sx={{ color: "#3a7735", height: "1.5em", width: "2em" }} />, optionCode: 3 }]

    useEffect(() => {
        setDisabledButton(true);
        if (balance.amount < 0) {
            setPayByExtraMoneyMassage(true);
        }
    }, [])
    const approvePayment = async () => {
        let promises = [];
        setPayByExtra(true);
        if (finalPrice === 0) {
            const res = await getSapGuid.requestPromise(products, "tribe");
            const payMethod = {
                Guid: res,
                ParentCardCode: products[0].ParentCardCode,
                UnitCode: products[0].TribeCode,
                PaymentDate: today,
                PaymentExecutionType: "10"
            }
            if (system !== "prod") {
                payMethod.PaymentPercent = 100
                payMethod.InvoiceNum = "0"
            }
            const result = await addPaymentHana(payMethod);
            if (result.Status) {
                products.map((product) => {
                    promises.push(UpdateRegAPI({ _id: product._id, data: { Status_request: "4", paid: product.FinalPrice, Payment_Method: "10" } }))
                })
                if (promises.length) {
                    const res = await Promise.all(promises);
                    if (res) {
                        handlePayment();
                    }
                }
            }
        }
        else {
            setPayWithAllExtra(false);
            setPayByExtraMoneyMassage(false);
        }
    }
    const handlePayment = () => {
        setIsPaid(true);
        setTribePayment(false);
        setLevel(4);
    }
    return (
        <Box pb="2rem" display='flex' flexDirection='column' overflow="auto" height='100%'>
            {!!products.length &&
                <Header>
                    <Box display='flex' flexDirection='row' alignItems="center">
                        <IconButton size="medium" onClick={e => prevLevel()}>
                            <ArrowForwardIosOutlinedIcon sx={{ color: '#3a7735' }} />
                        </IconButton>
                        <Typography sx={{ fontSize: { xs: "0.875rem", lg: "1.2rem" }, fontWeight: "bold" }} color='#000000'>{`${t('cartTitle')} ${t("familys")} ${products[0].Parent1_LastName} (${products.length})`}</Typography>
                    </Box>
                    <IconButton onClick={e => onCloseCart(e)} sx={{ marginRight: { xs: "1rem" } }}>
                        <CloseIcon sx={{ color: "#3a7735" }} />
                    </IconButton>
                </Header>}
            {payByExtraMoneyMassage ?
                <PaymentMessage
                    title={t("positiveBalanceExists")}
                    setLevel={setLevel}
                    approvePayment={approvePayment}
                    tribe={true} /> :
                <>
                    {!payScreen ? <><Box mt="8rem" display='flex' flexDirection='column' justifyContent="center" alignItems="center">
                        <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "1.375rem" }}>{t("beRight")}</Typography>
                        <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}>{t("choosePayOption")}</Typography>
                    </Box>
                        <Box mt="4rem" display='flex' flexDirection='row' justifyContent="center" alignItems="center" flexWrap="wrap" gap="2rem">
                            {optionArray.map(op => {
                                return (
                                    <OptionButton text={op.text} icon={op.icon} optionCode={op.optionCode} key={op.optionCode} selectedObj={selectedObj} setSelectedObj={setSelectedObj} />
                                )
                            })}
                        </Box>
                        <StartButton disabled={Object.values(selectedObj).every(el => !el)} onClick={e => setPayScreen(true)}>
                            {t("start")}
                        </StartButton>
                    </> :
                        <SecondStep
                            chosenMethod={selectedObj}
                            setTribePayment={setTribePayment}
                            tribePayment={tribePayment}
                            setDisabledButton={setDisabledButton}
                            products={products}
                            handlePayment={handlePayment} />
                    }
                </>}
        </Box>
    )
}
const OptionButton = ({ text, icon, optionCode, selectedObj, setSelectedObj }) => {
    const [add, setAdd] = useState(false);

    const handleAddOption = (e) => {
        const checked = e.target.checked;
        setAdd(checked);
        if (checked) {
            switch (optionCode) {
                case 1:
                    setSelectedObj({ ...selectedObj, cash: true })
                    break;
                case 2:
                    setSelectedObj({ ...selectedObj, check: true })
                    break;
                case 3:
                    setSelectedObj({ ...selectedObj, creditCard: true })
                    break;
                default:
                    break;
            }
        }
        else {
            switch (optionCode) {
                case 1:
                    setSelectedObj({ ...selectedObj, cash: false })
                    break;
                case 2:
                    setSelectedObj({ ...selectedObj, check: false })
                    break;
                case 3:
                    setSelectedObj({ ...selectedObj, creditCard: false })
                    break;
                default:
                    break;
            }
        }
    }
    return (
        <Box display='flex' flexDirection='column' justifyContent="center" alignItems="center" sx={{ width: "8rem", borderRadius: "10px", border: "1px solid #3a7735", backgroundColor: "#ffffff" }}>
            <Checkbox checked={add} onChange={e => handleAddOption(e)}
                sx={{
                    position: "relative",
                    right: "3rem",
                    bottom: "1rem"
                }}
                icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735', background: "#ffffff" }} fontSize="small" />}
                checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735', background: "#ffffff" }} fontSize="small" />}
            />
            {icon}
            <Typography sx={{ textAlign: "center", color: "#3a7735", fontSize: "1.1rem" }}>{text}</Typography>
        </Box>
    )
}
export default PaymentOptions;