import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import { useNavigate } from "raviger";
import { useSnackbar } from 'notistack';
import useGlobal from 'store/store';
//libs
import { format, isAfter, isBefore } from 'date-fns'
//mui
import { styled, Box, Link, Typography, Table, TableBody, TableCell, TableRow, Dialog } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
//components
import { MessageBox } from 'Components/Common/MessageBox';
import { BottomCancelReg, ContactCancelReg, TopCancelReg, ContactCantCancelReg, BottomCantCancelReg } from 'Components/Common/CancelRegPopups';
import StatusChip from 'Components/Common/StatusChip';
//api
import { getRegAPI } from 'api/api';
//assets
import NoHistory from '../../assets/NoHistory.svg'

const MuiTable = styled(Table)(({ theme }) => ({
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%', maxWidth: 500,
    "&::-webkit-scrollbar": {
        width: 8,
    },
    "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 60px ${theme.palette.disable.lighter}`,
        borderRadius: "10rem",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        outline: `none`,
        borderRadius: "10rem",
    }
}));

const unpaidStatus = "2";
const partPaidStatus = "3";
const fullyPaidStatus = "4";
const cancelStatus = "5"
const onlyPaid = "7"
const ParentHistory = ({ childId, removeHouseholdSelection, isUpdated, setIsUpdated, allChildren = false }) => {
    const [registeredActivities, setRegistered] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [messageBoxState, setMessageBox] = useState({ open: false });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [parentRegs, setParentRegs] = useGlobal((state) => state.parentRegs, (actions) => actions.setParentRegs);

    useEffect(() => {
        const registeredFilter = allChildren ? parentRegs.filter(regReq => (paidRegNotEnded(regReq) || unPaidRegNotStarted(regReq))) : parentRegs.filter(regReq => childId === regReq.StudentCardCode && (paidRegNotEnded(regReq) || unPaidRegNotStarted(regReq)));
        setRegistered(registeredFilter);
    }, [parentRegs, childId]);

    const registeredKPI = registeredActivities.filter(regReq => regReq.Status_request === fullyPaidStatus || regReq.Status_request === onlyPaid && paidRegNotEnded(regReq))
    const requestsKPI = registeredActivities.filter(regReq => [partPaidStatus, unpaidStatus].includes(regReq.Status_request) && unPaidRegNotStarted(regReq))

    const getRegistrations = async () => {
        try {
            const { data } = await getRegAPI({});
            setParentRegs(data);
        } catch (error) {
            setParentRegs([]);
            enqueueSnackbar(error?.message || t("Error"), { style: { backgroundColor: "#ff6645 !important" } });
        }
    }

    const onCancelRegistration = (row) => {
        if (row.Status_request === unpaidStatus) { //unpaid
            try {
                removeHouseholdSelection(row);
                getRegistrations();
                unpaidCancelMessage();
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { style: { backgroundColor: "#ff6645 !important" } });
            }
        } else {
            try {
                paidCancelMessage();
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { style: { backgroundColor: "#ff6645 !important" } });
            }
        }

    }

    const unpaidCancelMessage = () => {
        const closeMsgBox = () => {
            setMessageBox({ open: false });
            setIsUpdated(isUpdated + 1);

        };

        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <TopCancelReg closeMsgBox={closeMsgBox} />,
            content:
                <ContactCancelReg />,
            actions:
                <BottomCancelReg closeMsgBox={closeMsgBox} />
        });
    };

    const paidCancelMessage = () => {
        const closeMsgBox = () => {
            setMessageBox({ open: false });
        };

        const navtoContactForm = () => {
            closeMsgBox();
            navigate("/contact")
        }

        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <TopCancelReg closeMsgBox={closeMsgBox} />,
            content:
                <ContactCantCancelReg />,
            actions:
                <BottomCantCancelReg closeMsgBox={closeMsgBox} navtoContactForm={navtoContactForm} />
        });
    };

    return (
        <>
            <Box mt="1rem" sx={{ marginLeft: { xs: '.5rem', lg: '0' } }} >
                <Box display="flex" alignItems="center" >
                    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" justifyContent="center">
                        <EventAvailableIcon sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }} />
                        <Typography m="0 .5rem" variant="mediumDarkGreyBoldRes">{t("historyTitle")} </Typography>
                        <Link component="button" onClick={(e) => setOpenDrawer(true)}>
                            <Typography color="primary">{t('description')}</Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box pr={1} pl={1} display="flex" justifyContent='center' width='100%' >
                <Box display="flex" justifyContent='space-between' sx={{ width: { xs: '20rem', sm: '25rem', md: '30rem', lg: '100%' } }} >
                    <CircleNumber BGcolor={"#cee2cd"} text={registeredKPI.length ? t("registerActiv") : t("noRegisterActiv")} number={!registeredKPI.length ? "-" : registeredKPI.length} icon={<EventAvailableIcon sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '4rem' }, marginTop: '-3rem', alignSelf: "flex-end", color: "#cee2cd", transform: "rotate(-30deg)", display: { xs: "none", sm: "block" } }} />} />
                    <CircleNumber BGcolor={"#FBE8C2"} text={requestsKPI.length ? t("regReqActiv") : t("noRegReqActiv")} number={!requestsKPI.length ? "-" : requestsKPI.length} icon={<PendingActionsIcon sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '4rem' }, marginTop: '-3rem', alignSelf: "flex-end", color: "#FBE8C2", transform: "rotate(-30deg)", display: { xs: "none", sm: "block" } }} />} />
                </Box>
            </Box>
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
            <DescriptionDrawer childId={childId} opens={openDrawer} setOpen={setOpenDrawer} regs={registeredActivities} onCancelRegistration={onCancelRegistration} />
        </>
    )
}
const CircleNumber = ({ BGcolor, text, number, icon }) => {
    return (
        <Box display='flex' flexDirection='column' sx={{ backgroundColor: "#f5f7f5fc", width: { xs: '9rem', sm: '7rem', md: '14rem', lg: '34rem' }, height: "10rem", borderRadius: "25px" }} justifyContent="center" alignItems="center" >
            <Typography sx={{
                backgroundColor: BGcolor,
                color: '#333333',
                fontWeight: 'bold',
                fontSize: '28px',
                borderRadius: "100%",
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>{number}
            </Typography>
            <Typography color='primary' variant="responsiveTextSize" sx={{ zIndex: 1 }}>{text} </Typography>
            {icon}
        </Box>

    )
}
const DescriptionDrawer = ({ childId, opens, setOpen, regs, onCancelRegistration }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const PaperStyle = { style: { height: '45%', borderRadius: "50px", backgroundColor: "#f0f3f0" } }
    const BackdropStyle = { style: { opacity: '0.4' } }
    return (

        <Dialog
            open={opens}
            onClose={() => setOpen(false)}
            PaperProps={PaperStyle}
            BackdropProps={BackdropStyle}
        >
            <Box pt="2rem" pb="3rem" height='100%' width='100%' alignItems='center' display='flex' flexDirection='column'>
                {regs.length ? <MuiTable >
                    <TableBody>
                        {regs.map(regReq => <DrawerTableItem regReq={regReq} onCancelRegistration={onCancelRegistration} key={regReq._id} />)}
                    </TableBody>
                </MuiTable> :
                    <>
                        <Typography fontWeight='bold' color="inherit" sx={{ textAlign: "center", padding: "1rem" }}>{t('noFutureRegs')}</Typography>
                        <img
                            alt={"..."}
                            src={NoHistory}
                            width="150px"
                            height="150px"
                        />
                    </>
                }
                <Box display="flex" width="100%" justifyContent="center">
                    <Link component="button" onClick={(e) => navigate('/reghist', { state: childId })}>
                        <Typography fontWeight='bold' color="primary">{t('toHistory')}</Typography>
                    </Link>
                </Box>
            </Box>

        </Dialog>


    )
}
const DrawerTableItem = ({ regReq, onCancelRegistration }) => {
    const { t } = useTranslation();
    const formatDate = format(new Date(regReq.StartDateD), 'dd/MM/yyyy');
    return (<>
        <TableRow key={regReq._id}>
            <TableCell >
                <Box height='100%' display='flex' flexDirection="column">
                    <Typography variant="responsiveTextSize">{formatDate}</Typography>
                    <Box height='1.5rem' />
                </Box>
            </TableCell>
            <TableCell  >
                <Box display='flex' flexDirection="column">
                    <Typography variant="responsiveTextSize" fontWeight='bold'>{regReq.ActivName}</Typography>
                    <Typography variant="responsiveTextSize">{regReq.Pupil_FirstName}</Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box display='flex' flexDirection="column">
                    <StatusChip status={regReq.Status_request} size='small' />
                    <Link component='button' onClick={() => { onCancelRegistration(regReq) }} sx={{ width: "max-content" }} >
                        <Typography lineHeight='1.5rem' sx={{ color: '#000' }} variant="responsiveTextSize">{regReq.Status_request === fullyPaidStatus || regReq.Status_request === onlyPaid ? t("cancelReg") : t("cancelReq")}</Typography>
                    </Link>
                </Box>
            </TableCell>
        </TableRow>
    </>)
}
const paidRegNotEnded = (reg) => {
    return ((reg.Status_request === fullyPaidStatus || reg.Status_request === onlyPaid) && (isAfter(new Date(reg.EndDateD), new Date()) || reg.ItemPurposeType === "2" || reg.ItemPurposeType === "4"));
}

const unPaidRegNotStarted = (reg) => {
    return ([unpaidStatus, partPaidStatus, cancelStatus].includes(reg.Status_request) && (isBefore(new Date(), new Date(reg.StartDateD)) || reg.ItemPurposeType === "2" || reg.ItemPurposeType === "4"));
}

export default ParentHistory;