
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import Logo from '../../assets/Logo.png'
import { HBox } from '../../assets/SharedComponents';
import { httpGetAPI } from '../../api/api';
import PrintIcon from '@mui/icons-material/Print';

const useStyles = makeStyles(() => ({
    LogoSize: {
        width: '30px !important',
        height: '45px !important'
    },
    dialog: {
        borderRadius: '25px !important',
        "& .embeddedDoc div, & .embeddedDoc span, & .embeddedDoc p, & .embeddedDoc *": {
            margin: "0px !important;",
            padding: "0px !important;",
            textAlign: "-webkit-match-parent !important;",
            backgroundColor: "#f0f3f0"
        },
        "& .embeddedDoc img": {
            display: "none !important;"
        },
        "& .embeddedDoc span": {
            height: '0 !important;',
            width: '0 !important;'
        }
    },
    dialogTitle: {
        alignItems: 'center !important',
        display: "flex",
        flexDirection: "column"
    },
    dialogTitleText: {
        marginRight: '0.3rem !important',
        fontWeight: 'bold !important'
    },
    buttonFont1: {
        fontSize: '1rem !important',
        textTransform: "capitalize !important"
    },
    buttonGreen: {
        color: 'white !important',
        fontWeight: 'bold !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important'
    },
    buttonRed: {
        color: 'red !important',
        fontWeight: 'bold !important',
        backgroundColor: 'white !important',
        border: '1px solid red !important',
        borderRadius: '2rem !important'
    },

    printIcon: {
        justifyContent: 'left !important',
        margin: '0.2rem !important',
        cursor: 'pointer !important'
    },
    print: {
        '@media print': {
            '.page-break': {
                marginTop: "1rem !important",
                display: "block !important",
                pageBreakBefore: "auto"
            }
        }
    }

}));
const TermsDialog = ({ openDialog, setOpenDialog, title, src, termsConfirms, showButtons = false, print = false }) => {
    const classes = useStyles()
    const componentRef = useRef();
    const { t } = useTranslation();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const getTermsDiv = async (ref) => {

        if (ref && ref.childElementCount === 0) {

            let d = document.createElement('div');
            d.style.backgroundColor = "#f0f3f0"
            d.classList.add("embeddedDoc"); // optional
            try {
                const res = await httpGetAPI(src);
                d.innerHTML = res.data;
            } catch (error) {
                d.innerHTML = error;
            }

            ref.appendChild(d);

        }

    }

    return (
        <Dialog
            className={classes.dialog}
            open={openDialog}
            onClose={e => { setOpenDialog(false) }}
            scroll={'paper'}
            PaperProps={{
                sx: { borderRadius: "40px !important", backgroundColor: "#f0f3f0" }
            }}
        >
            <DialogTitle>
                <Box className={classes.dialogTitle}>
                    <img
                        className={classes.LogoSize}
                        src={Logo}
                        alt='..'
                        loading="lazy"
                    />
                    <Typography className={classes.dialogTitleText}>{title}</Typography>
                </Box>

            </DialogTitle>
            <DialogContent>
                {print && <Box display="flex" flexDirection="row" alignItems="center" className={classes.printIcon}>
                    <PrintIcon sx={{ color: '#3a7735' }} onClick={handlePrint} /></Box>}
                <DialogContentText className={`${classes.print}`} ref={componentRef} component="span">
                    <div ref={(r) => { getTermsDiv(r) }} dir="rtl" />
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center !important' }}>
                {showButtons &&
                    <Box display='flex' flexDirection='row' >
                        <Button className={`${classes.buttonGreen} ${classes.buttonFont1}`} sx={{ margin: "0.5rem" }} onClick={e => termsConfirms(true)}  >{t('agree')}</Button>
                        <Button className={`${classes.buttonRed} ${classes.buttonFont1}`} sx={{ margin: "0.5rem" }} onClick={e => termsConfirms(false)}> {t('disagree')}</Button>
                    </Box>}
            </DialogActions>
        </Dialog>)
}

export default TermsDialog;