import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Parents } from '../ParentComponents/MyFamilyComponents/Parents';
import { ApprenticesAndTribes } from '../ParentComponents/MyFamilyComponents/ApprenticesAndTribes';
import { useNavigate, usePathParams } from 'raviger';
import { getParentByCardCode } from 'api/api';
import useApi from 'hooks/useApi';
import useGlobal from 'store/store';
import { CustomBusy } from 'Components/Common/CustomBusy';

export const TribeMyFamily = ({ fam, setParentTab, familyUpdated }) => {
    const { t } = useTranslation();
    const [currentFamily, setCurrentFamily] = useState(null);
    const [familyTribes, setFamilyTribes] = useState([]);
    const { familyCardCode } = usePathParams('/tribeMyFamily/:familyCardCode');
    const getFamily = useApi(getParentByCardCode);
    const navigate = useNavigate();
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        handleFamilyUpdate();
    }, [familyUpdated]);

    useEffect(() => {
        handleFamily();
    }, []);

    useEffect(() => {
        handleTribes();
    }, [currentFamily]);

    const handleFamily = async () => {
        setReady(false);
        if (fam === null) {
            try {
                const res = await getFamily.requestPromise(familyCardCode);
                if (res.d?.results) {
                    setCurrentFamily(res.d.results[0]);
                    setReady(true);
                } else {
                    setFamily({});
                    navigate('/homepage');
                }
            } catch (error) {
                console.error('Failed to fetch family:', error);
                setFamily({});
                navigate('/homepage');
            }
        } else {
            setCurrentFamily(fam);
            setReady(true);
        }
    };

    const handleTribes = () => {
        const tmpTribes = {};
        currentFamily?.ChildrenList?.results.forEach((child) => {
            tmpTribes[child.BPLName.slice(5)] = true;
        });
        setFamilyTribes(Object.keys(tmpTribes));
        setFamily(currentFamily);
    };

    const handleFamilyUpdate = async () => {
        if (familyUpdated) {
            try {
                const res = await getFamily.requestPromise(familyCardCode);
                if (res.d?.results) {
                    setCurrentFamily(res.d.results[0]);
                    setReady(true);
                } else {
                    setFamily({});
                    navigate('/homepage');
                }
            } catch (error) {
                console.error('Failed to fetch family:', error);
                setFamily({});
                navigate('/homepage');
            }
        }
    };

    return currentFamily && ready ? (
        <Box height="100%">
            <Parents family={currentFamily} isFromTribe={true} setParentTab={setParentTab} />
            <ApprenticesAndTribes household={currentFamily} familyTribes={familyTribes} isFromTribe={true} />
        </Box>
    ) : <CustomBusy text={t("loading")} />;
};