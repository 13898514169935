import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import SearchFamily from 'Components/TribeComponents/SearchFamily';
import { t } from 'i18next';

const ActivityRegisterSearch = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
    '& [class*="addFamilyButton"],[class*="addChildButton"]': {
        display: 'none'
    },
    '& [class*="searchLine"]': {
        '& .MuiInputBase-root': {
            maxWidth: '100%',
            width: '35rem'
        },
        justifyContent: "start",
        margin: '0 !important'
    },
    '& [class*="noResults"]': {
        alignItems: 'start !important',
        margin: '0 !important',
        paddingLeft: '1rem'

    }
}));

export const ActivityRegister = ({ tribe }) => {
    return (
        <ActivityRegisterSearch height="100%">
            <Typography sx={{ fontWeight: 'bold', fontSize: "1.5rem", paddingLeft: "1.1rem" }}><FollowTheSignsIcon sx={{ marginRight: "1rem" }} />{t("activityRegister")}</Typography>
            <Typography sx={{ fontSize: "1.1rem", paddingLeft: "1rem", fontWeight: 'bold', color: "#3a7735" }}>{t("actRegText")}</Typography>
            <Typography sx={{ fontSize: "1rem", paddingLeft: "1rem" }}>{t("actRegText1")}</Typography>
            <Typography sx={{ fontSize: "1rem", paddingLeft: "1rem" }}>{t("actRegText2")}</Typography>
            <Typography sx={{ fontSize: "1rem", paddingLeft: "1rem" }}>{t("actRegText3")}</Typography>
            <SearchFamily tribe={tribe} addNewPupil={() => { }}
                setDisabledLevel1={true}
                setDisabledLevel2={true}
                setDisabledLevel3={true}
                setFamily={() => { }}
                tribeUser={true}
            />

        </ActivityRegisterSearch >
    );
}