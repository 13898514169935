import { fontSize, styled } from '@mui/system';
import { Button, Badge, Box, TextField, Accordion, Chip, MenuItem, Menu, Step } from "@mui/material";

export const StyledTextF = styled(TextField)(({ theme }) => ({
    background: 'white',
    width: '100%',
    minHeight: '50px',
    '& .MuiInput-underline:after': {
        borderBottom: '2px solid green !important'
    },
    '& .MuiInputBase-formControl': {
        height: '50px',
        width: '100%',
    },
    '& .MuiInput-input': {
        color: '#808080',
        paddingLeft: '1rem',
        fontSize: '1.2rem'
    }
}));
export const StyledHBox = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    backgroundColor: '#3a7735',
    margin: 'auto',
    marginTop: '.5rem !important',
    marginBottom: '.5rem !important',
    borderRadius: '50px',
    width: '13rem',
    flexDirection: 'row',
    display: 'flex'

}));
export const AddFamilyButton = styled(Button)(({ theme }) => ({
    color: '#064601 !important',
    fontSize: '1.1rem !important',
    fontWeight: 'bold !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#ffbb41 !important',
    margin: 'auto !important',
    position: 'sticky !important',
    bottom: '0 !important',
    marginTop: '1rem !important',
    borderRadius: '50px !important',
    width: '14.75rem !important',
    textTransform: "capitalize !important"
}));
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    border: 'none !important',
    overFlow: 'auto',
    boxShadow: 'none !important',
    maxWidth: '100rem',
    width: '90%',
    margin: '0 !important',
    '& .MuiIconButton-label': {
        color: 'black',
    }
}));
export const MyBadge = styled(Badge)(({ theme }) => ({
    color: '#3a7735 !important',
    borderRadius: '5rem !important',
    height: '3.5rem !important',
    width: '3.5rem !important',
    background: '#fff496 !important',
    "&:hover": {
        cursor: 'pointer',
        background: '#fff !important',
    },
    [theme.breakpoints.down('md')]: {
        marginTop: '-3rem !important',
        border: '.3rem solid #3a7735 !important',
    },
    [theme.breakpoints.up('md')]: {
        boxShadow: '0px -2px 5px 0px rgba(0, 0, 0, 0.35)'
    },
    "& .MuiSvgIcon-root": {
        margin: 'auto'
    },
    '& .MuiBadge-badge': {
        top: 13,
        padding: '0 1px !important',
        color: '#3a7735 !important',
        fontWeight: '600 !important',
        [theme.breakpoints.up('md')]: {
            border: `2px solid ${theme.palette.background.paper}`,
        },
    },
}));
export const FamilyAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: 'none !important',
    "& .MuiAccordionSummary-root": {
        width: '100%',
        background: '#e4f0da',
        minHeight: '40px',
        height: '40px',
        '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '40px',
        }

    }
}));
export const InfoLabel = styled(Chip)(({ theme }) => ({
    "& .MuiChip-label": {
        display: 'flex',
        justifyContent: 'center',
    },
    marginLeft: '0.5rem !important',
    padding: '0 0.3rem !important',
    height: '1rem !important',
    width: '1rem !important',
    color: `${theme.palette.green.darker} !important`,
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.green.darker} !important`
}));
export const TribeMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: `${theme.palette.green.main}`,
    color: `${theme.palette.neutral.main} !important`,
    border: 'none !important',
    justifyContent: 'center !important',
    "&:hover": {
        backgroundColor: `${theme.palette.green.hover} !important`,
    }
}));
export const TribeMenu = styled(Menu)(() => ({
    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
        top: '2rem !important'
    }
}));
export const AppBarMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: `#ffffff`,
    color: `${theme.palette.green.main} !important`,
    border: 'none !important',
    justifyContent: 'center !important',
    "&:hover": {
        backgroundColor: `${theme.palette.green.hover} !important`,
    }
}));
export const AppBarMenu = styled(Menu)(() => ({
    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {

    }
}));
export const RoundedButton = styled(Button)(({ theme }) => ({
    fontSize: '1rem',
    borderRadius: '50px',
    textTransform: "inherit",
    margin: "0.5rem"
}));

export const CartStepStyle = styled(Step)(({ theme, ...props }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root': {
        color: props.active || props.completed ? '#3a7735 !important' : '#797979 !important'
    },
    "& .MuiStepLabel-iconContainer": {
        display: "none"
    },
    '& .MuiStepLabel-label.Mui-active': {
        color: '#3a7735 !important',

        fontWeight: 'bold !important'
    },
    '& .MuiStepLabel-label.Mui-completed': {
        color: '#3a7735 !important',

        fontWeight: 'bold !important'
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: '0.5rem !important',
    },
    '& .MuiStepLabel-label': {
        color: '#797979 !important',

    },
    '& .MuiStepLabel-root': {
        padding: "2px 0 !important"
    }

}));