import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useApi from '../../../../hooks/useApi';
import useGlobal from '../../../../store/store';
//mui
import { Checkbox, Button, Typography, Tabs, Tab, FormHelperText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
//libs
import { format } from 'date-fns'
import { jsPDF } from 'jspdf';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas'
//compopnents
import { VBox, HBox } from '../../../../assets/SharedComponents';
import TermsDialog from 'Components/Common/TermsDialog';
import ParentCartHeader from '../ParentCartHeader';
import ChildStatment from './ChildStatement';
//api
import { associationInfo, updatePupilStatment, addParentStatmentAPI, uploadFile } from '../../../../api/api';
//assets
import penSvg from '../../../../assets/penSvg.svg'

const useStyles = makeStyles(() => ({
    cartTop: {
        background: '#f0f3f0 !important'
    },
    primaryBackground: {
        background: "#f0f3f0  !important",
        paddingRight: "1rem !important",
        paddingLeft: "1rem !important"
    },
    childrenTabs: {
        marginTop: "1rem",
        height: "4rem !important",
        textTransform: "capitalize !important",
        '& .MuiTabs-indicator': {
            display: "none !important"
        },
    },
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            color: "#3a7735",
            background: "#cee2cd",
            fontSize: '1rem !important',
            textTransform: "capitalize !important",
            borderRadius: "1.9rem !important",
            "&.Mui-selected": {
                color: "#ffffff",
                background: "#3a7735",
                borderRadius: "1.9rem !important",
                textTransform: "capitalize !important"
            }
        },
        "&:hover": {
            background: '#84a481 !important',
            borderRadius: "1.9rem !important"
        },

    },
    rtlBox: {
        direction: 'rtl !important'
    },
    allStatement: {
        backgroundColor: '#f6f9f6fc',
        height: "inherit !important"
    },
    title: {
        color: '#ffffff !important',
        textAlign: 'right !important',
        fontSize: '1.5rem !important',
    },
    aboveSign: {
        alignItems: 'center !important'
    },
    readAndAccept: {
        textAlign: "right",
        fontWeight: 'bold !important',
        color: '#3a7735 !important'
    },
    linkStyle: {
        paddingInlineStart: '0.3em !important',
        textDecoration: 'underline !important',
        fontWeight: 'bold !important',
        cursor: 'pointer !important',
        fontFamily: "Alef,sans-serif !important"
    },
    signatureText: {
        alignItems: 'center !important',
        justifyContent: 'center !important'
    },
    sign: {
        alignItems: 'center !important'
    },
    clearSignIcon: {
        alignItems: 'center !important',
        justifyContent: "end"
    },
    signatureContainer: {
        height: '227px !important',
        width: '310px !important',
        backgroundColor: '#ffffff !important',
        border: '2px solid black !important',
        margin: '0 auto !important',
    },
    cursorPen: {
        cursor: `url(${penSvg}), auto}`
    },
    notCursor: {
        cursor: "not-allowed"
    },
    signatureCanvas: {
        width: '100% !important',
        height: '100% !important'
    },
    LogoSize: {
        width: '30px !important',
        height: '45px !important'
    },
    dialog: {
        borderRadius: '25px !important',
        "& .embeddedDoc div, & .embeddedDoc span, & .embeddedDoc p, & .embeddedDoc *": {
            margin: "0px !important;",
            padding: "0px !important;",
            textAlign: "-webkit-match-parent !important;"
        },
        "& .embeddedDoc img": {
            display: "none !important;"
        },
        "& .embeddedDoc span": {
            height: '0 !important;',
            width: '0 !important;'
        }
    },
    dialogTitle: {
        alignItems: 'center !important',
    },
    dialogTitleText: {
        marginRight: '0.3rem !important',
        fontWeight: 'bold !important'
    },
    buttonFont1: {
        fontSize: '1rem !important',
        textTransform: "capitalize !important"
    },
    buttonGreen: {
        color: 'white !important',
        fontWeight: 'bold !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important'
    },
    buttonRed: {
        color: 'red !important',
        fontWeight: 'bold !important',
        backgroundColor: 'white !important',
        border: '1px solid red !important',
        borderRadius: '2rem !important'
    },
    bottomPart: {
        marginBottom: '2.5rem !important'
    },
    printIcon: {
        justifyContent: 'left !important',
        margin: '0.2rem !important',
        cursor: 'pointer !important'
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    }
}));

const ParentStatment = ({ prevLevel, products, setDisabledButton, statemantUpdater, setStatemantUpdater, setLevel, childrenList }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [agree, setAgree] = useState(false);
    const [tribeAgree, setTribeAgree] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [isChildValid, setIsChildValid] = useState([]);
    const [isStatmentValid, setIsStatmentValid] = useState(false);
    const [associationInfoList, setAssociationInfoList] = useState();
    const [openForPdf, setOpenForPdf] = useState(false);
    const [household] = useGlobal((state) => state.household);
    const associationInfo1 = useApi(associationInfo);
    const results = getSelectedChildren(products, household);
    const inputRef = useRef(null);
    const detailsRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [user, setUser] = useGlobal((state) => state.user, (actions) => actions.setUser);


    let sigRef = {};
    const [beforeChildren, setBeforeChildren] = useState([]);

    const photoPermissionMap = childrenList.reduce((acc, child) => {
        acc[child.CardCode] = child.PerPhotAndPubl;
        return acc;
    }, {});

    //request info from sap
    useEffect(() => {
        let isMounted = true; // Use a flag to track if the component is still mounted
        setDisabledButton(true);
        const fetchData = async () => {
            try {
                const data = await associationInfo1.requestPromise();
                if (isMounted) {
                    setAssociationInfoList(data?.d?.results[0]);

                    const updatedBeforeArray = childrenList.map(child => ({
                        childName: child.CardName,
                        CardCode: child.CardCode,
                        OtherFoodName: child.OtherFoodName,
                        OtherAllergyName: child.OtherAllergyName,
                        OtherDiseaseName: child.OtherDiseaseName,
                        PermPartWater: child.PermPartWater,
                        EmerDoctor: child.EmerDoctor,
                        DiseasesList: child.DiseasesList.results,
                        AllergiesList: child.AllergiesList.results,
                        FoodPreferencesList: child.FoodPreferencesList.results,
                        ContactName: child.ContactName,
                        ContactPhone: child.ContactPhone,
                        PerPhotAndPubl: photoPermissionMap[child.CardCode]
                    }));
                    setBeforeChildren(updatedBeforeArray);
                }
            } catch (error) {
                console.error('Failed to fetch data', error);
            } finally {
                if (isMounted) {

                    // setDisabledButton(false);
                }
            }
        };
        fetchData();
        return () => {
            isMounted = false; // Cleanup function to set the flag to false
        };
    }, []);

    //chack if the statment is valid and enable signature
    useEffect(() => {
        let isUnmounted = true;
        if (user.role !== 'tribe') {
            if (agree && isStatmentValid) {
                isUnmounted && sigRef.on();
            }
            else {
                isUnmounted && sigRef.off();
                isUnmounted && clearSignature();
                isUnmounted && setDisabledButton(true);
            }
        }
        else {
            if (agree && isStatmentValid && tribeAgree) {
                isUnmounted && sigRef.on();
            }
            else {
                isUnmounted && sigRef.off();
                isUnmounted && clearSignature();
                isUnmounted && setDisabledButton(true);
            }
        }
        return () => { isUnmounted = false }
    }, [agree, tribeAgree, isStatmentValid]);

    useEffect(() => {
        let isUnmounted = true;
        if (statemantUpdater)
            isUnmounted && updateStatment();
        return () => { isUnmounted = false; }
    }, [statemantUpdater]);

    const clearSignature = useCallback(() => {
        if (sigRef !== null) {
            sigRef.clear();
            setDisabledButton(sigRef.isEmpty());
        }
    }, [agree, isStatmentValid, tribeAgree, sigRef]);

    //if user agreed to terms and conditions
    const termsConfirms = (bool) => {
        setAgree(bool);
        setOpenDialog(false);
    }
    const updateStatment = async () => {
        const results = getSelectedChildren(products, household);
        let successAll = true;
        for (let child of results) {
            const isUpdateNeeded = user.role === "tribe" || isChildNeedUpdate(child);
            const data = {
                "Source": "APH",
                "CardCode": child.CardCode,
                "TribeNum": child.DflWhs,
                "Pupil_LastName": child.Pupil_lastName,
                "Pupil_FirstName": child.Pupil_FirstName,
                "Pupil_ID": child.LicTradNum,
                "ID_Type": child.ID_TYPE,
                "Pupil_BirthdayDate": format(new Date((child.BirthdayDate)), 'yyyy-MM-dd'),
                "Pupil_Gender": child.Gender,
                "Pupil_Kupa": child.Kupa,
                "Pupil_Class": child.Class,
                "Pupil_FatherCard": household.CardCode,
                "Pupil_PlaceOfBirth": child.Pupil_BirthState,
                "Pupil_EmigrationDate": child.Pupil_EmigrationDate,
                "PermPartWater": child.PermPartWater || "N",
                "Zamid": child.Zamid,
                "School": child.School,
                "PerPhotAndPubl": child.PerPhotAndPubl,
                "Group": child.Group,
                "LiveWithParent2": child.LiveWithParent2,
                "LiveDifAdress": child.LiveDifAdress,
                "LiveOutsideIsrael": child.LiveOutsideIsrael,
                "EmerDoctor": child.EmerDoctor,
                "City": child.City,
                "Street": child.Street,
                "Country": child.Country,
                "StreetNo": child.StreetNo,
                "ZipCode": child.ZipCode,
                "OtherAllergyName": child.OtherAllergyName,
                "OtherDiseaseName": child.OtherDiseaseName,
                "OtherFoodName": child.OtherFoodName,
                "PermPart": child.PermPart,
                "Troops": child.Troops,
                "Tel1Type": "1",
                "Tel2Type": "1",
                "Phone1": child.Phone1,
                "Phone2": "",
                "PupilStatus": child.isActive,
                "Pronounce": child.genderAppeal,
                "Pupil_Diseases": child.DiseasesList.results,
                "Pupil_Allergies": child.AllergiesList.results,
                "Pupil_FoodPreferences": child.FoodPreferencesList.results,
                "ContactName": child.ContactName,
                "ContactPhone": child.ContactPhone
            }
            try {
                if (isUpdateNeeded) {
                    const res = await updatePupilStatment(data);
                    if (!res.Status) {
                        successAll = false;
                        setStatemantUpdater(false);
                        enqueueSnackbar(t("updateStudentError"), { className: classes.redBG });
                    }
                }
            } catch (error) {
                enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
                successAll = false;
                setStatemantUpdater(false);
                console.log(error);
            }
            await addStatmentToMongo(child);
        }
        if (successAll) {
            setLevel(prev => prev + 1);
        }
        else {
            setStatemantUpdater(false);
            enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
        }
        setStatemantUpdater(false);
    }
    const generatePDF = async (childCardCode) => {
        const vars = inputRef.current.querySelectorAll(`.${"showOnPrint"}_${childCardCode}`);
        const vars1 = inputRef.current.querySelectorAll('h4');
        const addEls = [
            ...vars,
            ...vars1
        ];
        for (let i = 0; i < addEls.length; i++) {
            addEls[i].style.display = 'block';
        }
        const clonedElement = inputRef.current.cloneNode(true);
        for (let i = 0; i < addEls.length; i++) {
            addEls[i].style.display = 'none';
        }
        document.body.appendChild(clonedElement);

        const scale = 2; // Increase the scale factor for higher resolution
        const canvas = await html2canvas(clonedElement, { scale });

        document.body.removeChild(clonedElement);

        const imgWidth = canvas.width / 14; // Adjust the width of the image
        const imgHeight = canvas.height / 9; // Adjust the height of the image
        const sigWidth = 40; // Adjust the width of the signature image
        const sigHeight = 40; // Adjust the height of the signature image

        const pdf = new jsPDF('l', 'mm', 'a4');

        const img = canvas.toDataURL('image/jpeg', 1);
        const sig = sigRef.toDataURL('image/png');

        const isMobile = window.innerWidth <= 767; // Adjust the screen width threshold for mobile devices

        pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);

        if (isMobile) {
            pdf.addImage(sig, 'PNG', 0, 130, sigWidth, sigHeight);
        } else {
            pdf.addImage(sig, 'PNG', 100, 105, sigWidth, sigHeight);
        }

        // Open the PDF in a new tab
        // window.open(pdf.output('bloburl'));
        // Alternatively, you can save the PDF directly
        // pdf.save("download1.pdf");

        const blob = pdf.output('blob');
        const file = new File([blob], `${childCardCode}_${household.CardCode}.pdf`, { type: "application/pdf" });
        try {
            const uploadResult = await uploadFile(file);
            return uploadResult._id;
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, { className: classes.redBG });
        }
    };
    const addStatmentToMongo = async (child) => {
        try {
            // Extracting codes using map
            const foodCodes = child.FoodPreferencesList.results.map(code => code.FoodPrefCode);
            const diseasesCodes = child.DiseasesList.results.map(code => code.DiseaseCode);
            const allergyCodes = child.AllergiesList.results.map(code => code.AllergyCode);

            // Generating PDF
            const fileId = await generatePDF(child.CardCode);

            // Filtering and mapping activities
            const activitesList = products
                .filter(activity => activity.LicTradNum === child.LicTradNum)
                .map(item => ({
                    activeCode: item.ActivCode,
                    activeName: item.ActivName,
                    pupilFirstName: child.Pupil_FirstName,
                    pupilLastName: child.Pupil_LastName,
                    pupilIdNum: child.LicTradNum,
                    pupilCardCode: child.CardCode,
                    pupilFoodPref: foodCodes,
                    pupilOtherFoodPref: child.OtherFoodName,
                    pupilAllergyList: allergyCodes,
                    pupilOtherAllergy: child.OtherAllergyName,
                    pupilDiseasesList: diseasesCodes,
                    pupilOtherDiseases: child.OtherDiseaseName,
                    emerDrName: child.EmerDoctor,
                    photoPermission: child.PerPhotAndPubl,
                    swimPermission: child.PermPartWater,
                    parentEmail: household.Parent1_Mail,
                    parentPhoneNumber: household.Parent1_Tel1,
                    parentFirstName: household.Parent1_FirstName,
                    parentLastName: household.Parent1_LastName,
                    parentCardCode: household.CardCode,
                    signatureFileId: fileId,
                    ContactName: child.ContactName,
                    ContactPhone: child.ContactPhone,
                    date: new Date().toISOString(), // Using ISO string format
                }));

            // Sending the statement to the API
            await addParentStatmentAPI(activitesList);
        } catch (error) {
            console.error(error); // Logging the error
            enqueueSnackbar(error.message, { className: classes.redBG }); // Showing error message
        }
    };
    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) {
            return true;
        }
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    }
    const isChildNeedUpdate = (child) => {
        const checkChild = beforeChildren.find(c => c.CardCode === child.CardCode);
        if (checkChild.OtherDiseaseName !== child.OtherDiseaseName) {
            return true;
        }
        if (checkChild.OtherAllergyName !== child.OtherAllergyName) {
            return true;
        }
        if (checkChild.EmerDoctor !== child.EmerDoctor) {
            return true;
        }
        if (checkChild.PermPartWater !== child.PermPartWater) {
            return true;
        }
        if (checkChild.ContactName !== child.ContactName) {
            return true;
        }
        if (checkChild.PerPhotAndPubl !== child.PerPhotAndPubl || !checkChild.PerPhotAndPubl) {
            return true;
        }
        if (checkChild.ContactPhone !== child.ContactPhone) {
            return true;
        }
        const beforeDiseasesList = checkChild.DiseasesList.map((dis) => {
            return dis;
        });
        const afterDiseasesList = child.DiseasesList.results.map((dis) => {
            return dis;
        });
        beforeDiseasesList.sort();
        afterDiseasesList.sort();

        if (beforeDiseasesList.length !== afterDiseasesList.length) {
            return true;
        }

        const diseaseHasChanged = beforeDiseasesList.some((bef, index) => !deepEqual(bef, afterDiseasesList[index]));
        if (diseaseHasChanged) {
            return true;
        }

        const beforeAllergyList = checkChild.AllergiesList.map((allergie) => {
            return allergie;
        });
        const afterAllergyList = child.AllergiesList.results.map((allergie) => {
            return allergie;
        });
        beforeAllergyList.sort();
        afterAllergyList.sort();
        if (beforeAllergyList.length !== afterAllergyList.length) {
            return true;
        }

        const allregyHasChanged = beforeAllergyList.some((bef, index) => !deepEqual(bef, afterAllergyList[index]));
        if (allregyHasChanged) {
            return true;
        }

        const beforeFoodList = checkChild.FoodPreferencesList.map((food) => {
            return food.FoodPrefCode;
        });
        const afterFoodList = child.FoodPreferencesList.results.map((food) => {
            return food.FoodPrefCode;
        });
        beforeFoodList.sort();
        afterFoodList.sort();
        if (beforeFoodList.length !== afterFoodList.length) {
            return true;
        }
        if (!beforeFoodList.every((bef, index) => bef === afterFoodList[index])) {
            return true;
        }
    }

    return (
        associationInfo1 && <>
            <ParentCartHeader prevLevel={prevLevel} title={t('parentStatment')} />
            <VBox className={`${classes.allStatement} ${classes.rtlBox}`} >
                <VBox className={classes.cartTop}>
                    <Tabs
                        value={currentTab}
                        onChange={(e, newValue) => setCurrentTab(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                        className={`${classes.primaryBackground} ${classes.childrenTabs}`}>
                        {results.length !== 1 && <Tab label={t('everyone')} className={classes.tabs} />}
                        {results.length > 0 && results.map((child, i) => (
                            <Tab label={child.Pupil_FirstName} key={i} className={classes.tabs} />
                        ))}
                    </Tabs>
                </VBox>
                <div ref={inputRef}>
                    {results.map((child, i) => (
                        <ChildStatment
                            child={child}
                            products={products}
                            household={household}
                            currentTab={currentTab}
                            foodListApi={associationInfoList?.MasterDataFoodPreferences.results}
                            allergiesListApi={associationInfoList?.MasterDataAllergies.results}
                            diseasesListApi={associationInfoList?.MasterDataDiseases.results}
                            isChildValid={isChildValid}
                            setIsStatmentValid={setIsStatmentValid}
                            length={results.length}
                            clearSignature={clearSignature}
                            openForPdf={openForPdf}
                            index={i}
                            key={i}
                        />
                    ))}
                    <VBox className={classes.bottomPart}>
                        <HBox className={`${classes.aboveSign} ${classes.showOnPrint}`} >
                            <Checkbox checked={agree} onChange={e => setAgree(!agree)}
                                icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                                checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                            />
                            <HBox>
                                <Typography className={classes.readAndAccept} sx={{ fontSize: { xs: "0.8rem", xl: "1rem" } }}>{`${t('termsOfCancellation1')} ${t('termsOfCancellation2')} ${t('readAndAccept')}`}<span className={classes.linkStyle} onClick={e => { setOpenDialog(true) }} style={{ color: '#007f00' }}>{t('termsOfCancellation')}</span></Typography>
                            </HBox>

                            <TermsDialog
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                                termsConfirms={termsConfirms}
                                title={t('termsOfCancellation')}
                                src={process.env.REACT_APP_PARENT_STATEMENT_URL}
                                showButtons={true} />
                        </HBox>
                        {user.role === "tribe" &&
                            <HBox className={`${classes.aboveSign} ${classes.showOnPrint}`} marginTop={"1rem"}>
                                <Checkbox checked={tribeAgree} onChange={e => setTribeAgree(!tribeAgree)}
                                    icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                                    checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                                />
                                <HBox>
                                    <Typography className={classes.readAndAccept} sx={{ fontSize: { xs: "0.8rem", xl: "1rem" } }}>{`${t('tribeStatment')} ${t('tribeStatment1')}`}
                                        <span className={classes.linkStyle} style={{ color: '#007f00' }}>{t('tribeStatment2')}</span>
                                    </Typography>
                                </HBox>
                            </HBox>}
                        <HBox className={classes.clearSignIcon} data-html2canvas-ignore="true">
                            <Tooltip title={t("clerSig")}>
                                <Button onClick={clearSignature} disabled={!isStatmentValid} disableRipple >
                                    <CancelPresentationIcon fontSize='small' sx={{ color: agree && isStatmentValid ? 'red' : 'gray', visibility: { xs: "hidden", lg: "visible" } }} />
                                    <Typography className={classes.linkStyle} sx={{ color: agree && isStatmentValid ? 'red' : 'gray', visibility: { xs: "visible", lg: "hidden" }, display: { lg: "none" } }}>{t("clerSig")}</Typography>
                                </Button>
                            </Tooltip>
                        </HBox>
                        <VBox className={classes.sign}>
                            <FormHelperText error={true} style={{ fontSize: "1rem" }} >{!isStatmentValid ? t('completeStatmentCorrect') : ''}</FormHelperText>
                            <FormHelperText error={true} style={{ fontSize: "1rem" }}>{!agree ? t('agreeToCancelation') : ''}</FormHelperText>
                        </VBox>
                        <VBox className={classes.signatureContainer} sx={{ cursor: agree && isStatmentValid ? "default" : "not-allowed" }}>
                            <SignatureCanvas penColor='black' ref={(ref) => { sigRef = ref }}
                                onEnd={e => setDisabledButton(sigRef.isEmpty())} canvasProps={{ width: 290, height: 190, className: "signatureCanvas" }} />
                            <DriveFileRenameOutlineOutlinedIcon />
                        </VBox>
                        <HBox className={classes.signatureText} >
                            <Typography style={{ color: agree ? '#3a7735' : 'gray' }}>{t('parentSignature')}</Typography>
                        </HBox>
                        {user.role !== "tribe" ? <VBox sx={{ display: "none" }} className={classes.sigDetails} ref={detailsRef} component={"h4"}>
                            <Typography style={{ color: '#3a7735' }}>{`${t('signBy')} ${user.firstName} ${user.lastName} ${t('idNumber')}: ${user.username}`}</Typography>
                            <Typography style={{ color: '#3a7735' }}>{`${t('signInDate')} ${format(new Date(), 'dd/MM/yyyy')}  ${t('signInHour')} ${format(new Date(), 'HH:mm')}`}</Typography>
                        </VBox> :
                            <VBox sx={{ display: "none" }} className={classes.sigDetails} ref={detailsRef} component={"h4"}>
                                <Typography style={{ color: '#3a7735' }}>{`${t('signBy')} ${t('byTribeUser')} ${user.email} `}</Typography>
                                <Typography style={{ color: '#3a7735' }}>{`${t('signInDate')} ${format(new Date(), 'dd/MM/yyyy')}  ${t('signInHour')} ${format(new Date(), 'HH:mm')}`}</Typography>
                            </VBox>
                        }
                    </VBox>
                </div >
            </VBox >
        </>
    );
}
export default ParentStatment;

const getSelectedChildren = (products, household) => {
    const uniqueChildren = new Set();
    const results = [];

    products.forEach(product => {
        if (!uniqueChildren.has(product.Pupil_FirstName)) {
            uniqueChildren.add(product.Pupil_FirstName);
            household.ChildrenList?.results.forEach(child => {
                if (child.LicTradNum === product.LicTradNum) {
                    results.push(child);
                }
            });
        }
    });

    return results;
}