import { useEffect, useState } from 'react';
import appBG from '../../assets/appBG.jpg';
import { Animated } from 'react-animated-css'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, InputAdornment, TextField, IconButton, Container, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useQueryParams, useNavigate } from 'raviger'
import { MessageBox } from '../../Components/Common/MessageBox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import line from '../../assets/line.svg'
import mobileResetPass from '../../assets/mobileResetPass.svg'
import forgotPass from '../../assets/forgotPass.svg'
import zofimMobilePass from '../../assets/zofimMobilePass.svg'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { HBox, VBox } from 'assets/SharedComponents';

const useStyles = makeStyles(() => ({
    backgruond: {
        backgroundImage: `url(${appBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: "100%"
    },
    buttonGreen: {
        color: '#3a7735 !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#ffffff !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        width: "15rem !important",
        border: "1px solid !important",
        marginTop: "1rem !important"
    },
    buttonGreen1: {
        color: '#3a7735 !important',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#ffffff !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        width: "13rem !important",
        border: "1px solid !important",
        marginTop: "1rem !important",
        marginBottom: "1rem !important"
    },
    TextfieldILabelStyle: {
        '& .MuiInputLabel-root': {
            color: '#ffffff !important',
            top: '-3px',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#ffffff  !important',
            top: '-3px',
        },
        '& .Muirtl-1in441m': {
            fontSize: '1.2rem !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.7rem'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#ffffff !important"
        }
    },
    marginBottom1: {
        marginBottom: "0.5rem !important"
    },
    text: {
        color: '#ffffff !important',
        fontWeight: "bold !important",
        fontSize: "0.9rem !important"
    },
    text1: {
        color: '#3a7735  !important',
        fontWeight: "bold !important"
    }
}));


const ResetPasswordPage = () => {
    const classes = useStyles();
    const { t } = useTranslation()
    const urlParams = useQueryParams()
    const navigate = useNavigate()
    const auth = getAuth()
    const [showPassword, setShowPassword] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [messageBoxState, setMessageBox] = useState({ open: false });
    const actionCode = urlParams[0].oobCode;

    useEffect(() => {
        // Get the one-time code from the query parameter.
        const actionCode = urlParams[0].oobCode;
        // (Optional) Get the continue URL from the query parameter if available.
        const continueUrl = urlParams[0].continueUrl;
        // (Optional) Get the language code if available.
        const lang = urlParams[0].lang || 'en';
        handleResetPassword(auth, actionCode, continueUrl, lang);
    }, [])

    const handleResetPassword = (auth, actionCode, continueUrl, lang) => {
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            setEmailAddress(email)
        }).catch((error) => {
            setDisabled(true)
            setMessageBox({
                open: true,
                fullWidth: false,
                maxWidth: "5rem",
                title:
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <ErrorRoundedIcon />
                            <Typography variant="h5" paddingLeft={"1rem"}>
                                {t("note")}
                            </Typography>
                        </Box>
                    </Box >,
                content:
                    <>
                        <Box display="flex" flexDirection="column" textAlign="center">
                            <Typography className={` ${classes.text1}`} > {t("expLink")} </Typography>
                            <Typography className={` ${classes.text1}`} >  {t("expLink1")}</Typography>
                        </Box>
                    </>,
                actions:
                    <>
                        <Button className={classes.buttonGreen1} onClick={e => navigate("/")}>{t("backToLogin1")}</Button>
                    </>
            });
        })
    }
    const validationSchema = yup.object({
        password: yup
            .string(t("password"))
            .required(t("mandatoryField"))
            .min(8, t("correctPasswordPlease"))
            .matches(/(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}\w+/, t("correctPasswordPlease"))
    });
    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await confirmPasswordReset(auth, actionCode, values.password)
                setSuccess(true)
            } catch (error) {

                setDisabled(true)
                navigate("/")
                console.log(error)
            }
        }
    });
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.backgruond}>
            {!success ?
                <Container sx={{ padding: "1.5rem !important", textAlign: "center" }}>
                    <img
                        style={{ paddingLeft: "1.5rem", marginBottom: "1.5rem" }}
                        width="fit-contact"
                        height="auto"
                        alt={"..."}
                        src={forgotPass} />
                    <VBox sx={{ marginBottom: "1.1rem" }}>
                        <Typography className={`${classes.marginBottom1} ${classes.text}`} sx={{ fontSize: "1.5rem !important" }}>{`${t("emailAddPass")}`}</Typography>
                        <Typography className={` ${classes.text}`}>{`${t("emailAddPass1")}`}</Typography>
                        <Typography className={`${classes.marginBottom1} ${classes.text}`}>{`${t("emailAddPass2")} ${emailAddress}`}</Typography>
                    </VBox>
                    <form onSubmit={formik.handleSubmit} style={{ textAlign: "center", display: "inline-block" }}>
                        <HBox>
                            <TextField
                                className={`${classes.marginBottom1} ${classes.TextfieldILabelStyle}`}
                                label={t('choosePassword2')}
                                disabled={disabled}
                                type={showPassword ? "text" : "password"}
                                style={{ width: "100%" }}
                                id="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={(formik.touched.password && formik.errors.password)}
                                variant="outlined"
                                InputProps={{
                                    sx: { color: "#ffffff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={e => setShowPassword(!showPassword)}
                                                onMouseDown={e => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityIcon sx={{ color: "#ffffff" }} /> : <VisibilityOffIcon sx={{ color: "#ffffff" }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </HBox>
                        <FormHelperText style={{ fontSize: "0.8rem", color: "#ffffff" }} > {t("passwordRule1")}</FormHelperText>
                        <FormHelperText style={{ fontSize: "0.8rem", color: "#ffffff" }} > {t("passwordRule2")}</FormHelperText>
                        <Button className={classes.buttonGreen} type="submit" disabled={disabled}>{t("changePassowrd")}</Button>
                    </form>
                </Container>
                : <>
                    <Animated animationIn="fadeIn" animationInDuration={1800} isVisible={true}>
                        <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("passwordChanged")}</Typography>
                    </Animated>
                    <Animated animationIn="slideInUp" animationInDuration={1200} isVisible={true}>
                        <img
                            style={{ marginTop: "1rem", paddingLeft: "1.5rem" }}
                            width="fit-contact"
                            height="auto"
                            alt={"..."}
                            src={mobileResetPass} />
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={800} isVisible={true} >
                        <Animated animationIn="slideInUp" animationInDuration={1200} isVisible={true}>
                            <img
                                style={{ marginTop: "1rem" }}
                                width="fit-contact"
                                height="auto"
                                alt={"..."}
                                src={line} />
                        </Animated>
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={1800} animationInDelay={1801} isVisible={true}>
                        <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("nowGoBack1")}</Typography>
                        <Typography sx={{ color: "#ffffff", fontSize: "1.3rem", textAlign: "center" }}>{t("toBrowser")}</Typography>
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={800} animationInDelay={1201} isVisible={true} >
                        <Animated animationIn="slideInUp" animationInDuration={1200} animationInDelay={1203} isVisible={true}>
                            <img
                                style={{ marginTop: "1rem" }}
                                width="fit-contact"
                                height="auto"
                                alt={"..."}
                                src={zofimMobilePass} />

                        </Animated>
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={800} animationInDelay={1201} isVisible={true} >
                        <Animated animationIn="slideInUp" animationInDuration={1200} animationInDelay={1203} isVisible={true}>
                            <img
                                style={{ marginTop: "1rem" }}
                                width="fit-contact"
                                height="auto"
                                alt={"..."}
                                src={line} />
                        </Animated>
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={1200} animationInDelay={2405} isVisible={true}>
                        <Typography sx={{ color: "#ffffff", fontSize: "1.3rem" }}>{t("loginAgain")}</Typography>
                    </Animated>
                    <Animated animationIn="fadeIn" animationInDuration={700} animationInDelay={3600} isVisible={true} >
                        <Animated animationIn="slideInUp" animationInDuration={1800} animationInDelay={3600} isVisible={true} >
                            <Button className={classes.buttonGreen} onClick={e => navigate("/")}>{t("backToLogin1")}</Button>
                        </Animated>
                    </Animated>

                </>}
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
        </Box>
    )
}
export default ResetPasswordPage;