import { useState, useEffect } from "react";
// router
import { useRoutes, Redirect } from "raviger";
// Mui
import { Box, LinearProgress } from "@mui/material";
import { styled } from '@mui/system';
// components
import { TribeMyFamily } from "Components/TribeComponents/TribeMyFamily";
import TribeSearchFamily from "Components/TribeComponents/TribeSearchFamily";
import { TribeNavBar, TribeTabBar } from "../Components/TribeComponents/TribePageHandler";
import { Cart } from "../Components/Cart/Cart";
import { AddChildren } from "Components/Register/AddChildren";
import { ChildDetailsEditor } from "Components/ParentComponents/MyFamilyComponents/ChildDetailsEditor";
import { ParentsSettings } from "Components/ParentComponents/MyFamilyComponents/ParentsSetting";
import PupilUpdateDrawer from "Components/TribeComponents/PupilUpdateDrawer";
// store
import useGlobal from "../store/store";
// api
import useApi from '../hooks/useApi';
import { userAccessAPI, getFactories, getRegAPI, getTribes } from '../api/api';
import ActivitiesReport from "./ActivitiesReport";
import { Activities } from "./Activities";
//Mock Data
import { TribeIsUpdatedContext } from "context/tribeIsUpdateContext";
import { RegisterContext } from 'context/registerContext';
import PupilsUpdatePage from "./PupilsUpdatePage";
import UnlockActivityTribe from "Components/TribeComponents/UnlockActivityTribe";
import { ActivityRegister } from "./ActivityRegister";
import TribeRefundsPage from "Components/TribeComponents/TribeRefundsPage";

const PageWraper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '100%',
  margin: 'auto !important',
  background: theme.palette.background.paper,
}));

export const TribeHomePage = () => {
  const registrationRequests = useApi(getRegAPI);
  const factoriesRequests = useApi(getFactories);
  const tribesListApi = useApi(getTribes);

  const [tribeUpdate, setUpdated] = useState(0);
  const [linkUpdate, setLinkUpdate] = useState(0);
  const [reqsUpdate, setReqsUpdaet] = useState(0);
  const tribeUpdatedContext = { tribeUpdate, setUpdated, linkUpdate, setLinkUpdate, reqsUpdate, setReqsUpdaet };
  // local state
  const [currentTab, setCurrentTab] = useState(0);
  const [familyUpdated, setFamilyUpdated] = useState(0);
  const [parentTab, setParentTab] = useState(1);
  const [tribe, setTribe] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [allTribes, setAllTribes] = useState([]);
  const [addPupilToFamily, setAddPupilToFamily] = useState({});
  const [addNewFamily, setAddNewFamily] = useState({});
  const [updateParent, setUpdateParent] = useState({});
  const [childrenList, setChildrenList] = useState([]);
  // global state
  const [userAccess, setUserAccess] = useGlobal((state) => state.userAccess, (actions) => actions.setUserAccess);
  const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
  const [user, [removeProduct]] = useGlobal((state) => state.user, (actions) => [actions.removeProduct]);
  const [cartFamily, [clearProducts, setCartFamily]] = useGlobal((state) => state.cartFamily, (actions) => [actions.clearProducts, actions.setCartFamily]);
  const [openActivities, setOpenActivities] = useGlobal((state) => state.openActivities, (actions) => actions.setOpenActivities);
  const [activitiesList, setActivitiesList] = useGlobal((state) => state.activitiesList, (actions) => actions.setActivitiesList);
  const [totalOpenAct, setTotalOpenAct] = useGlobal((state) => state.totalOpenAct, (actions) => actions.setTotalOpenAct);
  const [openRequests, setOpenRequests] = useGlobal((state) => state.openRequests, (actions) => actions.setOpenRequests);
  const [tribePaid, setTribePaid] = useGlobal(state => state.tribePaid, actions => actions.setTribePaid);
  const [unlockActivityDrawer, setUnlockActivityDrawer] = useGlobal((state) => state.unlockActivityDrawer, (actions) => actions.setUnlockActivityDrawer);

  // side effects
  // refresh registarion reqs when user pays for something
  useEffect(() => {
    user?.email && getTribeUser();
  }, [user]);
  useEffect(() => {
    if (tribe) {
      getOpenRequsts();
      getActivities();
    }
    return () => {
      clearProducts();
      setOpenActivities([]);
    }
  }, [tribe]);
  useEffect(() => {
    if (tribe && linkUpdate) {
      getOpenRequsts();
      getActivities();
    }
    return () => {
      clearProducts();
      setOpenActivities([]);
    }
  }, [linkUpdate]);
  useEffect(() => {
    if (tribe && reqsUpdate) {
      getOpenRequsts();
    }
    return () => {
      clearProducts();
      setOpenActivities([]);
    }
  }, [reqsUpdate]);
  useEffect(() => {
    if (isPaid && tribe) {
      getOpenRequsts();
      setTribePaid({ checks: 0, cash: 0, card: 0 });
    }
    return () => {
      clearProducts();
      setOpenRequests([]);
    }
  }, [tribeUpdatedContext.tribeUpdate, isPaid])
  useEffect(() => {
    console.log(childrenList)
  }, [childrenList]);
  // methods
  const getActivities = async () => {
    try {
      const result = await factoriesRequests.requestPromise(tribe.UnitCode);
      setTotalOpenAct(result.totalLength);
      setOpenActivities(result.filterdArray);
      setActivitiesList(result.filteredFactories);
    } catch (error) {
      console.log(error);
    }
  }
  const getOpenRequsts = async () => {
    try {
      const result = await registrationRequests.requestPromise({ TribeCode: tribe.UnitCode, Status_request: { $in: ["2", "3"] } });
      setOpenRequests(result)
      setCartFamily(''); // reset selected family
    } catch (error) {
      console.log(error);
    }

  }
  const getTribeUser = async () => {
    try {
      const data = await userAccessAPI(user.email);
      const allTribeList = await tribesListApi.requestPromise();
      setUserAccess(data.d.results);
      setTribe(data.d.results[0]);
      setAllTribes(allTribeList.d.results);
    } catch (error) {
      console.log(error);
    }
  }

  // nested routes
  const tRoutes =
  {
    '/homepage': () => <Activities factoriesRequests={factoriesRequests} registrationRequests={registrationRequests} getOpenRequsts={getOpenRequsts} tribe={tribe} setChildrenList={setChildrenList} />,
    '/activitiesReport': () => <ActivitiesReport tribe={tribe} factoriesRequests={factoriesRequests} />,
    '/tribeSearch': () => <TribeSearchFamily tribe={tribe} addPupilToFamily={addPupilToFamily} addNewFamily={addNewFamily} setFamily={setFamily} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />,
    '/pupilsUpdatePage': () => <PupilsUpdatePage tribe={tribe} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />,
    '/tribeRefundsPage': () => <TribeRefundsPage tribe={tribe} />,
    '/tribeMyFamily/:familyCardCode': () => <TribeIsUpdatedContext.Provider value={tribeUpdatedContext}><TribeMyFamily fam={family} setParentTab={setParentTab} familyUpdated={familyUpdated} /></TribeIsUpdatedContext.Provider>,
    '/parentUserSettings/:familyCardCode': () => <ParentsSettings fromTribe={true} setParentTab={setParentTab} parentTab={parentTab} fam={family} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />,
    '/addChild/:familyCardCode': () => <RegisterContext.Provider value={setUpdateParent}><AddChildren cardCode={family.CardCode} tribe={true} lastName={family.Parent1_LastName} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} /></RegisterContext.Provider>,
    '/childDetailsEditor/:familyCardCode': () => <ChildDetailsEditor fromTribe={true} setFamilyUpdated={setFamilyUpdated} familyUpdated={familyUpdated} />,
    '/activityRegister': () => <ActivityRegister tribe={tribe} />,
    '*': () => <Redirect to='/tribe/homepage' />
  }

  const tribeRoutes = useRoutes(tRoutes, { basePath: "/tribe" });

  return (
    <PageWraper>
      <TribeIsUpdatedContext.Provider value={tribeUpdatedContext}>
        <Box height="100%">
          {/* top bar */}
          <TribeNavBar tribe={tribe} setTribe={setTribe} tribes={userAccess} setAddPupilToFamily={setAddPupilToFamily} setAddNewFamily={setAddNewFamily} setFamily={setFamily} family={family} />
          <Box display="flex" flexDirection="column" height={'100%'} sx={{ top: { xs: "110px", sm: "105px", xl: "160px" }, position: "relative" }}>
            {/* mid content */}
            {allTribes.length ? <>
              <TribeTabBar currentTab={currentTab} setCurrentTab={setCurrentTab} tribe={tribe} setTribe={setTribe} tribes={userAccess} allTribes={allTribes} />
              {tribeRoutes}
            </>
              : <LinearProgress />}
          </Box>
        </Box>
        <Cart removeProduct={removeProduct} userType={'tribe'} isPaid={isPaid} setIsPaid={setIsPaid} childrenList={childrenList} />
        {unlockActivityDrawer.isOpen && <UnlockActivityTribe />}
        <PupilUpdateDrawer tribe={tribe} familyUpdated={familyUpdated} setFamilyUpdated={setFamilyUpdated} />
      </TribeIsUpdatedContext.Provider>
    </PageWraper>
  );
}