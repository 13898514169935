import { useState, useEffect, useContext } from 'react';
// mui
import { styled } from '@mui/system';
import { Box, CircularProgress, Typography, Tooltip, Button } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// store
import useGlobal from '../../store/store';
// i18n
import { useTranslation } from 'react-i18next';
// api
import { paymentsService } from 'api/api';
import { BalanceContext } from 'context/balanceContext';
import { DiscountContext } from 'context/discountContext';
import TermsDialog from 'Components/Common/TermsDialog';

export const ContinueButton = styled(Button)(({ theme, ...props }) => ({
    alignSelf: 'center',
    fontSize: "1.25rem",
    zIndex: 99,
    boxShadow: 'none !important',
    backgroundColor: props?.disabled ? '#dbdbdb !important' : '#ffbb41',
    borderRadius: "100px",
    margin: "0.5rem",
    width: "17rem",
    color: props?.disabled ? '#a2a2a2 !important' : '#064601',
    "& .MuiSvgIcon-root": {
        fontSize: "1.5em !important",
        color: props?.disabled ? '#a2a2a2 !important' : '#064601'
    },
    ":hover": {
        backgroundColor: '#ffcc68'
    }
}));
export const BottomContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f6f9f6',
    position: 'sticky !important',
    bottom: 0,
    zIndex: 10
}));
export const CashText = styled(Typography)(({ theme, ...props }) => ({
    direction: 'rtl !important',
    fontSize: props.fontSize ? props.fontSize : '1rem',
    margin: '0',
    color: props?.negative
}));
export const AmountColor = styled(Typography)(({ theme, ...props }) => ({
    color: props?.negative
}));

const Balance = (props) => {
    const { nextLevel, disabledButton, productsHasUpdated, statemantUpdater, tribePayment } = props;
    const { t } = useTranslation();
    const [discountDialog, setDiscountDialog] = useState(false);
    const [tribePaidBalance, setTribePaidBalance] = useState(0);

    const [user] = useGlobal(state => state.user);
    const [total] = useGlobal((state) => state.total);
    const [cartFamily] = useGlobal((state) => state.cartFamily);
    const [products, setProducts] = useGlobal(state => state.products, actions => actions.setProducts);
    const [tribePaid, setTribePaid] = useGlobal(state => state.tribePaid, actions => actions.setTribePaid);
    const [finalPrice, setFinalPrice] = useGlobal(state => state.finalPrice, actions => actions.setFinalPrice);

    const { discount, setDiscount } = useContext(DiscountContext);
    const { balance, setBalance } = useContext(BalanceContext);

    const isParent = user.role !== "tribe";
    // calc total
    useEffect(() => {
        let hasCancelled = false,
            calc = total + balance.amount - discount.sum;
        if (calc < 0) {
            calc = 0;
        }
        !hasCancelled && setFinalPrice(+calc.toFixed(2));
        return () => { hasCancelled = true; };
    }, [total, balance, discount])

    // get discount for combined reg requests
    useEffect(() => {
        if (productsHasUpdated.changed) {
            if (products.length) {
                getPaymentData(products, setDiscount, setProducts, cartFamily, setBalance, isParent);
            }
            else {
                setDiscount({ sum: 0 });
                setBalance({ familyId: cartFamily, amount: 0 });
            }
        }
    }, [productsHasUpdated])

    useEffect(() => {
        const tempAmount = Number(tribePaid.cash + tribePaid.card + tribePaid.checks);
        setTribePaidBalance(tempAmount.toFixed(2) || 0);
    }, [tribePaid])

    const textForBalance = balance.amount < 0 ? t('plusBalance') : balance.amount > 0 ? t('minusBalance') : t('evenBalance');
    const negative = balance.amount > 0;

    return (
        <BottomContainer>
            <Box display="flex" justifyContent='space-between' paddingBottom='0.5rem' textAlign={"left"}>
                <Box display="flex" flexDirection='column' paddingLeft='0.5rem' >
                    <Typography color={'#000000'}> {t('sum')} ({products.length} {t('activities')})</Typography>
                    {isParent && <Box display="flex" flexDirection='row' alignItems={"center"}>
                        <Typography color={'#000000'}>{t('discounts')}</Typography>
                        <Tooltip title={t("discountTooltip")}>
                            <InfoOutlinedIcon sx={{ color: "#000000", fontSize: '1rem', marginLeft: "4px", cursor: "pointer" }} onClick={e => setDiscountDialog(true)} />
                        </Tooltip>
                    </Box>}
                    <AmountColor negative={negative ? "#FF0000" : "#000000"} >{textForBalance}</AmountColor>
                    {!isParent && <Typography color='#000000'>{t('paid')}</Typography>}
                </Box>
                <Box display="flex" flexDirection='column' paddingRight={'0.5rem'} alignItems={'end'}>
                    <CashText color={'#000000'}>{"\u20AA"} {total.toFixed(2)} </CashText>
                    {isParent && <CashText color={'#000000'}>{"\u20AA"} {discount.sum} </CashText>}
                    <CashText negative={negative ? "#FF0000" : "#000000"} >{"\u20AA"} {-balance.amount} </CashText>
                    {!isParent && <CashText color={'#000000'}> {"\u20AA"} {tribePaidBalance} </CashText>}
                </Box>
            </Box>
            <Box display="flex" alignItems='center' justifyContent="center">
                <ContinueButton
                    onClick={nextLevel}
                    aria-label="add"
                    disabled={disabledButton}>
                    {`${"\u20AA"}${finalPrice} ${t("continueToPayment")}`}
                    {statemantUpdater || tribePayment ?
                        <CircularProgress size={25} /> :
                        <ArrowBackIosNewIcon />}
                </ContinueButton>
            </Box>
            {discountDialog &&
                <TermsDialog
                    openDialog={discountDialog}
                    setOpenDialog={setDiscountDialog}
                    title={t("discountTitle")}
                    src={process.env.REACT_APP_DISCOUNTS_URL}
                    print={true} />
            }
        </BottomContainer >
    )
}
export default Balance;

const getPaymentData = async (products, setDiscount, setProducts, cartFamily, setBalance, isParent) => {
    const result = await paymentsService({
        registrationRequests: products,
        getPaymentLink: false
    });
    if (isParent) {
        setDiscount({ sum: result.discountResult.discount });
    }
    setProducts(result.discountResult.products);
    setBalance({ familyId: cartFamily, amount: result.balanceAmount });
}