import { useLayoutEffect, useState } from 'react';
import { format } from 'date-fns'
// mui
import { Card, Chip, Box, CardContent, Collapse, IconButton, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
// data
import { permanentInfo } from '../../data/permanentInfo'
import { useTranslation } from 'react-i18next';
import CornerRibbon from 'Components/Common/CornerRibbon';

const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '.5rem',
    marginTop: "0.5rem",
    boxShadow: '0 8px 9px rgb(125 125 125 / 7%)',
    backgroundColor: theme.palette.green.lighter,
    "& .MuiCardContent-root": {
        padding: '0.2rem !important'
    }
}));
const InfoLabel = styled(Chip)(({ theme }) => ({
    "& .MuiChip-label": {
        display: 'flex',
        justifyContent: 'center',
    },
    marginLeft: '0.2rem !important',
    height: '1rem !important',
    color: `${theme.palette.green.darker} !important`,
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.green.darker} !important`
}));
const PriceStyle = styled(Typography)(({ theme, ...props }) => ({
    borderRadius: '2rem',
    padding: '0 1rem',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    color: props.active === 'true' ? null : theme.palette.disable.main,
    backgroundColor: props.active !== 'true' && theme.palette.disable.lighter
}));

const Product = ({ props, onDelete, active, deleteOption, extraButton }) => {
    const [expanded, setExpanded] = useState(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (Number(props.DiscPrice).toFixed(0) - Number(props.FinalPrice).toFixed(0)) {
            setShowDiscount(true);
        }
    }, [])
    return (
        <CardStyle key={props._id}>
            <CardContent>
                {!active && <CornerRibbon style={{
                    padding: '0',
                    width: '5rem',
                    lineHeight: '9px',
                    fontSize: '10px',
                    top: '-4px',
                    left: '5px',
                    zIndex: "1"
                }}
                    containerStyle={{ position: 'relative', overflow: 'inherit', }}
                    position="top-left"
                    fontColor="#ffffff"
                    backgroundColor="gray"
                >
                    {t("registrationClosed")}
                </CornerRibbon>}
                <Box display='flex' marginLeft={'0.75rem'} alignItems={'center'} justifyContent='space-between'>
                    <Box display="flex" flexDirection='column'>
                        <Box display="flex" flexDirection='row'>
                            <Typography variant='responsiveTextSize' color={!active && 'disable.lighter'} >
                                {props.ActivName}
                            </Typography>
                            {props.ItmsGrpCod === "160" && <PriorityHighRoundedIcon sx={{ color: "#3a7735" }} />}
                        </Box>
                        <Link sx={{ cursor: 'pointer' }} onClick={e => setExpanded(!expanded)}>
                            {!expanded ? <Typography variant='responsiveTextSize' color={!active && 'disable.lighter'} >
                                {t('moreDetails')}
                            </Typography>
                                : <Typography variant='responsiveTextSize' color={!active && 'disable.lighter'} >
                                    {t('readLess')}
                                </Typography>}
                        </Link>
                    </Box>
                    <Box display="flex" alignItems={'center'} >
                        {showDiscount ? <><PriceStyle sx={{ marginRight: !active && '2.4rem', textDecoration: "line-through" }} active={active.toString()}>{props.DiscPrice} {"\u20AA"}</PriceStyle>
                            <PriceStyle sx={{ marginRight: !active && '2.4rem' }} active={active.toString()}>{Number(props.FinalPrice).toFixed(2)} {"\u20AA"}</PriceStyle></> :
                            <PriceStyle sx={{ marginRight: !active && '2.4rem' }} active={active.toString()}>{props.DiscPrice} {"\u20AA"}</PriceStyle>}
                        {active && extraButton}
                        {active && deleteOption &&
                            <IconButton size='small'
                                sx={{ color: "#99292e" }}
                                onClick={e => onDelete(props, true)}>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>}
                    </Box>
                </Box>
            </CardContent>
            <Collapse in={expanded} >
                <CardContent >
                    <Box display='flex' flexDirection='column' marginLeft={'1rem'}>
                        <Box display='flex' alignItems={'flex-start'} flexDirection={'column'}>
                            <InfoLabel size="small" label={`${t("classOf")} ${permanentInfo.childClasses[props.Class]}'`} active={active.toString()} />
                            {props.LongActDesc && <Typography fontSize={'0.875rem'} color={!active && 'disable.lighter'}>
                                {props.LongActDesc.slice(0, 16)}
                            </Typography>}
                        </Box>
                        {props.StartDateD &&
                            <Box display='flex' alignItems={'center'}>
                                <EventIcon fontSize='0.9rem' color={!active ? 'disabled' : 'inherit'} />
                                <Typography marginLeft='.25rem' variant="activityTxt" color={!active && 'disable.lighter'}>
                                    {format(new Date(props.StartDateD), 'dd/MM/yyyy')}
                                </Typography>
                            </Box>}
                        {props.Location &&
                            <Box display='flex' alignItems={'center'}>
                                <LocationOnIcon fontSize='0.9rem' color={!active ? 'disabled' : 'inherit'} />
                                <Typography marginLeft='.25rem' variant="activityTxt" fontWeight={'bold'} color={!active && 'disable.lighter'}>
                                    {props.Location}
                                </Typography>
                            </Box>}
                        {!!props.EndRegDate &&
                            <Box display='flex' alignItems={'center'}>
                                <AccessTimeIcon fontSize='0.9rem' color={!active ? 'disabled' : 'error'} />
                                <Typography marginLeft='.25rem' variant="activityTxt" color={!active ? 'disable.lighter' : 'error'}>
                                    {format(new Date(props.EndRegDate), 'dd/MM/yyyy')}
                                </Typography>
                            </Box>}
                    </Box>
                </CardContent>
            </Collapse>
        </CardStyle >
    );
}
export default Product;
